import { getWeightedCIBackgroundColor } from './CrossColumn';

export const AvgDoubleCIColumn = () => {
  // column that shows all the WINS ODDS of the NEXT RACE
  return {
    title: "",
    align: 'center',
    children: [
      {
        title: "D.CI",
        align: 'center',
        key: "ciOverCI",
        width: '2.5%',
        render: (_) => {
          if (_?.doubleStatisticsRow != null) {
            return <></>;
          }
          return (
            <div style={{ backgroundColor: getWeightedCIBackgroundColor(_.weightedDoubleCI?.averageDoubleCi), height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {_.weightedDoubleCI?.averageDoubleCi?.toFixed(2) || "-"}
            </div>
          )
        }
      }
    ],
  };
}