import { gainMapping, statisticsMapping } from "./miscellaneous";

export const horseColumnHandling = (detailData, horseNum, horse, result) => {
  try {
    // mapping horse name, draw num, horse num, weight
    (horse?.horseName) && (detailData.horseName = horse.horseName);
    detailData.horseNum = horseNum;
    (horse?.horseWeight != null) && (detailData.wgt = horse.horseWeight);
    detailData.horseCode = horse?.horseCode ? horse.horseCode : detailData.horseCode;
    detailData.horseHandicapWeight = horse?.handicapWeight ? horse.handicapWeight : detailData.horseHandicapWeight;
    detailData.drawNum = horse?.draw ? horse.draw : detailData.drawNum;
    // map each horse with the first three rank result
    if (result?.horses) {
      const horseResult = result.horses.find(horse => horse.horseNum === horseNum);
      detailData.rank = horseResult?.placing;
      detailData.lengthBehindWinner = horseResult?.lengthBehindWinner;
      detailData.runningPositions = horseResult?.runningPositions;
      detailData.runningDuration = horseResult?.runningDuration;
      detailData.finalWinOdds = horseResult?.finalWinOdds;
    }
  } catch (e) {
    console.error(e);
  }
}

export const jockeyColumnHandling = (detailData, horseNum, horse, jockeyGain, jockeyRankHistory, jtSingleOdds) => {
  try {
    (horse?.jockeyName) && (detailData.jockeyName = horse.jockeyName);
    (horse?.jockeyCode) && (detailData.jockeyCode = horse.jockeyCode);
    gainMapping(detailData.jockeyGain, jockeyGain, horse?.jockeyCode);
    (jockeyRankHistory?.[horse?.jockeyCode]) && (detailData.jockeyRankHistory = jockeyRankHistory[horse.jockeyCode]);
    statisticsMapping(detailData.jockeySingleOdds, jtSingleOdds?.[horseNum]?.jockey);
  } catch (e) {
    console.error(e);
  }
}

export const trainerColumnHandling = (detailData, horseNum, horse, trainerGain, trainerRankHistory, jtSingleOdds) => {
  try {
    (horse?.trainerName) && (detailData.trainerName = horse.trainerName);
    (horse?.trainerCode) && (detailData.trainerCode = horse.trainerCode);
    gainMapping(detailData.trainerGain, trainerGain, horse?.trainerCode);
    (trainerRankHistory?.[horse?.trainerCode]) && (detailData.trainerRankHistory = trainerRankHistory[horse.trainerCode]);
    statisticsMapping(detailData.trainerSingleOdds, jtSingleOdds?.[horseNum]?.trainer);
  } catch (e) {
    console.error(e);
  }
}

export const jtColumnHandling = (detailData, jtCollOdds, horseNum) => {
  try {
    // handle jockey-trainer collab column
    statisticsMapping(detailData.jtCollab, jtCollOdds?.[horseNum]);
  } catch (e) {
    console.error(e);
  }
}

export const futureHorseColumnHandling = (detailData, jockeyFutureHorses, trainerFutureHorses) => {
  try {
    if (detailData?.jockeyCode && jockeyFutureHorses?.[detailData.jockeyCode]) {
      detailData.jockeyFutureHorses = jockeyFutureHorses[detailData.jockeyCode];
    }
    if (detailData?.trainerCode && trainerFutureHorses?.[detailData.trainerCode]) {
      detailData.trainerFutureHorses = trainerFutureHorses[detailData.trainerCode];
    }
  } catch (e) {
    console.error(e)
  }
}
