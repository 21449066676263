import * as Helper from '../../utils/helper'
import { Fraction } from 'fractional';

export const entriesProcessingWS = (state, data) => {
  // prepare and extract all required data
  const races = data.payload.race;
  const totalNumOfRaces = Object.keys(races || {}).length;
  const results = data.payload.result;

  const jockey = data.payload.jockeyProfiles;
  const trainer = data.payload.trainerProfiles;
  const horse = data.payload.horseProfiles;
  const winOdds = data.payload.winOdds;
  const { jockeyAlias, trainerAlias, jtPast6Statistics, jtPast6Gain } = data.payload;

  const pastData = data.payload.pastData;

  if (pastData) {
    for (const [date, data] of Object.entries(pastData)) {
      handlePastRaceData(state, date, data);
    }
  }

  (races && state.additionalData.totalNumOfRaces < totalNumOfRaces) && (state.additionalData.totalNumOfRaces = totalNumOfRaces);
  if (jockey) {
    for (const [jockeyCode, jockeyData] of Object.entries(jockey || [])) {
      for (const race of Object.values(races || [])) {
        if (race.horses.find(horse => horse.jockeyCode === jockeyCode)) {
          state.detailData.jockey[jockeyCode] = jockeyData;
          state.additionalData.jockeyCurrentStatistics[jockeyCode] = { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 }
        }
      }
    }
  }
  if (trainer) {
    for (const [trainerCode, trainerData] of Object.entries(trainer || [])) {
      for (const race of Object.values(races || [])) {
        if (race.horses.find(horse => horse.trainerCode === trainerCode)) {
          state.detailData.trainer[trainerCode] = trainerData;
          state.additionalData.trainerCurrentStatistics[trainerCode] = { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 }
        }
      }
    }
  }
  (horse) && (state.additionalData.horse = horse);
  (jockeyAlias) && (state.additionalData.jockeyAlias = jockeyAlias);
  (trainerAlias) && (state.additionalData.trainerAlias = trainerAlias);

  for (const [raceNum, raceData] of Object.entries(races || {})) {
    state.detailData.race[raceNum] = raceData;
  }

  for (const [raceNum, result] of Object.entries(results || {})) {
    const raceData = state.detailData.race[raceNum]
    if (!raceData) {
      continue;
    }
    result.horses.forEach(({ horseNum, placing }) => {
      const raceHorse = raceData.horses.find(data => data.horseNum === horseNum);
      if (!raceHorse) {
        return;
      }
      raceHorse.placing = placing;
      if (placing === 1) {
        raceHorse.gain = result.dividendOdds.win[horseNum]
      } else if (placing < 4) {
        raceHorse.gain = result.dividendOdds.place[horseNum]
      }
    })
  }

  if (jtPast6Statistics || jtPast6Gain) {
    handleStatistics(state.additionalData, jtPast6Statistics, jtPast6Gain)
  }

  if (winOdds) {
    handleWinOdds(state.detailData, winOdds);
  }

  if (results) {
    handleResultLength(state.detailData, results);
  }

  handleInvestment(state.detailData, state.additionalData);

  state.updateTS = Helper.currentTimeIn24HoursFormat();
}

const handleStatistics = (additionalData, statistics, gainData) => {
  if (!statistics || !gainData) {
    return;
  }
  additionalData.jockeyStatistics = {};
  additionalData.trainerStatistics = {};
  const raceDateList = [...new Set(statistics.map(o => o.raceDate))].sort((a, b) => new Date(b) - new Date(a));

  const mapStatistics = (statistics, raceDate, code, placeNum, totalNum) => {
    const index = raceDateList.indexOf(raceDate) + 1;

    (!statistics[code]) && (statistics[code] = {});
    (!statistics[code][index]) && (statistics[code][index] = { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 });

    const stat = statistics[code][index];
    switch (placeNum) {
      case 1: stat.w += totalNum; break;
      case 2: stat.q += totalNum; break;
      case 3: stat.p += totalNum; break;
      case 4: stat.fourth += totalNum; break;
      default: break;
    }
    if (!additionalData.pastRaceDate.includes(raceDate)) {
      additionalData.pastRaceDate.push(raceDate);
    }
    stat.total += totalNum;
  }

  const mapGain = (statistics, raceDate, code, gain) => {
    const index = raceDateList.indexOf(raceDate) + 1;

    (!statistics[code]) && (statistics[code] = {});
    (!statistics[code][index]) && (statistics[code][index] = { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 });

    statistics[code][index].gain += gain;
  }

  for (const { raceDate, jockeyCode, trainerCode, placeNum, totalNumPlacing } of statistics) {
    mapStatistics(additionalData.jockeyStatistics, raceDate, jockeyCode, placeNum, totalNumPlacing);
    mapStatistics(additionalData.trainerStatistics, raceDate, trainerCode, placeNum, totalNumPlacing);
  }

  for (const { raceDate, jockeyCode, trainerCode, gain } of gainData) {
    mapGain(additionalData.jockeyStatistics, raceDate, jockeyCode, gain);
    mapGain(additionalData.trainerStatistics, raceDate, trainerCode, gain);
  }

  const totalMapping = (statistics) => {
    for (const statisticsEntry of Object.values(statistics)) {
      statisticsEntry.total = Object.values(statisticsEntry).reduce(
        (previousValue, currentValue) => {
          previousValue.w += currentValue.w;
          previousValue.q += currentValue.q;
          previousValue.p += currentValue.p;
          previousValue.fourth += currentValue.fourth;
          previousValue.total += currentValue.total;
          previousValue.gain += currentValue.gain;
          return previousValue;
        },
        { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 }
      )
    }
  }
  totalMapping(additionalData.jockeyStatistics);
  totalMapping(additionalData.trainerStatistics);
}

const handleWinOdds = (detailData, winOdds) => {
  for (const [raceNum, data] of Object.entries(winOdds)) {
    detailData.winOdds[Number(raceNum)] = {};
    for (const [horseNum, odds] of Object.entries(data)) {
      detailData.winOdds[Number(raceNum)][Number(horseNum)] = odds?.latest?.win || null;
    }
  }
}

const handleInvestment = (detailData, additionalData) => {
  const investmentMapping = (investment, code) => {
    if (!(code in additionalData.totalWinInvestment)) {
      additionalData.totalWinInvestment[code] = investment;
    } else {
      additionalData.totalWinInvestment[code] += investment;
    }
  }
  additionalData.totalWinInvestment = {};
  for (const [raceNum, data] of Object.entries(detailData.race)) {
    for (const horse of data.horses) {
      const horseNum = horse.horseNum;
      const winOdds = detailData?.winOdds?.[Number(raceNum)]?.[Number(horseNum)];
      if (winOdds == null){
        continue;
      }
      const investment = 0.825 / winOdds;
      investmentMapping(investment, horse.jockeyCode);
      investmentMapping(investment, horse.trainerCode);
    }
  }
}

const handleResultLength = (detailData, result) => {
  const getFraction = (value) => {
    const integar = Math.trunc(value);
    const fraction = new Fraction(value - integar);
    return [integar, fraction.numerator, fraction.denominator]
  }
  for (const [raceNum, data] of Object.entries(result)) {
    const race = detailData?.race?.[Number(raceNum)];
    if (race == null) {
      continue;
    }
    data.horses.sort((a, b) => a.placing - b.placing);
    const rankList = data.horses.map(horse => {
      return { horseNum: horse.horseNum, placing: horse.placing, lengthBehindWinner: horse.lengthBehindWinner };
    })
    
    rankList.forEach((entry, index) => {
      if (entry.placing === 1) {
        return;
      }
      entry.lengthBehindWinner = entry.lengthBehindWinner - data.horses[index - 1].lengthBehindWinner;
    })

    for (const horse of data.horses) {
      const raceHorse = race.horses.find(entry => entry.horseNum === horse.horseNum);
      raceHorse.lengthBehindWinner = getFraction(horse.lengthBehindWinner);
      raceHorse.lengthBehindPrevious = getFraction(rankList.find(entry => entry.horseNum === horse.horseNum).lengthBehindWinner);
    }
  }
}

const handlePastRaceData = (state, raceDate, data) => {
  state.pastData[raceDate] = data;
  state.pastDerivedData[raceDate] = {};

  const pastData = state.pastData[raceDate];
  const pastDerivedData = state.pastDerivedData[raceDate]
  const investmentMapping = (investment, code) => {
    if (!(code in pastDerivedData.totalWinInvestment)) {
      pastDerivedData.totalWinInvestment[code] = investment;
    } else {
      pastDerivedData.totalWinInvestment[code] += investment;
    }
  }
  pastDerivedData.totalWinInvestment = {};

  for (const data of Object.values(pastData)) {

    for (const horse of data?.race_card?.horses || []) {
      const horseNum = horse.horseNum;
      const winOdds = data?.win_odds?.[Number(horseNum)]?.latest?.win;
      if (winOdds == null){
        continue;
      }
      const investment = 0.825 / winOdds;
      investmentMapping(investment, horse.jockeyCode);
      investmentMapping(investment, horse.trainerCode);
    }
  }
}
