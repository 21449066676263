import { Button, Col, List, Row } from "antd";
import MorningExceriseMatrix from "./MorningExceriseMatrix";
import PastPerformanceRaceTable from "./PastPerformanceRaceTable";

import "./PastPerformanceList.css";
import MorningExceriseDetailList from "./MorningExceriseDetailList";
import { useSelector } from "react-redux";
import NameHighlight from "./NameHighlight";

const openNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const PastPerformanceListHeaderInfo = ({ data }) => {
  const winOddsAll = useSelector(state => state.pastPerformance.winOdds);
  const winOdds = winOddsAll?.[data.horseNum];
  const grade = useSelector(state => state.pastPerformance.morningExercise?.[data.horseCode]?.grade);
  const isLowestOddsHorse = winOdds === Math.min(...Object.values(winOddsAll));

  return (
    <div style={{ padding: "8px" }}>
      <Row>
        <Col span={6} style={{ fontWeight: "bold", fontSize: "18px" }}>
          {data.horseNum}
        </Col>
        <Col span={18} style={{ fontWeight: "bold", fontSize: "18px" }}>
          <NameHighlight value={data.horseName} code={data.horseCode} horseCode={data.horseCode} />
        </Col>
      </Row>
      <Row>
        <Col span={6} style={{ borderStyle: "ridge" }}>
          <span>
            {data.draw}
          </span>
        </Col>
        <Col span={18}>
          <div>
            <NameHighlight value={data.jockeyName} code={data.jockeyCode} horseCode={data.horseCode} needHorseCodeMatch={false} />
          </div>
          <div>
            <NameHighlight value={data.trainerName} code={data.trainerCode} horseCode={data.horseCode} needHorseCodeMatch={false} />
          </div>
        </Col>
      </Row>
      <span>{data.horseWeight}(+{data.handicapWeight - data?.apprenticeAllowance || 0})</span>
      <Button size="small" className="Week-Button Button" onClick={() => openNewTab(data.url)} style={{ fontWeight: "bold", fontSize: "16px" }}>
        {data.horseCode}
      </Button>
      <div style={{ marginTop: "2px" }}>
        <MorningExceriseMatrix horseCode={data.horseCode} startWeek={1} />
        <MorningExceriseMatrix horseCode={data.horseCode} startWeek={7} />
      </div>
      <div>
        <Row>
          <Col span={12}>
            <sup style={{ padding: "2px" }}>win</sup>
            <span style={{ fontSize: "24px", color: isLowestOddsHorse ? "rgb(255, 0, 0)" : "inherit" }}>{winOdds || -1}</span>
          </Col>
          <Col span={12}>
            <span style={{ fontSize: "24px", fontWeight: "bolder" }}>{grade}</span>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const PastPerformanceListEntry = ({ data }) => {
  const dataSource = useSelector(state => state.pastPerformance.pastPerformance[data.horseCode]?.slice() || []);

  return (
    <div style={{ padding: "0 4px" }}>
      <Row>
        <Col span={2} className="Past-Performance-Col-Right">
          <PastPerformanceListHeaderInfo data={data} />
        </Col>
        <Col span={22}>
          <div>
            <PastPerformanceRaceTable dataSource={dataSource} horseCode={data.horseCode} />
          </div>
          <div style={{ margin: "8px 0 0 4px", border: "1px solid rgb(200, 200, 200)" }}>
            <MorningExceriseDetailList horseCode={data.horseCode} horseNum={data.horseNum} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export const PastPerformanceList = () => {
  const dataSource = useSelector(state => Object.values(state.pastPerformance.horseData));
  const pastPerformanceDetails = useSelector(state => state.pastPerformance.pastPerformance || {});
  const { highlightedRaceId } = useSelector(state => state.pastPerformance.additionalData);

  dataSource.sort((a, b) => {
    if (b.horseNum < 0) {
      return -1;
    }
    const matchId = (horseCode) => pastPerformanceDetails?.[horseCode]?.some(data => data.raceId === highlightedRaceId.value);
    if (highlightedRaceId.onClick && matchId(a.horseCode)) {
      if (matchId(b.horseCode)) {
        return a.horseNum - b.horseNum;
      } else {
        return -1;
      }
    }
    return a.horseNum - b.horseNum;
  });

  return (
    <div style={{ width: "100vw" }}>
      <List
        itemLayout="horizontal"
        loading={dataSource.length === 0}
        dataSource={dataSource}
        renderItem={(data) => (
          <List.Item className="Past-Performance-List-Item">
            <PastPerformanceListEntry data={data} />
          </List.Item>
        )}
        className="Past-Performance-List"
      />
    </div>
  )
}