import { Col, Row } from "antd";

const getBackgroundColor = (diff) => {
  if (diff == null) {
    return null;
  }
  if (diff > 9) {
    return "rgb(255, 0, 255)";
  }
}

const WinInvestmentStatisticsColumn = () => {
  return {
    title: "Win\nInvest",
    align: 'center',
    key: `WinInvestment`,
    width: 60,
    render: (_) => {
      const background = getBackgroundColor(_.winInvestmentDiff?.latest);
      const winPercentageJockey = _.winInvestmentDifferenceStatistics?.jockey?.win_percentage != null ? _.winInvestmentDifferenceStatistics.jockey.win_percentage : _.winInvestmentDifferenceStatistics?.winPercentageJockey;
      const winPercentageTrainer = _.winInvestmentDifferenceStatistics?.trainer?.win_percentage != null ? _.winInvestmentDifferenceStatistics.trainer.win_percentage : _.winInvestmentDifferenceStatistics?.winPercentageTrainer;

      return (
        <div>
          <Row>
            <Col span={12} style={{ borderRight: "1px solid rgb(100,100,100)", backgroundColor: background }}>
              {_.winInvestmentDifferenceStatistics?.winOddsGroup || "-"}
            </Col>
            <Col span={12} style={{ backgroundColor: background }}>
              {_.winInvestmentDifferenceStatistics?.investmentDifferenceGroup || "-"}
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
                color: _.winInvestmentDifferenceStatistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: _.winInvestmentDifferenceStatistics?.jockey?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {winPercentageJockey != null ? (winPercentageJockey * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
                color: _.winInvestmentDifferenceStatistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: _.winInvestmentDifferenceStatistics?.trainer?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {winPercentageTrainer != null ? (winPercentageTrainer * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default WinInvestmentStatisticsColumn;