import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { chatDataProcessingWS } from "./handlingWS";

const chatSlice = createSlice({
  name: "chat",
  initialState: initialData(),
  reducers: {
    setChatDataWS: (state, data) => {
      chatDataProcessingWS(state, data);
    },
    appendUserMessage: (state, data) => {
      const {input, currentSection} = data.payload;
      state?.[currentSection].message.push(
        {
          user: {
            payload: input,
            owner: "user",
            updateTs: new Date().toISOString(),
          },
          chatbot: {
            payload: "...",
            owner: "chatbot",
            updateTs: new Date().toISOString(),
          }
        }
      );
      state[currentSection].waitForNewMessage = true;
    }
  }
});

export const {
  setChatDataWS,
  appendUserMessage
} = chatSlice.actions;

export default chatSlice.reducer;