import { Button, Col, Row, Timeline } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setDecisionTreeTimeline } from "../../../services/reducers/standardSlice";

const TimeLineSelector = () => {
  const dispatch = useDispatch();
  const decisionTree = Object.keys(useSelector(state => state.standard.decisionTree.data) || {});
  const raceTime = useSelector(state => state.standard.info.raceTime) || "2023-06-04T16:00:00+08:00";
  const selectedTimeline = useSelector(state => state.standard.decisionTree.timelineSelected) || null;

  decisionTree.sort((a, b) => {
    if (a > b) {
      return -1;
    } else {
      return 1;
    }
  });

  const [selectedTime, setSelectedTime] = useState(selectedTimeline);

  const onClick = (value) => {
    setSelectedTime(value);
    dispatch(setDecisionTreeTimeline(value));
  }

  useEffect(() => {
    setSelectedTime(selectedTimeline);
  }, [selectedTimeline]);

  const getMinuteDelta = (time) => {
    return Math.ceil((new Date(raceTime) - new Date(time)) / 1000 / 60);
  };

  const TimeLineItem = ({ value, raceTime }) => {
    const minuteDelta = getMinuteDelta(value);
    return (
      <Timeline.Item color={value === selectedTime ? "red" : "blue"}>
        <Button 
          size={"small"} 
          onClick={() => onClick(value)}
          style={{
            backgroundColor: value === selectedTime ? "rgb(220, 220, 220)" : "transparent",
          }}
        >
          {value} ({minuteDelta > 0 ? "+" : ""}{minuteDelta})
        </Button>
      </Timeline.Item>
    )
  }

  const getMinuteSnapshot = (targetMinuteSnapshot) => {
    const result = decisionTree.find(key => {
      return getMinuteDelta(key) === targetMinuteSnapshot;
    });
    if (result == null) {
      return getMinuteSnapshot(targetMinuteSnapshot - 1);
    }
    return result;
  }

  return (
    <div style={{ height: "45vh" }}>
      <h2>TimeLine Selector</h2>
      <div style={{ marginBottom: "2px" }}>
        Current Snapshot: <span style={{ fontWeight: "bold" }}>{selectedTime}</span>
      </div>
      <div style={{ marginBottom: "2px" }}>
        <Row>
          <Col span={8} offset={4}>
            <Button size={"small"} onClick={() => onClick(decisionTree[0])}>Latest</Button>
          </Col>
          <Col span={8}>
            <Button size={"small"} onClick={() => onClick(getMinuteSnapshot(0))}>Zero Mintue</Button>
          </Col>
        </Row>
      </div>
      <div style={{ maxHeight: "35vh", overflowY: "auto" }}>
        <Timeline style={{ padding: "10px 20px", textAlign: "left" }}>
          {decisionTree.map(key => <TimeLineItem value={key} raceTime={raceTime} />)}
        </Timeline>
      </div>
    </div>
  )
}

export default TimeLineSelector;