import { Table } from 'antd';
import { useSelector } from 'react-redux';

const GainTable = ({ dataSource, highlighted }) => {
  const winPlaceGainLimit = useSelector(state => state.drawer.standardCardDrawer.winPlaceGainLimit);

  const TableColumn = [
    {
      align: 'center',
      className: [highlighted],
      key: 'gain',
      width: '50%',
      render: (_) => (
        <div className={_.multiply >= winPlaceGainLimit ? "Gain-Data" : ""} style={{ textAlign: "center" }}>{_.multiply}</div>
      )
    },
    {
      align: 'center',
      className: [highlighted],
      key: 'additionGain',
      width: '50%',
      render: (_) => (
        <div style={{ textAlign: "center" }}>{_.addition}</div>
      )
    }
  ]
  return (
    <div style={{ padding: "2px", paddingLeft: "4px", paddingRight: "4px" }}>
      <Table
        pagination={false}
        dataSource={[dataSource]}
        columns={TableColumn}
        style={{ width: "100%", height: "24px" }}
        className="Gain-Table"
        showHeader={false}
        rowKey={(record, index) => index}
      />
    </div>
  );
}

export default GainTable;