import NameHighlight from "../../NameHighlight"

const TwoLayerHorseColumns = (horseColumnHeader1, horseColumnHeader2, horseColumnKey1, horseColumnKey2, mainHorseCode) => {
  return [
    {
      title: horseColumnHeader1,
      align: 'center',
      width: 120,
      children: [
        {
          title: horseColumnHeader2,
          align: 'center',
          key: 'horseName',
          width: 80,
          render: (_) => {
            const key1 = _?.[horseColumnKey1];
            const key2 = _?.[horseColumnKey2];
            return (
              <div>
                <div className="Border-Bottom"><NameHighlight value={key1?.horseName} code={key1?.horseCode} horseCode={key1?.horseCode} /></div>
                <div><NameHighlight value={key2?.horseName} code={key2?.horseCode} horseCode={key2?.horseCode} /></div>
              </div>
            )
          }
        }
      ]
    },
    {
      title: "J",
      align: 'center',
      key: 'jockey',
      width: 30,
      render: (_) => {
        const key1 = _?.[horseColumnKey1];
        const key2 = _?.[horseColumnKey2];
        return (
          <div>
            <div className="Border-Bottom"><NameHighlight value={key1?.jockeyName} code={key1?.jockeyCode} horseCode={key1?.horseCode} needHorseCodeMatch={mainHorseCode !== key1?.horseCode} /></div>
            <div><NameHighlight value={key2?.jockeyName} code={key2?.jockeyCode} horseCode={key2?.horseCode} needHorseCodeMatch={mainHorseCode !== key2?.horseCode} /></div>
          </div>
        )
      }
    },
    {
      title: "T",
      align: 'center',
      key: 'trainer',
      width: 30,
      render: (_) => {
        const key1 = _?.[horseColumnKey1];
        const key2 = _?.[horseColumnKey2];
        return (
          <div>
            <div className="Border-Bottom"><NameHighlight value={key1?.trainerName} code={key1?.trainerCode} horseCode={key1?.horseCode} needHorseCodeMatch={mainHorseCode !== key1?.trainerCode} /></div>
            <div><NameHighlight value={key2?.trainerName} code={key2?.trainerCode} horseCode={key2?.horseCode} needHorseCodeMatch={mainHorseCode !== key2?.trainerCode} /></div>
          </div>
        )
      }
    },
    {
      title: "odds",
      align: 'center',
      key: 'odds',
      width: 40,
      render: (_) => {
        const key1 = _?.[horseColumnKey1];
        const key2 = _?.[horseColumnKey2];
        return (
          <div>
            <div className="Border-Bottom">{key1?.winOdds?.toFixed(key1?.winOdds > 10 ? 0 : 1)}</div>
            <div>{key2?.winOdds?.toFixed(key2?.winOdds > 10 ? 0 : 1)}</div>
          </div>
        )
      }
    },
    {
      title: "CI",
      align: 'center',
      key: 'ci',
      width: 50,
      render: (_) => {
        const key1 = _?.[horseColumnKey1];
        const key2 = _?.[horseColumnKey2];
        return (
          <div>
            <div className="Border-Bottom">{key1?.ci?.toFixed(2)}</div>
            <div>{key2?.ci?.toFixed(2)}</div>
          </div>
        )
      }
    },
  ]
}

export default TwoLayerHorseColumns;
