export const initialData = () => {
  return {
    generic: initialSectionData(),
    betline: initialSectionData(),
  }
}

const initialSectionData = () => {
  return {
    message: [
      // {
      //   user: {
      //     payload: "Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you?",
      //     owner: "user",
      //     updateTs: new Date().toISOString(),
      //   },
      //   chatbot: {
      //     payload: "Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you? Hello, Who are you?",
      //     owner: "chatbot",
      //     updateTs: new Date().toISOString(),
      //   },
      // }
    ],
    waitForNewMessage: false,
  }
}
