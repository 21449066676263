// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/media/img/al-bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BG{\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 100vh;\n  background-size: 800vh auto;\n  opacity: 0.42;\n  z-index: -2;\n  width: 100vw;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.BG-Overlay{\n  background: linear-gradient(to right,rgba(0, 0, 0, 1),rgba(84, 84, 84, 0.7));\n  opacity: 0.98;\n  height: 100vh;\n  width: 100vw;\n  z-index: -1;\n  position: absolute;\n  top:0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/GeneralUI/BG.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;EACzD,aAAa;EACb,2BAA2B;EAC3B,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT;;AAEA;EACE,4EAA4E;EAC5E,aAAa;EACb,aAAa;EACb,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,KAAK;EACL,OAAO;AACT","sourcesContent":[".BG{\n  background-image: url('../../assets/media/img/al-bg.jpg');\n  height: 100vh;\n  background-size: 800vh auto;\n  opacity: 0.42;\n  z-index: -2;\n  width: 100vw;\n  position: absolute;\n  top: 0;\n  left: 0;\n}\n\n.BG-Overlay{\n  background: linear-gradient(to right,rgba(0, 0, 0, 1),rgba(84, 84, 84, 0.7));\n  opacity: 0.98;\n  height: 100vh;\n  width: 100vw;\n  z-index: -1;\n  position: absolute;\n  top:0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
