import { useState } from 'react';
import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine } from 'recharts';
import { getAxisStroke, getGridStroke } from '../../../../services/utils/chartHelper';


const mapping = (value) => {
  if (value <= 3.5) {
    return { min: 0, max: 4 };
  } else if (value <= 8) {
    return { min: 2, max: 9 };
  } else if (value <= 17) {
    return { min: 7, max: 18 };
  } else if (value > 17) {
    return { min: 16, max: dataMax => dataMax * 1.1 >= 99 ? dataMax : Math.ceil(dataMax * 1.1) };
  }
}

export const getZeroMinute = (raceTime, updateTsRaw) => {
  if (!raceTime || !updateTsRaw) {
    return;
  }
  // Calculate the time difference in milliseconds
  const timeDiff = Math.abs(new Date(raceTime).getTime() - new Date(updateTsRaw).getTime());

  // Convert milliseconds to minutes
  const minutesDiff = Math.floor(timeDiff / (1000 * 60));
  return minutesDiff;
}

const WinChart = ({ data, range, showRange }) => {
  const theme = useSelector(state => state.general.theme);
  const { raceTime, updateTsRaw } = useSelector(state => state.standard.info);

  const { hideCILine, hidePoolRatioLine, hideWinLine, hideQWLine } = useSelector(state => state.drawer.standardCardDrawer.hideLine);

  const [strokeWidth, setStrokeWidth] = useState(1.5);

  const zeroMinute = getZeroMinute(raceTime, updateTsRaw);

  return (
    <ResponsiveContainer width='100%' height='100%' maxHeight='100%'>
      <LineChart data={data} margin={{ top: 2, right: 0, left: 5, bottom: 6 }} onMouseEnter={() => setStrokeWidth(3)} onMouseLeave={() => setStrokeWidth(1.5)}>
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          height={8}
          dataKey="minute"
          domain={range}
          ticks={showRange}
          tick={{ fontSize: "8px" }}
          stroke={getAxisStroke(theme)}
        />
        <YAxis
          width={10}
          domain={[
            dataMin => dataMin !== Infinity ? mapping(dataMin)?.min : null,
            dataMax => {
              if (dataMax === -Infinity) {
                return null;
              }
              return dataMax > 17 ? mapping(dataMax)?.max(dataMax) : mapping(dataMax)?.max;
            }
          ]}
          tick={{ fontSize: "8px", dx: 5, dy: -3.5 }}
          yAxisId="1"
          interval="preserveStartEnd"
          allowDecimals={false}
          allowDataOverflow={true}
          stroke={getAxisStroke(theme)}
        />
        <YAxis
          orientation="right"
          interval={"preserveStartEnd"}
          domain={[0, 2]}
          width={10}
          tick={{ fontSize: "8px", dx: -5, dy: -3.5 }}
          yAxisId="2"
          allowDecimals={false}
          stroke={getAxisStroke(theme)}
        />
        {!hideCILine && <Line type="monotone" yAxisId="2" dataKey="ci" dot={false} stroke="rgb(66, 255, 56)" strokeWidth={1.5} />}
        {!hidePoolRatioLine && <Line type="monotone" yAxisId="1" dataKey="expectedWinOdds" dot={false} stroke="rgb(255, 0, 255)" strokeWidth={1.5} />}
        {!hideWinLine && <Line type="monotone" yAxisId="1" dataKey="win" dot={false} stroke="rgb(0, 0, 255)" strokeWidth={1.5} />}
        {!hideQWLine && <Line type="monotone" yAxisId="1" dataKey="qw" dot={false} stroke="rgb(255,120,0)" strokeWidth={strokeWidth} />}
        {(zeroMinute && zeroMinute > 0) && 
          <ReferenceLine x={zeroMinute * -1} yAxisId="1" stroke="rgba(0, 0, 0, 0.2)" strokeWidth={1} />
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WinChart;