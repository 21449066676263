import { createSlice } from "@reduxjs/toolkit";
import * as Helper from '../utils/helper';

const initialData = {
  updateTS: Helper.currentTimeIn24HoursFormat(),
  additionalData: {
    totalNumOfHorses: 1,
    fetchData: true
  },
  code: {
    1: {
      jockey: "CCC",
      trainer: "TTT"
    }
  },
  detailData: {
    1: {
      horseNum: 1,
      graphData: {},
    }
  },
  aliasData: {
    jockey: null,
    trainer: null
  }
};

const winSlice = createSlice({
  name: "win",
  initialState: initialData,
  reducers: {
    setWinData: (state, data) => {
      try {
        const race = data.payload.race;
        const winOddsAll = data.payload.winOdds;
        const qwOddsAll = data.payload.qwOdds;
        const poolRatioAll = data.payload.poolRatioAll;
        const ciAll = data.payload.ciAll;
        const jockeyAlias = data.payload.jockeyAlias;
        const trainerAlias = data.payload.trainerAlias;

        // mapping with jockey and trainer alias name dictionary
        (jockeyAlias) && (state.aliasData.jockey = jockeyAlias);
        (trainerAlias) && (state.aliasData.trainer = trainerAlias);
        // get total num of horses
        (race?.info?.totalNumOfHorses) && (state.additionalData.totalNumOfHorses = race.info.totalNumOfHorses);

        // handle win line chart data
        for (let i = 1; i <= state.additionalData.totalNumOfHorses; i++) {
          // if no horse data exists in memory, initialize it
          if (!state.detailData?.[i]) {
            state.detailData[i] = {
              horseNum: i,
              graphData: generateWinGraphData(-30, 0),
            }
            state.code[i] = {}
          }
          const detailData = state.detailData[i];
          const winOdds = winOddsAll?.[i] ? winOddsAll[i].slice(30) : [];
          const qwOdds = qwOddsAll?.[i] ? qwOddsAll[i] : [];
          const ci = ciAll?.[i] ? ciAll[i] : [];
          const poolRatio = poolRatioAll ? poolRatioAll : [];

          // map each value of api data into win graph data
          for (let j = 0; j <= 30; j++) {
            detailData.graphData[j].win = winOdds?.[j];
            detailData.graphData[j].qw = qwOdds?.[j];
            detailData.graphData[j].ci = ci?.[j];
            detailData.graphData[j].poolRatio = poolRatio?.[j];
          }
          // mapping each jockey code and trainer code
          if (race?.horses?.[i]?.jockeyCode) {
            state.code[i].jockey = race.horses[i].jockeyCode;
          }
          if (race?.horses?.[i]?.trainerCode) {
            state.code[i].trainer = race.horses[i].trainerCode;
          }
        }

        state.updateTS = Helper.currentTimeIn24HoursFormat();

        // check if the race is finished at least 1 hour before and required data exists
        const timeInterval = Math.floor((new Date() - new Date(data.payload.race?.info?.raceTime || 0)) / 60000);
        state.additionalData.fetchData = timeInterval < 60;
        Object.values(data.payload).forEach((data) => state.additionalData.fetchData = (state.additionalData.fetchData || (data == null)));
      } catch (e) {
        console.error(e);
      }
    },
    setWinDataWS: (state, data) => {
      try {
        const race = data.payload.race;
        const winOddsAll = data.payload.winOddsAll;
        const qwOddsAll = data.payload.qw;
        const poolRatioAll = data.payload.poolRatioAll;
        const ciAll = data.payload.ciAll;
        const jockeyAlias = data.payload.jockeyAlias;
        const trainerAlias = data.payload.trainerAlias;

        // mapping with jockey and trainer alias name dictionary
        (jockeyAlias) && (state.aliasData.jockey = jockeyAlias);
        (trainerAlias) && (state.aliasData.trainer = trainerAlias);
        // get total num of horses
        (race?.horses) && (state.additionalData.totalNumOfHorses = Math.max(...race?.horses.map(horse => horse.horseNum)));

        // handle win line chart data
        for (let i = 1; i <= state.additionalData.totalNumOfHorses; i++) {
          // if no horse data exists in memory, initialize it
          if (!state.detailData?.[i]) {
            state.detailData[i] = {
              horseNum: i,
              graphData: generateWinGraphData(-30, 0),
            }
            state.code[i] = {}
          }
          const horse = race?.horses?.find(horse => horse.horseNum === i)
          const detailData = state.detailData[i];
          const winOdds = winOddsAll?.[i]?.slice(30);
          const qwOdds = qwOddsAll?.[i];
          const ci = ciAll?.[i];

          // map each value of api data into win graph data
          for (let j = 0; j <= 30; j++) {
            (winOdds?.[j]) && (detailData.graphData[j].win = winOdds[j]);
            (qwOdds?.[j]) && (detailData.graphData[j].qw = qwOdds[j]);
            (ci?.[j]) && (detailData.graphData[j].ci = ci[j]);
            (poolRatioAll?.[j]) && (detailData.graphData[j].poolRatio = poolRatioAll[j]);
          }
          // mapping each jockey code and trainer code
          if (horse?.jockeyCode) {
            state.code[i].jockey = horse.jockeyCode;
          }
          if (horse?.trainerCode) {
            state.code[i].trainer = horse.trainerCode;
          }
        }

        state.updateTS = Helper.currentTimeIn24HoursFormat();

        // check if the race is finished at least 1 hour before and required data exists
        const timeInterval = Math.floor((new Date() - new Date(data.payload.race?.info?.raceTime || 0)) / 60000);
        state.additionalData.fetchData = timeInterval < 60;
        Object.values(data.payload).forEach((data) => state.additionalData.fetchData = (state.additionalData.fetchData || (data == null)));
      } catch (e) {
        console.error(e);
      }
    },
    removeWinData: (state) => {
      // reset all data
      state.additionalData = {
        totalNumOfHorses: 0,
        fetchData: true
      }
      state.code = {};
      state.detailData = {};
    }
  }
});

const generateWinGraphData = (minStart, minEnd) => {
  // generate empty value entries in minute range for win graph
  const result = [];
  for (let i = minStart; i <= minEnd; i++) {
    result.push({ minute: i, win: null, qw: null, ci: null, poolRatio: null });
  }
  return result;
}

export const { setWinData, setWinDataWS, removeWinData } = winSlice.actions;

export default winSlice.reducer;