import { Table } from "antd";
import { useSelector } from "react-redux";

import { InvestmentColumn } from "./InvestmentColumn";
import { RaceDateColumn } from "./RaceDateColumn";
import moment from 'moment';
import { RaceColumn } from "./RaceColumn";
import { StatisticColumn } from "./StatisticColumn";

const JTPastModalTable = ({ showJockey = true, code }) => {
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const additionalData = useSelector(state => state.entries.additionalData);
  const statisticsData = additionalData[showJockey ? "jockeyStatistics" : "trainerStatistics"];

  const dataSource = Object.entries(useSelector(state => state.entries.pastDerivedData))
    .map(d => { return { raceDate: d[0], totalWinInvestment: d[1].totalWinInvestment[code], code: code } });

  dataSource.push({ raceDate: selectedRaceDate, totalWinInvestment: additionalData?.totalWinInvestment?.[code], code: code });
  dataSource.sort((a, b) => moment(new Date(b.raceDate)).unix() - moment(new Date(a.raceDate)).unix())

  const columns = [
    RaceDateColumn(),
    StatisticColumn(showJockey, statisticsData),
    InvestmentColumn(),
    ...RaceColumn(showJockey),
  ];

  return (
    <div style={{ padding: 10, width: "99%"}}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        className="Entries-Table"
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.raceDate}
      />
    </div>
  );
}

export default JTPastModalTable;
