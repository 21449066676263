import { Button } from "antd"
import { useEffect, useRef, useState } from "react";
import chatbotIcon from "../../assets/media/img/chatbot-icon.png";
import ChatbotSection from "./ChatbotSection";
import "./index.css";


const ChatbotButton = () => {
  const [open, setOpen] = useState(true);
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: "fixed", bottom: 0, right: 0, zIndex: 12990 }}>
      <div style={{ padding: "8px" }} ref={popupRef}>
        <ChatbotSection open={open} setOpen={setOpen} />
        <Button
          className="Button"
          style={{ zIndex: 1301, height: "40px" }}
          size="small"
          onClick={() => setOpen(!open)}
        >
          <img
            src={chatbotIcon}
            alt={`Chatbot`}
            style={{ height: "30px", width: "30px", zIndex: 1301 }}
          />
        </Button>
      </div>
    </div>
  )
}

export default ChatbotButton;