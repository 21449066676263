import React from "react";
import { AccInfo } from "../components/AccManagement/AccInfo";
import { AccSider } from "../components/AccManagement/AccSider";
import { ChangePassword } from "../components/AccManagement/ChangePassword";
import { Layout } from "antd";

export const AccManagement = ({pageIndex}) => {

  return (
    <Layout>
      {/* the antd nav bar on the left */}
      <AccSider selectedKey = {pageIndex}/>
      <div
        style={{
          verticalAlign: "middle",
          lineHeight: "30px",
          minHeight: "91.5vh",
          paddingTop: "0px",
          paddingLeft: "200px",
        }}
      >
        {/* pageIndex decides whether to show AccInfo or ChangePassword component*/}
        {pageIndex === '1' && <AccInfo/>}
        {pageIndex === '2' && <ChangePassword/>}
      </div>
    </Layout>
  );
};
export default AccManagement;
