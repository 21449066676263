import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HorseNameColumn from "../../common/HorseNameColumn";
import HorseNumColumn from "../../common/HorseNumColumn";

const getBorderStyle = (drawNum) => {
  switch (drawNum) {
    case 1:
      return "3px solid rgb(255, 0, 0)";
    case 2:
      return "3px solid rgb(0, 255, 0)";
    case 3:
      return "3px solid rgb(0, 0, 255)";
    case 4:
      return "3px solid rgb(222, 255, 0)";
    default:
      return "";
  }
};

export const HorseColumn = (sortedInfo, additionalData, rebornData) => {
  const { t } = useTranslation();
  const highlightHorse =
    useSelector(
      (state) => state.drawer.standardCardDrawer.highlightColumn.highlightHorse
    ) && "Highlighted-Column";
  const hideHorse =
    useSelector(
      (state) => state.drawer.standardCardDrawer.hideColumn.hideHorse
    ) && "Hidden-Column";
  const winPoolDistributionGameMap = additionalData.winPoolDistributionGameMap;

  // column that shows HORSE INFO
  return {
    title: t("race::horseHeader"),
    className: [highlightHorse, hideHorse],
    align: "center",
    children: [
      {
        title: t("race::nameHeader"),
        className: [highlightHorse, hideHorse],
        align: "center",
        children: [
          HorseNumColumn(sortedInfo, 80, rebornData),
          HorseNameColumn(sortedInfo, winPoolDistributionGameMap, 80),
          {
            // column of horses' draw numbers
            title: (
              <div style={{ fontSize: "10px" }}>{t("race::drawHeader")}</div>
            ),
            className: [highlightHorse, hideHorse],
            align: "center",
            key: "drawNum",
            width: 40,
            sorter: {
              compare: (a, b) => a.drawNum - b.drawNum,
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(
              ({ columnKey }) => columnKey === "drawNum"
            )?.["order"],
            render: (_) => [
              <div
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  border: getBorderStyle(_.rank),
                }}
                key={_.drawNum}
              >
                {_.drawNum}
              </div>,
            ],
          },
        ],
      },
    ],
  };
};
