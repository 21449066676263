import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Icon from "@ant-design/icons";
import firstRankIcon from "../../../assets/media/img/first_rank_icon_alt.png";
import secondRankIcon from "../../../assets/media/img/second_rank_icon_alt.png";
import thirdRankIcon from "../../../assets/media/img/third_rank_icon_alt.png";
import fourthRankIcon from "../../../assets/media/img/fourth_rank_icon_alt.png";

const rankIconMapping = {
  1: firstRankIcon,
  2: secondRankIcon,
  3: thirdRankIcon,
  4: fourthRankIcon,
};

// mapping between medal icons and horse race results
const getRankIcon = (rank) => {
  if (rankIconMapping?.[rank] == null) {
    return;
  }
  return (
    <Icon
      component={() => (
        <img
          src={rankIconMapping?.[rank]}
          alt={`Rank_[${rank}]`}
          style={{ height: "24px", width: "24px" }}
          key={rank}
        />
      )}
    />
  );
};

const getJockeySuitByHorseCode = (horseCode) => {
  return (
    horseCode != null && (
      <img
        src={`https://racing.hkjc.com/racing/content/Images/RaceColor/${horseCode}.gif`}
        alt=""
      ></img>
    )
  );
};

const getBorderStyle = (rank) => {
  switch (rank) {
    case 1:
      return "3px solid rgb(255, 0, 0)";
    case 2:
      return "3px solid rgb(0, 255, 0)";
    case 3:
      return "3px solid rgb(0, 0, 255)";
    case 4:
      return "3px solid rgb(222, 255, 0)";
    default:
      return "";
  }
};

const highlightedStyle = {
  0: {
    textDecoration: "underline",
  },
  1: {
    backgroundColor: "rgba(255, 255, 0, 1)",
    color: "rgba(255, 0, 0, 1)",
    textDecoration: "underline",
  },
  3: {
    backgroundColor: "rgba(255, 165, 0, 1)",
    color: "rgba(0, 0, 0, 0.7)",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  deselect: { textDecoration: "line-through" },
};

const HorseNumColumn = (sortedInfo, width = "5.7%", rebornData) => {
  const reborn = rebornData?.[3]?.reborn || [];
  const reborn_1 = rebornData?.[3]?.reborn1 || [];
  const reborn_3 = rebornData?.[3]?.reborn3 || [];

  const getHighlightStyle = (horseNum) => {
    let result;
    if (reborn.length > 0 && reborn.includes(horseNum)) {
      result = highlightedStyle[0];
    }
    if (reborn_1.length > 0 && reborn_1.includes(horseNum)) {
      result = highlightedStyle[1];
    }
    if (reborn_3.length > 0 && reborn_3.includes(horseNum)) {
      result = highlightedStyle[3];
    }
    return result;
  };

  const { t } = useTranslation();

  return {
    // column of medal icons (if any), and horse numbers
    title: t("race::raceNumHeader"),
    align: "center",
    key: "horseNum",
    width: width,
    sorter: {
      compare: (a, b) => a.horseNum - b.horseNum,
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === "horseNum")?.[
      "order"
    ],
    render: (_) => {
      return (
        <Row>
          <Col span={5}>
            <Row>{getRankIcon(_.rank)}</Row>
            {rebornData?.[3]?.reborn.includes(_.horseNum) && (
              <Row
                style={{
                  ...(getHighlightStyle(_.horseNum) || {}),
                  paddingLeft: "6px",
                  fontWeight: "bold",
                }}
              >
                R
              </Row>
            )}
          </Col>
          <Col span={10}>
            <div
              style={{
                textAlign: "center",
                fontSize: 26,
                fontWeight: "bold",
                border: getBorderStyle(_.rank),
                borderRadius: "32px",
              }}
            >
              {_.horseNum}
            </div>
          </Col>
          <Col span={9}>{getJockeySuitByHorseCode(_.horseCode)}</Col>
        </Row>
      );
    },
  };
};

export default HorseNumColumn;
