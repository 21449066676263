import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { entriesProcessingWS } from "./handlingWS";

const entriesSlice = createSlice({
  name: "entries",
  initialState: initialData(),
  reducers: {
    setEntriesDataWS: (state, data) => {
      entriesProcessingWS(state, data);
    },
    removeEntriesData: (state) => {
      // reset all data
      state.detailData = {
        race: {},
        jockey: {},
        trainer: {},
        winOdds: {}
      };
      state.additionalData = {
        horse: {},
        jockeyStatistics: {},
        jockeyCurrentStatistics:{},
        trainerStatistics: {},
        trainerCurrentStatistics:{},
        jockeyAlias: {},
        trainerAlias: {},
        totalNumOfRaces: 0,
        totalWinInvestment: {},
        pastRaceDate: [],
      };
      state.pastData = {};
      state.pastDerivedData = {};
    }
  }
});

export const { setEntriesDataWS, removeEntriesData } = entriesSlice.actions;

export default entriesSlice.reducer;