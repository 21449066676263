import Keycloak from 'keycloak-js'

const keycloakConfig = {
   url: 'https://auth.agileexlab.net/auth/', 
   realm: 'HKJC-Data-Platform', 
   clientId: 'race-ui'
}

const keycloak = new Keycloak(keycloakConfig);

export default keycloak