import { setDoubleDataWS } from '../../reducers/doubleSlice';

export const handleDoubleCardEvent = (payload, raceDate, doubleNum) => async (dispatch) => {
  try {
    if (!raceDate || !doubleNum) {
      return
    }
    await dispatch(setDoubleDataWS(payload));
  } catch (error) {
    console.error(error.message);
  }
}
