import { Col, Row } from "antd";
import { useSelector } from "react-redux";

import "./JTProfile.css";

const DISPLAY_NULL_VALUE = "--"

const JTProfile = ({ type, code }) => {
  const profile = useSelector(state => state?.jtInfo?.[type]?.profile?.[code]);
  const statistics = useSelector(state => state?.jtInfo?.[type]?.statistics?.[code]);

  const stake = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'HKD',
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: 0
  }).format(statistics?.seasonWinAmount);

  return (
    <div>
      <Row>
        <Col span={20}>
          <div style={{ textAlign: "left", fontSize: "16px", border: "2px ridge rgb(120, 120, 255)", padding: "4px" }}>
            <Row>
              <Col span={4} style={{ borderRight: "2px ridge rgb(120, 120, 255)" }}>
                <div style={{ border: "3px double rgb(120, 120, 255)", height: "160px", width: "140px" }}>
                  {code && <img
                    src={`https://racing.hkjc.com/racing/content/Images/${type}/${code}.jpg`}
                    alt={code}
                    style={{ padding: "6px", height: "150px", width: "130px" }}
                  />}
                </div>
                <div><b>Name: </b>{profile?.name || DISPLAY_NULL_VALUE}</div>
                <div><b>Age: </b>{profile?.age || DISPLAY_NULL_VALUE}</div>
                <div><b>Nationality: </b>{profile?.nationality || DISPLAY_NULL_VALUE}</div>
              </Col>
              <Col span={20}>
                <Row>
                  <Col span={5} style={{ margin: "4px", borderRight: "2px ridge rgb(120, 120, 255)" }}>
                    <div><b>Stakes won: </b>{statistics?.seasonWinAmount ? stake : DISPLAY_NULL_VALUE}</div>
                    <div><b>Past 10 race day Wins: </b>{statistics?.season1StCountLast10Days || DISPLAY_NULL_VALUE}</div>
                    <div><b>Total races: </b>{statistics?.seasonRunCount || DISPLAY_NULL_VALUE}</div>
                    <div><b>Win %: </b>{statistics?.season1StRatio ? `${(statistics.season1StRatio * 100).toFixed(2)}%` : DISPLAY_NULL_VALUE}</div><br />
                    <div><b>No. of Wins: </b>{statistics?.season1StCount || DISPLAY_NULL_VALUE}</div>
                    <div><b>No. of 2nds: </b>{statistics?.season2NdCount || DISPLAY_NULL_VALUE}</div>
                    <div><b>No. of 3rds: </b>{statistics?.season3RdCount || DISPLAY_NULL_VALUE}</div>
                    <div><b>No. of 4ths: </b>{statistics?.season4ThCount || DISPLAY_NULL_VALUE}</div>
                  </Col>
                  <Col span={17} offset={1}>
                    <div style={{ height: "240px", overflowY: "auto" }}>
                      <div><b>Background: </b>{profile?.background || DISPLAY_NULL_VALUE}</div>
                      <div><b>Achievements: </b>{profile?.achievements || DISPLAY_NULL_VALUE}</div>
                      <div><b>Notable Wins: </b>{profile?.notableWins || DISPLAY_NULL_VALUE}</div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default JTProfile;
