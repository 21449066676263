import React from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next"
import { Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import BG from "../GeneralUI/BG";
import sampleProfile from "../../assets/media/img/sample-profile.jpg"

import "./AccountManagement.css";

export const AccInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // layout of the AccInfo page
  return (
    <div className="Management-Container">
      <BG />

      <img
        src={sampleProfile}
        style={{ width: "200px", height: "200px", borderRadius: "50%", padding: "20px" }}
        alt=""
      />

      <Input className="Login-Input" placeholder={t("nickname")} prefix={<UserOutlined />} />
      <Input className="Login-Input" placeholder={t("email")} prefix={<UserOutlined />} />

      <Button className="Login-Input Login-Button" type="primary" onClick={() => navigate("/")}>
        {t("save")}
      </Button>
    </div>
  );
}

