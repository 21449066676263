import { Table } from "antd";
import { useSelector } from "react-redux";
import IdHighlight from "./IdHighlight";

import "./PastPerformanceRaceIdTable.css"

const RACE_ID_COLOR_LIST = [
  { background: "rgba(255, 0, 0, 0.5)" },
  { background: "rgba(0, 255, 0, 0.5)" },
  { background: "rgba(0, 0, 255, 0.5)" },
  { background: "rgba(255, 255, 0, 0.5)" },
  { background: "rgba(255, 0, 255, 0.5)" },
  { background: "rgba(0, 255, 255, 0.5)" },
  { background: "rgba(128, 128, 128, 0.5)" },
]

const getColumns = (horseData, data, raceIdSet) => {
  const columns = [{
    align: 'center',
    key: "dummy",
    width: 4,
    fixed: "left"
  }];
  for (const horseNum of Object.keys(horseData)) {
    const pastPerformance = data?.[horseData[horseNum].horseCode];
    columns.push(
      {
        title: horseNum,
        align: 'center',
        key: horseNum,
        width: 80,
        render: (_, _2, index) => {
          const raceIdColorIndex = raceIdSet.indexOf(pastPerformance?.[index]?.raceId);

          if (raceIdColorIndex < 0) {
            return <IdHighlight value={pastPerformance?.[index]?.raceId} />
          } else {
            return <div style={RACE_ID_COLOR_LIST[raceIdColorIndex]} >
              <IdHighlight value={pastPerformance?.[index]?.raceId} />
            </div >
          }
        }
      }
    )
  }
  return columns;
}

const PastPerformanceRaceIdTable = () => {
  const horseData = useSelector(state => state.pastPerformance.horseData);
  const data = useSelector(state => state.pastPerformance.pastPerformance);

  const raceIdList = {};
  Object.values(data).forEach((horse) => horse?.forEach(past => {
    if (past.raceId in raceIdList) {
      raceIdList[past.raceId] += 1;
    } else {
      raceIdList[past.raceId] = 1;
    }
  }))
  const raceIdSet = Object.entries(raceIdList).filter(([_, value]) => value > 1).map(([date, _]) => date);

  const columns = getColumns(horseData, data, raceIdSet);
  const dataSource = [{ key: 0 }, { key: 1 }, { key: 2 }];

  return (
    <Table
      pagination={false}
      loading={false}
      dataSource={dataSource}
      columns={columns}
      className="Past-Performance-Race-Id-Table"
      scroll={{ x: 'max-content' }}
      rowKey={(record) => record.key}
    />
  )
}

export default PastPerformanceRaceIdTable
