import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setWinPlaceGainLimit, setHideInfo, setHideChart, setHideColumn, setHighlightColumn, setHideLine } from '../../services/reducers/drawerSlice';
import { Checkbox, InputNumber, Divider } from 'antd';
import '../../App.css'
// import { ColorPicker } from './ColorPicker';

export const StandardCardDrawerItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const standardCardDrawer = useSelector(state => state.drawer.standardCardDrawer)

  const { hideRaceTitle, hideRacePicker, hideResetButton, hideRaceInfo } = standardCardDrawer.hideInfo;
  const { hideForecastQuinellaSignalGraph, hideForecastTable, hideDoubleSignalGraph } = standardCardDrawer.hideChart;
  const { hideCILine, hidePoolRatioLine, hideWinLine, hideQWLine } = standardCardDrawer.hideLine;
  const { hideForecast, hideWin, hideDerivative, hideHorse, hideJockey, hideTrainer, hideJTFuture, hideJT, hideVelocity } = standardCardDrawer.hideColumn;
  const { highlightForecast, highlightWin, highlightDerivative, highlightHorse, highlightJockey, highlightTrainer, highlightJTFuture, highlightJT, highlightVelocity } = standardCardDrawer.highlightColumn;
  const winPlaceGainLimit = standardCardDrawer.winPlaceGainLimit;

  const hideInfo = (e) => {
    dispatch(setHideInfo([e.target.id, e.target.checked]));
  }
  const hideChart = (e) => {
    dispatch(setHideChart([e.target.id, e.target.checked]));
  }
  const hideLine = (e) => {
    dispatch(setHideLine([e.target.id, e.target.checked]));
  }
  const hideColumn = (e) => {
    dispatch(setHideColumn([e.target.id, e.target.checked]));
  }
  const highlightColumn = (e) => {
    dispatch(setHighlightColumn([e.target.id, e.target.checked]));
  }

  const onWinPlaceGainInput = (e) => {
    dispatch(setWinPlaceGainLimit(e.target.value));
  };

  return (
    <>
      {/* <div><ColorPicker/></div> */}
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideHeadersTitle")}</p>
        <Checkbox id='hideRaceTitle' onChange={hideInfo} checked={hideRaceTitle} className="Drawer-Items-Checkbox">{t("setting::hideRaceTitle")}</Checkbox>
        <Checkbox id='hideRacePicker' onChange={hideInfo} checked={hideRacePicker} className="Drawer-Items-Checkbox">{t("setting::hideRacePicker")}</Checkbox>
        <Checkbox id='hideResetButton' onChange={hideInfo} checked={hideResetButton} className="Drawer-Items-Checkbox">{t("setting::hideResetButton")}</Checkbox>
        <Checkbox id='hideRaceInfo' onChange={hideInfo} checked={hideRaceInfo} className="Drawer-Items-Checkbox">{t("setting::hideRaceInfo")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideChartsTitle")}</p>
        <Checkbox id='hideForecastQuinellaSignalGraph' onChange={hideChart} checked={hideForecastQuinellaSignalGraph} className="Drawer-Items-Checkbox">{t("setting::hideForecastQuinellaSignalGraph")}</Checkbox>
        <Checkbox id='hideForecastTable' onChange={hideChart} checked={hideForecastTable} className="Drawer-Items-Checkbox">{t("setting::hideForecastTable")}</Checkbox>
        <Checkbox id='hideDoubleSignalGraph' onChange={hideChart} checked={hideDoubleSignalGraph} className="Drawer-Items-Checkbox">{t("setting::hideDoubleSignalGraph")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideLinesTitle")}</p>
        <Checkbox id='hideCILine' onChange={hideLine} checked={hideCILine} className="Drawer-Items-Checkbox">{t("setting::hideCILine")}</Checkbox>
        <Checkbox id='hidePoolRatioLine' onChange={hideLine} checked={hidePoolRatioLine} className="Drawer-Items-Checkbox">{t("setting::hidePoolRatioLine")}</Checkbox>
        <Checkbox id='hideWinLine' onChange={hideLine} checked={hideWinLine} className="Drawer-Items-Checkbox">{t("setting::hideWinLine")}</Checkbox>
        <Checkbox id='hideQWLine' onChange={hideLine} checked={hideQWLine} className="Drawer-Items-Checkbox">{t("setting::hideQWLine")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideColumnsTitle")}</p>
        <Checkbox id='hideForecast' onChange={hideColumn} checked={hideForecast} className="Drawer-Items-Checkbox">{t("setting::hideForecast")}</Checkbox>
        <Checkbox id='hideWin' onChange={hideColumn} checked={hideWin} className="Drawer-Items-Checkbox">{t("setting::hideWin")}</Checkbox>
        <Checkbox id='hideDerivative' onChange={hideColumn} checked={hideDerivative} className="Drawer-Items-Checkbox">{t("setting::hideDerivative")}</Checkbox>
        <Checkbox id='hideHorse' onChange={hideColumn} checked={hideHorse} className="Drawer-Items-Checkbox">{t("setting::hideHorse")}</Checkbox>
        <Checkbox id='hideJockey' onChange={hideColumn} checked={hideJockey} className="Drawer-Items-Checkbox">{t("setting::hideJockey")}</Checkbox>
        <Checkbox id='hideTrainer' onChange={hideColumn} checked={hideTrainer} className="Drawer-Items-Checkbox">{t("setting::hideTrainer")}</Checkbox>
        <Checkbox id='hideJTFuture' onChange={hideColumn} checked={hideJTFuture} className="Drawer-Items-Checkbox">{t("setting::hideJTFuture")}</Checkbox>
        <Checkbox id='hideJT' onChange={hideColumn} checked={hideJT} className="Drawer-Items-Checkbox">{t("setting::hideJT")}</Checkbox>
        <Checkbox id='hideVelocity' onChange={hideColumn} checked={hideVelocity} className="Drawer-Items-Checkbox">{t("setting::hideVelocity")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::highlightColumnsTitle")}</p>
        <Checkbox id='highlightForecast' onChange={highlightColumn} checked={highlightForecast} className="Drawer-Items-Checkbox">{t("setting::highlightForecast")}</Checkbox>
        <Checkbox id='highlightWin' onChange={highlightColumn} checked={highlightWin} className="Drawer-Items-Checkbox">{t("setting::highlightWin")}</Checkbox>
        <Checkbox id='highlightDerivative' onChange={highlightColumn} checked={highlightDerivative} className="Drawer-Items-Checkbox">{t("setting::highlightDerivative")}</Checkbox>
        <Checkbox id='highlightHorse' onChange={highlightColumn} checked={highlightHorse} className="Drawer-Items-Checkbox">{t("setting::highlightHorse")}</Checkbox>
        <Checkbox id='highlightJockey' onChange={highlightColumn} checked={highlightJockey} className="Drawer-Items-Checkbox">{t("setting::highlightJockey")}</Checkbox>
        <Checkbox id='highlightTrainer' onChange={highlightColumn} checked={highlightTrainer} className="Drawer-Items-Checkbox">{t("setting::highlightTrainer")}</Checkbox>
        <Checkbox id='highlightJTFuture' onChange={highlightColumn} checked={highlightJTFuture} className="Drawer-Items-Checkbox">{t("setting::highlightJTFuture")}</Checkbox>
        <Checkbox id='highlightJT' onChange={highlightColumn} checked={highlightJT} className="Drawer-Items-Checkbox">{t("setting::highlightJT")}</Checkbox>
        <Checkbox id='highlightVelocity' onChange={highlightColumn} checked={highlightVelocity} className="Drawer-Items-Checkbox">{t("setting::highlightVelocity")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::setWPGainLimit")} (&gt;=0):</p>
        <InputNumber defaultValue={100} onBlur={onWinPlaceGainInput} onPressEnter={onWinPlaceGainInput} controls={false} value={winPlaceGainLimit} />
      </div>
    </>
  )
}