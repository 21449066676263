import { Col, Row, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedUser } from "../../../services/reducers/standardSlice";

const UserSelector = ({ defaultValue, targetIndex }) => {
  const dispatch = useDispatch();

  const userData = useSelector(state => state.standard.userData.otherUserData) || {};
  const options = Object.entries(userData).map(([userId, userData]) => { return { value: userId, label: userData?.user_name } });

  const onChange = (id) => {
    dispatch(changeSelectedUser({ targetIndex, id }));
  }

  return (
    <div style={{ margin: "8px" }}>
      <Row>
        <Col span={4} style={{ marginRight: "0px", fontSize: "20px"}}>
          {targetIndex === 0 ? "L" : "R"}:
        </Col>
        <Col span={19} offset={1}>
          <Select
            defaultValue={defaultValue}
            style={{
              width: 100,
            }}
            options={options}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  );
}

export default UserSelector;
