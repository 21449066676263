import { Table } from "antd";
import { MatrixTable } from "../standard/subTables/MartrixTable";

const MatrixIndexTable = () => {
  const TableColumn = [
    {
      align: 'center',
      key: 'index',
      width: '8.5%',
      render: (_) => <span>I<sub style={{ fontSize: "8px" }}>10</sub></span>
    },
    {
      align: 'center',
      key: 'index',
      width: '8.5%',
      render: (_) => <span>I<sub style={{ fontSize: "8px" }}>5</sub></span>
    },
    {
      align: 'center',
      key: 'index',
      width: '8.5%',
      render: (_) => <span>I</span>
    },
    {
      align: 'center',
      key: '1',
      width: '14.0%',
      render: (_) => <span>W</span>
    },
    {
      align: 'center',
      key: '2',
      width: '14.0%',
      render: (_) => <span>Q</span>
    },
    {
      align: 'center',
      key: '3',
      width: '14.0%',
      render: (_) => <span>P</span>
    },
    {
      align: 'center',
      key: '4',
      width: '14.0%',
      render: (_) => <span>4</span>
    },
    {
      align: 'center',
      key: '5',
      width: '18.5%',
      render: (_) => <span>T</span>
    }
  ];
  return (
    <div style={{ padding: "2px" }}>
      <Table
        pagination={false}
        dataSource={[1]}
        columns={TableColumn}
        style={{ width: "100%", height: "22px" }}
        className="Matrix-Header-Table"
        showHeader={false}
        rowKey={(record)=>record}
      />
    </div>
  );
}

const WinMatrixColumn = (width="11.6%") => {
  return {
    // first part of win signal graph column
    title: () => MatrixIndexTable(),
    align: 'center',
    width: width,
    onCell: (_) => {
      return { style: { zIndex: 1000 } }
    },
    render: (_) => [
      <MatrixTable dataSource={_} key={_.horseNum} />
    ],
  }
}

export default WinMatrixColumn;