import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, message } from "antd";
import { useState } from "react";

const RecommendationLLMPrompt = ({ data, minute }) => {
  const getTime = (key) => {
    if (!(data?.gpt4?.[key] || data?.top3?.[key] || data?.next5?.[key]))
      return "--";
    const time = new Date(
      data?.gpt4?.[key] || data?.top3?.[key] || data?.next5?.[key]
    );
    const year = time.getFullYear();
    const month = ("0" + (time.getMonth() + 1)).slice(-2); // Add leading zero if necessary
    const day = ("0" + time.getDate()).slice(-2); // Add leading zero if necessary

    // Get the hour, minute, and second components of the date
    const hour = ("0" + time.getHours()).slice(-2); // Add leading zero if necessary
    const minute = ("0" + time.getMinutes()).slice(-2); // Add leading zero if necessary
    const second = ("0" + time.getSeconds()).slice(-2); // Add leading zero if necessary

    // Concatenate the components into a string with the desired format
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const [open, setOpen] = useState(false);
  let command = data?.command_0min?.command || "none";
  let prompt = Object.entries(data?.gpt4?.prompt || {});
  //add top3 and next5 prompt to prompt
  prompt = prompt.concat(Object.entries(data?.top3?.prompt || {}));
  prompt = prompt.concat(Object.entries(data?.next5?.prompt || {}));
  prompt = prompt.concat(Object.entries(data?.others?.prompt || {}));

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onClick = (text) => {
    navigator.clipboard.writeText(text);
    message.open({
      key: "updateable",
      type: "success",
      content: `Copy to clipboard`,
      duration: 1.5,
    });
  };

  return (
    <div>
      <Button
        icon={<EditOutlined />}
        onClick={showModal}
        className="Button"
        hidden={prompt.length === 0}
      />
      <Modal
        title={
          <>
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                fontSize: "30px",
              }}
            >
              Recommendation LLM Prompt ({minute} minute)
            </div>

            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                fontSize: "20px",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "12px",
                  color: "rgb(120, 120, 120)",
                }}
              >
                LLM: {getTime("updateTs")}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "rgb(120, 120, 120)",
                }}
              >
                UI: {getTime("updateTsUI")}
              </div>
              Click to copy
            </div>
            {minute === 0 ? (
              command.length !== 0 ? (
                <Button
                  onClick={() => onClick(command)}
                  style={{
                    padding: "0px 5px",
                    fontSize: "15px",
                    marginTop: "10px",
                  }}
                >
                  Command
                </Button>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"1100px"}
      >
        <div>
          {prompt.map(([key, value]) => {
            return (
              <div className="Recommendation-LLM-Prompt">
                <Row>
                  <Col span={2}>
                    <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                      {key}:{" "}
                    </span>
                  </Col>
                  <Col>
                    <div className="Recommendation-LLM-Detail-Answer">
                      <Button
                        onClick={() => onClick(value)}
                        style={{
                          padding: "0px 10px",
                          fontSize: "20px",
                        }}
                      >
                        {key}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr
                  style={{
                    width: "95%",
                    marginBottom: "20px",
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default RecommendationLLMPrompt;
