import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRaceDateAndRaceNum } from '../../services/actions/raceAction';
import { setRaceNum } from '../../services/reducers/raceSlice'
import { Button, Spin } from 'antd';

export const RaceNumButtons = ({ useDouble = false }) => {
  const dispatch = useDispatch();

  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);
  const raceNumList = useSelector(state => {
    const total = state.race.raceDateWithRaceNum[selectedRaceDate];
    if (total == null && state.auth.userData?.resource_access?.["race-ui"]?.roles?.includes("admin") ){
      return Array.from({ length: 1 }, (_, i) => i + 1);
    }
    return Array.from({ length: useDouble ? total - 1 : total }, (_, i) => i + 1);
  });

  useEffect(() => {
    dispatch(loadRaceDateAndRaceNum());
  }, [dispatch]);

  const handleRaceNumChange = (e) => {
    dispatch(setRaceNum(Number(e)));
  }

  return (
    raceNumList.length === 0 ? <Spin style={{ marginLeft: "6px", marginTop: "4px" }} /> :
      <span>
        {raceNumList.map((raceNum) =>
          <Button
            size='small'
            style={{ width: "35px", height: "30px", marginLeft: "4px", marginRight: "4px" }}
            value={raceNum}
            className={raceNum === Number(selectedRaceNum) ? "Button-Primary" : "Button"}
            onClick={() => handleRaceNumChange(raceNum)}
            type={raceNum === Number(selectedRaceNum) ? "primary" : "default"}
            key={raceNum}
          >{raceNum}</Button>
        )}
      </span>
  )
}