import { Button, Col, Row } from "antd";
import { useSelector } from "react-redux";
import { getJockeyCodeByAlias, getJockeyDataByName, getTrainerCodeByAlias, getTrainerDataByName } from "../../services/utils/hJTHelper";
import NameHighlight from "./NameHighlight";

const openNewTab = (url, newWindow=false) => {
  if (url) {
    window.open(url, '_blank', newWindow ? "'location=yes,height=700,width=1000,scrollbars=yes,status=yes'" : "noopener,noreferrer");
  }
}

const MorningExceriseDetailList = ({ horseCode, horseNum }) => {
  const dataSource = useSelector(state => state.pastPerformance.morningExercise?.[horseCode]?.details);

  const mapping = (data) => {
    if (data == null) {
      return <></>;
    }
    if (data.type === "試閘") {
      const { workout } = data;
      const section = workout?.["section_durations"];
      const profileData = getJockeyDataByName(workout.jockey) || getTrainerDataByName(workout.jockey);
      const profileCode = profileData?.code || getJockeyCodeByAlias(workout.jockey) || getTrainerCodeByAlias(workout.jockey);
      return (
        <div>
          <div>
            <span style={{ borderStyle: "ridge" }}>{new Date(data.date).getDate()}</span>
            <Button className="Week-Button Button" size="small" onClick={() => openNewTab(data?.workout?.batch_url)}>
              <span style={{ fontWeight: "bold" }}>試</span>
            </Button>
            
            <span><NameHighlight value={profileData?.alias || workout.jockey} code={profileCode} horseCode={horseCode} needHorseCodeMatch={false} /> {workout.duration}</span> 
          </div>
          <div>
            <span className={section[section.length - 1] <= 23.0 ? "Morning-Exercise-Matrix-Highlight" : ""}>{section?.join(", ")}</span>
          </div>
        </div>
      );
    }
    if (data.type === "快操") {
      const { workout } = data;
      const jockey = (workout.jockey === "助手" || workout.jockey === "副練馬師") ? workout.jockey[0] : workout.jockey;
      const profileData = getJockeyDataByName(jockey) || getTrainerDataByName(jockey);
      const profileCode = profileData?.code || getJockeyCodeByAlias(jockey) || getTrainerCodeByAlias(jockey);
      return (
        <div>
          <div>
            <span style={{ borderStyle: "ridge" }}>{new Date(data.date).getDate()}</span>
            <span style={{ borderRight: "1px solid rgb(150, 150, 150)", fontWeight: "bold" }}>快</span>
            <span><NameHighlight value={profileData?.alias || jockey} code={profileCode} horseCode={horseCode} needHorseCodeMatch={false} /> {workout.duration}</span> 
          </div>
          <div>
            <span>{workout?.["section_durations"]?.join(", ")}</span>
          </div>
        </div>
      );
    }
    if (data.type === "出賽") {
      const { workout } = data;
      const profileData = getJockeyDataByName(workout.jockey) || getTrainerDataByName(workout.jockey);
      const profileCode = profileData?.code || getJockeyCodeByAlias(workout.jockey) || getTrainerCodeByAlias(workout.jockey);
      return (
        <div>
          <div>
            <span style={{ borderStyle: "ridge" }}>{new Date(data.date).getDate()}</span>
            <Button className="Week-Button Button" size="small" style={{ fontWeight: "bold" }} onClick={() => openNewTab(workout?.race_url, true)}>
              賽
            </Button>
            <span><NameHighlight value={profileData?.alias || workout.jockey} code={profileCode} horseCode={horseCode} needHorseCodeMatch={false} /> {workout.placing}</span> 
          </div>
          <div>
            <span>{workout.distance}</span>
          </div>
        </div>
      );
    }
    return (
      <div>
        <span></span>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[0])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[1])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[2])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[3])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[4])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[5])
          }
        </Col>
        <Col span={3} className="Past-Performance-Col-Right">
          {
            mapping(dataSource?.[6])
          }
        </Col>
        <Col span={3}>
          {
            mapping(dataSource?.[7])
          }
        </Col>
      </Row>
    </div>
  )
}

export default MorningExceriseDetailList;
