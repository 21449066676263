import { JockeyTrainerColumn } from "../../common/JockeyTrainerColumn";


const JockeyTrainerColumnOverride = () => {
  const column = JockeyTrainerColumn();
  column.children[0].children[0].width = 30;
  return column;
}

export default JockeyTrainerColumnOverride;
