export const initialData = () => {
  return {
    horseData: {
      A123: {
        horseNum: 1,
        horseName: "威威鬥士",
        drawNum: 1,
        jockeyName: "雷",
        jockeyCode: "ABC",
        trainerName: "韋",
        trainerCode: "ABC",
        weight: 1234,
        handcapWeight: 123
      }
    },
    pastPerformance: {
      A123: [
        {
          week: 3.5,
          placeNum: 5,
          venue: "田",
          length: 1650,
          drawNum: 8,
          jockeyName: "雷",
          jockeyCode: "ABC",
          trainerName: "韋",
          trainerCode: "ABC",
          horseWeight: 1013,
          handicapWeight: 133,
          sectionPositions: [1, 2, 5, 6, 8, 9],
          finishTime: "1.08.41",
          lengthBehindWinner: 1.25,
          winOdds: [10, 6.6, 7.4, 10],
          ci: 0.98,
          poolRatio: 1.21,
          gameMap: [0, 3, 5, 6],
          first: {
            horse: "威威鬥士",
            jockeyName: "薛",
            jockeyCode: "ABC",
            trainerName: "呂",
            trainerCode: "ABC",
            odds: 6.1,
            ci: 1.03,
          },
          second: {},
          third: {},
          favored: {},
          starters: {
            1: {
              jockeyName: "薛",
              jockeyCode: "ABC",
              trainerName: "呂",
              trainerCode: "ABC",
              odds: 7.5,
              ci: 0.92
            },
          }
        }
      ]
    },
    morningExercise: {
      A123: {
        twelveWeeks: {},
        details: {},
        grade: ""
      }
    },
    additionalData: {
      highlightedCode: {
        value: "A123",
        horseCode: "A123",
        onClick: false
      },
      highlightedRaceId: {
        value: "",
        onClick: false
      },
    },
    info: {
      winPoolAmount: -1, updatets: '-1', raceTime: null, raceStartTime: '', venue: 'N/A', track: 'N/A', class: 'N/A', course: 'N/A', distance: 'N/A'
    },
    winOdds: {
      1: 10
    }
  }
}