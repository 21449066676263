import { useState } from 'react';
import html2canvas from 'html2canvas';

function useReactScreenshot() {
  const [screenshot, setScreenshot] = useState(null);

  const getScreenshot = async (ref, options = {}) => {
    const canvas = await html2canvas(ref.current, {...options, useCORS: true, allowTaint: true});
    const data = canvas.toDataURL();
    setScreenshot(data);
    return data;
  };

  return { screenshot, getScreenshot };
}

export default useReactScreenshot;
