import { Tabs } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import keycloak from "../services/keycloak";
import EntriesTable from "../components/Entries/EntriesTable";
import { Indicator } from "../components/Entries/Indicator";
import LastUpdate from "../components/RaceCard/LastUpdate";
import { RaceSelector } from "../components/RaceCard/RaceSelector";
import { handleEntriesEvent } from "../services/actions/raceAction/entries";
import { removeEntriesData } from "../services/reducers/entriesSlice";
import useWebSocket from "../services/utils/useWebSocket";

import "./Entries.css"
import API_URL_DOMAIN from "../middleware/BaseURL";
import JTColTable from "../components/Entries/JTColTable";

const Entries = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();

  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);

  const websocketURL = `wss://${API_URL_DOMAIN}/ui/v1/ws/entries`;

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      authorization: `${keycloak.token}`
    },
    allowInitialize: selectedRaceDate != null,
    onMessage: payload => dispatch(handleEntriesEvent(payload, selectedRaceDate)),
    websocketName: "entries page",
  })

  // remove last race data when change the selected race date
  useEffect(() => {
    dispatch(removeEntriesData());
  }, [dispatch, selectedRaceDate]);

  useEffect(() => {
    sendMessage({ "race_date": selectedRaceDate });
  }, [sendMessage, selectedRaceDate]);

  return (
    <div>
      <div style={{ paddingTop: '16px', zIndex: 10 }}>
        <div style={{ display: "flex" }}>
          <LastUpdate target="entries" />
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{t("entries")}</span>
        </div>
        <RaceSelector showRaceNumSelector={false} />
        <Indicator />
      </div>

      <Tabs defaultActiveKey="1" type="card" size='large' className='Entries-Tabs'>
        <Tabs.TabPane tab={t("entries::jockeyHeader")} key="1">
          <EntriesTable showJockey={true} sendMessage={sendMessage} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("entries::trainerHeader")} key="2">
          <EntriesTable showJockey={false} sendMessage={sendMessage} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("entries::jTHeader")} key="3">
          <JTColTable />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab={"J/T Win Trend"} key="4">
          
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
}

export default Entries;
