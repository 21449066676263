import DoubleChart from "./DoubleChart";
import { HoverTable } from "./HoverTable"
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

export const getBackgroundColor = (rank) => {
  if (rank === 1) {
    return "rgb(255, 140, 0)";
  } else if (rank === 2) {
    return "rgb(0, 255, 0)";
  } else if (rank === 3) {
    return "rgb(115, 215, 255)";
  }
}

const getDoubleCIBackgroundColor = (ci) => {
  if (ci >= 1.6) {
    return "rgba(255, 0, 0, 0.8)";
  } else if (ci >= 1.2) {
    return "rgba(115, 215, 255, 0.6)";
  } else if (ci >= 0.8) {
    return "rgba(0, 128, 0, 0.5)";
  } else if (ci >= 0) {
    return "rgba(255, 156, 0, 0.6)";
  }
}

export const getWeightedCIBackgroundColor = (ci, transparent=true) => {
  if (ci >= 1.0) {
    return `rgba(255, 0, 0, ${transparent ? 0.8 : 1})`;
  } else if (ci >= 0.85) {
    return `rgba(115, 215, 255, ${transparent ? 0.6 : 1})`;
  } else if (ci >= 0.7) {
    return `rgba(0, 128, 0, ${transparent ? 0.5 : 1})`;
  } else if (ci >= 0) {
    return `rgba(255, 156, 0, ${transparent ? 0.6 : 1})`;
  }
  return "";
}

export const CrossColumn = (doubleData, doubleCurrentCode, aliasData, currentOddsLatest) => {
  const columns = [];
  const doubleCardDrawer = useSelector(state => state.drawer.doubleCardDrawer);
  const currentWeightedDoubleCI = useSelector(state => state.double.currentWeightedDoubleCI);
  const currentRace = doubleCardDrawer.currentRace;
  const hideColorBorder = doubleCardDrawer.hideColorBorder;

  // double table highlighting
  const colorFormatting = (firstJockey, firstTrainer, secondJockey, secondTrainer) => {
    if (firstJockey === secondJockey) {
      if (firstTrainer === secondTrainer) {
        return "Same-Jockey-Trainer-Cell"
      }
      return "Same-Jockey-Cell"
    } else if (firstTrainer === secondTrainer) {
      return "Same-Trainer-Cell"
    }
    return ""
  }
  
  const winOddsList = Object.entries(currentOddsLatest || {}).filter(e => e[1] > 0);
  winOddsList.sort((a, b) => a[1] > 0 ? (b[1] > 0 ? a[1] - b[1] : -1) : 999).map(e => e[0]);

  for (const [key] of winOddsList) {
    // odds of 14 current horses
    const column = {
      // the graphs on the first row
      title: () => {
        return (
          <div>
            <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)", backgroundColor: getWeightedCIBackgroundColor(currentWeightedDoubleCI?.[key]?.doubleWinPlaceCi) }}>
              {currentWeightedDoubleCI?.[key]?.doubleWinPlaceCi?.toFixed(2) || "-"}
            </div>
            <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)", backgroundColor: getWeightedCIBackgroundColor(currentWeightedDoubleCI?.[key]?.winPlaceCi) }}>
              {currentWeightedDoubleCI?.[key]?.winPlaceCi?.toFixed(2) || "-"}
            </div>
            <div style={{ textAlign: "center", backgroundColor: getWeightedCIBackgroundColor(currentWeightedDoubleCI?.[key]?.averageDoubleCi) }}>
              {currentWeightedDoubleCI?.[key]?.averageDoubleCi?.toFixed(2) || "-"}
            </div>
          </div>
        );
      },
      align: 'center',
      children: [
        {
          // row that show all the JOCKEY/HORSENUM/TRAINER of the current race
          title: () => {
            return (
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: "4px", backgroundColor: getBackgroundColor(winOddsList.map(e => e[0]).indexOf(key) + 1) }}>
                <div style={{ textAlign: 'center' }}>{aliasData?.jockey?.[doubleCurrentCode[key].jockey]}</div>
                <div style={{ textAlign: 'center' }}>{key}</div>
                <div style={{ textAlign: 'center' }}>{aliasData?.trainer?.[doubleCurrentCode[key].trainer]}</div>
              </div>
            )
          },
          align: 'center',
          key: `cross_${key}`,
          width: '3.5%',
          onCell: (_) => {
            return {
              className: (!hideColorBorder && colorFormatting(doubleCurrentCode[key].jockey, doubleCurrentCode[key].trainer, _?.code?.jockey, _?.code?.trainer)),
            }
          },
          // table cells that show all the DOUBLE ODDs and their CIs (all charts)
          render: (_) => {
            if (_?.doubleStatisticsRow != null) {
              const doubleStatistics = _?.doubleStatisticsRow?.[key];
              return (
                <div>
                <div
                  style={
                    {
                      color: doubleStatistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                      fontWeight: doubleStatistics?.jockey?.within_range ? "bold" : null,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
                    }
                  }
                >
                  {doubleStatistics?.jockey?.win_percentage != null ? (doubleStatistics.jockey.win_percentage * 100).toFixed(1) : "-"}%
                </div>
                <div style={{ color: doubleStatistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)", fontWeight: doubleStatistics?.trainer?.within_range ? "bold" : null }}>
                  {doubleStatistics?.trainer?.win_percentage != null ? (doubleStatistics.trainer.win_percentage * 100).toFixed(1) : "-"}%
                </div>
              </div>
              )
            }
            if (_?.odds?.[key] == null) {
              return (<div style={{ width: "inherit", height: "100%", backgroundColor: getDoubleCIBackgroundColor(_?.doubleCI?.[key]) }}>
                <DoubleChart data={_?.odds?.[key]} ratio={_?.doubleCI?.[key] || "-"} />
              </div>)
            }
            // <Popover /> can only work with a component wrapped within a <div /> tag
            return (<Popover content={HoverTable(_?.odds?.[key])} overlayClassName="Popover" destroyTooltipOnHide={false} style={{ width: "100%", height: "100%" }}>
              <div style={{ width: "inherit", height: "100%", backgroundColor: getDoubleCIBackgroundColor(_?.doubleCI?.[key]) }}>
                <DoubleChart data={_.odds[key]} ratio={_?.doubleCI?.[key] || "-"} />
              </div>
            </Popover>)
          }
        }
      ],
    };
    (!currentRace[`hideCurrentHorse${key}`] && columns.push(column));
  }
  return columns;
}