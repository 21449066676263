import { Col, InputNumber, Row } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendUpdateRoadmapData } from "../../../../services/actions/userAction";
import { setHorseScore } from "../../../../services/reducers/standardSlice";


const MAX_SCORE = 100;
const MIN_SCORE = -100;

const sumAllRequiredScore = (sumKey, scores, givenScores, decisionTreeScore) => {
  return sumKey.reduce(
    (prev, curr) => prev + (scores?.[curr] || 0) + (givenScores?.[curr] || 0) + (decisionTreeScore?.[curr] || 0), 0
  );
}

const Score = ({ horseNum, scoreKey, sumKey, sendMessage }) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.standard.userData?.userData)
  const scores = userData?.score?.[Number(horseNum)];
  const givenScores = useSelector(state => state.standard.userData?.scoreData?.[Number(horseNum)] || {});
  const decisionTreeScore = useSelector(state => state.standard.decisionTree?.extraScore?.[Number(horseNum)] || {});

  const [scoreState, setScoreState] = useState(scores?.[scoreKey] || 0);

  const onChange = () => {
    const v = scoreChecker(scoreState);
    if (v === scores?.[scoreKey]) {
      return;
    }
    dispatch(setHorseScore({ currentScore: v, horseNum, scoreKey }));
    sendUpdateRoadmapData(sendMessage);
    setScoreState(v);
  }

  useEffect(() => setScoreState(scores?.[scoreKey] || 0), [scoreKey, scores, setScoreState]);

  const keyValueSum = sumAllRequiredScore(sumKey, scores, givenScores, decisionTreeScore);

  const scoreChecker = (v) => {
    if (Number(v) > MAX_SCORE) {
      return MAX_SCORE;
    }
    if (Number(v) < MIN_SCORE) {
      return MIN_SCORE;
    }
    return v;
  }

  return (
    <div>
      <div style={{ padding: "2px" }}>
        <span style={{ textDecoration: "underline" }}>{(givenScores?.[scoreKey] || 0) + (decisionTreeScore?.[scoreKey] || 0)}</span>
      </div>
      <Row style={{ padding: "2px" }}>
        <InputNumber
          placeholder="0"
          defaultValue={0}
          bordered
          onBlur={onChange}
          onPressEnter={onChange}
          onChange={(v) => { v != null ? setScoreState(v) : setScoreState(0) }}
          value={scoreState}
          style={{ width: "100%", border: scoreState !== 0 ? "2px solid rgb(150, 250, 0)" : "", boxSizing: "border-box" }}
          controls={false}
        />
      </Row>
      <Row style={{ padding: "2px" }}>
        <Col span={24} style={{ fontWeight: "bold" }}>
          {keyValueSum}
        </Col>
      </Row>
    </div>
  )
}

const ScoreColumn = (columnName, sortedInfo, key, sumKey, scores, sendMessage) => {
  const { t } = useTranslation();
  const scoreData = useSelector(state => state.standard.userData?.scoreData);
  const decisionTreeScore = useSelector(state => state.standard.decisionTree?.extraScore || {});

  return {
    title: columnName,
    align: 'center',
    key: `score_${key}`,
    children: [
      {
        title: t("roadmap::scoreHeader"),
        align: 'center',
        key: `score_${key}`,
        children: [
          {
            title: t("roadmap::totalSubScoreHeader"),
            align: 'center',
            key: `score_${key}`,
            width: 45,
            sorter: {
              compare: (a, b) => {
                if (a?.scratched === true) return 1;
                if (b?.scratched === true) return -1;
                const decisionTreeA = decisionTreeScore?.[Number(a.horseNum)]?.[key] || 0;
                const decisionTreeB = decisionTreeScore?.[Number(b.horseNum)]?.[key] || 0;
                return ((scoreData?.[Number(b.horseNum)]?.[key] || 0) + decisionTreeB) - ((scoreData?.[Number(a.horseNum)]?.[key] || 0) + decisionTreeA);
              },
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `score_${key}`)?.['order'],
            onCell: () => {
              return { style: { backgroundColor: "rgba(180, 180, 180, 0.3)" } };
            },
            render: (_) => {
              return <Score horseNum={_.horseNum} scoreKey={key} sumKey={sumKey || []} sendMessage={sendMessage} />
            }
          }
        ]
      }
    ]
  }
}

export default ScoreColumn;