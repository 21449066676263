import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie'

const initialData = {
  theme: 'light'
};

const generalSlice = createSlice({
  name: "general",
  initialState: initialData,
  reducers: {
    setTheme: (state, data) => {
      state.theme = data.payload;
      document.getElementById('base').setAttribute('data-theme', state.theme);
      Cookies.set('theme', state.theme);
    },
  },
});

export const { setTheme } = generalSlice.actions;

export default generalSlice.reducer;