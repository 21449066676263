import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToggleColumn } from "./columns/ToggleColumn";
import { ForecastColumn } from "./columns/ForecastColumn";
import { WinColumn } from "./columns/WinColumn";
import { DerivativeOddsColumn } from "./columns/DerivativeOddsColumn";
import { HorseColumn } from "./columns/HorseColumn";
import { JockeyColumn } from "./columns/JockeyColumn";
import { TrainerColumn } from "./columns/TrainerColumn";
import { JockeyTrainerColumn } from "../common/JockeyTrainerColumn";
import { JockeyTrainerFutureHorseColumn } from "./columns/JockeyTrainerFutureHorseColumn";
import { InvestmentDiffColumn } from "./columns/InvestmentDiffColumn";
import NewColdDoorColumn from "./columns/NewColdDoorColumn";

import { Table } from "antd";
import {
  setSortedInfo,
  setSortingHorseNumList,
} from "../../../services/reducers/standardSlice";

import "../RaceTable.css";
import TotalScoreColumn from "../roadmap/columns/TotalScoreColumn";
import SelectiveRankingColumn from "./columns/SelectiveRankingColumn";
import JTNodeColumn from "./columns/JTNodeColumn";
import { ExpectedWinOddsColumn } from "./columns/ExpectedWinOddsColumn";

export const RaceTable = ({ sendMessage }) => {
  const dispatch = useDispatch();

  // dataSource: basically all info like jockey/trainer name, all odds/CIs, velocity ...
  const dataSource = useSelector((state) =>
    Object.values(state.standard.detailData)
  );

  // headerData: ratios and intervals
  const headerData = useSelector((state) => state.standard.headerData);

  // sortedInfo: sorting info of the antd table
  const sortedInfo = useSelector((state) => state.standard.sortedInfo);

  // additionalData: horse num that won last race, velocity data availability, ...
  const additionalData = useSelector((state) => state.standard.additionalData);
  const userData = useSelector((state) => state.standard.userData);

  const rebornData = useSelector(
    (state) => state.standard.recommendation.recommendLLMGPT4
  );

  const columns = [
    // ToggleColumn(additionalData),
    // SelectiveRankingColumn(sortedInfo, sendMessage, userData),
    ForecastColumn(headerData, sortedInfo, additionalData),
    WinColumn(headerData, sortedInfo),
    // DerivativeOddsColumn(),
    ExpectedWinOddsColumn(),
    HorseColumn(sortedInfo, additionalData, rebornData),
    JockeyColumn(sortedInfo),
    // JTNodeColumn(additionalData),
    TrainerColumn(sortedInfo),
    NewColdDoorColumn(sortedInfo),
    // JockeyTrainerFutureHorseColumn(),
    JockeyTrainerColumn(sortedInfo),
    InvestmentDiffColumn(),
    TotalScoreColumn(sortedInfo, userData, true),
  ];

  const handleChange = (_1, _2, sorter, { currentDataSource }) => {
    // if the sort info is not an array, turn it into array
    if (!Array.isArray(sorter)) {
      sorter = [sorter];
    }
    // only keep the first and last sorting info
    if (sorter.length > 2) {
      sorter.splice(1, 1);
    }
    // map into redux acceptable format
    const info = sorter.map((data) => {
      return { columnKey: data.columnKey, order: data.order };
    });
    dispatch(setSortedInfo(info));
    dispatch(
      setSortingHorseNumList(currentDataSource.map((horse) => horse.horseNum))
    );
  };

  return (
    <div style={{ padding: 10, width: "98vw" }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        scroll={{ x: "99%" }}
        rowKey={(record) => record?.horseNum}
        style={{ width: "auto" }}
      />
    </div>
  );
};
