import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import VerticalRecordColumn from './VerticalRecordColumn';

import '../RaceTable.css';

const WinTable = () => {
  // dataSource: graph data for each horse
  const dataSource = useSelector(state => [state.win.detailData]);

  // aliasData: alias of jockey/trainer surnames
  const aliasData = useSelector(state => state.win.aliasData);

  // additional data, e.g. total num of horses
  const additonalData = useSelector(state => state.win.additionalData);

  // jockey code and trainer code of each horse
  const code = useSelector(state => state.win.code);

  const columns = [
    VerticalRecordColumn(additonalData, aliasData, code)
  ]

  return (
    <div style={{ padding: 20 }}>
      <Table
        pagination={false}
        loading={additonalData.totalNumOfHorses === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index)=>index}
      />
    </div>
  );
}

export default WinTable;
