import { Table } from "antd";
import { useSelector } from "react-redux";
import { InvestmentColumn } from "./columns/InvestmentColumn";
import { NameColumn } from "./columns/NameColumn";
import { RaceColumn } from "./columns/RaceColumn";
import { StatisticColumn } from "./columns/StatisticColumn";

import "./EntriesTable.css";

const EntriesTable = ({ showJockey = true, sendMessage }) => {
  const dataSource = Object.values(useSelector(state => state.entries.detailData[showJockey ? "jockey" : "trainer"]));

  const statisticsData = useSelector(state => state.entries.additionalData[showJockey ? "jockeyStatistics" : "trainerStatistics"]);
  const investmentData = useSelector(state => state.entries.additionalData.totalWinInvestment);

  dataSource.sort((a, b) => {
    if (statisticsData?.[b.code]?.total?.w == null) {
      return -1;
    }
    return statisticsData?.[b.code]?.total?.w - statisticsData?.[a.code]?.total?.w ||
      statisticsData?.[b.code]?.total?.q - statisticsData?.[a.code]?.total?.q ||
      statisticsData?.[b.code]?.total?.p - statisticsData?.[a.code]?.total?.p ||
      statisticsData?.[b.code]?.total?.fourth - statisticsData?.[a.code]?.total?.fourth ||
      statisticsData?.[b.code]?.total?.total - statisticsData?.[a.code]?.total?.total ||
      statisticsData?.[b.code]?.total?.gain - statisticsData?.[a.code]?.total?.gain
  });

  const columns = [
    NameColumn(showJockey, sendMessage),
    StatisticColumn(showJockey, statisticsData),
    InvestmentColumn(investmentData),
    ...RaceColumn(showJockey)
  ];

  return (
    <div style={{ padding: 10, width: "98vw" }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        className="Entries-Table"
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.code}
      />
    </div>
  );
}

export default EntriesTable;
