import { Col, Row } from "antd"

export const WinOddsColumn = () => {
  return {
    title: "Win",
    align: 'center',
    children: [
      {
        title: "30/10/2/0",
        align: 'center',
        key: 'winOdds',
        width: 140,
        render: (_) => {
          return (
            <Row>
              <Col span={6}>
                <div>{_?.winOdds?.[0]}</div>
              </Col>
              <Col span={6}>
                <div>{_?.winOdds?.[1]}</div>
              </Col>
              <Col span={6}>
                <div>{_?.winOdds?.[2]}</div>
              </Col>
              <Col span={6}>
                <div>{_?.winOdds?.[3]}</div>
              </Col>
            </Row>
          )
        }
      }
    ]
  }
}