const AIPredictionColumn = (sortedInfo, recommendationData) => {
  return {
    title: "AI",
    align: 'center',
    key: `ai`,
    width: 20,
    fixed: "right",
    sorter: {
      compare: (a, b) => {
        return recommendationData?.recommendMachine?.latest?.ranking?.indexOf(b.horseNum) - recommendationData?.recommendMachine?.latest?.ranking?.indexOf(a.horseNum);
      },
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === "ai")?.['order'],
    render: (_) => {
      const index = recommendationData?.recommendMachine?.latest?.ranking?.indexOf(_.horseNum);
      return (
        <div>
          {index != null && index !== -1 ? index + 1 : "-"}
        </div>
      )
    }
  };
};

export default AIPredictionColumn;
