import React from 'react';
import { Button, Drawer } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setToggle, resetSetting } from '../../services/reducers/drawerSlice';
import 'antd/dist/antd.css';

const ControlPanel = ({ title, DrawerItems }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // toggle drawer on and off
  const drawerToggle = useSelector(state => state.drawer.toggle)
  const onClose = () => {
    dispatch(setToggle(false))
  };

  // function call to reset drawer setting to default
  const resetDefaultSetting = (e) => {
    dispatch(resetSetting());
  };

  return (
    <div>
      <Drawer title={
        <div>
          <span className="Drawer-Title" style={{ fontSize: "29" }}>{t("setting::SettingTitle")}</span>
          <Button onClick={resetDefaultSetting} type="primary" style={{ marginLeft: "30px" }} >{t("setting::resetButton")} </Button>
        </div>
      } className="Drawer-Wrapper" width="440" onClose={onClose} visible={drawerToggle}>
        <DrawerItems />
      </Drawer>
    </div>
  );
}

export default ControlPanel;