import { isValidInputTimeValue } from "@testing-library/user-event/dist/utils";
import * as Helper from "../../utils/helper";
import { horseInit } from "./common";
import {
  futureHorseColumnHandling,
  horseColumnHandling,
  jockeyColumnHandling,
  jtColumnHandling,
  trainerColumnHandling,
} from "./logic/hjt";
import {
  jockeyTrainerAliasHandling,
  lossDetailHandling,
  commandHandling,
  landscapeDistributionHandling,
} from "./logic/miscellaneous";
import {
  derivativeOddsColumnHandling,
  doubleSignalHandling,
  expectedWinOddsHandling,
  fQHandlingV2,
  forecastColumnHandling,
  quinellaColumnHandling,
  winInvesmentSumHandling,
  winInvesmentSumTop6Handling,
  winPlaceColumnHandling,
} from "./logic/odds";
import {
  raceTableHeaderHandling,
  raceTableInfoHandling,
  resultHandling,
} from "./logic/raceInfo";
import {
  recommendationLLMHandling,
  recommendationMachineHandling,
} from "./logic/recommendation";
import {
  calucateTotalScore,
  handleColdDoorScore,
  handleHorseNoteFromAPI,
  handleRoadmapScore,
  speedProHandling,
} from "./logic/scores";
import {
  coldDoorStatisticsHandling,
  decisionTreeHandling,
  doubleStatisticsHandling,
  fQStatisticsHandling,
  gameMapHandling,
  jtNodeHandling,
  martixColumnHandling,
  newColdDoorHandling,
  overboughtHandling,
  quartetStatisticsHandling,
  qwStatisticsHandling,
  speedProStatisticsHandling,
  tiereceStatisticsHandling,
  top3WinPercentStatisticsHandling,
  winInvestmentDifferenceStatisticsHandling,
  winInvestmentSnapshotStatisticsHandling,
  winPoolDistributionGameMapHandling,
} from "./logic/statistics";

export const standardDataProcessingWS = (state, data) => {
  const payload = data.payload;
  const race = payload.race;
  // get all horses in fetched data, if no data, use data in store instead
  const horses = race?.horses;

  // store total num of horses
  if (horses) {
    state.additionalData.numOfHorseCurrent = Math.max(
      ...horses.map((horse) => horse.horseNum)
    );
  }

  if (payload?.nextRace?.horses) {
    state.additionalData.numOfHorseNext = Math.max(
      ...payload.nextRace.horses.map((horse) => horse.horseNum)
    );
  }

  raceTableInfoHandling(state, payload.pool, race, payload.winOddsAll);
  raceTableHeaderHandling(
    state.headerData,
    payload.forecastOdds,
    payload.wPRatio,
    race
  );

  resultHandling(state, payload.result);
  // process each horse data and map all other related data (odds, gain, jockey...) to the horse
  for (
    let horseNum = 1;
    horseNum <= state.additionalData.numOfHorseCurrent;
    horseNum++
  ) {
    const horse = horses
      ? horses.find((horse) => horse.horseNum === horseNum)
      : null;
    // initialize the horse data in store if no such horse in store
    if (!(horseNum in state.detailData) && horse) {
      state.detailData[horseNum] = horseInit(horseNum, horse);
    }

    // get the stored horse data
    const detailData = state.detailData[horseNum];

    if (!detailData) {
      continue;
    }

    if (!(horseNum in state.userData.scoreData)) {
      state.userData.scoreData[horseNum] = {};
    }

    horse && (detailData.scratched = horse?.scratched);

    winPlaceColumnHandling(
      detailData,
      horseNum,
      payload.winOdds,
      payload.winOddsAll,
      payload.qw,
      payload.ci,
      payload.ciAll,
      payload.expectedWinOddsAll,
      payload.qwLatest
    );

    forecastColumnHandling(
      detailData,
      horseNum,
      payload.forecastOdds,
      payload.forecastCi,
      state.headerData.forecastHorseNum
    );

    quinellaColumnHandling(
      detailData,
      horseNum,
      payload.quinellaOdds,
      payload.quinellaCi,
      state.headerData.forecastHorseNum
    );

    derivativeOddsColumnHandling(
      detailData,
      horseNum,
      payload.winToWin,
      payload.quinellaToWin
    );

    horseColumnHandling(detailData, horseNum, horse, payload.result);

    jockeyColumnHandling(
      detailData,
      horseNum,
      horse,
      payload.jockeyGain,
      payload.jockeyRankHistory,
      payload.jtSingleOdds
    );

    trainerColumnHandling(
      detailData,
      horseNum,
      horse,
      payload.trainerGain,
      payload.trainerRankHistory,
      payload.jtSingleOdds
    );

    jtColumnHandling(detailData, payload.jtCollab, horseNum);

    martixColumnHandling(detailData, state.userData, payload.matrix, horseNum);

    futureHorseColumnHandling(
      detailData,
      payload.jockeyFutureHorses,
      payload.trainerFutureHorses
    );

    winInvestmentDifferenceStatisticsHandling(
      detailData,
      payload.winInvestmentDifferenceStatistics?.[horseNum]
    );

    overboughtHandling(detailData, payload.overboughtStatistics?.[horseNum]);

    doubleStatisticsHandling(detailData, payload.doubleStatistics);

    fQStatisticsHandling(detailData, payload.fQStatistics);

    speedProHandling(detailData, payload.speedPro?.[horseNum]);

    qwStatisticsHandling(detailData, payload.qwStatistics?.[horseNum]);

    speedProStatisticsHandling(
      detailData,
      payload.speedProStatistics?.[horseNum]
    );

    coldDoorStatisticsHandling(
      detailData,
      payload.coldDoorStatistics?.[horseNum]
    );

    newColdDoorHandling(detailData, payload.newColdDoor?.[horseNum]);

    winInvestmentSnapshotStatisticsHandling(
      detailData,
      payload.winInvestmentSnapshotStatistics?.[horseNum]
    );

    expectedWinOddsHandling(detailData, horseNum, payload.expectedWinOdds);
  }

  fQHandlingV2(
    state.signalData,
    state.additionalData,
    state.detailData,
    payload.fQSignal,
    payload.forecastOddsAll,
    payload.quinellaOddsAll,
    payload.forecastOddsNorm,
    payload.quinellaOddsNorm
  );

  doubleSignalHandling(
    state.additionalData,
    state.signalData,
    state.detailData,
    payload.doubleCI?.horses || payload.doubleCI,
    payload.weightedDoubleCI
  );

  gameMapHandling(state.additionalData, state.userData, payload.gameMap);

  recommendationLLMHandling(
    state.recommendation,
    payload.recommendLLM,
    payload.recommendationLLMGPT4,
    payload.recommendationLLMNext5,
    payload.recommendationLLMTop3,
    payload.recommendationLLMOthers,
    payload.recommendationLLMLiveRanking
  );

  winInvesmentSumHandling(state.additionalData, payload.winInvestmentSum);

  winInvesmentSumTop6Handling(
    state.additionalData,
    payload.winInvestmentSumTop6
  );

  winPoolDistributionGameMapHandling(
    state.additionalData,
    payload.winPoolDistributionGameMap,
    payload.winOdds
  );

  jtNodeHandling(state.additionalData, state.detailData, payload.jtCollabNode);

  decisionTreeHandling(state.decisionTree, payload.decisionTree);

  quartetStatisticsHandling(state.additionalData, payload.quartetStatistics);

  tiereceStatisticsHandling(state.additionalData, payload.tierceStatistics);

  lossDetailHandling(state.resultData, payload.lossDetail);

  commandHandling(state, payload.command);

  landscapeDistributionHandling(state, payload.landscapeDistribution);

  top3WinPercentStatisticsHandling(
    state.additionalData,
    payload.top3WinPercentStatistics
  );

  jockeyTrainerAliasHandling(state, payload.jockeyAlias, payload.trainerAlias);

  handleRoadmapScore(state.userData.scoreData, payload.roadmap);

  state.updateTS = Helper.currentTimeIn24HoursFormat();

  calucateTotalScore(state.userData, state.decisionTree);
};

export const roadmapProcessingWS = (state, data) => {
  const { payload, userId } = data.payload;
  if (!payload || !userId) {
    return;
  }
  if (payload.selectiveRanking) {
    state.userData.selectiveRanking = payload.selectiveRanking;
    return;
  }
  for (const [sourceUserId, userData] of Object.entries(payload)) {
    if (sourceUserId === userId) {
      handleHorseNoteFromAPI(state, { payload: userData });
      continue;
    }
    if (sourceUserId === "cold_door") {
      handleColdDoorScore(state.userData.scoreData, userData, null, state);
      continue;
    }
    if (sourceUserId === "selectiveRanking") {
      continue;
    }
    state.userData.otherUserData[sourceUserId] = userData;
  }
};
