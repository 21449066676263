import React from "react";
import { Table } from "antd";

// numbers in cells on the hover table
const cellRender = (wins, total) => {
  return (
    <div style={{ padding: "6px" }}>
      <div style={{ textAlign: "center" }}>
        {total > 0 && wins > 0 ? `${((wins / total) * 100).toFixed(0)}%` : "-"}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left" }}>{wins}</div>
        <div style={{ textAlign: "right" }}>{total}</div>
      </div>
    </div>
  );
};

const TableColumn = (t, title, group) => {
  return {
    title: title,
    align: "center",
    key: "jtHover",
    children: [
      // total win rate
      {
        title: t("race::totalHeader"),
        align: "center",
        key: "total",
        width: "20.0%",
        onCell: (_) => {
          return group === "tTL" ? { className: "Highlighted-Column" } : {};
        },
        render: (_) => cellRender(_.data.tTL.wins, _.data.tTL.total),
      },
      // fav win rate
      {
        title: t("race::favHeader"),
        align: "center",
        key: "fav",
        width: "20.0%",
        onCell: (_) => {
          return group === "fav" ? { className: "Highlighted-Column" } : {};
        },
        render: (_) => cellRender(_.data.fav.wins, _.data.fav.total),
      },
      // sup win rate
      {
        title: t("race::supHeader"),
        align: "center",
        key: "sup",
        width: "20.0%",
        onCell: (_) => {
          return group === "sup" ? { className: "Highlighted-Column" } : {};
        },
        render: (_) => cellRender(_.data.sup.wins, _.data.sup.total),
      },
      // inf win rate
      {
        title: t("race::infHeader"),
        align: "center",
        key: "inf",
        width: "20.0%",
        onCell: (_) => {
          return group === "inf" ? { className: "Highlighted-Column" } : {};
        },
        render: (_) => cellRender(_.data.inf.wins, _.data.inf.total),
      },
      // unw win rate
      {
        title: t("race::unwHeader"),
        align: "center",
        key: "unw",
        width: "20.0%",
        onCell: (_) => {
          return group === "unw" ? { className: "Highlighted-Column" } : {};
        },
        render: (_) => cellRender(_.data.unw.wins, _.data.unw.total),
      },
    ],
  };
};

const FutureHorsesGroupingTable = ({ dataSource }) => {
  const tableColumn = [
    {
      align: "center",
      key: "row",
      width: "15.2%",
      render: (_, record, index) => (
        <span>{index === 0 ? "J.Future" : "T.Future"}</span>
      ),
    },
    {
      align: "center",
      key: "fav",
      width: "15.2%",
      render: (_) => <span>{_?.[0] != null ? _[0] : "-"}</span>,
    },
    {
      align: "center",
      key: "sup",
      width: "15.2%",
      render: (_) => <span>{_?.[1] != null ? _[1] : "-"}</span>,
    },
    {
      align: "center",
      key: "inf",
      width: "15.2%",
      render: (_) => <span>{_?.[2] != null ? _[2] : "-"}</span>,
    },
    {
      align: "center",
      key: "unw",
      width: "15.2%",
      render: (_) => <span>{_?.[3] != null ? _[3] : "-"}</span>,
    },
  ];
  return (
    <div style={{ padding: "2px" }}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={tableColumn}
        style={{ width: "100%", height: "54px" }}
        className="Future-Horses-Grouping-Table"
        showHeader={false}
        rowKey={(record, index) => index}
      />
    </div>
  );
};

export const StatisticsHoverTable = (t, data, title, group) => {
  const dataSource = [{ data: data }];

  const columns = [TableColumn(t, title, group)];

  return (
    <div style={{ width: "400px", height: "120px" }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index) => index}
      />
    </div>
  );
};

export const JTStatisticsHoverTable = (t, data, title, group) => {
  const dataSource = [{ data: data.jtCollab }];

  const columns = [TableColumn(t, title, group)];

  const jockeyFutureHorses = data.jockeyFutureHorses;
  const trainerFutureHorses = data.trainerFutureHorses;

  return (
    <div style={{ width: "400px", height: "170px" }}>
      <FutureHorsesGroupingTable
        dataSource={[jockeyFutureHorses, trainerFutureHorses]}
      />
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index) => index}
      />
    </div>
  );
};
