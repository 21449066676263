import { Col, Row } from "antd";

const QWStatisticsColumn = () => {
  return {
    title: "QW\nCI",
    align: 'center',
    key: `qwStatistics`,
    width: 60,
    render: (_) => {
      const qwStatisticsJockey = _.qwStatistics.jockey;
      const qwStatisticsTrainer = _.qwStatistics.trainer;

      return (
        <div>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
                color: qwStatisticsJockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: qwStatisticsJockey?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {qwStatisticsJockey?.win_percentage != null ? (qwStatisticsJockey.win_percentage * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
                color: qwStatisticsTrainer?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: qwStatisticsTrainer?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {qwStatisticsTrainer?.win_percentage != null ? (qwStatisticsTrainer.win_percentage * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default QWStatisticsColumn;