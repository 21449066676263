import { useTranslation } from "react-i18next"

export const InvestmentColumn = (investmentData) => {
  const { t } = useTranslation();

  return {
    title: t("entries::investmentHeader"),
    align: "center",
    key: 'invest',
    fixed: 'left',
    sorter: {
      compare: (a, b) => investmentData?.[b.code] - investmentData?.[a.code],
    },
    width: 50,
    render: (_) => {
      if (investmentData?.[_.code]){
        const value = Math.ceil(investmentData?.[_.code] * 100);
        return (
          <div>
            <div>{value}%</div>
          </div>
        )
      }
      return (
        <div>-</div>
      )
    },
  }
}