import * as Helper from '../../utils/helper'

export const initialData = () => ({
  detailData: {
    updateTS: Helper.currentTimeIn24HoursFormat(),
    race: {
      "1": {
        raceDate: "2022-07-16",
        raceNum: 1,
        raceTime: "2022-07-16T12:30:00+08:00",
        revisedRaceTime: "2022-07-16T12:30:00+08:00",
        raceClass: "第五班",
        name: "叻先生讓賽",
        venue: "沙田",
        track: "草地",
        trackCondition: "好地",
        course: "A",
        distance: 1200,
        prize: 780000,
        horses: [
          {
            horseNum: 8,
            draw: 13,
            horseCode: "E098",
            horseWeight: 1076,
            handicapWeight: 129,
            rating: 33,
            gears: ["TT"],
            apprenticeAllowance: 0,
            scratched: false,
            horseName: "龍寶寶",
            rank: 1,
            jockeyName: "巴度",
            trainerName: "蔡約翰",
            jockeyCode: "BA",
            trainerCode: "SJJ",
          }
        ]
      }
    },
    jockey: {
      WCV: {
        code: "WCV",
        profileUrl: "https://racing.hkjc.com/racing/information/Chinese/Jockey/JockeyProfile.aspx?JockeyCode=WCV",
        statisticUpToRaceDate: "2022-07-13",
        nationality: "中國",
        season1StCount: 2,
        seasonRunCount: 134,
        seasonWinAmount: 4781925,
        season2NdCount: 5,
        season1StRatio: 0.0149,
        season1StCountLast10Days: 0,
        season3RdCount: 6,
        season4ThCount: 6,
        name: "黃俊",
        age: 28,
        background: "香港賽馬會見習騎師學校學生黃俊自2014年6月開始在海外接受訓練，最初跟隨澳洲塔斯曼尼亞省頂尖練馬師Leon Wells學藝。黃俊於2015年1月獲得批准策騎出賽，之後兩年在塔斯曼尼亞省合共出賽四百八十五次，勝出四十五場賽事，勝出率達百分之九點三。於2017年1月，黃俊轉到南澳洲著名練馬師兼前頂級騎師佐利的馬房繼續學習。在接著的十三個月裏，他再攻下七十五場賽事。2017/2018年度馬季，黃俊回港策騎，當時他憑四十二場頭馬在南澳洲騎師榜暫居第七位。黃俊獲派跟隨練馬師苗禮德學藝，並於2018年3月25日在沙田處子登場。八天後，他策騎容天鵬旗下「必得勝」取得在港首場頭馬。黃俊在港從騎首季共取得十三場頭馬，第二季更創下在港出道以來一季最多頭馬紀錄，合共取得三十八場頭馬，可惜其後因傷休賽。黃俊於2020年初傷癒復出，並於3月8日在沙田馬場策騎「笑傲成功」取得308天以來首場頭馬。他該季其後再添八場頭馬進賬。",
        updateTs: "2022-07-14T09:06:55.505024+08:00",
        achievements: "",
        notableWins: ""
      }
    },
    trainer: {
      TYS: {
        code: "TYS",
        profileUrl: "https://racing.hkjc.com/racing/information/Chinese/Trainers/TrainerProfile.aspx?TrainerId=TYS",
        statisticUpToRaceDate: "2022-07-10",
        seasonWinAmount: 26325350,
        season1StCount: 21,
        seasonRunCount: 450,
        season1StCountLast10Days: 7,
        season2NdCount: 18,
        season1StRatio: 0.0467,
        season3RdCount: 33,
        season4ThCount: 22,
        name: "徐雨石",
        background: "徐雨石畢業於香港賽馬會見習騎師學校，自1980至1986年在港從騎期間，共勝出二十一場頭馬。2005年獲發練馬師牌照，之前曾在不同馬房任職助理練馬師，最後一次是為呂健威效力。徐雨石從練不久即能取得理想成績，令其馬房得以壯大，迄今以2007/2008年度馬季的成績最佳，共取得五十一場頭馬，並在該季練馬師榜位列第三。其英文名字Me其實是Amigo的簡稱，事緣他在騎師學校時，同學認為他的英文名字較為拗口，故簡稱他為Me，其後一直沿用至今。由徐雨石訓練的「藍天堡馬」於2018年在首爾競逐韓國短途錦標（韓國一級賽），以短距離之差屈居亞軍。2019年5月8日，他旗下的「天賜寶」在沙田掄元，為他帶來在港從練以來第五百場頭馬。",
        notableWins: "香港三級賽洋紫荊短途錦標（2014年「神舟十號」）。",
        updateTs: "2022-07-11T09:08:43.069892+08:00",
        age: 61,
        achievements: ""
      }
    },
    winOdds: {
      "1": {
        "1": 12
      }
    }
  },
  additionalData: {
    horse: {
      C341: {
        profileUrl: "https://racing.hkjc.com/racing/information/Chinese/Horse/Horse.aspx?HorseId=HK_2018_C341",
        name: "紅旺",
        brand: "C341",
        origin: "紐西蘭",
        age: 6,
        color: "灰",
        gender: "閹",
        seasonStakes: 1460100,
        totalStakes: 5193750,
        total1stCount: 4,
        total2ndCount: 5,
        total3rdCount: 7,
        totalRunCount: 38,
        owner: "俊暉團體",
        seasonCurrentRating: 66,
        seasonStartRating: 73,
        updatedAt: "2022-07-16T09:05:10.366800+00:00"
      }
    },
    jockeyStatistics: {
      WCV: {
        6: {
          w: 1,
          q: 1,
          p: 1,
          fourth: 1,
          total: 8,
          gain: 99
        }
      }
    },
    jockeyCurrentStatistics: {
      WCV: {
        6: {
          w: 1,
          q: 1,
          p: 1,
          fourth: 1,
          total: 8,
          gain: 99
        }
      }
    },
    trainerStatistics: {
      TYS: {
        6: {
          w: 1,
          q: 1,
          p: 1,
          fourth: 1,
          total: 8,
          gain: 99
        }
      }
    },
    trainerCurrentStatistics: {
      TYS: {
        6: {
          w: 1,
          q: 1,
          p: 1,
          fourth: 1,
          total: 8,
          gain: 99
        }
      }
    },
    jockeyAlias: {},
    trainerAlias: {},
    totalNumOfRaces: 10,
    totalWinInvestment: {},
    pastRaceDate: [],
  },
  pastData: {
    "2023-01-01": {
      1: {

      }
    }
  },
  pastDerivedData: {
    "2023-01-01": {

    }
  }
})