import { Col, Row } from "antd";

const OverBoughtTop4Column = () => {
  return {
    title: "Over\nBought\nTop4",
    align: 'center',
    key: `overBoughtTop4`,
    width: 60,
    render: (_) => {
      const { jTCollabWinPercent, jTCollabTop4Percent } = _.overboughtStatistics;
      return (
        <div>
          <Row>
            <Col span={24}>
              {jTCollabWinPercent != null ? (jTCollabWinPercent * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
              }}
            >
              {jTCollabTop4Percent != null ? (jTCollabTop4Percent * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default OverBoughtTop4Column;