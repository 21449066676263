import { Popover, Calendar, Button, Select, Col, Row } from "antd";
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment'

import './CalendarPicker.css'

const CalendarPicker = ({ targetDate, onSelect, disabledDate = null, allowHeaderDate = null }) => {
  const onSelectInner = (value) => {
    if (disabledDate && !disabledDate(value)) {
      onSelect(value);
    }
  }

  const headerRender = ({ value, onChange }) => {
    const year = value.year();
    const yearOptions = [];
    for (let i = year - 5; i < year + 5; i++) {
      for (const date of allowHeaderDate) {
        if (date.year() === i) {
          yearOptions.push(
            <Select.Option key={`${i}`}>
              {i}
            </Select.Option>,
          );
          break;
        }
      }
    }

    const monthOptions = [];
    for (let i = 1; i <= 12; i++) {
      for (const date of allowHeaderDate) {
        if (date.year() === year && date.month() === i ) {
          monthOptions.push(
            <Select.Option key={`${i}`}>
              {i + 1}
            </Select.Option>,
          );
          break;
        }
      }
    }
    const month = value.month();

    return (
      <div style={{ padding: 8 }}>
        <Row gutter={8}>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              onChange={newYear => {
                const newDate = allowHeaderDate.find(date => date.year() === Number(newYear));
                onChange(newDate);
              }}
              value={String(year)}
              className='Select'
              dropdownClassName="Select-DropDown"
            >
              {yearOptions}
            </Select>
          </Col>
          <Col>
            <Select
              size="small"
              dropdownMatchSelectWidth={false}
              value={String(month)}
              onChange={selectedMonth => {
                const newDate = allowHeaderDate.find(date => date.year() === Number(year) && date.month() === Number(selectedMonth));
                onChange(newDate);
              }}
              className='Select'
              dropdownClassName="Select-DropDown"
            >
              {monthOptions}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Popover
      overlayClassName="CustomPopover"
      content={
        <div style={{ width: 300 }}>
          <Calendar
            headerRender={headerRender}
            fullscreen={false}
            value={moment(targetDate)}
            onSelect={onSelectInner}
            disabledDate={disabledDate}
            className="CalendarPicker"
          />
        </div>
      }
    >
      <Button className='Button' icon={<CalendarOutlined />} />
    </Popover>
  );
};

export default CalendarPicker;