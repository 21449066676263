export const chatDataProcessingWS = (state, data) => {
  const { payload, command } = data.payload;

  if (command === "send_new_bot_message") {
    handleBotMessage(state[payload.type], payload);
  }
}


const handleBotMessage = (state, payload) => {
  state.message[state.message.length - 1].chatbot = {
    payload: payload.message,
    owner: "chatbot",
    updateTs: new Date().toISOString(),
  }
  if (payload.status === "completed") {
    state.waitForNewMessage = false;
  }
}