import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const RaceInfo = ({ store }) => {
  const { t } = useTranslation();
  // RaceDetails contains information needed for RaceInfowin
  // e.g. Win pool amount, Venue, Track, Class, Distance, ...
  const RaceDetails = useSelector((state) => state?.[store]?.info);
  const spanStyle = { paddingRight: "18px" };

  const additionalData = useSelector(
    (state) => state?.standard?.additionalData
  );

  // convert remainingTimeToStart(in second format) to hh:mm or mm:ss format
  const getRemainingTime = () => {
    const time = Math.floor(
      (new Date(RaceDetails.raceTime).getTime() - new Date().getTime()) / 1000
    );
    if (time >= 3600) {
      return `${Math.floor(time / 3600)} ${t("hours")} ${
        Math.floor(time / 60) % 60
      } ${t("minutes")}`;
    } else if (time > 0) {
      const second = Math.floor(time % 60);
      return (
        <span style={{ color: "red" }}>{`${Math.floor(time / 60)}:${
          second < 10 ? `0${second}` : second
        }`}</span>
      );
    } else {
      return 0;
    }
  };

  const winPoolAmount = `$${new Intl.NumberFormat("en-US").format(
    RaceDetails.winPoolAmount
  )}`;

  return (
    <>
      <div
        style={{
          textAlign: "left",
          paddingLeft: 20,
          paddingRight: 20,
          fontSize: "16px",
        }}
      >
        <div>
          <span style={{ paddingRight: "10px" }}>
            {t("race::winPoolAmount")}: <b>{winPoolAmount}</b>
          </span>
          <span style={spanStyle}>
            ({t("race::updateAt")}: <b>{RaceDetails.updatets}</b>)
          </span>
          <span style={spanStyle}>
            {t("race::raceBeginAt")}:{" "}
            <b>
              {new Date(RaceDetails.raceTime)?.toLocaleTimeString(
                {},
                { hourCycle: "h24" }
              )}
            </b>
          </span>
          <span style={spanStyle}>
            Horse Count: <b>{additionalData.numOfHorseCurrent}</b>
          </span>
        </div>
        <div>
          <span style={spanStyle}>
            {t("race::venue")}: <b>{RaceDetails.venue}</b>
          </span>
          <span style={spanStyle}>
            {t("race::track")}: <b>{RaceDetails.track}</b>
          </span>
          <span style={spanStyle}>
            {t("race::course")}: <b>{RaceDetails.course}</b>
          </span>
          <span style={spanStyle}>
            {t("race::class")}: <b>{RaceDetails.class}</b>
          </span>
          <span style={spanStyle}>
            Name: <b>{RaceDetails.name}</b>
          </span>
          <span style={spanStyle}>
            {t("race::distance")}: <b>{RaceDetails.distance}</b>
          </span>
          <span style={spanStyle}>
            {t("race::startTime")}: <b>{getRemainingTime()}</b>
          </span>
        </div>
      </div>
    </>
  );
};
