import * as API from '../../../middleware/API';
import { setRaceDateWithRaceNum } from '../../reducers/raceSlice';
import { setHorseTrackworkGrade, setSimulationGameMap, setStandardDataWS } from '../../reducers/standardSlice'
import store from "../../reducers"
import { message } from 'antd';
import { compareObjects } from '../../utils/helper';
import { sendUpdateRoadmapData } from '../userAction';

let controller = null;

const checking = {
  updated: false,
  previousScore: {}
};

export const loadRaceDateAndRaceNum = () => async (dispatch) => {
  try {
    // call the api directly
    const { data } = await API.loadRaceDateAndRaceNum();

    dispatch(setRaceDateWithRaceNum(data.payload));

    checking.updated = false;
    checking.previousScore = {}
  } catch (error) {
    console.log(error.message);
  }
}

export const triggerSimualtion = (horseNum) => async (dispatch) => {
  // check if the previous api call is finished already, if not finished, abort the previous fetch
  if (controller) {
    controller.abort('Operation canceled by the user.');
  }
  // generate new controller signal to allow abortion of current fetch if necessary
  controller = new AbortController();
  const signal = controller.signal;

  try {
    // get current race day and race num
    const raceDate = store.getState().race.selectedRaceDate;
    const raceNum = store.getState().race.selectedRaceNum;

    // default calllist
    const callList = [
      API.loadSimulationOddsFromWin(raceDate, raceNum, horseNum, signal),
      API.loadSimulationOddsFromQuinella(raceDate, raceNum, horseNum, signal),
    ];
    // call api with default call list
    const [winToWin, quinellaToWin] = await Promise.all(callList.map(p => p.catch(e => null)));

    // reset controller for next api fetch
    controller = null;

    // map a object to pass to reducer
    const raceDetailData = {
      winToWin: winToWin?.data?.payload,
      quinellaToWin: quinellaToWin?.data?.payload,
    };

    // check if the selected race date or race num is changed
    if (raceDate !== store.getState().race.selectedRaceDate || raceNum !== store.getState().race.selectedRaceNum) {
      console.debug("Race Date or Race Num is changed, so abandon");
      return;
    }
    // pass the data object into reducer
    dispatch(setStandardDataWS(raceDetailData));
  } catch (error) {
    console.error(error.message);
  }
}

export const triggerGameMapSimulation = (gameMap, simulateWPR) => async (dispatch) => {
  try {
    const {grouping} = gameMap;
    if (grouping == null) {
      return;
    }
    const fav = (grouping?.fav || []).length;
    const sup = (grouping?.sup || []).length;
    const inf = (grouping?.inf || []).length;

    const {data} = await API.loadSimulationGameMap(
      fav,
      sup,
      inf,
      simulateWPR
    );

    dispatch(setSimulationGameMap(data.payload.normal));
  } catch(e) {
    console.error(e);
  }
}

export const handleStandardCardEvent = (payload, raceDate, raceNum, sendRoadmapMessage) => async (dispatch) => {
  try {
    if (!raceDate || !raceNum) {
      return
    }
    payload.raceDate = store.getState().race.selectedRaceDate;
    payload.raceNum = store.getState().race.selectedRaceNum;
    const standardData = store.getState().standard;
    const simulationHorseNum = standardData.additionalData.simulationHorseNum;

    if (standardData.userData.snapshotTriggered) {
      await dispatch(setStandardDataWS(payload?.snapshot || {}));
    } else {
      await dispatch(setStandardDataWS(payload));
    }

    if (!payload?.winOdds) {
      return
    }
    // default calllist without double related data
    const callList = [
      API.loadSimulationOddsFromWin(payload.raceDate, payload.raceNum, simulationHorseNum),
      API.loadSimulationOddsFromQuinella(payload.raceDate, payload.raceNum, simulationHorseNum),
    ];
    // call api with default call list
    const [winToWin, quinellaToWin] = await Promise.all(callList.map(p => p.catch(e => null)));

    const getTotalScore = () => {
      const originalScoreData = {};
      Object.entries(store.getState().standard.userData.scoreData).forEach(([horseNum, entry]) => {
        originalScoreData[horseNum] = entry.totalScore;
      });
      return originalScoreData;;
    }

    // map a large object to pass to reducer
    const raceDetailData = {
      raceDate: payload.raceDate,
      raceNum: payload.raceNum,
      winToWin: winToWin?.data?.payload,
      quinellaToWin: quinellaToWin?.data?.payload,
    };
    // pass the data object into reducer
    dispatch(setStandardDataWS(raceDetailData));

    const newScoreData = getTotalScore();

    if (!compareObjects(checking.previousScore, newScoreData) || !checking.updated) {
      console.log("Update User Total Score");
      if (!sendRoadmapMessage) {
        return;
      }
      const sendMessageWhenChange = (command, input) => {
        sendRoadmapMessage({
          command: command,
          payload: {
            race_date: payload.raceDate,
            race_num: payload.raceNum,
            user_name: store.getState().auth.userData?.given_name,
            ...input
          }
        });
      }
      sendUpdateRoadmapData(sendMessageWhenChange);
      checking.updated = true;
      checking.previousScore = newScoreData;
    }

    message.destroy("standard page");
  } catch (error) {
    console.error(error.message);
  }
}


export const loadHorsePerformance = (raceDate, raceNum) => async (dispatch) => {
  try {
    if (!raceDate || !raceNum) {
      return;
    }

    const data = (await API.loadMorningExercise(raceDate, raceNum))?.data?.payload || [];
    const result = data.reduce((prev, horse) => { prev[horse.horse_code] = horse.grade; return prev }, {});

    dispatch(setHorseTrackworkGrade({ grade: result }));
  } catch (error) {
    console.error(error.message);
  }
}
