import * as API from "../../middleware/API"
import { setAuthenticated, clearAuth } from "../reducers/authSlice";
import { parseJwt } from "../utils/helper";
import Cookies from 'js-cookie'

export const login = (username, password, keycloak) => async (dispatch) => {
  try {
    // call the login api
    await API.authenticate(username, password, keycloak.clientId).then(({ data }) => {
      setKeyclockData(keycloak, data.access_token, data.refresh_token, true);
    });

    await keycloak.loadUserInfo();
    await keycloak.loadUserProfile();

    // set jwt on cookies
    setTokenCookie(keycloak.token, keycloak.refreshToken);

    // set authenticated user info into store state
    dispatch(setAuthenticated(parseJwt(keycloak.token)));

    Cookies.set('id', username, { sameSite: 'strict', secure: true });

    return true;
  } catch (error) {
    console.error(error.message);
  }
}

export const logout = (keycloak) => async (dispatch) => {
  try {
    // call the logout api    
    // remove jwt on cookies
    removeTokenCookie();

    // clear authenticated user info on store state
    dispatch(clearAuth());

    await API.logout(keycloak.clientId, keycloak.token, keycloak.refreshToken);

    keycloak.clearToken();
  } catch (error) {
    console.error(error.message);
  }
}

export const autoLogin = (keycloak) => async (dispatch) => {
  try {
    // call login api using cookie
    const { token, refreshToken } = getTokenCookie();

    if (token != null) {
      await API.loadUserInfo(keycloak.clientId, token).then((data) => {
        setKeyclockData(keycloak, token, refreshToken, true);
      }).catch();

      await dispatch(refreshTokenAPI(keycloak));
    }
  } catch (error) {
    console.error(error.message);
    removeTokenCookie();
  }
}

export const refreshTokenAPI = (keycloak) => async (dispatch) => {
  try {
    if (keycloak.token != null) {
      // call refresh token api
      // set keycloack, cookies, and state again
      await API.refreshToken(keycloak.clientId, keycloak.token, keycloak.refreshToken).then(({ data }) => {
        setKeyclockData(keycloak, data.access_token, data.refresh_token, true);
        setTokenCookie(keycloak.token, keycloak.refreshToken);
        dispatch(setAuthenticated(parseJwt(keycloak.token)));
      }).catch((error) => {
        // remove saved data if there is error
        if (error.response.status === 400) {
          removeTokenCookie();
          dispatch(clearAuth(parseJwt(keycloak.token)));
          keycloak.authenticated = false;
          keycloak.clearToken();
        }
      });
    }
  } catch (error) {
    console.error(error.message);
  }
}

const setTokenCookie = (token, refreshToken) => {
  Cookies.set('jwt', token, { sameSite: 'strict', secure: true });
  Cookies.set('refrest_jwt', refreshToken, { sameSite: 'strict', secure: true });
}

const removeTokenCookie = () => {
  Cookies.remove('jwt');
  Cookies.remove('refrest_jwt');
}

const getTokenCookie = () => {
  return { token: Cookies.get('jwt'), refreshToken: Cookies.get('refrest_jwt') };
};

const setKeyclockData = (keycloak, token, refreshToken, authenticated) => {
  keycloak.token = token;
  keycloak.refreshToken = refreshToken;
  keycloak.authenticated = authenticated;
}
