import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useEffect, useRef, useState } from 'react';
import { encodeQueryData } from "./helper";
import { message } from "antd";

message.config({
  top: 50,
  duration: 2,
  maxCount: 3,
  rtl: true
});

const concatRaceDateAndRaceNum = (requestParam, message) => {
  let result = message
  result = requestParam?.raceDate ? result.concat(`race date: ${requestParam?.raceDate}, `) : result;
  result = requestParam?.raceNum ? result.concat(`race_num: ${requestParam?.raceNum}`) : result;
  return result;
}

const createWebsocketConnection = (client, url, onOpen, onClose, onMessage, onError, setReconnect, requestParam, reconnect=null, websocketName="") => {
  if (!reconnect){
    loadingMessagePopUp(requestParam, websocketName);
  }

  client.current = new W3CWebSocket(url);

  client.current.onopen = () => {
    connectedMessagePopUp(websocketName);
    if (onOpen != null) {
      onOpen();
    } else {
      console.log(`Connected to websocket: ${url}`);
    }
  };

  client.current.onclose = (event) => {
    if (event.code === 1006) {
      !reconnect && reconnectingMessagePopUp(requestParam, websocketName);
      setReconnect(true);
      onClose != null && onClose(event);
      console.log(`[Close websocket connection] code: ${event.code}, now try reconnect to server every 5 second`);
    } else if (onClose != null) {
      onClose(event);
    } else {
      console.log(`[Close websocket connection] code: ${event.code}, reason: ${event.reason}`);
    }
    client.current = null;
  };

  client.current.onmessage = (message) => {
    if (onMessage != null) {
      const payload = JSON.parse(message.data);
      onMessage(payload);
    } else {
      console.log(`Processing message: ${message.data}`);
    }
  };

  client.current.onerror = (error) => {
    if (onError != null) {
      onError(error);
    } else {
      console.log(`Error recieved on websocket`);
    }
  };
}

const loadingMessagePopUp = (requestParam, key="updateable") => {
  let text = concatRaceDateAndRaceNum(requestParam, `Connecting to ${key} websocket...`);
  message.open(
    {
      key: key,
      type: 'loading',
      content: text,
      duration: 0
    }
  )
}

const reconnectingMessagePopUp = (requestParam, key="updateable") => {
  let text = concatRaceDateAndRaceNum(requestParam, `Reconnecting to ${key} websocket...`);
  message.open(
    {
      key: key,
      type: 'loading',
      content: text,
      duration: 0
    }
  )
}

const connectedMessagePopUp = (key="updateable") => {
  message.open(
    {
      key: key,
      type: 'success',
      content: `Real-time Update ${key} Websocket Connected`,
      duration: 1.5
    }
  )
}

const useWebSocket = ({ url, requestParam = null, allowInitialize = true, onOpen = null, onClose = null, onMessage = null, onError = null, websocketName="" }) => {
  const client = useRef();
  const [reconnect, setReconnect] = useState(false);

  // send message in form of JSON object
  const sendMessage = (message) => {
    if (client.current && message && client.current.readyState === 1) {
      client.current.send(JSON.stringify(message));
    }
  };

  // close websocket connection manually
  const closeClient = (code) => {
    if (client.current) {
      if (code === 1000) {
        client.current.onclose = () => { };
      }
      client.current.close(code);
      client.current = null;
    }
  };

  // initialise websocket connection
  useEffect(() => {
    if (url !== "" && !reconnect && !client.current && allowInitialize) {
      const newURL = requestParam ? url + "?" + encodeQueryData(requestParam) : url;
      createWebsocketConnection(client, newURL, onOpen, onClose, onMessage, onError, setReconnect, requestParam, null, websocketName);
    }
  }, [client, url, requestParam, onOpen, onClose, onMessage, onError, allowInitialize, reconnect, websocketName]);

  // terminate connection when change url (change page)
  useEffect(() => {
    return () => closeClient(1000);
  }, [url]);

  // retry connection after connection closed unexpectedly (change page)
  useEffect(() => {
    if (reconnect) {
      const newURL = requestParam ? url + "?" + encodeQueryData(requestParam) : url;
      const interval = setInterval(() => {
        if (client?.current?.readyState === 0) {
          return
        } else if (client?.current?.readyState === 1) {
          console.log(`Reconnected on ${newURL}`);
          setReconnect(false);
        } else {
          console.log(`Reconnecting on ${newURL}`);
          createWebsocketConnection(client, newURL, onOpen, onClose, onMessage, onError, setReconnect, requestParam, reconnect, websocketName);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [client, url, onOpen, onClose, onMessage, onError, reconnect, setReconnect, requestParam, websocketName]);
  
  return { sendMessage, closeClient }
};

export default useWebSocket