import { useTranslation } from 'react-i18next';

export const SecondHorseColumn = () => {
  const { t } = useTranslation();

  // column that shows all the HORSE NUM of the race
  return {
    title: () => (
      <div>
        <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)" }}>
          CI/CI
        </div>
        <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)" }}>
          Wp.CI
        </div>
        <div style={{ textAlign: "center" }}>
          D.CI
        </div>
      </div>
    ),
    align: 'center',
    children: [
      {
        title: t("race::horseHeader"),
        align: 'center',
        key: "horseNum",
        width: '2.5%',
        sorter: (a, b) => a.horseNum - b.horseNum,
        render: (_) => [
          <div style={{ textAlign: 'center' }}>{_.horseNum}</div>,
        ],
      }
    ],
  };
}