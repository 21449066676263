import { message } from "antd";
import { SCORE_ORDER_MAPPING } from "../../../components/RaceCard/roadmap/RoadMapTable";
import store from "../../reducers"

const getHorseTotalScore = (scores, givenScores) => {
  return SCORE_ORDER_MAPPING.reduce((prev, curr) => prev + (scores?.[curr] || 0) + (givenScores?.[curr] || 0), 0);
}

export const copyBetlineToClipboard = (pool, banker, totalNumberOfSelection) => {
  let text = "";
  const standardData = store.getState().standard;

  const totalNumOfHorse = standardData.additionalData.numOfHorseCurrent;
  if (totalNumOfHorse <= totalNumberOfSelection) {
    message.open(
      {
        key: "updateable",
        type: 'error',
        content: `Available num of horse is less than total number of selection`,
        duration: 1.5
      }
    )
    return;
  }

  const totalScoreList = [];
  for (let i = 1; i <= totalNumOfHorse; i++) {
    if (standardData.detailData?.[i].scratched === true) {
      continue;
    }
    const score = getHorseTotalScore(standardData.userData.scoreData?.[i], standardData.userData.userData.score?.[i]);
    totalScoreList.push({ horseNum: i, score: score });
  }
  totalScoreList.sort((a, b) => b.score - a.score);

  if (banker === "top") {
    banker = totalScoreList.shift()?.horseNum;
  } else if (banker === "secondTop") {
    banker = totalScoreList.splice(1, 1)?.[0]?.horseNum;
  }

  const selectionList = totalScoreList.slice(0, totalNumberOfSelection).map(horse => horse.horseNum);

  if (pool == null) {
    text = `${banker}>${selectionList.join(",")}`;
  } else {
    text = `${pool}:${banker}>${selectionList.join(",")}`;
  }

  navigator.clipboard.writeText(text);
  message.open(
    {
      key: "updateable",
      type: 'success',
      content: `Copy to clipboard: ${text}`,
      duration: 1.5
    }
  )
}