import { Table } from "antd";

import "./Top3WinPercentTable.css";
import { useSelector } from "react-redux";

const getColumns = () => {
  const getBackgroundColor = (index) => {
    switch (index) {
      case 0: return "rgb(255,105,180)";
      case 1: return "rgb(0, 255, 0)";
      case 2: return "rgb(145, 145, 255)";
      default: return "";
    }
  }
  return [
    {
      title: "Top N Horse",
      key: "",
      align: "center",
      width: 50,
      render: (_) => {
        return (
          <div style={{ backgroundColor: getBackgroundColor(_.index) }}>
            {_.key}
          </div>
        )
      }
    },
    {
      title: "Win %",
      key: "",
      align: "center",
      width: 50,
      render: (_) => {
        return (
          <div>
            {_.value ? (_.value * 100)?.toFixed(1) : "--"}%
          </div>
        )
      }
    }
  ];
}

const Top3WinPercentTable = () => {
  const dataSourceRaw = useSelector(state => state.standard.additionalData.top3WinPercentStatistics);
  const hottestList = useSelector(state => state.standard.additionalData?.hottestList) || [null, null, null];
  const columns = getColumns();

  if (Object.keys(dataSourceRaw || {}).length === 0) return <></>;

  const dataSource = [
    { key: hottestList?.[0], value: dataSourceRaw?.[1], index: 0 },
    { key: hottestList?.[1], value: dataSourceRaw?.[2], index: 1 },
    { key: hottestList?.[2], value: dataSourceRaw?.[3], index: 2 },
    { key: "others", value: dataSourceRaw?.others, index: 3},
  ]

  return (
    <div>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        className="Top-3-Win-Percent-Table"
        rowKey={(record, index) => index}
      />
    </div>
  )
};

export default Top3WinPercentTable;