import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  authenticated: false,
  userData: {
    // there is some other attribute in this userData when fetched from keycloak server, which is not yet useful, so ignore in here
    azp: "react-app",
    email: "",
    email_verified: true,
    exp: 1651730340,
    family_name: "LastName",
    given_name: "FirstName",
    iss: "http://localhost:8080/realms/HKJC%20Data%20Platform",
    jti: "e2d28178-5a1d-40ac-a4d6-39fd991d4d75",
    name: "FirstName LastName",
    preferred_username: "username",
    realm_access: {roles: Array(4)},
    resource_access: {
      "react-app": {
        roles: []
      }
    },
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialData,
  reducers: {
    setAuthenticated: (state, data) => {
      state.userData = data.payload;
      state.authenticated = true;
    },
    clearAuth: (state) => {
      state.authenticated = false;
      state.userData = {};
    }
  },
});

export const { setAuthenticated, clearAuth } = authSlice.actions;

export default authSlice.reducer;