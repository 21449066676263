import { Button, List, Modal, Popover } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendUpdateRoadmapData } from "../../../../services/actions/userAction";
import { setHorseNote } from "../../../../services/reducers/standardSlice";

export const NewColdDoorModal = ({ horse }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <div>
      <></>
      <Button
        onClick={showModal}
        style={{ padding: "1px" }}
        className="Button"
        shape={"circle"}
      >
        {horse.newColdDoor.score}
      </Button>
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
              fontSize: "24px",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            {horse.horseNum} - {horse.horseName}, [Score:{" "}
            {horse.newColdDoor.score}]
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleOk}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div style={{ fontSize: "20px" }}> {horse.newColdDoor.text}</div>
      </Modal>
    </div>
  );
};

export const NewColdDoorBox = ({ horse }) => {
  return (
    <>
      <div style={{ fontWeight: "bold" }}>{horse.newColdDoor.score}</div>
      <Popover
        overlayClassName="Popover"
        content={
          <div style={{ fontSize: "18px", width: "200px" }}>
            {horse.newColdDoor.text}
          </div>
        }
      >
        <div
          style={{
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            width: "100%",
            padding: "3px",
          }}
        >
          {horse.newColdDoor.text}
        </div>
      </Popover>
    </>
  );
};

const NewColdDoorColumn = (sortedInfo) => {
  return {
    title: () => <div>New Cold Door</div>,
    align: "center",
    key: "newColdDoor",
    width: 80,
    sorter: {
      compare: (a, b) => b.newColdDoor.score - a.newColdDoor.score,
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(
      ({ columnKey }) => columnKey === "newColdDoor"
    )?.["order"],
    render: (_) => {
      return <NewColdDoorBox horse={_} />;
    },
  };
};

export default NewColdDoorColumn;
