export const jockeyDataProcessing = (state, data) => {
  const payload = data.payload;
  const { profile, statistics, pastPerformance } = payload;

  profileMapping(state.jockey, profile);

  statisticsMapping(state.jockey, statistics);

  pastPerformanceMapping(state.jockey, pastPerformance);
}

export const trainerDataProcessing = (state, data) => {
  const payload = data.payload;
  const { profile, statistics, pastPerformance } = payload;

  profileMapping(state.trainer, profile);

  statisticsMapping(state.trainer, statistics);

  pastPerformanceMapping(state.trainer, pastPerformance);
}

const profileMapping = (storage, profile) => {
  if (profile) {
    storage.profile = profile;
  }
}

const statisticsMapping = (storage, statistics) => {
  if (statistics) {
    storage.statistics = statistics;
  }
}

const pastPerformanceMapping = (storage, pastPerformance) => {
  if (pastPerformance) {
    for (const entries of Object.values(pastPerformance || {})) {
      for (const entry of entries) {
        const raceDate = new Date(entry.raceDate);
        const month = raceDate.getMonth() + 1;
        const date = raceDate.getDate();
        entry.raceId = `${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}#${entry.raceNum}`;    
      }
    }
    storage.pastPerformance = pastPerformance;
  }
}
