import { Col, Row } from "antd"

const layout = (topFirst, topSecond, topThird, topFourth, bottomFirst, bottomSecond, bottomThird, bottomFourth) => {
  return (
    <div>
      <Row className="Border-Bottom">
        <Col span={3} className="Border-Right" style={topFirst.style}>
          {topFirst.value}
        </Col>
        <Col span={5} className="Border-Right" style={topSecond.style}>
          {topSecond.value}
        </Col>
        <Col span={4} className="Border-Right" style={topThird.style}>
          {topThird.value}
        </Col>
        <Col span={12} style={topFourth.style}>
          {topFourth.value}
        </Col>
      </Row>
      <Row>
        <Col span={6} className="Border-Right" style={bottomFirst.style}>
          {bottomFirst.value}
        </Col>
        <Col span={6} className="Border-Right" style={bottomSecond.style}>
          {bottomSecond.value}
        </Col>
        <Col span={6} className="Border-Right" style={bottomThird.style}>
          {bottomThird.value}
        </Col>
        <Col span={6} style={bottomFourth.style}>
          {bottomFourth.value}
        </Col>
      </Row>
    </div>
  )
}

export const MiscellaneousColumn = () => {
  const highlightStyle = { fontWeight: "bold", textDecoration: "underline" };
  const getHighlightIndex = (odds) => {
    if (odds <= 3.5) {
      return 0;
    } else if (odds <= 8) {
      return 1;
    } else if (odds <= 17) {
      return 2;
    } else if (odds > 17) {
      return 3;
    }
  };

  return {
    title: layout(
      { value: "V" },
      { value: "L" },
      { value: "D" },
      { value: "Weight" },
      { value: "Fav" },
      { value: "Sup" },
      { value: "Inf" },
      { value: "Unw" }
    ),
    align: 'center',
    key: 'venue',
    width: 200,
    fixed: 'left',
    render: (_) => {
      const index = getHighlightIndex(_.starters?.find(horse => horse.placeNum === _.placeNum)?.odds);
      return layout(
        { value: _.venue || _.raceVenue},
        { value: _.length || _.raceLength},
        { value: _.drawNum },
        { value: `${_.horseWeight}/${_.handicapWeight || _.horseHandicapWeight || "--" }` },
        { value: _.gameMap[0], style: index === 0 ? highlightStyle : null },
        { value: _.gameMap[1], style: index === 1 ? highlightStyle : null  },
        { value: _.gameMap[2], style: index === 2 ? highlightStyle : null  },
        { value: _.gameMap[3], style: index === 3 ? highlightStyle : null  }
      );
    }
  }
}