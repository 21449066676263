import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LastUpdate = ({target}) => {
  const { t } = useTranslation();
  const lastUpdate = useSelector(state => state[target].updateTS);
  
  // show the time of "Last Update" in hh:mm:ss format
  return (
    <div style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 20, fontSize: "16px" }}>
      {t("race::lastUpdate")}: <span style={{ fontWeight: "bold" }}>{lastUpdate}</span>
    </div>
  )
}

export default LastUpdate;