import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";

const colorMapper = (value) => {
  if (value == null) {
    return null;
  }
  if (value < -3) {
    return "rgb(255,165,0)";
  } else if (value <= 3) {
    return "rgb(0, 255, 0)";
  } else if (value <= 9) {
    return "rgba(135, 206, 255)";
  } else if (value > 9) {
    return "rgb(255, 0, 255)";
  }
};

const InvestmentDiff = ({
  upper,
  bottomLeft,
  bottomRight,
  colorCodes = [null, null, null],
}) => {
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          borderBottom: "1px solid rgb(0, 0, 0)",
          backgroundColor: colorCodes[0],
        }}
      >
        {upper}
      </div>
      <Row>
        <Col
          span={12}
          style={{
            borderRight: "1px solid rgb(0, 0, 0)",
            backgroundColor: colorCodes[1],
          }}
        >
          {bottomLeft}
        </Col>
        <Col span={12} style={{ backgroundColor: colorCodes[2] }}>
          {bottomRight}
        </Col>
      </Row>
    </div>
  );
};

export const InvestmentDiffColumn = () => {
  const { t } = useTranslation();

  return {
    title: t("race::winInvestmentDiffHeader"),
    align: "center",
    children: [
      {
        title: () => {
          return (
            <InvestmentDiff upper="3-0" bottomLeft="3-1" bottomRight="3-2" />
          );
        },
        align: "center",
        key: "investmentWinDiff",
        width: 80,
        render: (_) => {
          return (
            <InvestmentDiff
              upper={`${
                _?.winInvestmentDiff?.zero != null
                  ? _.winInvestmentDiff.zero
                  : "-"
              }%`}
              bottomLeft={`${
                _?.winInvestmentDiff?.one != null
                  ? _.winInvestmentDiff.one
                  : "-"
              }%`}
              bottomRight={`${
                _?.winInvestmentDiff?.two != null
                  ? _.winInvestmentDiff.two
                  : "-"
              }%`}
              colorCodes={[
                colorMapper(_?.winInvestmentDiff?.zero),
                colorMapper(_?.winInvestmentDiff?.one),
                colorMapper(_?.winInvestmentDiff?.two),
              ]}
            />
          );
        },
      },
      {
        title: "3-L",
        align: "center",
        key: "velocity",
        width: 50,
        render: (_) => {
          return (
            <div
              style={{
                backgroundColor: colorMapper(_?.winInvestmentDiff?.latest),
              }}
            >
              {`${_?.winInvestmentDiff?.latest || "-"}%`}{" "}
            </div>
          );
        },
      },
    ],
  };
};
