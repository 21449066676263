import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import keycloak from '../services/keycloak';
import { RaceSelector } from '../components/RaceCard/RaceSelector'
import HorseHideInfo from '../components/RaceCard/HorseHideInfo';
import LastUpdate from '../components/RaceCard/LastUpdate';
import DoubleTable from '../components/RaceCard/double';
import ControlPanel from '../components/drawers/ControlPanel';
import { removeDoubleData } from '../services/reducers/doubleSlice';
import useWebSocket from '../services/utils/useWebSocket';
import { handleDoubleCardEvent } from '../services/actions/raceAction/double';
import { DoubleCardDrawerItems } from '../components/drawers/DoubleCardDrawerItems';
import API_URL_DOMAIN from '../middleware/BaseURL';

export const DoubleCard = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();

  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedDoubleNum = useSelector(state => state.race.selectedRaceNum);
  const { hideRaceTitle, hideRacePicker, hideResetButton } = useSelector(state => state.drawer.doubleCardDrawer.hideInfo);
  const isReplaying = useSelector(state => state.race.isReplaying);
  const websocketURL = !isReplaying ? `wss://${API_URL_DOMAIN}/ui/v1/ws/double-race-card` : "";

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      doubleNum: selectedDoubleNum,
      authorization: `${keycloak.token}`
    },
    allowInitialize: !isReplaying && selectedRaceDate != null, 
    onMessage: payload => dispatch(handleDoubleCardEvent(payload, selectedRaceDate, selectedDoubleNum)),
    websocketName: "double page",
  })

  // fetch latest double data using selected race date, race num, and interval
  useEffect(() => {
    dispatch(removeDoubleData())
  }, [dispatch, selectedRaceDate, selectedDoubleNum]);

  useEffect(() => {
    sendMessage({ "race_date": selectedRaceDate, "double_num": selectedDoubleNum });
  }, [sendMessage, selectedRaceDate, selectedDoubleNum]);

  return (
    <>
      <ControlPanel DrawerItems={DoubleCardDrawerItems} />
      <div style={{ paddingTop: '16px' }}>
        <div style={{ display: 'flex' }}>
          {!hideRaceTitle && <LastUpdate target="double" />}
          {!hideRaceTitle && <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{t("doubleCard")}</span>}
        </div>
        <div style={{ display: "flex" }}>
          {!hideRacePicker && <RaceSelector useDouble={true} />}
          {!hideResetButton && <HorseHideInfo />}
        </div>
        <DoubleTable />
      </div>
    </>
  )
}
export default DoubleCard;
