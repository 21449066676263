import { useTranslation } from "react-i18next";

const ForecastQuinellaColumn = (sortedInfo, signalData) => {
  const { t } = useTranslation();

  return {
    title: t("roadmap::fqHeader"),
    align: 'center',
    key: `fq`,
    width: 45,
    sorter: {
      compare: (a, b) => signalData?.fqScoreSum?.[b.horseNum] - signalData?.fqScoreSum?.[a.horseNum],
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `fq`)?.['order'],
    render: (_) => {
      const fQStatistics = _?.fQStatistics;
      return (
        <div>
          <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.5)" }}>
            {signalData?.fqScoreSum?.[_.horseNum] || "-"}
          </div>
          <div
            style={
              {
                color: fQStatistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: fQStatistics?.jockey?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }
            }
          >
            {fQStatistics?.jockey?.win_percentage != null ? (fQStatistics.jockey.win_percentage * 100).toFixed(1) : "-"}%
          </div>
          <div style={{ color: fQStatistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)", fontWeight: fQStatistics?.trainer?.within_range ? "bold" : null }}>
            {fQStatistics?.trainer?.win_percentage != null ? (fQStatistics.trainer.win_percentage * 100).toFixed(1) : "-"}%
          </div>
        </div>
      )
    }
  }
}

export default ForecastQuinellaColumn;