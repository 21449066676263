import { Col, Row } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ControlPanel from "../components/drawers/ControlPanel";
import { PastPerformanceList } from "../components/PastPerformance/PastPerformanceList";
import PastPerformanceRaceIdTable from "../components/PastPerformance/PastPerformanceRaceIdTable";
import { RaceInfo } from "../components/RaceCard/RaceInfo";
import { RaceSelector } from "../components/RaceCard/RaceSelector";
import { loadPastPerformanceData } from "../services/actions/raceAction/pastPeformance";
import { removePerformanceData } from "../services/reducers/pastPerformanceSlice";

const PastPerformance = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);

  const DrawerItems = () => {
    return (
      <div>
        a,b,c
      </div>
    )
  }

  useEffect(() => {
    dispatch(removePerformanceData());
    dispatch(loadPastPerformanceData(selectedRaceDate, selectedRaceNum));
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  return (
    <>
      <ControlPanel DrawerItems={DrawerItems} />
      <div style={{ paddingTop: '16px' }}>
        <Row>
          <Col span={15}>
            <div style={{ textAlign: "left", paddingLeft: "20px" }}>
              <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t("pastPerformance")}</span>
            </div>

            <div>
              <RaceSelector />
              <RaceInfo store="pastPerformance" />
            </div>
          </Col>
          <Col span={9}>
            <PastPerformanceRaceIdTable />
          </Col>
        </Row>

        <div>
          <PastPerformanceList />
        </div>
      </div>
    </>
  )
}

export default PastPerformance;
