import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBackgroundColor } from './CrossColumn';

export const CurrentColumn = (aliasData) => {
  const { t } = useTranslation();

  const nextWinOddsList = useSelector(state => state.double.additionalData.nextWinOddsList) || [];

  // column that shows all the JOCKEY/HORSENUM/TRAINER of the NEXT RACE
  return {
    title: () => (
      <div>
        <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)" }}>
          CI/CI
        </div>
        <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)" }}>
          Wp.CI
        </div>
        <div style={{ textAlign: "center" }}>
          D.CI
        </div>
      </div>
    ),
    align: 'center',
    children: [
      {
        title: t("race::horseHeader"),
        align: 'center',
        key: "horse",
        width: '2.5%',
        sorter: (a, b) => a.horseNum - b.horseNum,
        render: (_) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: "4px", backgroundColor: getBackgroundColor(nextWinOddsList.indexOf(_.horseNum) + 1) }}>
              <div style={{ textAlign: 'center' }}>{aliasData?.jockey?.[_?.code?.jockey]}</div>
              <div style={{ textAlign: 'center' }}>{_?.horseNum}</div>
              <div style={{ textAlign: 'center' }}>{aliasData?.trainer?.[_?.code?.trainer]}</div>
            </div>

          )
        }
      }
    ],
  };
}