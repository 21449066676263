const WinOddsColumn = (sortedInfo, header) => {
  return {
    // wPOdds and CI at interval latest
    title: header.wPRatio.latest,
    align: 'center',
    children: [
      {
        title: 'latest',
        align: 'center',
        key: 'winOddsLast',
        width: 80,
        sorter: {
          compare: (a, b) => {
            if (a.winPlaceOdds.latest < 0) {
              return 999;
            } else if (b.winPlaceOdds.latest < 0) {
               return -1;
            } else {
              return a.winPlaceOdds.latest - b.winPlaceOdds.latest;
            }
          },
          multiple: 1,
        },
        sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === 'winOddsLast')?.['order'],
        onCell: () => {
          return {
            className: "Highlighted-Win-Column Disable-Row-Hover"
          }
        },
        render: (_) => {
          return (
            <div>
              <div style={{ textAlign: 'center', fontSize: "20px", fontWeight: "bold" }} >{_.winPlaceOdds.latest}</div>
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '4px' }}>
                <div style={{ textAlign: 'left' }}>{_.winQWinRatio ? _.winQWinRatio.toFixed(2) : '-'}</div>
                <div style={{ textAlign: 'right' }}>{_.confidentIndex.latest}</div>
              </div>
            </div>
          )
        }
      }
    ],
  }
}

export default WinOddsColumn;
