import { useTranslation } from "react-i18next";

export const ExpectedWinOddsColumn = () => {
  const { t } = useTranslation();

  // column that shows the win odds
  return {
    title: <span style={{ fontSize: "12px" }}>Expected</span>,
    align: "center",
    children: [
      {
        // column of double odds with the winning horse last race
        title: "Win",
        align: "center",
        children: [
          {
            title: "Odds",
            align: "center",
            key: "expectedWinOdds",
            width: 45,
            render: (_) => {
              let ratio = null;
              if (_.winPlaceOdds?.latest && _.expectedWinOdds?.latest) {
                ratio = (
                  _.expectedWinOdds.latest / _.winPlaceOdds.latest
                ).toFixed(2);
              }
              return (
                <>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "4px",
                    }}
                  >
                    {_.expectedWinOdds?.latest?.toFixed(1) || "--"}
                  </div>
                  <div>{ratio || "--"}</div>
                </>
              );
            },
          },
        ],
      },
    ],
  };
};
