import { createSlice } from "@reduxjs/toolkit";
import { initialData, reset } from "./common";
import { roadmapProcessingWS, standardDataProcessingWS } from "./handlingWS";
import { decisionTreeHandling } from "./logic/statistics";
import { calucateTotalScore, handleColdDoorScore, handleHorseNote, handleHorseNoteFromAPI, handleHorseScore, handleResetHorseNoteData, handleSetHorseTrackworkGrade, updateSelectiveRanking } from "./logic/scores";

const standardSlice = createSlice({
  name: "standard",
  initialState: initialData(),
  reducers: {
    setStandardDataWS: (state, data) => {
      standardDataProcessingWS(state, data);
    },
    removeStandardData: (state) => {
      // manually remove the all data and reset them if necessary
      reset(state);
    },
    setSortedInfo: (state, data) => {
      // set the sorting info of ant table
      state.sortedInfo = data.payload;
    },
    resetSortedInfo: (state) => {
      // reset the sorting info of ant table
      state.sortedInfo = [];
      for (const horseNum of Object.keys(state.detailData)) {
        state.detailData[horseNum].selected = false;
      }
    },
    appendSelectedHorse: (state, data) => {
      state.detailData[data.payload["horseNum"]].selected = data.payload["selected"];
    },
    setSimulationHorseNum: (state, data) => {
      state.additionalData.simulationHorseNum = Number(data.payload);
    },
    setHorseScore: (state, data) => {
      handleHorseScore(state, data);
    },
    setHorseNote: (state, data) => {
      handleHorseNote(state, data);
    },
    setHorseNoteFromAPI: (state, data) => {
      handleHorseNoteFromAPI(state, data);
    },
    resetHorseNoteData: (state, data) => {
      handleResetHorseNoteData(state, data);
    },
    setHorseTrackworkGrade: (state, data) => {
      handleSetHorseTrackworkGrade(state, data);
    },
    setRoadmapData: (state, data) => {
      roadmapProcessingWS(state, data);
    },
    resetRoadmapDataRow: (state, data) => {
      const { horseNum } = data.payload;
      state.userData.userData.score[horseNum] = {};
      calucateTotalScore(state.userData, state.decisionTree);
    },
    changeSelectedUser: (state, data) => {
      const { targetIndex, id } = data.payload;
      state.userData.targetUser[targetIndex] = id;
    },
    setSimulationGameMap: (state, data) => {
      state.additionalData.gameMap.simulation = data.payload;
    },
    setColdDoorData: (state, data) => {
      handleColdDoorScore(state.userData.scoreData, data.payload.data.payload, data.payload.raceNum, state);
    },
    setSnapshotTriggered: (state, data) => {
      state.userData.snapshotTriggered = data.payload;
    },
    setSelectiveRanking: (state, data) => {
      updateSelectiveRanking(state, data);
    },
    setSortingHorseNumList: (state, data) => {
      state.additionalData.sortingHorseNumList = data.payload;
    },
    setDecisionTreeTimeline: (state, data) => {
      state.decisionTree.timelineSelected = data.payload;
    },
    setMockDecisionTreeHandling: (state, data) => {
      const mock = {};
      const d = JSON.parse(data.payload)
      mock[d.update_ts] = d;
      decisionTreeHandling(state.decisionTree, mock);
    }
  }
});

export const {
  setStandardDataWS,
  removeStandardData,
  setSortedInfo,
  resetSortedInfo,
  appendSelectedHorse,
  setSimulationHorseNum,
  setHorseScore,
  setHorseNote,
  setHorseNoteFromAPI,
  resetHorseNoteData,
  setHorseTrackworkGrade,
  setRoadmapData,
  changeSelectedUser,
  resetRoadmapDataRow,
  setSimulationGameMap,
  setColdDoorData,
  setSnapshotTriggered,
  setSelectiveRanking,
  setSortingHorseNumList,
  setDecisionTreeTimeline,
  setMockDecisionTreeHandling,
} = standardSlice.actions;

export default standardSlice.reducer;