import { Button, Col, Row, Table, message } from "antd";
import { useSelector } from "react-redux";
import "./RecommendationTable.css";
import RecommendationLLMDetail from "./RecommendationLLMDetail";
import RecommendationLLMPrompt from "./RecommendationLLMPrompt";
import { CopyTwoTone } from "@ant-design/icons";
import dataMapping from "./mapping";

const highlightedStyle = {
  0: {
    textDecoration: "underline",
  },
  1: {
    backgroundColor: "rgba(255, 255, 0, 1)",
    color: "rgba(255, 0, 0, 1)",
    textDecoration: "underline",
  },
  3: {
    backgroundColor: "rgba(255, 165, 0, 1)",
    color: "rgba(0, 0, 0, 0.7)",
    textDecoration: "underline",
    fontWeight: "bold",
  },
  deselect: { textDecoration: "line-through" },
  double: {
    border: "2px solid blue",
    borderRadius: "5px",
    paddingBottom: "1px",
  },
};

const verticallyAlignCenter = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};

const Ranking = ({ ranking, entryStyle, targetNumOfRanking }) => {
  if (ranking == null) {
    return <></>;
  }
  return (
    <>
      <Row>
        <Col span={4}>
          {ranking.slice(0, 3).map((horseNum) => {
            return (
              <span
                style={{
                  ...(entryStyle(horseNum) || {}),
                  margin: "0 3px",
                }}
                key={horseNum}
              >
                {horseNum}
              </span>
            );
          })}
        </Col>
        <Col span={1}>
          {ranking.length > 3 ? (
            <span
              style={{
                margin: "0 8px",
                color: "rgba(0,0,0,0.4)",
              }}
              key={"separator_4"}
            >
              &#8285;
            </span>
          ) : (
            <></>
          )}
        </Col>
        <Col span={7}>
          {ranking.slice(3, 9).map((horseNum) => {
            return (
              <span
                style={{
                  ...(entryStyle(horseNum) || {}),
                  margin: "0 3px",
                }}
                key={horseNum}
              >
                {horseNum}
              </span>
            );
          })}
        </Col>
        <Col span={1}>
          {ranking.length > 9 ? (
            <span
              style={{
                margin: "0 8px",
                color: "rgba(0,0,0,0.4)",
              }}
              key={"separator_8"}
            >
              &#8285;
            </span>
          ) : (
            <></>
          )}
        </Col>
        <Col span={7}>
          {ranking.slice(9).map((horseNum) => {
            return (
              <span
                style={{
                  ...(entryStyle(horseNum) || {}),
                  margin: "0 3px",
                }}
                key={horseNum}
              >
                {horseNum}
              </span>
            );
          })}
        </Col>
      </Row>
    </>
  );
};

const RankingTop3Next5 = ({ rankingData, entryStyle, targetNumOfRanking }) => {
  return (
    <>
      <Row>
        <Col span={4}>
          {rankingData?.top3?.ranking ? (
            rankingData?.top3?.ranking.slice(0, 3).map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            })
          ) : (
            <>---</>
          )}
        </Col>
        <Col span={1}>
          <span
            style={{
              margin: "0 8px",
              color: "rgba(0,0,0,0.5)",
            }}
            key={"separator_4"}
          >
            &#8285;
          </span>
        </Col>
        <Col span={7}>
          {rankingData?.next5?.ranking ? (
            rankingData?.next5?.ranking.slice(0, 6).map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            })
          ) : (
            <>---</>
          )}
        </Col>
        <Col span={1}>
          {rankingData?.next5?.ranking.length > 4 ? (
            <span
              style={{
                margin: "0 8px",
                color: "rgba(0,0,0,0.4)",
              }}
              key={"separator_8"}
            >
              &#8285;
            </span>
          ) : (
            <></>
          )}
        </Col>
        <Col span={7}>
          {rankingData?.others?.ranking ? (
            rankingData?.others?.ranking.slice(0, 5).map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            })
          ) : (
            <>---</>
          )}
        </Col>
      </Row>
    </>
  );
};

const RebornByRace = ({ race, dataKey, entryStyle }) => {
  return (
    <div>
      <Row>
        <Col span={4}>{dataKey}:</Col>
        <Col span={20}>
          <span>
            {race?.level1?.map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            }) || "--"}
          </span>
          <span
            style={{
              margin: "0 8px",
              color: "rgba(0,0,0,0.4)",
            }}
            key={"separator_level1"}
          >
            &#8285;
          </span>
          <span>
            {race?.level2?.map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            }) || "--"}
          </span>
          <span
            style={{
              margin: "0 8px",
              color: "rgba(0,0,0,0.4)",
            }}
            key={"separator_level2"}
          >
            &#8285;
          </span>
          <span>
            {race?.level3?.map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            }) || "--"}
          </span>
          <span
            style={{
              margin: "0 8px",
              color: "rgba(0,0,0,0.4)",
            }}
            key={"separator_level3"}
          >
            &#8285;
          </span>
          <span>
            {race?.level4?.map((horseNum) => {
              return (
                <span
                  style={{
                    ...(entryStyle(horseNum) || {}),
                    margin: "0 3px",
                  }}
                  key={horseNum}
                >
                  {horseNum}
                </span>
              );
            }) || "--"}
          </span>
        </Col>
      </Row>
    </div>
  );
};

const RankingEntry = ({ data }) => {
  const reborn = data?.gpt4?.reborn || [];
  const double_signal = data?.gpt4?.doubleSignal || [];
  const reborn_1 = data?.gpt4?.reborn1 || [];
  const reborn_3 = data?.gpt4?.reborn3 || [];
  const deselect = data?.gpt4?.deselect || [];
  const historicalReborn = data?.gpt4?.historicalReborn || [];
  const highlightTop3 =
    data?.llm?.isTop3 || !(data?.llm?.isTop3 || data?.llm?.isNext5);
  const highlightNext5 = data?.llm?.isNext5;

  const getHighlightStyle = (horseNum) => {
    let result = {};
    if (reborn.length > 0 && reborn.includes(horseNum)) {
      result = highlightedStyle[0];
    }
    if (reborn_1.length > 0 && reborn_1.includes(horseNum)) {
      result = { ...result, ...highlightedStyle[1] };
    }
    if (reborn_3.length > 0 && reborn_3.includes(horseNum)) {
      result = { ...result, ...highlightedStyle[3] };
    }
    if (deselect.length > 0 && deselect.includes(horseNum)) {
      result = { ...result, ...highlightedStyle.deselect };
    }
    if (double_signal.length > 0 && double_signal.includes(horseNum)) {
      result = { ...result, ...highlightedStyle.double };
    }
    return result;
  };

  const onClick = () => {
    let text = `llm:`;
    const { ranking, reborn } = data?.gpt4 || {};

    text = text.concat(
      (ranking || []).filter((rank) => !(reborn || []).includes(rank)).join(",")
    );
    if ((reborn || []).length > 0) {
      text = text.concat(",");
      text = text.concat(reborn.map((horseNum) => `(${horseNum})`).join(","));
    }
    navigator.clipboard.writeText(text);
    message.open({
      key: "updateable",
      type: "success",
      content: `Copy to clipboard: ${text}`,
      duration: 1.5,
    });
  };

  const getTime = (key) => {
    if (!(data?.gpt4?.[key] || data?.top3?.[key] || data?.next5?.[key]))
      return "--";
    const time = new Date(
      data?.gpt4?.[key] || data?.top3?.[key] || data?.next5?.[key]
    );
    const year = time.getFullYear();
    const month = ("0" + (time.getMonth() + 1)).slice(-2); // Add leading zero if necessary
    const day = ("0" + time.getDate()).slice(-2); // Add leading zero if necessary

    // Get the hour, minute, and second components of the date
    const hour = ("0" + time.getHours()).slice(-2); // Add leading zero if necessary
    const minute = ("0" + time.getMinutes()).slice(-2); // Add leading zero if necessary
    const second = ("0" + time.getSeconds()).slice(-2); // Add leading zero if necessary

    // Concatenate the components into a string with the desired format
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  function compareN5Lists(data) {
    // Mami ranking is the fourth item to the ninth item of the live ranking
    if (data?.next5?.ranking && data?.liveRanking?.ranking) {
      let next5Ranking = data?.next5?.ranking.slice(0, 5);
      let mamiRanking = data?.liveRanking?.ranking.slice(3, 9);
      if (
        (next5Ranking[0] === 1 && next5Ranking[1] === 1) ||
        (mamiRanking[0] === 1 && mamiRanking[1] === 1)
      ) {
        return "";
      } else if (next5Ranking[0] === mamiRanking[0]) {
        return "N5: G ";
      } else if (
        compareSets([...mamiRanking.slice(0, 2)], [...next5Ranking.slice(0, 2)])
      ) {
        return "N5: S ";
      } else if (
        compareSets([...mamiRanking.slice(0, 3)], [...next5Ranking.slice(0, 3)])
      ) {
        return "N5: S ";
      } else {
        return "N5: F ";
      }
    }
  }

  function compareT3Lists(data) {
    if (data?.top3?.ranking && data?.liveRanking?.ranking) {
      let top3Ranking = data?.top3?.ranking.slice(0, 3);
      let mamiRanking = data?.liveRanking?.ranking.slice(0, 3);
      if (
        (top3Ranking[0] === 1 && top3Ranking[1] === 1) ||
        (mamiRanking[0] === 1 && mamiRanking[1] === 1)
      ) {
        return "";
      } else if (top3Ranking[0] === mamiRanking[0]) {
        return "T3: G ";
      } else {
        return "T3: F ";
      }
    }
  }

  function compareSets(setA, setB) {
    if (setA.length !== setB.length) {
      return false;
    }
    for (let item of setA) {
      if (!setB.includes(item)) {
        return false;
      }
    }
    return true;
  }

  return (
    <Row>
      <Col span={2} style={verticallyAlignCenter}>
        {data && <RecommendationLLMPrompt data={data} minute={data?.minute} />}
      </Col>
      <Col
        span={3}
        style={{ fontWeight: "bold", fontSize: "10px", margin: "auto 0" }}
      >
        <div>
          <div style={{ color: "green", fontSize: "12px" }}>
            <div>
              <span style={{ color: "blue", fontSize: "14px" }}>
                {data?.minute === 10 || data?.minute === 3
                  ? compareT3Lists(data)
                  : ""}
              </span>
              -{" "}
              <span style={{ color: "blue", fontSize: "14px" }}>
                {data?.minute === 10 || data?.minute === 3
                  ? compareN5Lists(data)
                  : ""}
              </span>
            </div>
            {data?.minute === 0 ? (
              data?.command_0min?.betDescision ? (
                data?.command_0min?.betDescision
              ) : data?.gpt4?.betStrategy ? (
                <div style={{ color: "blue", display: "inline" }}>
                  {data?.gpt4?.betStrategy}
                </div>
              ) : (
                "None"
              )
            ) : data?.gpt4?.betStrategy ? (
              data?.gpt4?.betStrategy
            ) : (
              "None"
            )}
            {/* {" - "}
            {data?.gpt4?.top2Prediction ? data?.gpt4?.top2Prediction : ""} */}
          </div>
        </div>

        <div style={{ fontSize: "14px" }}>
          ndcg_llm:{" "}
          {data?.gpt4?.ndcgLlm !== undefined ? (
            data.gpt4.ndcgLlm === 0 ? (
              "0"
            ) : (
              data.gpt4.ndcgLlm
            )
          ) : (
            <></>
          )}
        </div>
        <div style={{ fontSize: "14px" }}>
          ndcg_roadmap:{" "}
          {data?.gpt4?.ndcgRoadmap !== undefined ? (
            data.gpt4.ndcgRoadmap === 0 ? (
              "0"
            ) : (
              data.gpt4.ndcgRoadmap
            )
          ) : (
            <></>
          )}
        </div>
      </Col>
      <Col span={3} style={{ fontWeight: "bold", ...verticallyAlignCenter }}>
        {data?.minute}
      </Col>
      <Col span={11}>
        <div>
          <Row>
            <Col span={3}></Col>
            <Col span={3}>{"Live: "}</Col>
            <Col span={18}>
              {data?.liveRanking?.ranking ? (
                <Ranking
                  ranking={data.liveRanking.ranking}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div>

        {/* <div style={{ color: "rgb(120, 120, 120)" }}>
          <Row>
            <Col span={3}></Col>
            <Col span={3}>{"Q-F: "}</Col>
            <Col span={18}>
              {data?.gpt4?.ranking ? (
                <Ranking
                  ranking={data.gpt4.ranking}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div> */}

        {/* <div>
          <Row>
            <Col span={4}>
              {"GPT 3: "}
            </Col>
            <Col span={20}>
              {data?.top2Ranking ? <Ranking ranking={data.top2Ranking} entryStyle={getHighlightStyle} targetNumOfRanking={data.ranking?.length} /> : "--"}
            </Col>
          </Row>
        </div> */}

        <div>
          <Row>
            <Col span={3}></Col>
            <Col span={3}>
              <span>{"TNOs: "}</span>
            </Col>
            <Col span={18}>
              {data?.top3?.ranking ||
              data?.next5?.ranking ||
              data?.others?.ranking ? (
                <RankingTop3Next5
                  rankingData={data}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div>

        {/* <div>
          <Row>
            <Col span={4}>
              <span
                style={{
                  backgroundColor: highlightNext5
                    ? "rgba(245, 241, 16, 1)"
                    : "",
                }}
              >
                {"Next 5: "}
              </span>
            </Col>
            <Col span={20}>
              {data?.next5?.ranking ? (
                <Ranking
                  ranking={data.next5.ranking}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div> */}

        <div style={{ color: "rgb(120, 120, 120)" }}>
          <Row>
            <Col span={3}>
              {data?.gpt4?.hotRoadmapMovement
                ? "[" + data?.gpt4?.hotRoadmapMovement.join(", ") + "]"
                : ""}
            </Col>
            <Col span={3}>{"RMap: "}</Col>
            <Col span={18}>
              {data?.gpt4?.roadmapRanking ? (
                <Ranking
                  ranking={data.gpt4.roadmapRanking}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div>
        <div style={{ color: "rgb(120, 120, 120)" }}>
          <Row>
            <Col span={3}></Col>
            <Col span={3}>{"HotL: "}</Col>
            <Col span={18}>
              {data?.gpt4?.hotAscending ? (
                <Ranking
                  ranking={data.gpt4.hotAscending}
                  entryStyle={getHighlightStyle}
                />
              ) : (
                "--"
              )}
            </Col>
          </Row>
        </div>
      </Col>
      {/* <Col span={1} style={verticallyAlignCenter}>
        <Button
          icon={<CopyTwoTone />}
          className="Button"
          size="small"
          onClick={onClick}
        />
      </Col> */}
      {/* <Col span={1} style={verticallyAlignCenter}>
        <RecommendationLLMDetail data={data?.llm} />
      </Col> */}
      <Col span={5}>
        <div>
          <Row>
            <Col span={4}>Lv 0:</Col>
            <Col span={20}>
              {reborn &&
                reborn.map((horseNum) => {
                  const style = getHighlightStyle(horseNum);
                  return style ? (
                    <span
                      style={{
                        ...style,
                        margin: "0 3px",
                        whiteSpace: "nowrap",
                      }}
                      key={horseNum}
                    >
                      {horseNum}
                    </span>
                  ) : (
                    <></>
                  );
                })}
              {reborn.length !== 0 && historicalReborn.length !== 0 && (
                <span
                  style={{
                    margin: "0 8px",
                    color: "rgba(0,0,0,0.4)",
                  }}
                  key={"separator_4"}
                >
                  &#8285;
                </span>
              )}
              {historicalReborn &&
                historicalReborn.map((horseNum) => {
                  return (
                    <span
                      style={{
                        margin: "0 3px",
                        whiteSpace: "nowrap",
                      }}
                      key={horseNum}
                    >
                      {horseNum}
                    </span>
                  );
                })}
              {reborn.length === 0 && historicalReborn.length === 0 && "--"}
            </Col>
          </Row>
        </div>
        <div>
          <RebornByRace
            race={data?.gpt4?.allRaceReborn}
            dataKey="All"
            entryStyle={getHighlightStyle}
          />
        </div>
        <div>
          <RebornByRace
            race={data?.gpt4?.coldRaceReborn}
            dataKey="Cold"
            entryStyle={getHighlightStyle}
          />
        </div>
        <div>
          <Row>
            <Col span={4}>Doub:</Col>
            <Col span={20}>
              {double_signal &&
                double_signal.map((horseNum) => {
                  const style = getHighlightStyle(horseNum);
                  return style ? (
                    <span
                      style={{
                        ...style,
                        margin: "0 3px",
                        whiteSpace: "nowrap",
                      }}
                      key={horseNum}
                    >
                      {horseNum}
                    </span>
                  ) : (
                    <></>
                  );
                })}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const getColumns = (header) => {
  return {
    title: header,
    align: "center",
    width: "50%",
    key: header,
    render: (_, _2, index) => {
      if (index === 0) {
        return (
          <Row>
            <Col span={2} style={{ fontWeight: "bold" }}>
              Prompt
            </Col>
            <Col span={3} style={{ fontWeight: "bold" }}>
              Bet-Info
            </Col>
            <Col span={3} style={{ fontWeight: "bold" }}>
              Minute
            </Col>
            <Col span={11} style={{ fontWeight: "bold" }}>
              Ranking
            </Col>
            {/* <Col span={1} style={{ fontWeight: "bold" }}>
              Copy
            </Col> */}
            {/* <Col span={1} style={{ fontWeight: "bold" }}>
              Detail
            </Col> */}
            <Col span={3} style={{ fontWeight: "bold" }}>
              Reborn
            </Col>
          </Row>
        );
      }
      return <RankingEntry data={_} />;
    },
  };
};

const RecommendationLLMTable = ({ start = 0, end = null }) => {
  const recommendationData = useSelector(
    (state) => state.standard.recommendation
  );
  const command_0min = useSelector((state) => state.standard.command);
  const {
    recommendLLM,
    recommendLLMGPT4,
    recommendLLMTop3,
    recommendLLMNext5,
    recommendLLMOthers,
    recommendLLMLiveRanking,
  } = recommendationData;

  const tableData = Object.values(
    dataMapping(
      recommendLLM,
      recommendLLMGPT4,
      recommendLLMTop3,
      recommendLLMNext5,
      recommendLLMOthers,
      recommendLLMLiveRanking,
      command_0min
    )
  )
    .sort((a, b) => {
      return a.minute - b.minute;
    })
    .slice(start, end ? end : undefined);

  if (start > tableData.length) return <></>;

  const columns = [getColumns("Recommendation Engine LLM")];
  return (
    <div
      style={{ padding: "8px 20px", width: "1500px" }}
      className="Recommendation-Table-Wrapper"
    >
      <span>
        <span>Deselect: </span>
        <span style={{ fontSize: "16px", textDecoration: "line-through" }}>
          10
        </span>
        <span style={{ marginLeft: "16px" }}>Investment Difference: </span>
        <span style={{ fontSize: "16px" }}>
          <label
            style={{
              backgroundColor: highlightedStyle[1].backgroundColor,
              color: highlightedStyle[1].color,
            }}
          >
            &#9679;{">"}1
          </label>
          <label
            style={{
              backgroundColor: highlightedStyle[3].backgroundColor,
              color: highlightedStyle[3].color,
              marginLeft: "4px",
            }}
          >
            &#9679;{">"}3
          </label>
        </span>
        <span style={{ marginLeft: "16px" }}>
          Lv 0 Reborn:{" "}
          <span style={{ textDecoration: "underline" }}>Recent</span> &#8285;
          Historical
        </span>
      </span>
      <Table
        pagination={false}
        // loading={recommendLLM.length === 0}
        dataSource={[{}, ...tableData]}
        columns={columns}
        className="Recommendation-Table"
        style={{}}
        rowKey={(record, index) => index}
      />
    </div>
  );
};

export default RecommendationLLMTable;
