import { Col, Row } from "antd";

const SpeedProColumn = (sortedInfo) => {
  return {
    title: <div style={{ fontSize: "12px" }}>{"Speed\nPro"}</div>,
    align: 'center',
    key: `speedProStatistics`,
    width: 50,
    sorter: {
      compare: (a, b) => {
        return 1;
      },
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `speedProStatistics`)?.['order'],
    onCell: (_) => {
      if (_.speedPro <= -5){
        return { style: { backgroundColor: "rgba(255, 0, 255, 0.7)" } }
      }
    },
    render: (_) => {
      const { jockey, trainer } = _.speedProStatistics;
      const jockeyWinPercent = jockey?.win_percentage;
      const trainerWinPercent = trainer?.win_percentage;

      return (
        <div>
          <Row>
            <Col span={24}>
              {_.speedPro || 0}
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
              }}
            >
              {jockeyWinPercent != null ? (jockeyWinPercent * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                borderTop: "1px solid rgb(100,100,100)",
              }}
            >
              {trainerWinPercent != null ? (trainerWinPercent * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default SpeedProColumn;