import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TotalScoreColumn = (sortedInfo, userData, self, targetIndex) => {
  const { t } = useTranslation();
  const selfUserId = useSelector(state => state.auth.userData.sub);
  const targetId = useSelector(state => state.standard.userData.targetUser.filter(id => id !== selfUserId)[targetIndex]);

  const key = self ? `totalScore` : targetIndex === 0 ? "totalScoreL" : "totalScoreR";

  const title = self ? <div style={{ fontSize: "12px" }}>{t("roadmap::totalScoreHeader")}</div> : targetIndex === 0 ? "L" : "R";

  return {
    title: title,
    align: 'center',
    key: key,
    width: 30,
    fixed: "right",
    sorter: {
      compare: (a, b) => userData.scoreData?.[b.horseNum]?.totalScore - userData.scoreData?.[a.horseNum]?.totalScore,
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === key)?.['order'],
    onCell: () => {
      return { style: { backgroundColor: self ? "rgba(255, 200, 200)" : "" } };
    },
    render: (_) => {
      let totalScore;
      if (self) {
        totalScore = userData.scoreData?.[_.horseNum]?.totalScore || 0;
      }else {
        totalScore = Object.values(userData.otherUserData?.[targetId]?.score?.[_.horseNum] || {}).reduce((partialSum, a) => partialSum + a, 0) +
        Object.entries(userData.scoreData?.[_.horseNum] || {}).reduce((partialSum, [key, a]) => key !== "totalScore" ? partialSum + a : partialSum, 0);
      }
      return (
        <div>{totalScore}</div>
      )
    }
  }
}

export default TotalScoreColumn;