import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getOrdinalSuffix } from "../../../services/utils/helper";

export const PoolOddsResultTable = () => {
  const { t } = useTranslation();

  const dataSource = useSelector(state => state.standard.resultData?.poolResult);
  const doubleNum = useSelector(state => state.race.selectedRaceNum) - 1;

  const spanSet = {}
  for (let i = 0; i < dataSource.length; i++) {
    if (!(dataSource[i].pool in spanSet)) {
      spanSet[dataSource[i].pool] = {
        startIndex: i,
        span: 0
      }
    } else {
      spanSet[dataSource[i].pool].span++;
    }
  }

  const columns = [
    {
      title: <div>{t("race::resultPoolHeader")}</div>,
      align: 'center',
      width: '25.0%',
      onCell: (_, index) => {
        if (_.pool in spanSet && spanSet[_.pool].span > 0) {
          if (spanSet[_.pool].startIndex === index) {
            return { rowSpan: spanSet[_.pool].span + 1 };
          } else {
            return { rowSpan: 0 };
          }
        }
        return {};
      },
      render: (_) => {
        if (_.pool.includes("Double")) {
          return (
            <div>{doubleNum}{getOrdinalSuffix(doubleNum)} {t(_.pool)}</div>
          )
        }
        return (
          <div>{t(_.pool)}</div>
        )
      },
    },
    {
      title: t("race::resultCombinationHeader"),
      align: 'center',
      width: '40.0%',
      render: (_) => {
        return (
          <div>{_.combination}</div>
        )
      },
    },
    {
      title: t("race::resultDividendHeader"),
      align: 'center',
      width: '35.0%',
      render: (_) => {
        const amount = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'HKD',
          currencyDisplay: 'narrowSymbol',
          maximumFractionDigits: 1
        }).format(_.odds);
        return (
          <div style={{ textAlign: "right", padding: "8px" }}>{amount}</div>
        )
      },
    },
  ];

  return (
    <Table
      pagination={false}
      loading={dataSource.length === 0}
      dataSource={dataSource}
      columns={columns}
      className="Result-Table"
      rowKey={(record, index)=>index}
    />
  );
}