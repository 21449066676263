import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { CrossColumn } from './CrossColumn';
import { SecondHorseColumn } from './SecondHorseColumn';
import { WinOddsColumn } from './WinOddsColumn';

import '../RaceTable.css';

const ForecastTable = () => {
  // dataSource: basically all info like jockey/trainer name, forecast odds/CIs, quinella odds/CIs, ...
  const dataSource = useSelector(state => Object.values(state.forecast.detailData));
  dataSource.sort((a, b) => a.winOddsLatest > 0 ? (b.winOddsLatest > 0 ? a.winOddsLatest - b.winOddsLatest : -1) : 999);

  // forecastCurrentData: odds for current race
  const forecastCurrentData = useSelector(state => state.forecast.winOdds);
  if (dataSource.length > 0) {
    dataSource.push({ fQStatisticsRow: true } || {});
  }

  // additionalData: latest CIs of every horse
  const additionalData = useSelector(state => state.forecast.additionalData);

  const columns = [
    WinOddsColumn(),
    SecondHorseColumn(),
    ...CrossColumn(forecastCurrentData, additionalData, dataSource.map(d => d.horseNum).filter(d => d != null))
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index)=>index}
      />
    </div>
  );
}

export default ForecastTable;
