import Header from './Header';
import Body from './Body';
import Footer from './Footer';
import useWebSocket from '../../services/utils/useWebSocket';
import { useDispatch, useSelector } from 'react-redux';
import { handleChatbotEvent } from '../../services/actions/chatAction';
import { Tabs } from 'antd';
import { useState } from 'react';
import { DollarOutlined, GlobalOutlined } from '@ant-design/icons';

const websocketURL = `wss://api-ihr.agileexlab.net/chatbot/v3/ws/chatbot`;
// const websocketURL = `ws://localhost:8000/ws/chatbot`;

const ChatbotSection = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.auth.userData.sub);

  const [currentSection, setCurrentSection] = useState("betline");
  const [connected, setConnected] = useState(false);

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      user_id: userId
    },
    allowInitialize: true,
    onMessage: payload => dispatch(handleChatbotEvent(payload)),
    websocketName: "chatbot",
    onOpen: () => setConnected(true),
    onClose: () => setConnected(false),
    onError: () => setConnected(false),
  })

  return (
    <div className={`Chatbot-Container ${open ? "Chatbot-Container-Slide-In" : "Chatbot-Container-Slide-Out"}`}>
      <Header setOpen={setOpen} />
      <Tabs
        defaultActiveKey="betline"
        type="card"
        style={{ height: "85%", padding: "2px", margin: "4px", }}
        tabPosition={"left"}
        onChange={(key) => setCurrentSection(key)}
        className='Chatbot-Body-Tabs-Container Standard-Card-Tabs'
      >
        <Tabs.TabPane tab={<><DollarOutlined />Betline</>} key="betline">
          <Body currentSection={"betline"} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<><GlobalOutlined />General</>} key="generic">
          <Body currentSection={"generic"} />
        </Tabs.TabPane>
      </Tabs>
      <Footer sendMessage={sendMessage} currentSection={currentSection} connected={connected} />
    </div>
  )
}

export default ChatbotSection;