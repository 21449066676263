import store from "../../reducers";
import * as API from "../../../middleware/API";
import { setPerformanceData } from "../../reducers/pastPerformanceSlice";

let controller = null;

export const loadPastPerformanceData = (raceDate, raceNum) => async (dispatch) => {
  // check if the previous api call is finished already, if not finished, abort the previous fetch
  if (controller) {
    controller.abort('Operation canceled by the user.');
  }
  // generate new controller signal to allow abortion of current fetch if necessary
  controller = new AbortController();
  const signal = controller.signal;

  try {
    if (!raceDate || !raceNum) {
      return
    }

    // race call list for current race and if current race is not the first race, also add the previous race into call list
    let callList = [
      API.loadRace(raceDate, raceNum, signal).catch(e => null),
    ];
    // call api with the race call list
    const [race] = await Promise.all(callList.map(p => p?.catch(e => null)));
    // get all horse code, jockey code and trainer code into three list for further api call
    // get all horse code, jockey code and trainer code into three list for further api call
    const { horseCode, jockeyCode, trainerCode } = race?.data?.payload?.horses ? Object.values(race.data.payload.horses).reduce((r, detail) => (
      r.horseCode.push(detail.horseCode) && r.jockeyCode.push(detail.jockeyCode) &&
      r.trainerCode.push(detail.trainerCode) && r
    ), { horseCode: [], jockeyCode: [], trainerCode: [] }) : { horseCode: [], jockeyCode: [], trainerCode: [] };

    callList = [
      API.loadHorseProfile(horseCode.join(","), signal),
      API.loadJockeyProfile(jockeyCode.join(","), signal),
      API.loadTrainerProfile(trainerCode.join(","), signal),
      API.loadPastPerformance(raceDate, horseCode.join(","), signal),
      API.loadMorningExercise(raceDate, raceNum, signal),
      API.loadWinPlaceOdds(raceDate, raceNum, signal),
      API.loadPool(raceDate, raceNum, signal),
    ];
    // call api with default call list
    const [horseProfile, jockeyProfile, trainerProfile, pastPerformance, 
      morningExercise, winOdds, pool] = await Promise.all(callList.map(p => p.catch(e => null)));

    // reset controller for next api fetch
    controller = null;

    // map a large object to pass to reducer
    const detailData = {
      race: race?.data?.payload,
      horseProfile: horseProfile?.data?.payload?.data,
      jockeyProfile: jockeyProfile?.data?.payload?.data,
      trainerProfile: trainerProfile?.data?.payload?.data,
      pastPerformance: pastPerformance?.data?.payload,
      morningExercise: morningExercise?.data?.payload,
      winOdds: winOdds?.data?.payload,
      pool: pool?.data?.payload
    };

    // check if the selected race date or race num is changed
    if (raceDate !== store.getState().race.selectedRaceDate || raceNum !== store.getState().race.selectedRaceNum) {
      console.debug("Race Date or Race Num is changed, so abandon");
      return;
    }

    // pass the data object into reducer
    dispatch(setPerformanceData(detailData));
  } catch (error) {
    console.error(error.message);
  }
}