import { message } from "antd";
import keycloak from "../keycloak";

export const currentTimeIn24HoursFormat = () => {
  const formatTimeUnit = (unit) => {
    return unit < 10 ? `0${unit}` : unit
  }

  const date = new Date();
  return `${formatTimeUnit(date.getHours())}:${formatTimeUnit(date.getMinutes())}:${formatTimeUnit(date.getSeconds())}`
}

export const dateStringIn24HoursFormat = (dateEntry) => {
  const formatTimeUnit = (unit) => {
    return unit < 10 ? `0${unit}` : unit
  }

  const date = new Date(dateEntry);
  return `${formatTimeUnit(date.getHours())}:${formatTimeUnit(date.getMinutes())}:${formatTimeUnit(date.getSeconds())}`
}

export const isAdmin = (userData) => {
  return userData?.["resource_access"]?.[keycloak.clientId]?.["roles"].includes("admin")
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.error(e)
    return null;
  }
};

export const checkApiError = (error, code, handling) => {
  if (error.response.status === code) {
    handling();
  }
  return null;
}

export const fixedValueDigit = (obj, digit) => {
  const d = {};
  for (const [key, value] of Object.entries(obj)) {
    d[key] = Number(value).toFixed(digit);
  }
  return d;
};

// highlight the comparisons of the forecast CI with the ratio of horses' CI
export const getForecastColorCode = (value, ci) => {
  if (value >= ci) {
    return 'rgb(255, 0, 0)';
  } else if (value >= ci * 0.85) {
    return 'rgb(255, 165, 0)';
  } else if (value >= ci * 0.7) {
    return 'rgb(0, 255, 0)';
  } else if (value >= ci * 0.55) {
    return 'rgb(0, 0, 255)';
  } else {
    return 'rgb(0, 0, 0)';
  }
}

export const fixedArrayValueDigit = (arr, digit, field) => {
  const d = [];
  for (const entry of arr) {
    const fixedEntry = entry;
    if (typeof fixedEntry[field] !== "number") {
      continue;
    }
    fixedEntry[field] = fixedEntry[field].toFixed(digit);
    d.push(fixedEntry);
  }
  return d;
}

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(camelToSnakeCase(d)) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export const extractURLWithoutParam = (url) => url.substring(0, url.indexOf('?'));

export const getOrdinalSuffix = (number) => { return ["st", "nd", "rd"][((number + 90) % 100 - 10) % 10 - 1] || "th" };

export const checkBroswer = (browserType) => {
  return navigator.userAgent.toLowerCase().indexOf(browserType) > -1;
}

export const getBroswerZoomLevel = () => {
  return window.devicePixelRatio;
}

export const takeScreenshot = async (ref, getScreenshot, name="") => {
  message.open(
    {
      key: "screenshot",
      type: 'loading',
      content: `Taking screenshot, please wait ...`,
      duration: -1
    }
  )
  try {
    const data = await getScreenshot(ref);
    const displayWindow = window.open('', '_blank');
    var image = new Image();

    image.src = data;
    image.style.width = '100%';

    displayWindow.document.title = name;
    displayWindow.document.body.appendChild(image);
    message.open(
      {
        key: "screenshot",
        type: 'success',
        content: `Taking screenshot success!`,
        duration: 1.5
      }
    )
  }
  catch (e) {
    console.error(e);
    message.open(
      {
        key: "screenshot",
        type: 'error',
        content: `Taking screenshot error: ${String(e)}`,
        duration: 1.5
      }
    )
  }
}

export const compareObjects = (obj1, obj2) => {
  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the objects have a different number of keys, they are not equal
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate over each key and compare the values
  for (const key of keys1) {
    // If the second object doesn't have the same key, they are not equal
    if (!obj2.hasOwnProperty(key)) {
      return false;
    }

    // If the values are not equal, the objects are not equal
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // If we got here, the objects are equal
  return true;
}
