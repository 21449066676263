export const recommendationLLMHandling = (
  recommendationData,
  recommendLLM,
  recommendLLMGPT4,
  recommendLLMNext5,
  recommendLLMTop3,
  recommendLLMOthers,
  recommendLLMLiveRanking
) => {
  try {
    if (recommendLLM) {
      recommendationData.recommendLLM = recommendLLM;
    }
    if (recommendLLMGPT4) {
      recommendationData.recommendLLMGPT4 = recommendLLMGPT4;
    }
    if (recommendLLMNext5) {
      recommendationData.recommendLLMNext5 = recommendLLMNext5;
    }
    if (recommendLLMTop3) {
      recommendationData.recommendLLMTop3 = recommendLLMTop3;
    }
    if (recommendLLMOthers) {
      recommendationData.recommendLLMOthers = recommendLLMOthers;
    }
    if (recommendLLMLiveRanking) {
      recommendationData.recommendLLMLiveRanking = recommendLLMLiveRanking;
    }
  } catch (e) {
    console.error(e);
  }
};

export const recommendationMachineHandling = (
  recommendationData,
  recommendMachine,
  raceStartTime
) => {
  if (!recommendMachine?.init && !recommendMachine?.append) {
    return;
  }
  try {
    if (recommendMachine.init) {
      const raceTime = new Date(raceStartTime);
      const ranking = recommendMachine.init;
      const listBeforeRaceTime = ranking.filter(
        (data) => new Date(data["predictionTime"]) <= raceTime
      );
      const listAfterRaceTime = ranking.filter(
        (data) => new Date(data["predictionTime"]) > raceTime
      );
      const latestAfterRaceTime = listAfterRaceTime.pop();
      const latest =
        listBeforeRaceTime.length > 0
          ? listBeforeRaceTime.pop()
          : latestAfterRaceTime;

      recommendationData.recommendMachine = {
        latest: latest,
        latestAfterRaceTime: latestAfterRaceTime,
        previous: listBeforeRaceTime.sort(
          (a, b) =>
            new Date(b["predictionTime"]) - new Date(a["predictionTime"])
        ),
        after: listAfterRaceTime.sort(
          (a, b) =>
            new Date(b["predictionTime"]) - new Date(a["predictionTime"])
        ),
      };
    } else if (recommendMachine.append) {
      const origin = recommendationData.recommendMachine;
      const raceTime = new Date(raceStartTime);
      const ranking = recommendMachine.append;
      if (new Date(ranking["predictionTime"]) <= raceTime) {
        origin.latest && origin.previous.unshift(origin.latest);
        origin.latest = ranking;
      } else {
        origin.latestAfterRaceTime &&
          origin.after.unshift(origin.latestAfterRaceTime);
        origin.latestAfterRaceTime = ranking;
      }
    }
  } catch (e) {
    console.error(e);
  }
};
