import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Popover, Row } from 'antd';
import { StatisticsHoverTable } from '../subTables/StatisticsHoverTable';
import GainTable from '../subTables/GainTable';
import RankHistoryTable from '../subTables/RankHistoryTable';

export const TrainerColumn = (sortedInfo) => {
  const { t } = useTranslation();
  const hideTrainer = useSelector(state => state.drawer.standardCardDrawer.hideColumn.hideTrainer) && 'Hidden-Column';
  const highlightTrainer = useSelector(state => state.drawer.standardCardDrawer.highlightColumn.highlightTrainer) && 'Highlighted-Column';

  // column of horses' trainers and their gains
  return {
    title: t("race::trainerHeader"),
    className: [hideTrainer, highlightTrainer],
    align: 'center',
    children: [
      {
        title: t("race::nameHeader"),
        className: [hideTrainer, highlightTrainer],
        align: 'center',
        children: [
          {
            title: t("race::winPlaceGainHeader"),
            className: [hideTrainer, highlightTrainer],
            align: 'center',
            key: 'trainer',
            width: 100,
            sorter: {
              compare: (a, b) => a.trainerGain - b.trainerGain,
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === 'trainer')?.['order'],
            render: (_) => {
              const win = _.winPlaceOdds?.latest;
              let group = "tLT";
              if (win <= 3.5) {
                group = "fav";
              } else if (win <= 8) {
                group = "sup";
              } else if (win <= 17) {
                group = "inf";
              } else if (win > 17) {
                group = "unw";
              }
              return (
              <Popover content={StatisticsHoverTable(t, _.trainerSingleOdds, t("race::trainerHoverHeader"), group)} overlayClassName="Popover" destroyTooltipOnHide={true}>
                <GainTable dataSource={_.trainerGain} highlighted={highlightTrainer} />
                <Row>
                  <Col span={5}>
                    <span style={{ textAlign: 'center', fontSize: "14px", textDecorationLine: "underline"}}>{_.trainerGameMapPercentage > 99 ? 99 : _.trainerGameMapPercentage}</span>
                  </Col>
                  <Col span={14}>
                    <div style={{ textAlign: 'center', fontSize: "17px", fontWeight: "bold" }} >
                      {_.trainerName?.length > 3 ? _.trainerName.slice(0, 3) : _.trainerName}
                    </div>
                  </Col>
                </Row>
                <RankHistoryTable dataSource={_.trainerRankHistory} highlighted={highlightTrainer} />
              </Popover>
              )
            }
          }
        ]
      },
    ]
  };
}