import { useTranslation } from "react-i18next"
import JTPastModal from "../JTPastModal";

export const NameColumn = (showJockey, sendMessage) => {
  const { t } = useTranslation();

  return {
    title: showJockey ? t("entries::jockeyHeader") : t("entries::trainerHeader"),
    align: "center",
    key: 'name',
    fixed: 'left',
    children: [
      {
        title: `${t("entries::stakesHeader")} (HKD)`,
        align: "center",
        key: 'name',
        width: 90,
        fixed: 'left',
        render: (_) => {
          const amount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'HKD',
            currencyDisplay: 'narrowSymbol',
            maximumFractionDigits: 0
          }).format(_.seasonWinAmount / 1000);
          return (
            <div>
              <JTPastModal value={_.name} code={_.code} showJockey={showJockey} sendMessage={sendMessage} />
              <div>{amount}K</div>
            </div>
          )
        },
      }
    ]
  }
}