import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import keycloak from "../services/keycloak";
import { handleStandardCardEvent } from "../services/actions/raceAction";
import {
  removeStandardData,
  resetSortedInfo,
  setRoadmapData,
} from "../services/reducers/standardSlice";
import { RaceTable } from "../components/RaceCard/standard";
import { RaceSelector } from "../components/RaceCard/RaceSelector";
import { RaceInfo } from "../components/RaceCard/RaceInfo";
import { StandardCardDrawerItems } from "../components/drawers/StandardCardDrawerItems";
import HorseHideInfo from "../components/RaceCard/HorseHideInfo";
import LastUpdate from "../components/RaceCard/LastUpdate";
import useWebSocket from "../services/utils/useWebSocket";
import ControlPanel from "../components/drawers/ControlPanel";
import { ResultInfo } from "../components/RaceCard/result";
import RaceGameMap from "../components/RaceCard/standard/subTables/RaceGameMap";
import { Button, Col, Row, Tabs } from "antd";

import WinInvestmentChart from "../components/RaceCard/standard/graphs/WinInvestmentChart";
import API_URL_DOMAIN from "../middleware/BaseURL";
import RoadMapTable from "../components/RaceCard/roadmap/RoadMapTable";
import { loadHorsePerformance } from "../services/actions/raceAction/standard";
import UserSelector from "../components/RaceCard/roadmap/UserSelector";
import ColdDoorButton from "../components/RaceCard/roadmap/ColdDoorButton";
import CopySection from "../components/RaceCard/roadmap/CopySection";
import SnapshotSwitch from "../components/RaceCard/SnapshotSwitch";

import useReactScreenshot from "../services/utils/useReactScreenshot";
import { takeScreenshot } from "../services/utils/helper";
import DecisionTreeSection from "../components/RaceCard/decisionTree";
import RecommendationLLMTable from "../components/RaceCard/recommendation/RecommendationLLMTable";
import ChatbotButton from "../components/chatbot";
import QuartetTable from "../components/RaceCard/standard/graphs/miscellaneous/QuartetTable";

import "./RaceCard.css";
import Top3WinPercentTable from "../components/RaceCard/standard/subTables/Top3WinPercentTable";
import { LandscapeDistribution } from "../components/RaceCard/LandscapeDistribution";

export const RaceCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedRaceDate = useSelector((state) => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector((state) => state.race.selectedRaceNum);
  const selfUserName =
    useSelector((state) => state.auth.userData?.given_name) || "";
  const { hideRaceTitle, hideRacePicker, hideResetButton, hideRaceInfo } =
    useSelector((state) => state.drawer.standardCardDrawer.hideInfo);
  const userId = useSelector((state) => state.auth.userData.sub);

  const [snapshotTriggered, setSnapshot] = useState(false);
  const [allowShowAll, setAllowShowAll] = useState(true);

  const [screenshotLock, setScreenshotLock] = useState(false);
  const ref = useRef(null);
  const { getScreenshot } = useReactScreenshot();

  const nameList = ["Steve", "Manfred", "Sean"].filter(
    (name) => name !== selfUserName
  );

  const websocketURL = `wss://${API_URL_DOMAIN}/ui/v1/ws/standard-race-card`;

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`,
    },
    allowInitialize: selectedRaceDate != null,
    onMessage: (payload) =>
      dispatch(
        handleStandardCardEvent(
          payload,
          selectedRaceDate,
          selectedRaceNum,
          sendMessageRoadmap
        )
      ),
    websocketName: "standard page",
  });

  // remove last race data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeStandardData());
    dispatch(resetSortedInfo());
    dispatch(loadHorsePerformance(selectedRaceDate, selectedRaceNum));
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendMessage({ race_date: selectedRaceDate, race_num: selectedRaceNum });
    sendMessageRoadmap({
      command: "change",
      payload: {
        race_date: selectedRaceDate,
        race_num: selectedRaceNum,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessage, selectedRaceDate, selectedRaceNum]);

  const onTabClick = (key) => {
    if (key === "1" || key === "2") {
      setSnapshot(false);
    } else if (key === "3") {
      setSnapshot(true);
    }
  };

  const sendMessageRoadmap = useWebSocket({
    url: `wss://${API_URL_DOMAIN}/user-social/v1/ws/roadmap`,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`,
      userId: userId,
    },
    allowInitialize: selectedRaceDate != null,
    onMessage: (payload) => dispatch(setRoadmapData({ payload, userId })),
    websocketName: "roadmap",
  })?.sendMessage;

  return (
    <>
      <ControlPanel DrawerItems={StandardCardDrawerItems} />
      <ChatbotButton />
      <div style={{ paddingTop: "16px" }} ref={ref}>
        <Row>
          <Col span={13}>
            <div style={{ display: "flex" }}>
              {!hideRaceTitle && <LastUpdate target="standard" />}
              {!hideRaceTitle && (
                <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {t("standardCard")}
                </span>
              )}
              <SnapshotSwitch
                sendMessage={sendMessage}
                defaultZero={snapshotTriggered}
              />
              <Button
                style={{ marginLeft: "16px" }}
                onClick={() => {
                  if (screenshotLock) return;
                  setScreenshotLock(true);
                  setAllowShowAll(false);
                  setTimeout(() => {
                    takeScreenshot(
                      ref,
                      getScreenshot,
                      `${selectedRaceDate} #${selectedRaceNum}`
                    );
                    setScreenshotLock(false);
                    setAllowShowAll(true);
                  }, 1000);
                }}
                className="Button"
                size="small"
              >
                Take screenshot
              </Button>
            </div>

            <div style={{ display: "flex" }}>
              {!hideRacePicker && <RaceSelector />}
              {!hideResetButton && <HorseHideInfo />}
            </div>
            {!hideRaceInfo && <RaceInfo store="standard" />}
          </Col>
          <Col span={11}>
            <Row>
              <Col span={7}>
                <LandscapeDistribution />
              </Col>
              <Col span={9}>
                <Top3WinPercentTable />
              </Col>
              <Col span={8}>
                <RaceGameMap />
              </Col>
            </Row>
          </Col>
        </Row>

        <WinInvestmentChart range={[-30, 0]} showRange={[-20, 0]} />
        <QuartetTable />

        <Tabs
          defaultActiveKey="1"
          type="card"
          size="large"
          className="Standard-Card-Tabs"
          onTabClick={onTabClick}
        >
          <Tabs.TabPane tab="Race Card" key="1">
            <RaceTable sendMessage={sendMessageRoadmap} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Result" key="2">
            <ResultInfo />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Roadmap" key="3">
            <Row>
              <Col span={4}>
                <ColdDoorButton />
              </Col>
              <Col span={15}>
                <CopySection />
              </Col>
              <Col span={2}>
                <UserSelector defaultValue={nameList[0]} targetIndex={0} />
              </Col>
              <Col span={2} offset={0}>
                <UserSelector defaultValue={nameList[1]} targetIndex={1} />
              </Col>
            </Row>
            <RoadMapTable sendMessage={sendMessageRoadmap} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Decision-Tree" key="4">
            <DecisionTreeSection />
          </Tabs.TabPane>
        </Tabs>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          Quinella Reborn
        </div> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <RecommendationLLMTable start={0} end={!allowShowAll ? 4 : null} />
          {!allowShowAll && <RecommendationLLMTable start={4} end={8} />}
        </div>
      </div>
    </>
  );
};
export default RaceCard;
