import { Button, Popover } from "antd";
import { useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ChatUnitMenu = ({content}) => {
  return (
    <div stlye={{ width: "100%" }}>
      <div>
        <Button size="small">
          Like
        </Button>
      </div>
      <div>
        <Button size="small">
          Dislike
        </Button>
      </div>
      <div>
        <Button size="small"
          onClick={() => {
            navigator.clipboard.writeText(content);
          }}
        >
          Copy
        </Button>
      </div>
    </div>
  )
}

const ChatMenuButton = ({ hidden, isUser, content }) => {
  return (
    <Popover
      content={<ChatUnitMenu content={content} />}
      trigger="click"
      placement={isUser ? "left" : "right"}
      destroyTooltipOnHide={true}
    >
      <Button
        style={{
          margin: "auto 4px",
          backgroundColor: "transparent",
          borderRadius: "100%",
        }}
        size="middle"
        hidden={hidden}
        shape="circle"
      >
        ...
      </Button>
    </Popover>
  )
}

const ChatUnit = ({ record }) => {
  const [buttonHidden, setButtonHidden] = useState(true);

  const isUser = record.owner === "user";
  const pos = isUser ? "flex-end" : "flex-start";
  const marginLeft = isUser ? "auto" : "0";
  const bg = isUser ? "rgb(168, 229, 255)" : "rgb(153, 255, 204)";
  const messageTime = new Date(record.updateTs).toLocaleTimeString();

  return (
    <div
      style={{ padding: `1px 2px 0 0`, maxHeight: "10%", width: "100%", display: "flex", justifyContent: pos }}
      onMouseEnter={() => setButtonHidden(false)}
      onMouseLeave={() => setButtonHidden(true)}
    >
      <div style={{ marginLeft: marginLeft, width: "100%" }}>
        <div style={{ display: "flex", justifyContent: pos, width: "100%" }}>
          {isUser && <ChatMenuButton hidden={buttonHidden} isUser={isUser} content={record.payload} />}
          <div
            style={{
              backgroundColor: bg,
              borderRadius: 8,
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
              padding: "3px 0px",
              maxWidth: "80%"
            }}
          >
            <div
              style={{ margin: "1px 6px", width: "95%" }}
            >
              <ReactMarkdown
                className="Chatbot-Message"
              >
                {record.payload}
              </ReactMarkdown>
            </div>
          </div>
          {!isUser && <ChatMenuButton hidden={buttonHidden} isUser={isUser} content={record.payload} />}
        </div>
        <div style={{ color: "rgb(150, 150, 150)", textAlign: isUser ? "right" : "left", width: "100%", padding: "0 20px 0 0" }}>
          {messageTime}
        </div>
      </div>
    </div>
  )
}

export default ChatUnit;
