import { createSlice } from "@reduxjs/toolkit";

const initialData = {
  raceDateWithRaceNum: {},
  selectedRaceDate: null,
  selectedRaceNum: null,
  selectedDoubleInterval: 0,
  isReplaying: false
};

const raceSlice = createSlice({
  name: "race",
  initialState: initialData,
  reducers: {
    setRaceDateWithRaceNum: (state, data) => {
      const sorted = Object.keys(data.payload).sort();
      state.raceDateWithRaceNum = sorted.reduce(
        (obj, key) => { obj[key] = data.payload[key]; return obj}, {}
      );
      if (state.selectedRaceDate == null) {
        state.selectedRaceDate = sorted.slice().reverse()[0];
        state.selectedRaceNum = 1;
      }
      state.isReplaying = false;
    },
    setRaceDate: (state, data) => {
      state.selectedRaceDate = data.payload;
      state.isReplaying = false;
    },
    setRaceNum: (state, data) => {
      state.selectedRaceNum = data.payload;
    },
  },
});

export const { setRaceDateWithRaceNum, setRaceDate, setRaceNum } = raceSlice.actions;

export default raceSlice.reducer;