import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, ReferenceLine } from 'recharts';
import { getAxisStroke, getGridStroke } from '../../../services/utils/chartHelper';
import { getZeroMinute } from '../standard/graphs/WinChart';

const DoubleChart = ({ data, ratio }) => {
  // line chart display at double card for double odds
  const theme = useSelector(state => state.general.theme);
  const {raceTime, updateTsRaw} = useSelector(state => state.double);

  const doubleCardDrawer = useSelector(state => state.drawer.doubleCardDrawer)
  const { high, low } = doubleCardDrawer.highlightGraphsByOddsLimit;

  // disable graphs outside range of high and low
  const disabledGraph = !(data?.['30']?.pv > low && data?.['30']?.pv < high) && "Disabled-Graph";

  const zeroMinute = getZeroMinute(raceTime, updateTsRaw);

  return (
    <ResponsiveContainer width='100%' height='90%' >
      <LineChart data={data} margin={{ top: 2, right: 4, left: 5, bottom: 6 }} className={disabledGraph}>
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          height={8}
          dataKey="minute"
          domain={[-30, 0]}
          ticks={[-30, -20, 0]}
          tick={{ fontSize: "8px" }}
          stroke={getAxisStroke(theme)}
        >
          <Label value={ratio} position={"insideTopRight"} style={{ fontSize: "12px" }} />
        </XAxis>
        <YAxis
          width={18}
          // auto generate y-axis range by max * 1.1 and min * 0.9
          domain={[dataMin => dataMin < 999 ? Math.floor(dataMin * 0.9) : dataMin, dataMax => dataMax * 1.1 >= 999 ? 999 : Math.ceil(dataMax * 1.1)]}
          tick={{ fontSize: "8px", dx: 7, dy: -3.5 }}
          allowDecimals={false}
          stroke={getAxisStroke(theme)}
        />
        <Line type="monotone" dataKey="pv" dot={false} stroke="rgb(0, 0, 0)" isAnimationActive={false} strokeWidth={1.5} />
        {(zeroMinute && zeroMinute > 0) && 
          <ReferenceLine x={zeroMinute * -1} stroke="rgba(0, 0, 0, 0.5)" strokeWidth={1} />
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DoubleChart;