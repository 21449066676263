import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import commonEN from '../assets/locales/en/common.json';
import commonCN from '../assets/locales/cn/common.json';
import raceEN from '../assets/locales/en/race.json';
import raceCN from '../assets/locales/cn/race.json';
import settingEN from '../assets/locales/en/setting.json';
import settingCN from '../assets/locales/cn/setting.json';
import entriesEN from '../assets/locales/en/entries.json';
import entriesCN from '../assets/locales/cn/entries.json';
import roadmapEN from '../assets/locales/en/roadmap.json';
import roadmapCN from '../assets/locales/cn/roadmap.json';

const resources = {
  en: {
    common: commonEN,
    race: raceEN,
    setting: settingEN,
    entries: entriesEN,
    roadmap: roadmapEN,
  },
  cn: {
    common: commonCN,
    race: raceCN,
    setting: settingCN,
    entries: entriesCN,
    roadmap: roadmapCN,
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    ns: ["common", "race", "setting", "entries"],
    nsSeparator: '::',
    defaultNS: "common",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;