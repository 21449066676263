const WinMatrixJTColumn = (sortedInfo) => {
  return {
    title: <div style={{ fontSize: "12px" }}>{"Matrix\nJT"}</div>,
    align: 'center',
    key: `winMatrixJT`,
    width: 50,
    sorter: {
      compare: (a, b) => {
        if (a.scratched < 0) {
          return 999;
        } else if (b.scratched < 0) {
          return -1;
        } else {
          return (Number(b.jockeyGameMapPercentage) + Number(b.trainerGameMapPercentage)) -
            (Number(a.jockeyGameMapPercentage) + Number(a.trainerGameMapPercentage));
        }
      },
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `winMatrixJT`)?.['order'],
    render: (_) => {
      const jockey = _.jockeyGameMapPercentage;
      const trainer = _.trainerGameMapPercentage;
      return (
        <div>
          <div
            style={
              {
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                fontWeight: Number(jockey) + Number(trainer) ? "bold" : null,
              }
            }
          >
            {jockey != null && trainer != null ? Number(jockey) + Number(trainer) : "-"}%
          </div>
          <div
            style={
              {
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                fontWeight: Number(jockey) !== 0 ? "bold" : null,
              }
            }
          >
            {jockey != null ? jockey : "-"}%
          </div>
          <div
            style={
              {
                fontWeight: Number(trainer) !== 0 ? "bold" : null,
              }
            }
          >
            {trainer != null ? trainer : "-"}%
          </div>
        </div>
      )
    }
  }
}

export default WinMatrixJTColumn;
