import axios from './axios'
import API_URL_DOMAIN from './BaseURL';

// integrated with Solace event driven cloud service V2
const RACE_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/race/v3`;

const WIN_PLACE_ODDS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/win-place-odds/v3`;
const FORECAST_ODDS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/forecast-odds/v3`;
const QUINELLA_ODDS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/quinella-odds/v3`;
const DOUBLE_ODDS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/double-odds/v3`;
const DERIVATIVE_ODDS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/derivative-odds/v3`;


const WIN_PLACE_CI_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/win-place-ci/v3`;
const FORECAST_CI_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/forecast-ci/v3`;
const QUINELLA_CI_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/quinella-ci/v3`;
const DOUBLE_CI_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/double-ci/v3`;

const POOL_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/pool/v3`;

const WIN_PLACE_VELOCITY_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/win-place-velocity/v3`;
// const QUINELLA_VELOCITY_SERVICE_BASE_URL = ``;
// const DOUBLE_VELOCITY_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/double-velocity/v3`;

const HORSE_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/horse/v1`;
const JOCKEY_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/jockey/v1`;
const TRAINER_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/trainer/v1`;

const INSIGHT_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/insight/v1/api`;
const SIGNAL_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/signal-detector/v3`;

const MATRIX_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/matrix/v3`;

const STATISTICS_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/statistics/v3`;
const TRACKWORK_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/trackwork/v1`;

const USER_STORAGE_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/user-storage/v1`;

const USER_SOCIAL_SERVICE_BASE_URL = `https://${API_URL_DOMAIN}/user-social/v1`;

// Keycloak service url for authentication
const KEYCLOAK_SERVICE_BASE_URL = "https://auth.agileexlab.net/auth"

export const loadRaceDateAndRaceNum = (signal = null) =>
  axios.get(`${RACE_SERVICE_BASE_URL}/api/races`, { signal: signal });

export const loadRace = (raceDate, raceNum, signal = null) =>
  axios.get(`${RACE_SERVICE_BASE_URL}/api/races`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadWPRatio = (raceDate, raceNum, signal = null) =>
  axios.get(`${POOL_SERVICE_BASE_URL}/api/pool_summary`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadPool = (raceDate, raceNum, signal = null) =>
  axios.get(`${POOL_SERVICE_BASE_URL}/api/pool`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadPoolAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${POOL_SERVICE_BASE_URL}/api/pool_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadWinPlaceOdds = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_ODDS_SERVICE_BASE_URL}/api/win_place_odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadWinPlaceOddsAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_ODDS_SERVICE_BASE_URL}/api/win_place_odds_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadConfidentIndex = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_CI_SERVICE_BASE_URL}/api/wp-ci`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadConfidentIndexAll = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_CI_SERVICE_BASE_URL}/api/wp_ci_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastOddsRanked = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_ODDS_SERVICE_BASE_URL}/api/forecast_odds_ranked`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastOdds = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_ODDS_SERVICE_BASE_URL}/api/forecast_odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastOddsAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_ODDS_SERVICE_BASE_URL}/api/forecast_odds_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );


export const loadForecastCiRanked = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_CI_SERVICE_BASE_URL}/api/forecast_ci_ranked`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastCi = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_CI_SERVICE_BASE_URL}/api/forecast_ci`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastNormAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${FORECAST_CI_SERVICE_BASE_URL}/api/forecast_norm_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadQuinellaOdds = (raceDate, raceNum, signal = null) =>
  axios.get(`${QUINELLA_ODDS_SERVICE_BASE_URL}/api/quinella_odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadQuinellaOddsAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${QUINELLA_ODDS_SERVICE_BASE_URL}/api/quinella_odds_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadQuinellaCI = (raceDate, raceNum, signal = null) =>
  axios.get(`${QUINELLA_CI_SERVICE_BASE_URL}/api/quinella_ci`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadQuinellaNormAbs = (raceDate, raceNum, signal = null) =>
  axios.get(`${QUINELLA_CI_SERVICE_BASE_URL}/api/quinella_norm_abs`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadSimulationOddsFromWin = (raceDate, raceNum, horseNum = null, signal = null) => {
  const params = {
    race_date: raceDate,
    race_num: raceNum
  }
  if (horseNum) {
    params["horse_num"] = horseNum;
  }
  return axios.get(`${DERIVATIVE_ODDS_SERVICE_BASE_URL}/api/redistributed-win-odds-without-selected-horse`,
    {
      params: params,
      signal: signal
    }
  );
}


export const loadSimulationOddsFromQuinella = (raceDate, raceNum, horseNum, signal = null) => {
  const params = {
    race_date: raceDate,
    race_num: raceNum
  }
  if (horseNum) {
    params["horse_num"] = horseNum;
  }
  return axios.get(`${DERIVATIVE_ODDS_SERVICE_BASE_URL}/api/derivative-win-odds-from-quinella-without-selected-horse`,
    {
      params: params,
      signal: signal
    }
  );
}


export const loadDerivativeOddsFromQuinella = (raceDate, raceNum, signal = null) =>
  axios.get(`${DERIVATIVE_ODDS_SERVICE_BASE_URL}/api/derivative-win-odds-from-quinella`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadHorseProfile = (horse_code, signal = null) =>
  axios.get(`${HORSE_SERVICE_BASE_URL}/api/profiles`,
    {
      params: {
        brands: horse_code,
      },
      signal: signal
    }
  )

export const loadJockeyProfile = (jockey_code, signal = null) => {
  if (jockey_code !== "") {
    return axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/profile`,
      {
        params: {
          codes: jockey_code,
        },
        signal: signal
      }
    );
  }
  return axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/profile`, { signal: signal });
}

export const loadJockeyStatistics = (jockey_code, signal = null) =>
  axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/statistics-latest`,
    {
      params: {
        codes: jockey_code,
      },
      signal: signal
    }
  );

export const loadJockeyGain = (raceDate, raceNum, signal = null) =>
  axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/gain`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadJockeyAlias = (signal = null) =>
  axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/alias`,
    {
      signal: signal
    }
  );

export const loadJockeyRankHistory = (raceDate, raceNum, signal = null) =>
  axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/rank-history`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadJockeyFutureHorsesGrouping = (raceDate, raceNum, signal = null) =>
  axios.get(`${JOCKEY_SERVICE_BASE_URL}/api/future-horses-grouping`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadTrainerProfile = (trainer_code, signal = null) => {
  if (trainer_code !== "") {
    return axios.get(`${TRAINER_SERVICE_BASE_URL}/api/profile`,
      {
        params: {
          codes: trainer_code,
        },
        signal: signal
      }
    );
  }
  return axios.get(`${TRAINER_SERVICE_BASE_URL}/api/profile`, { signal: signal });
}

export const loadTrainerStatistics = (trainer_code, signal = null) =>
  axios.get(`${TRAINER_SERVICE_BASE_URL}/api/statistics-latest`,
    {
      params: {
        codes: trainer_code,
      },
      signal: signal
    }
  );

export const loadTrainerGain = (raceDate, raceNum, signal = null) =>
  axios.get(`${TRAINER_SERVICE_BASE_URL}/api/gain`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadTrainerAlias = (signal = null) =>
  axios.get(`${TRAINER_SERVICE_BASE_URL}/api/alias`,
    {
      signal: signal
    }
  );

export const loadTrainerRankHistory = (raceDate, raceNum, signal = null) =>
  axios.get(`${TRAINER_SERVICE_BASE_URL}/api/rank-history`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadTrainerFutureHorsesGrouping = (raceDate, raceNum, signal = null) =>
  axios.get(`${TRAINER_SERVICE_BASE_URL}/api/future-horses-grouping`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadJTCollOdds = (raceDate, raceNum, signal = null) =>
  axios.get(`${INSIGHT_SERVICE_BASE_URL}/jt-coll-odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadJTSingleOdds = (raceDate, raceNum, signal = null) =>
  axios.get(`${INSIGHT_SERVICE_BASE_URL}/jt-single-odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadDoubleOdds = (raceDate, doubleNum, interval = 0, signal = null) =>
  axios.get(`${DOUBLE_ODDS_SERVICE_BASE_URL}/api/double_odds`,
    {
      params: {
        race_date: raceDate,
        double_num: doubleNum,
        interval: interval
      },
      signal: signal
    }
  )

export const loadDoubleCi = (raceDate, doubleNum, interval = 0, signal = null) =>
  axios.get(`${DOUBLE_CI_SERVICE_BASE_URL}/api/double-ci`,
    {
      params: {
        race_date: raceDate,
        double_num: doubleNum,
        interval: interval
      },
      signal: signal
    }
  )

export const loadDoubleSignal = (raceDate, doubleNum, signal = null) =>
  axios.get(`${SIGNAL_SERVICE_BASE_URL}/api/double_signal`,
    {
      params: {
        race_date: raceDate,
        race_num: doubleNum
      },
      signal: signal
    }
  )

export const loadWinVelocity = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_VELOCITY_SERVICE_BASE_URL}/api/delta_odds`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  )

export const loadWinMovingAvg = (raceDate, raceNum, signal = null) =>
  axios.get(`${WIN_PLACE_VELOCITY_SERVICE_BASE_URL}/api/moving_avg`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  )

export const loadWinOddsSignal = (raceDate, raceNum, signal = null) =>
  axios.get(`${SIGNAL_SERVICE_BASE_URL}/api/win_signal`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadAllDoubleOdds = (raceDate, doubleNum, signal = null) =>
  axios.get(`${DOUBLE_ODDS_SERVICE_BASE_URL}/api/double_abs`,
    {
      params: {
        race_date: raceDate,
        double_num: doubleNum
      },
      signal: signal
    }
  );

export const loadForecastQuinellaSignal = (raceDate, raceNum, signal = null) =>
  axios.get(`${SIGNAL_SERVICE_BASE_URL}/api/qf_signal`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  );

export const loadForecastQuinellaMatrixSignal = (data, signal = null) =>
  axios.post(`${SIGNAL_SERVICE_BASE_URL}/api/qf_matrix_signal`, data);

export const loadMatrixData = (data, signal = null) =>
  axios.post(`${MATRIX_SERVICE_BASE_URL}/api/matrix`, data);

export const loadPastPerformance = (raceDate, horseCode, signal = null) =>
  axios.get(`${STATISTICS_SERVICE_BASE_URL}/api/past_performance`,
    {
      params: {
        race_date: raceDate,
        brand: horseCode
      },
      signal: signal
    }
  )

export const loadPastPerformanceJT = (code, type, signal = null) =>
  axios.get(`${STATISTICS_SERVICE_BASE_URL}/api/past_performance_jt`,
    {
      params: {
        code: code,
        person_type: type
      },
      signal: signal
    }
  )

export const loadSimulationGameMap = (favored, superior, inferior, poolRatio, signal = null) =>
  axios.get(`${STATISTICS_SERVICE_BASE_URL}/api/game_map`,
    {
      params: {
        favored: favored,
        superior: superior,
        inferior: inferior,
        pool_ratio: poolRatio,
      },
      signal: signal
    }
  )

export const loadMorningExercise = (raceDate, raceNum, signal = null) =>
  axios.get(`${TRACKWORK_SERVICE_BASE_URL}/api/horse-performance`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum
      },
      signal: signal
    }
  )

export const loadHorseNote = (raceDate, raceNum, userId, signal = null) =>
  axios.get(`${USER_STORAGE_SERVICE_BASE_URL}/api/horse-note`,
    {
      params: {
        race_date: raceDate,
        race_num: raceNum,
        user_id: userId
      },
      signal: signal
    }
  );

export const updateHorseNote = (userData, signal = null) =>
  axios.post(`${USER_STORAGE_SERVICE_BASE_URL}/api/horse-note`, userData);

export const updateRoadmapColdDoor = (coldDoorData, signal = null) =>
  axios.post(`${USER_SOCIAL_SERVICE_BASE_URL}/ws/cold-door`, coldDoorData);

export const authenticate = (username, password, clientId) =>
  axios.post(`${KEYCLOAK_SERVICE_BASE_URL}/realms/HKJC-Data-Platform/protocol/openid-connect/token`,
    new URLSearchParams({
      username: username,
      password: password,
      grant_type: "password",
      client_id: clientId,
    })
  );

export const logout = (clientId, accessToken, refreshTokenData) =>
  axios.post(`${KEYCLOAK_SERVICE_BASE_URL}/realms/HKJC-Data-Platform/protocol/openid-connect/logout`,
    new URLSearchParams({
      client_id: clientId,
      refresh_token: refreshTokenData,
    }),
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );

export const loadUserInfo = (clientId, token) =>
  axios.post(`${KEYCLOAK_SERVICE_BASE_URL}/realms/HKJC-Data-Platform/protocol/openid-connect/userinfo`,
    new URLSearchParams({
      client_id: clientId,
    }),
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );

export const refreshToken = (clientId, token, refreshTokenData) =>
  axios.post(`${KEYCLOAK_SERVICE_BASE_URL}/realms/HKJC-Data-Platform/protocol/openid-connect/token`,
    new URLSearchParams({
      refresh_token: refreshTokenData,
      grant_type: "refresh_token",
      client_id: clientId,
    }),
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
