import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import keycloak from '../services/keycloak';
import { RaceSelector } from '../components/RaceCard/RaceSelector'
import HorseHideInfo from '../components/RaceCard/HorseHideInfo';
import LastUpdate from '../components/RaceCard/LastUpdate';
import ForecastTable from '../components/RaceCard/forecast';
import { removeForecastData, setCurveDisplay } from '../services/reducers/forecastSlice';
import { Collapse, Radio } from 'antd';
import ControlPanel from '../components/drawers/ControlPanel';
import useWebSocket from '../services/utils/useWebSocket';
import { handleForecastCardEvent } from '../services/actions/raceAction/forecast';
import API_URL_DOMAIN from '../middleware/BaseURL';


export const ForecastCard = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const DrawerItems = () => {
    return (
      <div>
        a,b,c
      </div>
    )
  }

  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);
  const showReal = useSelector(state => state.forecast.additionalData.showRealCurve);
  const showNorm = useSelector(state => state.forecast.additionalData.showNormCurve);
  const isReplaying = useSelector(state => state.race.isReplaying);
  const websocketURL = !isReplaying ? `wss://${API_URL_DOMAIN}/ui/v1/ws/forecast-race-card` : "";

  const { sendMessage } = useWebSocket({
    url: websocketURL, 
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`
    },
    allowInitialize: !isReplaying && selectedRaceDate != null, 
    onMessage: payload => dispatch(handleForecastCardEvent(payload, selectedRaceDate, selectedRaceNum)),
    websocketName: "forecast page",
  })

  // remove last race data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeForecastData())
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendMessage({ "race_date": selectedRaceDate, "race_num": selectedRaceNum });
  }, [sendMessage, selectedRaceDate, selectedRaceNum]);

  const getRadioCurve = () => {
    if (showReal && showNorm) {
      return 1;
    }
    return showReal ? 2 : 3;
  }

  const onCurveRadioChange = (e) => {
    const settings = {
      showRealCurve: e.target.value === 3 ? false : true,
      showNormCurve: e.target.value === 2 ? false : true,
    }
    dispatch(setCurveDisplay(settings));
  }

  return (
    <>
      <ControlPanel DrawerItems={DrawerItems} />
      <div style={{ paddingTop: '16px' }}>
        <Collapse style={{ float: "right", marginRight: "16px", marginBottom: "6px", width: "350px" }} className="Collapse-Item">
          <Collapse.Panel header={t("race::forecastQuinellaGraph")} key="1">
            <label style={{ color: "rgb(136, 132, 216)", padding: "4px" }}>&#9679;Forecast</label>
            <label style={{ color: "rgb(94, 235, 75)", padding: "4px" }}>&#9679;Forecast Reverse</label>
            <label style={{ color: "rgb(240, 51, 41)", padding: "4px" }}>&#9679;Quinella</label>
            <Radio.Group onChange={onCurveRadioChange} value={getRadioCurve()} style={{ paddingTop: "8px" }}>
              <Radio value={1}>Both</Radio>
              <Radio value={2}>Real</Radio>
              <Radio value={3}>Norm</Radio>
            </Radio.Group>
          </Collapse.Panel>
        </Collapse>
        <div style={{ display: "flex" }}>
          <LastUpdate target='forecast' />
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{t("forecastCard")}</span>
        </div>
        <div style={{ display: "flex" }}>
          <RaceSelector />
          <HorseHideInfo />
        </div>
        <ForecastTable />
      </div>
    </>

  )
}
export default ForecastCard;
