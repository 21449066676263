import { getInterval } from "./miscellaneous";

export const raceTableInfoHandling = (state, pool, raceInfo, winOddsAll) => {
  try {
    // map each race info to the stored race info
    if (pool) {
      state.info.winPoolAmount = pool.win;
      state.info.updatets = new Date(pool.updateTs).toLocaleString(
        {},
        { hourCycle: "h24" }
      );
    }
    if (winOddsAll) {
      state.info.updateTsRaw = winOddsAll.updateTs;
    } else if (pool) {
      state.info.updateTsRaw = pool.updateTs;
    }
    if (raceInfo) {
      // calculate the remaining time before the race start
      state.info.raceTime = raceInfo.revisedRaceTime;
      state.info.remainingTimeToStart = Math.floor(
        (new Date(raceInfo.revisedRaceTime).getTime() - new Date().getTime()) /
          1000
      );
      state.info.venue = raceInfo.venue;
      state.info.track = raceInfo?.track ? raceInfo.track : "N/A";
      state.info.class = raceInfo.raceClass;
      state.info.course = raceInfo?.course ? raceInfo.course : "N/A";
      state.info.distance = raceInfo.distance;
      state.info.name = raceInfo.name;

      if (state.info.remainingTimeToStart < 0) {
        state.info.remainingTimeToStart = 0;
      }

      const raceTime = new Date(state.info.raceTime);
      raceTime.setMinutes(raceTime.getMinutes());
      if (raceTime <= new Date()) {
        state.additionalData.allowSnapshot = true;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const raceTableHeaderHandling = (
  headerData,
  forecastOdds,
  wPRatio,
  raceInfo
) => {
  try {
    // map hottest forecast odds and forecast investment to the header data
    if (forecastOdds?.hottest && forecastOdds?.investment) {
      Object.entries({ lowest: 0, secondLowest: 1, thirdLowest: 2 }).forEach(
        ([key, apiIndex]) => {
          headerData.forecastHorseNum[key] = forecastOdds.hottest[apiIndex];
          headerData.forecastPoolPercentage[key] =
            forecastOdds.investment[apiIndex].toFixed(2);
        }
      );
    }
    // map win place ratio of win place poll to header data
    ["thirty", "ten", "six", "three", "zero", "latest"].forEach((interval) => {
      if (wPRatio?.[interval]?.poolWpRatio) {
        headerData.wPRatio[interval] = wPRatio[interval].poolWpRatio.toFixed(2);
        return;
      }
      headerData.wPRatio[interval] =
        wPRatio?.[interval]?.poolRatio != null
          ? wPRatio[interval].poolRatio.toFixed(2)
          : headerData.wPRatio[interval];
    });
    // calculate the header interval actual time
    if (raceInfo) {
      const raceMintue = new Date(raceInfo.revisedRaceTime).getMinutes();
      [30, 10, 6, 3, 0].forEach((interval) => {
        headerData.wPMinInterval[interval] = getInterval(raceMintue, interval);
      });
    }
  } catch (e) {
    console.error(e);
  }
};

export const resultHandling = (state, result) => {
  try {
    if (!result) {
      return;
    }
    state.additionalData.resultUrl = result?.resultUrl;
    if (result?.dividendOdds && result?.horses) {
      const dividendOdds = result.dividendOdds;
      const horses = result.horses.sort((a, b) => a.placing - b.placing);
      const placingList = horses
        .filter((data) => data.placing <= 3)
        .map((data) => data.horseNum);

      const newResultData = [];
      const poolTranslationMapping = {
        win: "race::resultPoolWin",
        place: "race::resultPoolPlace",
        quinella: "race::resultPoolQuinella",
        quinellaPlace: "race::resultPoolQuinellaPlace",
        forecast: "race::resultPoolForecast",
        tierce: "race::resultPoolTierce",
        trio: "race::resultPoolTrio",
        firstFour: "race::resultPoolFirstFour",
        quartet: "race::resultPoolQuartet",
        double: "race::resultPoolDouble",
      };
      const appendData = (pool, combination, odds) =>
        newResultData.push({
          pool: poolTranslationMapping[pool],
          combination,
          odds,
        });

      for (const [pool, data] of Object.entries(dividendOdds)) {
        if (!data) {
          continue;
        }
        if (pool === "double") {
          for (const [firstHorsNum, combination] of Object.entries(data)) {
            for (const placing of placingList) {
              placing in combination &&
                appendData(
                  pool,
                  `${firstHorsNum}/${placing}`,
                  combination[placing] * 10
                );
            }
          }
        } else if (pool === "place") {
          for (const placing of placingList) {
            placing in data && appendData(pool, placing, data[placing] * 10);
          }
        } else if (Array.isArray(data)) {
          for (const singleData of data) {
            appendData(
              pool,
              singleData.combination.join(","),
              singleData.odds * 10
            );
          }
        } else if (typeof data === "object") {
          for (const [combination, odds] of Object.entries(data)) {
            appendData(pool, combination, odds * 10);
          }
        }
      }
      state.resultData.poolResult = newResultData;
    }
  } catch (e) {
    console.error(e);
  }
};
