export const pastPerformanceDataProcessing = (state, data) => {
  const payload = data.payload;
  const { race, horseProfile, jockeyProfile, trainerProfile, morningExercise, pastPerformance, winOdds, pool } = payload;

  horseDataMapping(state.horseData, race, horseProfile, jockeyProfile, trainerProfile);

  pastPerformanceMapping(state.pastPerformance, pastPerformance);

  morningExerciseMapping(state.morningExercise, morningExercise);

  winOddsMapping(state.winOdds, winOdds);

  raceInfoHandling(state.info, pool, race);
}

const horseDataMapping = (horseData, race, horseProfile, jockeyProfile, trainerProfile) => {
  for (const [horseNum, horse] of Object.entries(race?.horses || {})) {
    if (horse.scratched) {
      continue;
    }
    horseData[horseNum] = horse;
    horse.horseName = horseProfile[horse.horseCode]?.name;
    horse.jockeyName = jockeyProfile[horse.jockeyCode]?.name;
    horse.trainerName = trainerProfile[horse.trainerCode]?.name;
    horse.url = horseProfile[horse.horseCode]?.profileUrl;
  }
}

const pastPerformanceMapping = (pastPerformanceData, pastPerformance) => {
  for (const entry of pastPerformance || []) {
    const { horse, pastPerformance } = entry;
    for (const data of pastPerformance) {
      const raceDate = new Date(data.raceDate);
      const month = raceDate.getMonth() + 1;
      const date = raceDate.getDate();
      data.raceId = `${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}#${data.raceNum}`;  
    }
    pastPerformanceData[horse] = pastPerformance;
  }
}

const morningExerciseMapping = (morningExerciseData, morningExercise) => {
  for (const { horse_code, grade, weeks } of morningExercise || []) {
    morningExerciseData[horse_code] = {
      twelveWeeks: {},
      details: {},
      grade: grade
    };
    weeks.sort((a, b) => new Date(a) - new Date(b));
    for (let i = 0; i < weeks.length; i++) {
      morningExerciseData[horse_code].twelveWeeks[i + 1] = weeks[i];
    }
    const reducedData = Object.values(morningExerciseData[horse_code].twelveWeeks || {})
                              .reduce((prev, week) => [...prev, ...week.workouts], []);
    morningExerciseData[horse_code].details = reducedData;
  }
}

const winOddsMapping = (winOddsData, winOdds) => {
  for (const [horseNum, odds] of Object.entries(winOdds || {})) {
    winOddsData[horseNum] = odds?.latest?.win;
  }
}

const raceInfoHandling = (info, pool, raceInfo) => {
  if (pool) {
    info.winPoolAmount = pool.win;
    info.updatets = new Date(pool.updateTs).toLocaleString({}, { hourCycle: "h24" });
  }
  if (raceInfo) {
    // calculate the remaining time before the race start
    info.raceTime = raceInfo.revisedRaceTime;
    info.remainingTimeToStart = Math.floor((new Date(raceInfo.revisedRaceTime).getTime() - new Date().getTime()) / 1000);
    info.venue = raceInfo.venue;
    info.track = raceInfo?.track ? raceInfo.track : 'N/A';
    info.class = raceInfo.raceClass;
    info.course = raceInfo?.course ? raceInfo.course : 'N/A';
    info.distance = raceInfo.distance;

    if (info.remainingTimeToStart < 0) {
      info.remainingTimeToStart = 0;
    }
  }
}
