import { useSelector } from "react-redux";
import ReactFlow, { Background, Position } from 'reactflow';
import HoverEdge from "./miscellaneous/HoverEdge";
import { useEffect, useRef, useState } from "react";
import { checkBroswer } from "../../../../services/utils/helper";

const defaultNode = {
  style: { width: 1, height: 1, display: "default" },
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
}

const PopUpStatistics = ({ enable, popupPos, targetEdge }) => {
  const statistics = targetEdge?.data?.winStatistics || {};
  return (
    <div
      style={{
        display: enable ? "inherit" : "none",
        backgroundColor: "rgb(240, 240, 240)",
        border: "2px solid rgba(100, 100, 100, 0.5)",
        position: "fixed",
        left: popupPos.x,
        top: popupPos.y,
      }}>
      {statistics?.win_count} / {statistics?.top4_count} / {statistics?.total_count}
      <br />
      {targetEdge?.data?.jockeyName} - {targetEdge?.data?.trainerName}
      <br />
      {targetEdge?.source} - {targetEdge?.target?.split("_")?.[0]}
    </div>
  )
}

const JTNodeGraph = ({ numOfHorse }) => {
  const ref = useRef();
  // const containerWidth = ref.current?.getBoundingClientRect()?.width;
  const containerHeight = ref.current?.getBoundingClientRect()?.height;

  const additionalData = useSelector(state => state.standard.additionalData);
  const sortingHorseNumList = additionalData?.sortingHorseNumList || [];
  const currentNumOfHorse = additionalData?.numOfHorseCurrent;
  const edges = additionalData.jtNode.edges;
  const nodes = [];

  const [enablePopup, setEnablePopup] = useState(false);
  const [popupPos, setPopupPos] = useState({ x: 0, y: 0 });
  const [targetEdge, setTargetEdge] = useState(null);
  const [selectedNode, setNode] = useState({ jockey: null, trainer: null });
  const [containerWidth, setContainerWidth] = useState(ref.current?.getBoundingClientRect()?.width);

  const yStartPos = () => { return containerHeight != null ? containerHeight / currentNumOfHorse / 3 : 30 };
  const yOffset = () => { return containerHeight != null ? containerHeight / currentNumOfHorse : 90 };;
  const getXPosJockey = () => { return -12};
  const getXPosTrainer = () => { return containerWidth != null ? containerWidth - 10 : 89 };

  const getYPos = (horseNum) => {
    if (sortingHorseNumList?.length > 0) {
      return horseNum == null ? -100 : (sortingHorseNumList?.indexOf(horseNum)) * yOffset() + yStartPos();
    } else {
      return horseNum == null ? -100 : (horseNum - 1) * yOffset() + yStartPos();
    }
  }

  for (const edge of edges) {
    if (!nodes.find(node => node.id === edge.source)) {
      nodes.push({
        id: edge.source,
        position: { x: getXPosJockey(), y: getYPos(edge.data?.idHorseNumMapping?.[0]) },
        data: { type: "jockey" },
        ...defaultNode,
      });
    }
    if (!nodes.find(node => node.id === edge.target)) {
      const y = getYPos(edge.data?.idHorseNumMapping?.[1]);
      nodes.push({
        id: edge.target,
        position: { x: getXPosTrainer(), y: y },
        data: { type: "trainer" },
        ...defaultNode,
      });
    }
  }
  const edgeTypes = {
    hover: HoverEdge,
  };

  const onEdgeMouseEnter = (event, edge) => {
    setPopupPos({ x: event.clientX - 30, y: event.clientY - 90 });
    setEnablePopup(true);
    setTargetEdge(edge);
  }

  const onEdgeMouseLeave = (event, edge) => {
    setEnablePopup(false);
  }

  const setSelectedNode = (node) => {
    if (node?.data?.type === "jockey") {
      setNode({ jockey: selectedNode.jockey !== node?.id ? node?.id : null, trainer: null });
    } else if (node?.data?.type === "trainer") {
      setNode({ jockey: null, trainer: selectedNode.trainer !== node?.id.split("_")[0] ? node?.id.split("_")[0] : null });
    }
    setEnablePopup(false);
  }

  useEffect(() => {
    const intervalId = setInterval(() => setContainerWidth(ref.current?.getBoundingClientRect()?.width), 3000);
    return () => clearInterval(intervalId);
  });

  return (
    <div style={{ height: checkBroswer("firefox") ? numOfHorse * 90 : `100%`, width: "99%" }} ref={ref}>
      <ReactFlow
        nodes={nodes}
        edges={edges.filter(edge => {
          if (selectedNode.jockey != null && edge.source !== selectedNode.jockey) return false;
          if (selectedNode.trainer != null && edge.target.split("_")[0] !== selectedNode.trainer) return false;
          return true;
        })}
        elementsSelectable={true}
        nodesConnectable={false}
        nodesDraggable={false}
        zoomOnScroll={false}
        panOnScroll={false}
        panOnScrollMode={false}
        zoomOnDoubleClick={false}
        edgeTypes={edgeTypes}
        zoomActivationKeyCode={null}
        onEdgeMouseMove={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        panOnDrag={false}
        onNodeClick={(event, node) => setSelectedNode(node)}
      >
        <Background />
      </ReactFlow>
      <PopUpStatistics targetEdge={targetEdge} enable={enablePopup} popupPos={popupPos} />
    </div>
  )
}


export default JTNodeGraph;
