export const handleColdDoorScore = (scoreData, coldDoorData, raceNum, state) => {
  for (const [horseNum, score] of Object.entries(raceNum != null ? coldDoorData?.[raceNum] || {} : coldDoorData || {})) {
    if (!(horseNum in scoreData)) {
      scoreData[horseNum] = {};
    }
    scoreData[horseNum].commentaryScore = score;
  }


  calucateTotalScore(state.userData, state.decisionTree);
}

export const speedProHandling = (detailData, speedProData) => {
  if (!speedProData) {
    return;
  }

  try {
    detailData.speedPro = Number(speedProData || 0);
  } catch (e) {
    console.error(e);
  }
}

export const handleHorseScore = (state, data) => {
  const { currentScore, horseNum, scoreKey } = data.payload;
  const userData = state.userData.userData;

  if (!(horseNum in userData.score)) {
    userData.score[horseNum] = {}
  }
  userData.score[horseNum][scoreKey] = currentScore;

  calucateTotalScore(state.userData, state.decisionTree)
}

export const handleHorseNote = (state, data) => {
  const { text, horseNum } = data.payload;
  const userData = state.userData.userData;

  userData.note[horseNum] = text;
}

export const handleHorseNoteFromAPI = (state, data) => {
  if (data.payload != null) {
    const { note, score } = data.payload;
    state.userData.userData.note = note;
    state.userData.userData.score = score;
  }

  calucateTotalScore(state.userData, state.decisionTree);
}

export const handleResetHorseNoteData = (state, data) => {
  state.userData.userData.note = {};
  state.userData.userData.score = {};

  calucateTotalScore(state.userData, state.decisionTree);
}

export const calucateTotalScore = (userData, decisionTree) => {
  for (const [horseNum, data] of Object.entries(userData.scoreData)) {
    if (!(horseNum in userData.scoreData)) {
      userData.scoreData[horseNum] = {};
    }

    data.totalScore = 0;

    const totalScore = Object.values(userData.userData.score?.[horseNum] || {}).reduce((partialSum, a) => partialSum + a, 0) +
      Object.values(data || {}).reduce((partialSum, a) => partialSum + a, 0) +
      Object.values(decisionTree?.extraScore?.[horseNum] || {}).reduce((partialSum, a) => partialSum + a, 0);

      userData.scoreData[horseNum].totalScore = totalScore;
  }
}

export const handleSetHorseTrackworkGrade = (state, data) => {
  const { grade } = data.payload;
  state.additionalData.horseTrackworkGrade = grade;
}


export const updateSelectiveRanking = (state, data) => {

  const { horseNum, ranking, prompt } = data.payload;
  const userData = state.userData;
  const selectiveRanking = userData.selectiveRanking;

  if (prompt != null) {
    selectiveRanking.prompt = prompt;
  }

  if (horseNum == null) {
    return;
  }
  if (ranking != null) {
    selectiveRanking.horses[horseNum] = ranking;
  }

}

export const handleRoadmapScore = (scoreData, roadmap) => {
  try {
    if (!roadmap) {
      return;
    }
    const roadmapScoreMapping = {
      cold_door: "commentaryScore",
      speed_pro: "speedProScore",
      // trackwork: "trackworkScore",
      fitness_rating: "fitnessRatingScore",
      qw: "qwScore",
      win_matrix_jockey: "jockeyMatrixScore",
      win_matrix_trainer: "trainerMatrixScore",
      win_matrix_jockey_trainer: "jtMatrixScore",
      jt_collab: "jTScore",
      double_statistics: "doubleCIScore",
      game_map: "gameMapScore",
      forecast_quinella: "fqScore",
      overbought_statistics: "overBoughtScore",
      win_investment: "winInvestmentScore",
    }

    for (const [inputKey, scoreKey] of Object.entries(roadmapScoreMapping)) {
      if (!roadmap?.[inputKey]) {
        continue;
      }
      for (const [horseNum, score] of Object.entries(roadmap[inputKey])) {
        scoreData[horseNum][scoreKey] = score;
      }
    }
  } catch (e) {
    console.error(e);
  }
}