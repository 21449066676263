export const RaceDateColumn = () => {
  return {
    title: "date",
    align: "center",
    key: 'raceDate',
    fixed: 'left',
    width: 60,
    render: (_) => {
      return (
        <div>
          {_.raceDate}
        </div>
      )
    },
  }
}