import { Popover, Radio, Space, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSnapshotTriggered } from "../../services/reducers/standardSlice";

const SwitchPopover = ({ checked, minute, setMinute, setChecked }) => {
  const [value, setValue] = useState("latest");

  const onChange = (e) => {
    const v = e.target.value;
    setValue(v);

    if (v === "latest") {
      setChecked(true);
    } else {
      setMinute(v);
      setChecked(false);
    }
  };

  useEffect(() => {
    if (checked) {
      setValue("latest");
    } else {
      setValue(minute);
    }
  }, [checked]);

  return (
    <div style={{ width: "120px", height: "130px" }}>
      <span>Snapshot Select:</span>
      <Radio.Group onChange={onChange} value={value}>
        <Space direction="vertical">
          <Radio value={"latest"}>Latest</Radio>
          <Radio value={0}>Zero Min</Radio>
          <Radio value={-1}>-1 Min</Radio>
          <Radio value={3}>3 Min</Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

const SnapshotSwitch = ({ sendMessage }) => {
  const dispatch = useDispatch();

  const selectedRaceDate = useSelector((state) => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector((state) => state.race.selectedRaceNum);
  const allowSnapshot = useSelector(
    (state) => state.standard.additionalData.allowSnapshot || false
  );
  const [checked, setChecked] = useState(true);
  const [minute, setMinute] = useState(0);

  useEffect(() => {
    setChecked(true);
  }, [selectedRaceDate, selectedRaceNum]);

  const onChange = (checked) => {
    if (sendMessage) {
      setChecked(checked);
    }
  };

  useEffect(() => {
    dispatch(setSnapshotTriggered(!checked));

    sendMessage({
      race_date: selectedRaceDate,
      race_num: selectedRaceNum,
      snapshot: !checked,
      snapshot_minute: minute,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, minute]);

  return (
    <div style={{ marginLeft: "4px" }}>
      <Popover
        content={
          <SwitchPopover
            setMinute={setMinute}
            setChecked={setChecked}
            checked={checked}
            minute={minute}
          />
        }
        overlayClassName="Popover"
        placement="bottom"
      >
        <Switch
          checkedChildren="latest"
          unCheckedChildren={`${minute} min`}
          defaultChecked
          onChange={onChange}
          checked={checked}
          disabled={!allowSnapshot}
        />
      </Popover>
    </div>
  );
};

export default SnapshotSwitch;
