import store from "../reducers";
import * as API from '../../middleware/API';
import { setColdDoorData, setHorseNoteFromAPI, setSelectiveRanking } from "../reducers/standardSlice";
import { message } from "antd";

export const saveUserHorseData = (userData) => async(dispatch) => {
  try{
    const raceDate = store.getState().race.selectedRaceDate;
    const raceNum = store.getState().race.selectedRaceNum;

    const userId = store.getState().auth.userData.sub;

    if (!userId){
      return false;
    }

    const requestBody = {
      raceDate,
      raceNum,
      userId,
      userData
    }

    message.open(
      {
        key: "updateable",
        type: 'loading',
        content: `Updaing user defined horse note data: race date: ${raceDate}, race_num: ${raceNum}`,
        duration: 0
      }
    )

    const result = await API.updateHorseNote(requestBody);

    message.open(
      {
        key: "updateable",
        type: 'success',
        content: `Updated`,
        duration: 1.5
      }
    );

    return result;
  }
  catch (error){
    console.error(error.message);
    message.open(
      {
        key: "updateable",
        type: 'error',
        content: `Cannot save the data, please try later`,
        duration: 0
      }
    )
  }
}

export const getUserHorseData = () => async(dispatch) => {
  try{
    const raceDate = store.getState().race.selectedRaceDate;
    const raceNum = store.getState().race.selectedRaceNum;

    const userId = store.getState().auth.userData.sub;

    if (!userId){
      return false;
    }

    message.open(
      {
        key: "updateable",
        type: 'loading',
        content: `Loading user defined horse note data: race date: ${raceDate}, race_num: ${raceNum}`,
        duration: 0
      }
    )

    const result = await API.loadHorseNote(raceDate, raceNum, userId);

    dispatch(setHorseNoteFromAPI(result?.data?.payload));
    
    message.open(
      {
        key: "updateable",
        type: 'success',
        content: `Loaded`,
        duration: 1.5
      }
    );
  }
  catch (error){
    console.error(error.message);
    message.open(
      {
        key: "updateable",
        type: 'success',
        content: `No stored data found`,
        duration: 1.5
      }
    );
  }
}

export const sendUpdateRoadmapData = (sendMessage) => {
  const userData = store.getState().standard.userData.userData;
  const totalScore = {};
  const scoreData = store.getState().standard.userData.scoreData;

  for (const key in scoreData) {
    if (scoreData.hasOwnProperty(key) && typeof scoreData[key] === 'object' && scoreData[key].hasOwnProperty("totalScore")) {
      totalScore[key] = scoreData[key].totalScore;
    }
  }
  const user_name = store.getState().auth.userData.given_name;
  sendMessage("update", { user_data: {...userData, user_name, totalScore: totalScore} || {} });
}


export const sendRoadmapColdDoorData = (body) => async(dispatch) => {
  const raceDate = store.getState().race.selectedRaceDate;
  const raceNum = store.getState().race.selectedRaceNum;

  const coldDoorData = {
    "race_date": raceDate,
    "body": body
  }

  const {data} = await API.updateRoadmapColdDoor(coldDoorData);
  
  dispatch(setColdDoorData({ data: data, raceNum: raceNum} || {}));
}


export const updateSelectiveRanking = (payload, sendMessage) => async (dispatch) => {
  try {
    if (!sendMessage) {
      return;
    }
    const {horseNum, prompt, ranking, mode} = payload;

    if (prompt != null) {
      dispatch(setSelectiveRanking({ prompt}));
    }
    if (horseNum != null && ranking != null && mode != null) {
      dispatch(setSelectiveRanking({ horseNum, ranking, mode}));
    }
    const message = {
      command: "updateSelectiveRanking",
      payload: {
        race_date: store.getState().race.selectedRaceDate,
        race_num: store.getState().race.selectedRaceNum,
        user_name: store.getState().auth.userData.given_name,
        data: store.getState().standard.userData.selectiveRanking
      }
    }
    sendMessage(message);
  } catch (error) {
    console.error(error.message);
  }
}