import * as Helper from '../../utils/helper';

export const initialData = () => (
  {
    updateTS: Helper.currentTimeIn24HoursFormat(),
    updateTsRaw: null,
    raceTime: null,
    currentOdds: {
      1: []
    },
    currentOddsLatest:{
      1: {},
    },
    currentWeightedDoubleCI: {
      1: {}
    },
    currentDoubleStatistics: {
      1: {}
    },
    currentCode: {
      1: {
        jockey: "CCC",
        trainer: "TTT"
      }
    },
    detailData: {
      1: {
        horseNum: 1,
        nextOdds: [],
        odds: {
          1: []
        },
        code: {
          jockey: "CCC",
          trainer: "TTT"
        },
        nextOddsLatest: {},
        oddsLatest: {
          1: 0.0
        }
      }
    },
    additionalData: {
      fetchDouble: true,
      fetchWinCurrent: true,
      fetchWinNext: true,
      totalNumOfHorsesFirst: 0,
      totalNumOfHorsesSecond: 0
    },
    aliasData: {
      jockey: null,
      trainer: null
    }
  }
)

export const mapOddsByMinute = (dataArray, minStart, minEnd) => {
  const result = [];
  for (let i = minStart, j = 0; i <= minEnd; i++, j++) {
    result.push({
      minute: i,
      pv: dataArray?.[j] != null ? dataArray[j] : null
    });
  }
  return result;
}

export const generateEmptyValueGraphData = (minStart, minEnd) => {
  const result = [];
  for (let i = minStart; i <= minEnd; i++) {
    result.push({ minute: i, pv: null })
  }
  return result
}
