import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { sendUpdateRoadmapData } from "../../../../services/actions/userAction";
import { resetRoadmapDataRow } from "../../../../services/reducers/standardSlice";

const FlushColumn = (sendMessage) => {
  const dispatch = useDispatch();

  const onClick = (horseNum) => {
    dispatch(resetRoadmapDataRow({ horseNum }));
    sendUpdateRoadmapData(sendMessage);
  }

  return {
    title: "clr",
    align: 'center',
    key: `re`,
    width: 10,
    fixed: "left",
    sorter: {
      compare: (a, b) => {
        if (a?.scratched === true) return 1;
        if (b?.scratched === true) return -1;
        return a.selected - b.selected
      },
      multiple: 3
    },
    sortOrder: 'ascend',
    render: (_) => {
      return (
        <div>
          <Button type="primary" shape="circle" size="small" icon={<DeleteOutlined />} className="Button" onClick={() => onClick(_.horseNum)} />
        </div>
      )
    }
  };
};

export default FlushColumn