import { Col, Row } from "antd";

const WinInvestmentSnapshotStatisticsColumn = () => {
  return {
    title: "Win\nInvest\nSnapshot",
    align: 'center',
    key: `WinInvestment`,
    width: 100,
    render: (_) => {
      const winPercentageJockey = _.winInvestmentSnapshotStatistics?.jockey;
      const winPercentageTrainer = _.winInvestmentSnapshotStatistics?.trainer;
      const jockeyPercentage = winPercentageJockey ? (winPercentageJockey[0] / (winPercentageJockey[2] || 1) * 100).toFixed(1) : "-";
      const trainerPercentage = winPercentageTrainer ? (winPercentageTrainer[0] / (winPercentageTrainer[2] || 1) * 100).toFixed(1) : "-";

      
      return (
        <div>
          <Row
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
              backgroundColor: _.winInvestmentSnapshotStatistics?.is_invested ? "rgba(255,255,0,0.8)" : "",
            }}
          >
            <Col
              span={12}
            >
              {winPercentageJockey ? `${winPercentageJockey[0]}/${winPercentageJockey[2]}` : "-"}
            </Col>
            <Col
              span={12}
            >
              {jockeyPercentage}%
            </Col>
          </Row>
          <Row
            style={{
              backgroundColor: _.winInvestmentSnapshotStatistics?.is_invested ? "rgba(255,255,0,0.8)" : "",
            }}
          >
            <Col
              span={12}
            >
              {winPercentageTrainer ? `${winPercentageTrainer[0]}/${winPercentageTrainer[2]}` : "-"}
            </Col>
            <Col
              span={12}
            >
              {trainerPercentage}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default WinInvestmentSnapshotStatisticsColumn;