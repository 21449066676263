import { Table } from "antd"
import NameHighlight from "../../NameHighlight";

const HeaderTable = () => {
  const columns = [];

  for (let i = 1; i <= 14; i++) {
    columns.push({
      align: 'center',
      key: i,
      width: 35,
      render: (_) => {
        return <span>{i}</span>
      }
    });
  }

  return (
    <Table
      showHeader={false}
      dataSource={[{ key: 0 }]}
      columns={columns}
      pagination={false}
      className="Horse-Info-Table"
      rowKey={(record) => record.key}
    />
  )
}

const HorseTable = ({ showDetail, dataSource, placeNum }) => {
  const columns = [];

  for (let i = 1; i <= 14; i++) {
    columns.push({
      align: 'center',
      key: i,
      width: 35,
      onCell: () => {
        if (i === placeNum) {
          return { style: { fontWeight: "bolder", textDecoration: "underline" } }
        }
      },
      render: (_, _2, index) => {
        const data = dataSource?.find(horse => horse.placeNum === i);
        switch (index) {
          case 0: return <NameHighlight value={data?.jockeyName} code={data?.jockeyCode} horseCode={data?.horseCode} needHorseCodeMatch={i !== placeNum} />;
          case 1: return <NameHighlight value={data?.trainerName} code={data?.trainerCode} horseCode={data?.horseCode} needHorseCodeMatch={i !== placeNum} />;
          case 2: return <span>{data?.odds >= 0 ? data?.odds?.toFixed(data?.odds > 10 ? 0 : 1) : (data?.jockeyName ? -1 : "")}</span>;
          case 3: return <span>{data?.ci >= 0 ? data?.ci?.toFixed(2) : (data?.jockeyName ? -1 : "")}</span>;
          default: ;
        }
      }
    });
  }

  return (
    <Table
      showHeader={false}
      dataSource={showDetail ? [{key: 0}, {key: 1}, {key: 2}, {key: 3}] : [{key: 0}, {key: 1}]}
      columns={columns}
      pagination={false}
      className="Horse-Info-Table"
      rowKey={(record) => record.key}
    />
  )
}

export const HorseInfoColumn = () => {
  return {
    title: () => {
      return (
        <div>
          <div>Horses</div>
          <div style={{ padding: "2px" }}><HeaderTable /></div>
        </div>
      )
    },
    align: 'center',
    key: 'horses',
    width: 500,
    fixed: 'left',
    render: (_, _2, index) => {
      return (
        <div style={{ padding: "2px" }}>
          <HorseTable showDetail={index === 0} dataSource={_.starters} placeNum={_.placeNum} />
        </div>
      )
    }
  }
}