import { Select } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const JTSelector = ({ code, type }) => {
  const navigate = useNavigate();

  const nameList = useSelector(state => Object.values(state.jtInfo?.[type]?.profile || {}).map(profile => ({name: profile?.name, code: profile?.code})));

  const handleInfoChange = (value) => {
    navigate(`/jt-info?type=${type}&code=${value}`)
  }

  return (
    <>
      <div style={{ textAlign: 'left', fontSize: "20px" }}>
        {type === "trainer" ? "Trainer" : "Jockey"} Name List:
        <Select
          size="default"
          defaultValue={code || "---"}
          value={code || "---"}
          onChange={handleInfoChange}
          style={{ width: 160, paddingLeft: 5 }}
          dropdownClassName="Select-DropDown"
          className="Select">
          {
            nameList.map(o =>
              <Select.Option key={o.code} value={o.code}>{o.name}</Select.Option>
            )
          }
        </Select>
      </div>
    </>
  )
}

export default JTSelector;
