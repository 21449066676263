import { useSelector } from 'react-redux';
import Rroutes from './routes'
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './services/keycloak';

import './App.css';
import 'reactflow/dist/style.css';
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.min.css';

export default function App() {
  const theme = useSelector(state => state.general.theme)

  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <div className="App" data-theme={theme}>
        <Rroutes />
      </div>
    </ReactKeycloakProvider>
  );
}