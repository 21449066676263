import { useSelector } from 'react-redux';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, ReferenceLine } from 'recharts';
import { getAxisStroke, getGridStroke } from '../../../services/utils/chartHelper';
import { getZeroMinute } from '../standard/graphs/WinChart';

const WinChart = ({ data, range, showRange, odds }) => {
  // line chart display at both double and forecast/quinella card for win odds
  const theme = useSelector(state => state.general.theme);

  const {raceTime, updateTsRaw} = useSelector(state => state.forecast);
  const zeroMinute = getZeroMinute(raceTime, updateTsRaw);

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={data} margin={{ top: 2, right: 4, left: 5, bottom: 6 }}>
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          height={8}
          dataKey="minute"
          domain={range}
          ticks={showRange}
          tick={{ fontSize: "8px" }}
          stroke={getAxisStroke(theme)}
        >
          {odds != null && <Label value={odds} position={"insideTopLeft"} style={{ fontSize: "12px"}} offset={3} />}
        </XAxis>
        <YAxis
          width={16}
          // auto generate y-axis range by max * 1.1 and min * 0.9
          domain={[dataMin => dataMin < 99 ? Math.floor(dataMin * 0.9) : dataMin, dataMax => dataMax * 1.1 >= 99 ? 99 : Math.ceil(dataMax * 1.1)]}
          interval="preserveStartEnd"
          tick={{ fontSize: "8px", dx: 5, dy: -3.5 }}
          allowDecimals={false}
          stroke={getAxisStroke(theme)}
        />
        <Line type="monotone" dataKey="pv" dot={false} stroke="rgb(136, 132, 216)" isAnimationActive={false} strokeWidth={1.5} />
        <Line type="monotone" dataKey="qw" dot={false} stroke="rgb(136, 132, 216)" isAnimationActive={false} strokeWidth={1.5} strokeDasharray="5 5" />
        {(zeroMinute && zeroMinute > 0) && 
          <ReferenceLine x={zeroMinute * -1} stroke="rgba(0, 0, 0, 0.7)" strokeWidth={1} />
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

export default WinChart;