import { useSelector } from "react-redux";
import { Popover } from "antd";
import { JTStatisticsHoverTable } from "../standard/subTables/StatisticsHoverTable";
import { useTranslation } from "react-i18next";

// cells in J/T column
const cellRender = (wins, total) => {
  const displayPercentage = total > 0 && wins > 0;
  const colorCode = (percent) => {
    if (percent < 0.2) {
      return "";
    }
    return percent < 0.4 ? "rgb(247, 202, 24)" : "rgb(255, 165, 0)";
  };
  return (
    <div style={{ padding: "6px" }}>
      <div
        style={{
          textAlign: "center",
          backgroundColor: displayPercentage ? colorCode(wins / total) : "",
        }}
      >
        {displayPercentage ? `${((wins / total) * 100).toFixed(0)}%` : "-"}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: "left" }}>{wins}</div>
        <div style={{ textAlign: "right" }}>{total}</div>
      </div>
    </div>
  );
};

export const JockeyTrainerColumn = (sortedInfo) => {
  const { t } = useTranslation();
  const hideJT =
    useSelector((state) => state.drawer.standardCardDrawer.hideColumn.hideJT) &&
    "Hidden-Column";
  const highlightJT =
    useSelector(
      (state) => state.drawer.standardCardDrawer.highlightColumn.highlightJT
    ) && "Highlighted-Column";

  // column that shows the performance of different jockey/trainer cocombination
  return {
    title: t("race::jtCollabHeader"),
    className: [hideJT, highlightJT],
    align: "center",
    children: [
      {
        title: `${t("race::numOfWinHeader")}`,
        className: [hideJT, highlightJT],
        align: "center",
        children: [
          {
            title: t("race::numOfCollabHeader"),
            className: [hideJT, highlightJT],
            align: "center",
            key: "totalJT",
            width: 40,
            render: (_) => {
              const win = _.winPlaceOdds?.latest;
              let current = _.jtCollab?.tTl;
              if (win <= 3.5) {
                current = _.jtCollab?.fav;
              } else if (win <= 8) {
                current = _.jtCollab?.sup;
              } else if (win <= 17) {
                current = _.jtCollab?.inf;
              } else if (win > 17) {
                current = _.jtCollab?.unw;
              }
              return (
                <Popover
                  content={JTStatisticsHoverTable(
                    t,
                    _,
                    t("race::jtCollabHoverHeader"),
                    false
                  )}
                  overlayClassName="Popover"
                  destroyTooltipOnHide={true}
                >
                  {cellRender(current.wins, current.total)}
                </Popover>
              );
            },
          },
        ],
      },
    ],
  };
};
