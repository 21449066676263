const DoubleStatisticsColumn = (sortedInfo) => {
  return {
    title: <div style={{ fontSize: "14px" }}>Double</div>,
    align: 'center',
    key: `double`,
    width: 30,
    render: (_) => {
      const doubleStatistics = _?.doubleStatistics;
      return (
        <div>
          <div
            style={
              {
                color: doubleStatistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: doubleStatistics?.jockey?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }
            }
          >
            {doubleStatistics?.jockey?.win_percentage != null ? (doubleStatistics.jockey.win_percentage * 100).toFixed(1) : "-"}%
          </div>
          <div style={{ color: doubleStatistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)", fontWeight: doubleStatistics?.trainer?.within_range ? "bold" : null }}>
            {doubleStatistics?.trainer?.win_percentage != null ? (doubleStatistics.trainer.win_percentage * 100).toFixed(1) : "-"}%
          </div>
        </div>
      )
    }
  }
}

export default DoubleStatisticsColumn;
