import { List } from "antd";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ChatUnit from "./miscellaneous/ChatUnit";

const Body = ({ currentSection }) => {
  const { message } = useSelector(state => state.chat?.[currentSection]);

  const ref = useRef()

  useEffect(() => {
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [message])

  return (
    <div
      ref={ref}
      className="Chatbot-Message-Container"
    >
      <List>
        {(message || []).map((record, index) => {
          return (
            <>
              {record.user && <ChatUnit record={record.user} key={`user_${index}`} />}
              {record.chatbot && <ChatUnit record={record.chatbot} key={`chatbot_${index}`} />}
            </>
          )
        })}
      </List>
    </div>
  )
}

export default Body;