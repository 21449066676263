import IdHighlight from "../../IdHighlight"

export const RaceIdColumn = () => {
  return {
    title: "Id",
    align: 'center',
    key: 'id',
    width: 70,
    fixed: 'left',
    render: (_) => {
      return (
        <div>
          <IdHighlight value={_.raceId}/>
        </div>
      )
    }
  }
}