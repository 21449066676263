import { Table } from "antd";
import { useSelector } from "react-redux";

const getColumns = (trainers, races) => {
  const columns = [{
    title: "J\\T",
    align: "center",
    key: 'invest',
    fixed: "left",
    width: 67,
    render: (_) => {
      return (
        <div>{_.name}</div>
      )
    },
  }];

  for (const trainer of trainers) {
    columns.push({
      title: trainer.name,
      align: "center",
      key: `trainer_${trainer.code}`,
      width: 67,
      render: (_) => {
        const jockeyCode = _.code;
        const trainerCode = trainer.code;
        let count = 0;

        for (const race of races) {
          if (!race.horses) {
            continue;
          }
          count += race.horses.filter(horse => horse.jockeyCode === jockeyCode && horse.trainerCode === trainerCode).length;
        }
        return (
          <div>{count !== 0 && count}</div>
        )
      },
    });
  }

  return columns;
}

const JTColTable = () => {
  const jockey = Object.values(useSelector(state => state.entries.detailData.jockey));
  const trainer = Object.values(useSelector(state => state.entries.detailData.trainer));
  const races = Object.values(useSelector(state => state.entries.detailData.race));

  const jockeyStatisticsData = useSelector(state => state.entries.additionalData.jockeyStatistics);
  const trainerStatisticsData = useSelector(state => state.entries.additionalData.trainerStatistics);

  jockey.sort((a, b) => {
    if (jockeyStatisticsData?.[b.code]?.total?.w == null) {
      return -1;
    }
    return jockeyStatisticsData?.[b.code]?.total?.w - jockeyStatisticsData?.[a.code]?.total?.w ||
      jockeyStatisticsData?.[b.code]?.total?.q - jockeyStatisticsData?.[a.code]?.total?.q ||
      jockeyStatisticsData?.[b.code]?.total?.p - jockeyStatisticsData?.[a.code]?.total?.p ||
      jockeyStatisticsData?.[b.code]?.total?.fourth - jockeyStatisticsData?.[a.code]?.total?.fourth ||
      jockeyStatisticsData?.[b.code]?.total?.total - jockeyStatisticsData?.[a.code]?.total?.total ||
      jockeyStatisticsData?.[b.code]?.total?.gain - jockeyStatisticsData?.[a.code]?.total?.gain
  });

  trainer.sort((a, b) => {
    if (trainerStatisticsData?.[b.code]?.total?.w == null) {
      return -1;
    }
    return trainerStatisticsData?.[b.code]?.total?.w - trainerStatisticsData?.[a.code]?.total?.w ||
      trainerStatisticsData?.[b.code]?.total?.q - trainerStatisticsData?.[a.code]?.total?.q ||
      trainerStatisticsData?.[b.code]?.total?.p - trainerStatisticsData?.[a.code]?.total?.p ||
      trainerStatisticsData?.[b.code]?.total?.fourth - trainerStatisticsData?.[a.code]?.total?.fourth ||
      trainerStatisticsData?.[b.code]?.total?.total - trainerStatisticsData?.[a.code]?.total?.total ||
      trainerStatisticsData?.[b.code]?.total?.gain - trainerStatisticsData?.[a.code]?.total?.gain
  });

  return (
    <div style={{ padding: 10, width: "98vw" }}>
      <Table
        pagination={false}
        loading={jockey.length === 0}
        dataSource={jockey}
        columns={getColumns(trainer, races)}
        className="Entries-Table"
        scroll={{ x: 'max-content' }}
        rowKey={(record) => record.code}
      />
    </div>
  );
}

export default JTColTable;