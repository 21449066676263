import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { doubleProcessing } from "./handling";
import { doubleProcessingWS } from "./handlingWS";

const doubleSlice = createSlice({
  name: "double",
  initialState: initialData(),
  reducers: {
    setDoubleData: (state, data) => {
      doubleProcessing(state, data);
    },
    setDoubleDataWS: (state, data) => {
      doubleProcessingWS(state, data);
    },
    removeDoubleData: (state) => {
      // reset all data
      state.currentOdds = {};
      state.currentOddsLatest = {};
      state.currentWeightedDoubleCI = {};
      state.currentCode = {};
      state.detailData = {};
      state.additionalData = {
        fetchDouble: true,
        fetchWinCurrent: true,
        fetchWinNext: true,
        totalNumOfHorsesFirst: 0,
        totalNumOfHorsesSecond: 0
      };
      state.updateTS = null;
      state.updateTsRaw = null;
      state.raceTime = null;
    }
  }
});

export const { setDoubleData, setDoubleDataWS, removeDoubleData } = doubleSlice.actions;

export default doubleSlice.reducer;