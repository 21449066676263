export const initialData = () => {
  return {
    jockey: {
      profile: {
        MHT: {
          code: "MHT",
          name: "巫顯東",
          age: 28,
          background: "巫顯東曾先後在南非及紐西蘭接受海外訓練。他於2013年5月開始在南非德班市南非騎師學院習騎，同年12月首度策騎出賽。他在南非合共上陣一百七十四次，取得五場頭馬。2014年12月，巫顯東獲派到紐西蘭新派拉謀夫，跟隨練馬師施樂克學藝，而前見習騎師蔣嘉琦亦曾跟隨該練馬師習騎。2017年1月1日，巫顯東策騎「炸魚薯條」取得在港首場頭馬，而他以二十四捷結束在港首個馬季。這位年輕騎師在港第二季從騎表現續有進步，取得二十五勝，惟因於2018年3月下旬在試閘期間墮馬受傷而未能於該季餘下時間恢復策騎。他於2018/2019年度馬季同樣贏得二十五場頭馬，而在2019/2020、2020/2021及2021/22年度馬季則分別勝出十二、七場及十一場賽事。",
          achievements: "",
          notableWins: "",
          profileUrl: "https://racing.hkjc.com/racing/information/Chinese/Jockey/JockeyProfile.aspx?JockeyCode=MHT",
          nationality: "中國"
        },
      },
      statistics: {
        CLR: {
          code: "CLR",
          seasonWinAmount: 902100,
          seasonRunCount: 27,
          season1StCount: 0,
          season2NdCount: 1,
          season3RdCount: 0,
          season4ThCount: 1,
          season1StCountLast10Days: 0,
          season1StRatio: 0.0
        },
      },
      pastPerformance: {

      }
    },
    trainer: {
      profile: {},
      statistics: {},
      pastPerformance: {}
    }
  }
}