import { Col, Row } from "antd";
import { useSelector } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import {
  getAxisStroke,
  getGridStroke,
} from "../../../../services/utils/chartHelper";

const WinInvestmentChart = ({ range, showRange }) => {
  const dataSource = useSelector(
    (state) => state.standard.additionalData.winInvestmentSum
  );
  const top6WinInvestmentSum = useSelector(
    (state) => state.standard.additionalData.winInvestmentSumTop6
  );
  const theme = useSelector((state) => state.general.theme);

  const strokeWidth = 1.5;

  return (
    <div
      style={{
        height: "90px",
        width: "150px",
        position: "absolute",
        top: "190px",
        left: "30vw",
      }}
    >
      <Row style={{ height: "inherit", width: "inherit" }}>
        <Col span={23}>
          <ResponsiveContainer width="100%" height="100%" maxHeight="100%">
            <LineChart
              data={dataSource}
              margin={{ top: 0, right: 2, left: 16, bottom: 20 }}
            >
              <CartesianGrid stroke={getGridStroke(theme)} />
              <XAxis
                height={8}
                dataKey="minute"
                domain={range}
                ticks={showRange}
                tick={{ fontSize: "8px" }}
                stroke={getAxisStroke(theme)}
              />
              <YAxis
                width={10}
                domain={["dataMin", "dataMax"]}
                tick={{ fontSize: "8px", dx: 5, dy: -3.6 }}
                yAxisId="1"
                interval="preserveStartEnd"
                allowDataOverflow={true}
                stroke={getAxisStroke(theme)}
              />

              <Line
                type="monotone"
                yAxisId="1"
                dataKey="value"
                dot={false}
                stroke="rgb(255,120,0)"
                strokeWidth={strokeWidth}
              />
            </LineChart>
          </ResponsiveContainer>
        </Col>
        <Col span={1}>
          {dataSource?.[29]
            ? `${Number(dataSource[29]?.value)?.toFixed(0)}%`
            : ""}
          {top6WinInvestmentSum?.[29]
            ? `(${Number(top6WinInvestmentSum[29]?.value)?.toFixed(0)}%)`
            : ""}
        </Col>
      </Row>
    </div>
  );
};

export default WinInvestmentChart;
