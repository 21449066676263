/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row, Col } from "antd"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { HorseResultList } from "./HorseResultList";

import "./index.css"
import { PoolOddsResultTable } from "./PoolOddsResultTable";
import LossDetailTable from "./LossDetailTable";

const openNewWindow = (url) => {
  if (url) {
    window.open(url, "_blank", "'location=yes,height=700,width=1000,scrollbars=yes,status=yes'");
  }
}

export const ResultInfo = () => {
  const { t } = useTranslation();
  const additionalData = useSelector(state => state.standard.additionalData);
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);

  const getReplayUrl = () => {
    const num = selectedRaceNum < 10 ? `0${selectedRaceNum}` : selectedRaceNum;
    return `https://racing.hkjc.com/racing/video/play.asp?type=replay-full&date=${selectedRaceDate?.replaceAll("-", "")}&no=${num}&lang=chi`;
  }

  return (
    <div style={{ borderBottom: "1px solid rgb(220, 220, 220)" }}>
      <div style={{ padding: "10px" }}>
        <Row>
          <Col span={8} className="Result-Col-Left">
            <div style={{ padding: "0 16px 16px 16px" }}>
              {
                additionalData?.resultUrl ?
                  <div>
                    <Row>
                      <Col span={12} offset={6}>
                        <a style={{ fontSize: "24px" }} href={additionalData.resultUrl} target='_blank' rel="noreferrer">{t("race::resultDividenTitle")}</a>
                      </Col>
                      <Col span={6} offset={0}>
                        <a
                          style={{ fontSize: "24px" }}
                          onClick={() => openNewWindow(getReplayUrl())}
                        >{t("race::replay")}</a>
                      </Col>
                    </Row>
                  </div> :
                  <span style={{ fontSize: "24px" }}>{t("race::resultDividenTitle")}</span>
              }
              <PoolOddsResultTable />
            </div>
          </Col>
          <Col span={16} className="Result-Col-Right">
            <div style={{ padding: "0 16px 16px 24px" }}>
              <HorseResultList />
            </div>
          </Col>
        </Row>
      </div>
      <LossDetailTable />
    </div>
  );
};