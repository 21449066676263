export const generateEmptyValueGraphData = (minStart, minEnd) => {
  const result = [];
  for (let i = minStart; i <= minEnd; i++) {
    result.push({ minute: i, win: null, qw: null, ci: null, poolRatio: null });
  }
  return result;
};

export const getInterval = (baseMinute, interval) => {
  const result = baseMinute - interval;
  return result >= 0 ? result : result + 60;
};

export const gainMapping = (detailData, gainData, code) => {
  // map mulitply gain data by code
  if (gainData?.data?.[code] != null) {
    detailData.multiply = Math.ceil(gainData.data[code]);
  }
  // map addition gain data by code
  if (gainData?.addition?.[code] != null) {
    detailData.addition = gainData.addition[code];
    detailData.addition > 0 &&
      (detailData.addition = detailData.addition.toFixed(2));
  }
};

export const statisticsMapping = (state, statistics) => {
  if (!statistics) {
    return;
  }

  // api field name : ui stored field name
  const statisticsGroupMapping = {
    total: "tTL",
    favoured: "fav",
    superior: "sup",
    inferior: "inf",
    unwanted: "unw",
  };
  const statisticsEntriesMapping = {
    wins: "wins",
    involvements: "total",
    collaborations: "total",
    active: "active",
  };

  for (const [k, v] of Object.entries(statisticsGroupMapping)) {
    if (statistics?.[k] == null) {
      continue;
    }
    for (const [origin, target] of Object.entries(statisticsEntriesMapping)) {
      if (statistics[k]?.[origin] == null) {
        continue;
      }
      state[v][target] = statistics[k][origin];
      statistics[k]["active"] &&
        (state.current[target] = statistics[k][origin]);
    }
  }
};

export const lossDetailHandling = (resultData, lossDetail) => {
  if (!lossDetail) {
    return;
  }
  try {
    resultData.lossDetail = lossDetail;
  } catch (e) {
    console.error(e);
  }
};

export const jockeyTrainerAliasHandling = (
  state,
  jockeyAlias,
  trainerAlias
) => {
  if (jockeyAlias) {
    state.additionalData.jockeyAlias = jockeyAlias;
  }
  if (trainerAlias) {
    state.additionalData.trainerAlias = trainerAlias;
  }
};

export const commandHandling = (state, command) => {
  if (command) {
    state.command = command;
  }
};

export const landscapeDistributionHandling = (state, landscapeDistribution) => {
  if (landscapeDistribution) {
    state.landscapeDistribution = landscapeDistribution;
  }
};
