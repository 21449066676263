import { Row, Col } from "antd"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getFraction } from "../../../services/utils/fraction";

export const getBackgroundColor = (placing) => {
  switch (placing) {
    case 1: return "rgb(255, 127, 0)";
    case 2: return "rgb(0, 255, 0)";
    case 3: return "rgb(135, 206, 235)";
    case 4: return "rgb(240, 240, 0)";
    default: return "";
  }
}

export const getWinOddsColor = (winOdds) => {
  if (winOdds <= 3.5) {
    return "rgb(255, 0, 0)";
  } else if (winOdds <= 8) {
    return "rgb(0, 255, 0)";
  } else if (winOdds <= 17) {
    return "rgb(135, 206, 235)";
  } else if (winOdds > 17) {
    return "rgb(240, 240, 0)";
  }
}

const HorseEntry = ({ horse, horseProfile, alias, odds, placing }) => {
  return (
    <div style={{ padding: "8px 2px 8px 2px" }}>
      <Row style={{ fontSize: "12px" }}>
        <Col span={6} className="Entries-Col-Left" offset={9}>
          <div style={{ textAlign: "center", fontWeight: "bold", textDecoration: "underline", color: getWinOddsColor(odds)}}>{odds > 99 ? 99 : odds || 0}</div>
        </Col>
      </Row>
      <Row>
        <Col span={3}>
          <div style={{ borderStyle: "ridge", margin: "2px -4px 0 0px", fontSize: "12px" }}>
            <span>{horse.horseNum}</span>
          </div>
        </Col>
        <Col span={18}>
          <span style={{ textAlign: "center", fontWeight: "bold", backgroundColor: getBackgroundColor(placing) }}>{horse.horseName}</span>
          <span style={{ textAlign: "center", fontSize: "14px", margin: "0px" }} >{horseProfile?.age}<sup>a</sup></span>
        </Col>
        <Col span={3}>
          <div style={{ borderStyle: "ridge", margin: "2px 0px 0 -4px", fontSize: "12px" }}>
            <span>{alias}</span>
          </div>
        </Col>
      </Row>
      <Row style={{ fontSize: "12px", margin: "0 0 0 0" }}>
        <Col span={5} className="Entries-Col-Left">
          <div className="Circle-Number">{horse.draw < 0 ? "-" : horse.draw}</div>
        </Col>
        <Col span={14}>
          <Row>
            <Col span={6} className="Entries-Col-Left">
              <span>{horseProfile?.total1stCount || horseProfile?.total1StCount || 0}</span>
            </Col>
            <Col span={6} className="Entries-Col-Left">
              <span>{horseProfile?.total2ndCount || horseProfile?.total2NdCount || 0}</span>
            </Col>
            <Col span={6} className="Entries-Col-Left">
              <span>{horseProfile?.total3rdCount || horseProfile?.total3RdCount || 0}</span>
            </Col>
            <Col span={6} className="Entries-Col-Left">
              <span>{horseProfile?.totalRunCount || 0}</span>
            </Col>
          </Row>
        </Col>
        <Col span={5} className="Entries-Col-Left">

        </Col>
      </Row>
      <Row style={{ fontSize: "12px" }}>
        <Col span={6} className="Entries-Col-Left" offset={6}>
          <span>{horse?.lengthBehindWinner?.[0]}{getFraction(horse?.lengthBehindWinner?.[1], horse?.lengthBehindWinner?.[2])}</span>
        </Col>
        <Col span={6} className="Entries-Col-Left">
          <span>{horse?.lengthBehindPrevious?.[0]}{getFraction(horse?.lengthBehindPrevious?.[1], horse?.lengthBehindPrevious?.[2])}</span>
        </Col>
      </Row>
    </div>
  );
}

export const RaceColumn = (showJockey) => {
  const { t } = useTranslation();

  const pastData = useSelector(state => state.entries.pastData);
  const detailData = useSelector(state => state.entries.detailData) || {};
  const currentRaces = detailData?.race || {};
  const currentWinOdds = detailData?.winOdds || {};
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const totalRace = Math.max(...Object.values(pastData).map(d => Object.keys(d).length, Object.keys(currentRaces).length));

  const additionalData = useSelector(state => state.entries.additionalData);
  const horseProfiles = additionalData.horse;
  const jockeyAlias = additionalData.jockeyAlias;
  const trainerAlias = additionalData.trainerAlias;

  const columns = [];

  for (let i = 1; i <= totalRace; i++) {
    columns.push({
      title: () => {
        return (
          <div>
            <span>{t("entries::raceHeader")} {i}</span><br />
          </div>
        )
      },
      align: "center",
      key: 'wins',
      width: 130,
      render: (_) => {
        const checkRaceDate = selectedRaceDate === _.raceDate;
        const race = !checkRaceDate ? pastData?.[_.raceDate]?.[i]?.race_card : currentRaces?.[i];
        const filteredHorses = race?.horses?.filter(data => data[showJockey ? "jockeyCode" : "trainerCode"] === _.code) || [];
        const winOdds = !checkRaceDate ? pastData?.[_.raceDate]?.[i]?.win_odds : currentWinOdds?.[i];
        return (
          <div>
            {
              filteredHorses?.map((horse) => {
                const alias = showJockey ? trainerAlias?.[horse.trainerCode] : jockeyAlias?.[horse.jockeyCode];
                const odds = !checkRaceDate ? winOdds?.[String(horse.horseNum)]?.latest?.win : winOdds?.[horse.horseNum];
                const placing = !checkRaceDate ? 
                  pastData?.[_.raceDate]?.[i]?.race_result?.horses?.find(entry => entry.horseNum === horse.horseNum)?.placing : 
                  horse.placing;

                return <HorseEntry horse={horse} horseProfile={horseProfiles?.[horse.horseCode]} alias={alias} odds={odds} key={horse.horseNum} placing={placing} />
              }
              )
            }
          </div>
        )
      },
    })
  }

  return columns;
}
