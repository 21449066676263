import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch } from "react-redux";
import { sendRoadmapColdDoorData } from "../../../services/actions/userAction";

const ColdDoorButton = () => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const placeholder = "Import the data in form of 1\\123012312;2\\12311213;"

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    dispatch(sendRoadmapColdDoorData(text));
    setOpen(false);
  };
  const handleCancel = () => {
    setText("")
    setOpen(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <div style={{ margin: "8px" }}>
      <Button
        onClick={showModal}
        style={{ padding: "1px" }}
        className="Button"
      >
        Comment Public Score Import
      </Button>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            Cold Door Score Import
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <TextArea value={text} onChange={(v) => setText(v.target.value)} rows={1} placeholder={placeholder} />
      </Modal>
    </div>
  )
};

export default ColdDoorButton;
