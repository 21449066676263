import { Table } from "antd";
import { CloseOutlined } from '@ant-design/icons';
import * as Helper from '../../../../services/utils/helper';
import { useSelector } from "react-redux";

const TableColumn = (header) => {
  const highlightForecast = useSelector(state => state.drawer.standardCardDrawer.highlightColumn.highlightForecast) && 'Highlighted-Column';
  const hideForecast = useSelector(state => state.drawer.standardCardDrawer.hideColumn.hideForecast) && 'Hidden-Column';

  // rendering logic for each cell in table
  const cellRendering = (horseNum, forecastHorseNum, forecastOdds, forecastCI, quinellaOdds, quinellaCi, confidentIndex, displayQuinella, index) => {
    // if same horse num combination, display cross symbol
    if (String(horseNum) === forecastHorseNum) {
      if (index === 0) {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 2px 0 2px' }}>
              <div style={{ textAlign: 'left' }} >f.o</div>
              <div style={{ textAlign: 'right' }}>q.o</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 2px 0 2px' }}>
              <div style={{ textAlign: 'left' }} >f.ci</div>
              <div style={{ textAlign: 'right' }}>q.ci</div>
            </div>
          </div>
        )
      }
      return (
        <CloseOutlined />
      )
    }
    // get the color code of forecast ci
    const color = Helper.getForecastColorCode(Number(forecastCI), Number(confidentIndex))
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 2px 0 2px' }}>
          <div style={{ textAlign: 'left' }} >{forecastOdds}</div>
          <div style={{ textAlign: 'right' }}>{displayQuinella && quinellaOdds}</div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 2px 0 2px' }}>
          <div style={{ textAlign: 'left', color: color }} >{forecastCI}</div>
          <div style={{ textAlign: 'right' }}>{displayQuinella && quinellaCi}</div>
        </div>
      </div>
    )
  }
  const headerNum = [
    header.forecastHorseNum.lowest,
    header.forecastHorseNum.secondLowest,
    header.forecastHorseNum.thirdLowest,
  ]

  return [
    {
      title: "",
      className: [highlightForecast, hideForecast],
      align: 'center',
      key: 'forecastNum',
      width: '10.0%',
      render: (_) => [
        <div style={{ textAlign: 'center' }} >{_.horseNum}</div>,
      ],
    },
    {
      title: header.forecastPoolPercentage.lowest || -1,
      className: [highlightForecast, hideForecast],
      align: 'center',
      key: 'hottestForecast',
      width: '30.0%',
      children: [
        {
          title: headerNum[0] || -1,
          className: [highlightForecast, hideForecast],

          align: 'center',
          render: (_, _2, index) => cellRendering(_.horseNum, headerNum[0], _.forecastOdds["lowest"], _.forecastCi["lowest"], _.quinellaOdds["lowest"], _.quinellaCi["lowest"], _.confidentIndex["latest"], false, index),
        },
      ],
    },
    {
      title: header.forecastPoolPercentage.secondLowest || -1,
      className: [highlightForecast, hideForecast],
      align: 'center',
      key: 'secondHottestForecast',
      width: '30.0%',
      children: [
        {
          title: headerNum[1] || -1,
          className: [highlightForecast, hideForecast],
          align: 'center',
          render: (_, record, index) => cellRendering(_.horseNum, headerNum[1], _.forecastOdds["secondLowest"], _.forecastCi["secondLowest"], _.quinellaOdds["secondLowest"], _.quinellaCi["secondLowest"], _.confidentIndex["latest"], index === 0),
        },
      ],
    },
    {
      title: header.forecastPoolPercentage.thirdLowest || -1,
      className: [highlightForecast, hideForecast],
      align: 'center',
      key: 'thirdHottestForecast',
      width: '30.0%',
      children: [
        {
          title: headerNum[2] || -1,
          className: [highlightForecast, hideForecast],
          align: 'center',
          render: (_, record, index) => cellRendering(_.horseNum, headerNum[2], _.forecastOdds["thirdLowest"], _.forecastCi["thirdLowest"], _.quinellaOdds["thirdLowest"], _.quinellaCi["thirdLowest"], _.confidentIndex["latest"], index < 2),
        },
      ],
    }
  ];
}

export const ForecastTable = () => {
  // forecast, quinella odds and ci table for the hottest 3 horse in standard card
  const dataSource = useSelector(state => Object.values(state.standard.detailData));
  const headerData = useSelector(state => state.standard.headerData);

  const reducedData = []
  for (const num of Object.values(headerData.forecastHorseNum)) {
    const data = dataSource.find(data => data.horseNum === Number(num));
    data && reducedData.push(data);
  }

  return (
    <div style={{ padding: "4px" }}>
      <Table
        pagination={false}
        loading={reducedData.length === 0}
        dataSource={reducedData}
        columns={TableColumn(headerData)}
        style={{ width: "99%", top: 0 }}
        rowKey={(record, index)=>index}
      />
    </div>
  );
}