import { generateEmptyValueGraphData, mapOddsByMinute } from "./common";
import * as Helper from '../../utils/helper';

export const doubleProcessing = (state, data) => {
  // prepare and extract all required data
  const updateTS = new Date();
  const doubleOdds = data.payload.doubleOdds;
  const firstOdds = data.payload.firstOdds;
  const secondOdds = data.payload.secondOdds;
  const firstHorses = data.payload.firstRace?.horses;
  const secondHorses = data.payload.secondRace?.horses;
  const raceTime = new Date(data.payload.firstRace?.info?.raceTime || 0);
  const timeInterval = Math.floor((raceTime - updateTS) / 60000) * -1;

  // store alias if no alias stored
  if (data.payload.jockeyAlias) {
    state.aliasData.jockey = data.payload.jockeyAlias;
  }
  if (data.payload.trainerAlias) {
    state.aliasData.trainer = data.payload.trainerAlias;
  }
  // mapping win odds, and jt code of first race
  for (const horseNum of Object.keys(firstHorses || {})) {
    if (!state.currentOdds[horseNum]) {
      state.currentOdds[horseNum] = generateEmptyValueGraphData(-30, 0);
    }
    state.currentOdds[horseNum] = firstOdds?.[horseNum] ? firstOdds[horseNum].slice(30).map((data, index) => { return { minute: index - 30, pv: data } }) : state.currentOdds[horseNum];
    state.currentCode[horseNum] = { jockey: firstHorses[horseNum]?.jockeyCode, trainer: firstHorses[horseNum]?.trainerCode };
  }
  // mapping each row data by each horse combinations
  for (const secondHorseNum of Object.keys(secondHorses || {})) {
    doubleHandling(state, secondHorseNum, secondOdds?.[secondHorseNum], doubleOdds, firstHorses, secondHorses);
  }
  state.updateTS = Helper.currentTimeIn24HoursFormat();

  // store if data already exist and no need for further fetching
  state.additionalData.fetchDouble = !(timeInterval > 10 && doubleOdds) && state.additionalData.fetchDouble;
  state.additionalData.fetchWinCurrent = !(timeInterval > 10 && firstOdds) && state.additionalData.fetchWinCurrent;
  state.additionalData.fetchWinNext = !(timeInterval > 10 && secondOdds) && state.additionalData.fetchWinNext;
}

const doubleHandling = (state, secondHorseNum, secondOdds, doubleOdds, firstHorses, secondHorses) => {
  // if no each horse in data, initialize
  if (state.detailData[secondHorseNum] == null) {
    state.detailData[secondHorseNum] = {
      horseNum: secondHorseNum,
      nextOdds: generateEmptyValueGraphData(-60, -30),
      odds: {},
      code: {}
    };
  }
  const data = state.detailData[secondHorseNum];

  // store the next race horse win odds data
  data.nextOdds = secondOdds ? secondOdds.slice(0, 31).map((data, index) => { return { minute: index - 60, pv: data } }) : data.nextOdds;

  // store double odds, and jt code of second race 
  for (const firstHorseNum of Object.keys(firstHorses || {})) {
    if (doubleOdds?.[firstHorseNum]?.[secondHorseNum]) {
      data.odds[firstHorseNum] = mapOddsByMinute(doubleOdds?.[firstHorseNum]?.[secondHorseNum], -30, 0);
    }
    data.code = { jockey: secondHorses[secondHorseNum]?.jockeyCode, trainer: secondHorses[secondHorseNum]?.trainerCode };
  }
}
