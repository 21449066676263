import { t } from "i18next";
import { dateStringIn24HoursFormat } from "../../../utils/helper";

export const martixColumnHandling = (
  detailData,
  userData,
  matrixData,
  horseNum
) => {
  try {
    if (!matrixData) {
      return;
    }

    // mapping method of jockey and trainer matrix
    const matrixMapping = (datasource, target) => {
      if (datasource?.[horseNum]?.jockey) {
        detailData.matrixDataJockey[target] = datasource[horseNum].jockey;
      } else {
        detailData.matrixDataJockey[target] = {};
      }
      if (datasource?.[horseNum]?.trainer) {
        detailData.matrixDataTrainer[target] = datasource[horseNum].trainer;
      } else {
        detailData.matrixDataJockey[target] = {};
      }
    };
    // mapping apiField : storageField
    Object.entries({
      latest: "latest",
      five: "five",
      ten: "ten",
    }).forEach(([apiField, storageField]) =>
      matrixMapping(matrixData?.[apiField], storageField)
    );

    let matrixIndex = [];
    const jockey = matrixData.latest[horseNum]?.jockey;
    const trainer = matrixData.latest[horseNum]?.trainer;
    if (jockey) {
      detailData.jockeyGameMapPercentage =
        jockey.first > 0 && jockey.total > 0
          ? ((jockey.first / jockey.total) * 100).toFixed(0)
          : 0;
      matrixIndex = jockey.index;
    }
    if (trainer) {
      detailData.trainerGameMapPercentage =
        trainer.first !== -1 && trainer.total > 0
          ? ((trainer.first / trainer.total) * 100).toFixed(0)
          : 0;
      matrixIndex = trainer.index;
    }

    if (matrixIndex.length < 3) {
      return;
    }
  } catch (e) {
    console.error(e);
  }
};

export const gameMapHandling = (additionalData, userData, gameMap) => {
  if (!gameMap) {
    return;
  }
  try {
    additionalData.gameMap = { secondLast: {}, last: {}, latest: {} };
    const storedGameMap = additionalData.gameMap;
    const mapping = (key, eventKey) => {
      if (!(eventKey in gameMap)) {
        return;
      }
      storedGameMap[key] = {};
      storedGameMap[key].grouping = gameMap[eventKey]?.grouping || [];
      storedGameMap[key].statistics = gameMap[eventKey]?.statistics;
      storedGameMap[key].poolRatio = gameMap[eventKey]?.poolRatio;
      storedGameMap[key].updateTs = gameMap[eventKey]?.updateTs;
    };

    if ("normal" in gameMap) {
      mapping("latest", "normal");
      if ("prediction" in gameMap) {
        // storedGameMap.prediction = gameMap.prediction;
      } else {
        mapping("last", "prediction1");
        mapping("secondLast", "prediction2");
      }
      mapping("zero", "zero");
    } else {
      mapping("latest", "latest");
      mapping("last", "last");
      mapping("secondLast", "secondLast");
    }
  } catch (e) {
    console.error(e);
  }
};

export const winInvestmentDifferenceStatisticsHandling = (
  detailData,
  winInvestmentDifferenceStatistics
) => {
  if (!winInvestmentDifferenceStatistics) {
    return;
  }
  try {
    detailData.winInvestmentDifferenceStatistics.winOddsGroup =
      winInvestmentDifferenceStatistics?.win_odds_group;
    detailData.winInvestmentDifferenceStatistics.investmentDifferenceGroup =
      winInvestmentDifferenceStatistics?.investment_difference_group;
    detailData.winInvestmentDifferenceStatistics.winPercentageJockey =
      winInvestmentDifferenceStatistics?.win_percentage_jockey;
    detailData.winInvestmentDifferenceStatistics.winPercentageTrainer =
      winInvestmentDifferenceStatistics?.win_percentage_trainer;

    detailData.winInvestmentDifferenceStatistics.jockey =
      winInvestmentDifferenceStatistics?.jockey;
    detailData.winInvestmentDifferenceStatistics.trainer =
      winInvestmentDifferenceStatistics?.trainer;
  } catch (e) {
    console.error(e);
  }
};

export const qwStatisticsHandling = (detailData, qwStatistics) => {
  if (!qwStatistics) {
    return;
  }
  try {
    const jockey = qwStatistics?.jockey;
    const trainer = qwStatistics?.trainer;
    detailData.qwStatistics.jockey = jockey;
    detailData.qwStatistics.trainer = trainer;
  } catch (e) {
    console.error(e);
  }
};

export const overboughtHandling = (detailData, overboughtStatistics) => {
  if (!overboughtStatistics) {
    return;
  }
  try {
    detailData.overboughtStatistics.winOddsGroup =
      overboughtStatistics?.win_odds_group;
    detailData.overboughtStatistics.overbought =
      overboughtStatistics?.overbought;
    detailData.overboughtStatistics.winPercentageJockey =
      overboughtStatistics?.win_percentage_jockey;
    detailData.overboughtStatistics.winPercentageTrainer =
      overboughtStatistics?.win_percentage_trainer;
    detailData.overboughtStatistics.jockey = overboughtStatistics?.jockey;
    detailData.overboughtStatistics.trainer = overboughtStatistics?.trainer;
    detailData.overboughtStatistics.jTCollabWinPercent =
      overboughtStatistics?.jt_collab_win_percent;
    detailData.overboughtStatistics.jTCollabTop4Percent =
      overboughtStatistics?.jt_collab_top_4_percent;
  } catch (e) {
    console.error(e);
  }
};

export const doubleStatisticsHandling = (detailData, doubleStatistics) => {
  if (!doubleStatistics) {
    return;
  }

  try {
    detailData.doubleStatistics = doubleStatistics?.[detailData.horseNum];
  } catch (e) {
    console.error(e);
  }
};

export const fQStatisticsHandling = (detailData, fQStatistics) => {
  if (!fQStatistics) {
    return;
  }

  try {
    detailData.fQStatistics = fQStatistics?.[detailData.horseNum];
  } catch (e) {
    console.error(e);
  }
};

export const winPoolDistributionGameMapHandling = (
  additionalData,
  winPoolDistributionGameMap,
  winPlaceOdds
) => {
  if (!winPoolDistributionGameMap || !winPlaceOdds) {
    return;
  }
  try {
    const winOddsMapping = [];

    for (const [horseNum, odds] of Object.entries(winPlaceOdds || {})) {
      winOddsMapping.push({ horseNum, winOdds: odds?.latest?.win || 999 });
    }
    winOddsMapping.sort((a, b) => a.winOdds - b.winOdds);

    additionalData.winPoolDistributionGameMap = winPoolDistributionGameMap.map(
      (entry, index) => {
        return {
          horseNum: winOddsMapping?.[index]?.horseNum,
          value: (entry * 100).toFixed(1),
        };
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export const jtNodeHandling = (additionalData, detailData, jtNode) => {
  if (!jtNode) {
    return;
  }
  try {
    additionalData.jtNode = {};
    additionalData.jtNode.edges = [];
    const highlightMapping = {
      PZ: "rgb(255, 0, 255)",
      HCY: "rgb(0, 0, 255)",
      BH: "rgb(0, 255, 0)",
    };

    const append = (
      id,
      jockeyCode,
      trainerCode,
      winStatistics,
      idHorseNumMapping,
      jockeyName,
      trainerName
    ) => {
      additionalData.jtNode.edges.push({
        id: id,
        source: jockeyCode,
        target: trainerCode,
        type: "straight",
        interactionWidth: 5,
        data: {
          winStatistics: winStatistics || {},
          idHorseNumMapping: idHorseNumMapping,
          jockeyName: jockeyName,
          trainerName: trainerName,
        },
        style: {
          stroke: highlightMapping?.[jockeyCode] || "rgb(120, 120, 120)",
        },
      });
    };

    for (const data of jtNode) {
      if (data.win_statistics.win_count < 1) continue;
      const jockeyHorse = Object.values(detailData || {}).filter(
        (horse) => horse.jockeyCode === data?.jockey_code
      )?.[0];
      if (!jockeyHorse) continue;

      const trainerHorseList = Object.values(detailData || {}).filter(
        (horse) => horse.trainerCode === data?.trainer_code
      );

      if (trainerHorseList.length === 1) {
        append(
          `${data?.jockey_code}-${data?.trainer_code}`,
          data?.jockey_code,
          data?.trainer_code,
          data?.win_statistics,
          [jockeyHorse?.horseNum, trainerHorseList[0]?.horseNum],
          jockeyHorse?.jockeyName,
          trainerHorseList[0]?.trainerName
        );
      } else {
        trainerHorseList.forEach((horse, index) => {
          append(
            `${data?.jockey_code}-${data?.trainer_code}_${index}`,
            data?.jockey_code,
            `${data?.trainer_code}_${index}`,
            data?.win_statistics,
            [jockeyHorse.horseNum, horse.horseNum],
            jockeyHorse?.jockeyName,
            horse?.trainerName
          );
        });
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const decisionTreeHandling = (decisionTreeData, decisionTree) => {
  if (!decisionTree) {
    return;
  }
  try {
    decisionTreeData.data = {};
    for (const [key, value] of Object.entries(decisionTree)) {
      const d = new Date(key);
      // get date string in yyyy-mm-dd from d
      const dateString = d.toISOString().split("T")[0];

      const time = `${dateString} ${dateStringIn24HoursFormat(d)}`;
      decisionTreeData.data[time] = value;
      decisionTreeData.timelineSelected = time;
      // decisionTreeData.rebornHorses = value?.reborn?.all || value?.reborn || [];
      // decisionTreeData.eliminatedHorses = value?.eliminated || [];
    }
    decisionTreeData.extraScore = {};
    // const extraScore = decisionTreeData.extraScore
    // decisionTreeData.rebornHorses?.forEach((horseNum) => {
    //   if (!(horseNum in extraScore)) {
    //     extraScore[horseNum] = {};
    //   }
    //   extraScore[horseNum].qwScore = 9;
    // })
    // decisionTreeData.eliminatedHorses?.forEach((horseNum) => {
    //   if (!(horseNum in extraScore)) {
    //     extraScore[horseNum] = {};
    //   }
    //   extraScore[horseNum].qwScore = -9;
    // })
  } catch (e) {
    console.error(e);
  }
};

export const speedProStatisticsHandling = (detailData, speedProStatistics) => {
  if (!speedProStatistics) {
    return;
  }
  try {
    detailData.speedProStatistics = speedProStatistics;
  } catch (e) {
    console.error(e);
  }
};

export const coldDoorStatisticsHandling = (detailData, coldDoorStatistics) => {
  if (!coldDoorStatistics) {
    return;
  }
  try {
    detailData.coldDoorStatistics = coldDoorStatistics;
  } catch (e) {
    console.error(e);
  }
};

export const newColdDoorHandling = (detailData, newColdDoor) => {
  if (!newColdDoor) {
    return;
  }
  try {
    detailData.newColdDoor = newColdDoor;
  } catch (e) {
    console.error(e);
  }
};

export const winInvestmentSnapshotStatisticsHandling = (
  detailData,
  winInvestmentSnapshotStatistics
) => {
  if (!winInvestmentSnapshotStatistics) {
    return;
  }

  try {
    let pointer = 99;
    for (const [minute, payload] of Object.entries(
      winInvestmentSnapshotStatistics
    )) {
      if (!payload || pointer < Number(minute)) continue;
      pointer = Number(minute);
      detailData.winInvestmentSnapshotStatistics = payload;
    }
  } catch (e) {
    console.error(e);
  }
};

export const quartetStatisticsHandling = (
  additionalData,
  quartetStatistics
) => {
  if (!quartetStatistics) {
    return;
  }
  try {
    additionalData.quartetStatistics = quartetStatistics;
  } catch (e) {
    console.error(e);
  }
};

export const tiereceStatisticsHandling = (additionalData, tierceStatistics) => {
  if (!tierceStatistics) {
    return;
  }
  try {
    additionalData.tierceStatistics = tierceStatistics;
  } catch (e) {
    console.error(e);
  }
};

export const top3WinPercentStatisticsHandling = (
  additionalData,
  top3WinPercentStatistics
) => {
  if (!top3WinPercentStatistics) {
    return;
  }
  try {
    additionalData.top3WinPercentStatistics = top3WinPercentStatistics;
  } catch (e) {
    console.error(e);
  }
};
