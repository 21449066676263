import React from 'react';

import './BG.css';

const BG = () => {
  return (
    <div>
      <div className='BG-Overlay' ></div>
      <div className='BG'></div>
    </div>
  )
}

export default BG;