import { getWeightedCIBackgroundColor } from './CrossColumn';

export const DoubleWpCIColumn = () => {
  // column that shows all the WINS ODDS of the NEXT RACE
  return {
    title: "",
    align: 'center',
    children: [
      {
        title: "CI/CI",
        align: 'center',
        key: "ciOverCI",
        width: '2.5%',
        render: (_) => {
          if (_?.doubleStatisticsRow != null) {
            return <></>;
          }
          return (
            <div style={{ backgroundColor: getWeightedCIBackgroundColor(_?.weightedDoubleCI?.doubleWinPlaceCi), height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {_?.weightedDoubleCI?.doubleWinPlaceCi?.toFixed(2) || "-"}
            </div>
          )
        }
      }
    ],
  };
}