import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { triggerGameMapSimulation } from "../../../../services/actions/raceAction/standard";

const mapping = (value) => {
  if (value == null || value < 0) {
    return "";
  }
  if (value <= 3.5) {
    return "favored";
  } else if (value <= 8) {
    return "superior";
  } else if (value <= 17) {
    return "inferior";
  } else if (value > 17) {
    return "unwanted";
  }
}

const GameMapColumn = (sortedInfo, additionalData, headerData) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [simulateWPR, setSimulateWPR] = useState(headerData?.wPRatio?.latest || 0);
  const [isSimulating, setIsSimulating] = useState(false);

  const onChange = (e) => {
    const v = Number(e.target.value);
    if (isNaN(v) || v < 0 || v > 3) {
      return;
    }
    setSimulateWPR(v);
    if (additionalData.gameMap.latest == null) {
      return;
    }
    setIsSimulating(true);
    dispatch(triggerGameMapSimulation(additionalData?.gameMap?.latest || {}, v));
  }

  useEffect(() => !isSimulating && setSimulateWPR(headerData?.wPRatio?.latest || 0),
    [headerData?.wPRatio?.latest, isSimulating]);

  useEffect(() => isSimulating && dispatch(triggerGameMapSimulation(additionalData?.gameMap?.latest || {}, simulateWPR)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [additionalData.gameMap?.latest]);

  return {
    title: <InputNumber controls={false} step={0.1} value={simulateWPR} onPressEnter={onChange} style={{ width: "100%", margin: "2px" }} />,
    align: 'center',
    key: `gameMap`,
    children: [
      {
        title: <Button type="primary" shape="circle" size="small" icon={<CloseCircleOutlined />} className="Button" onClick={() => setIsSimulating(false)} />,
        align: 'center',
        key: `gameMap`,
        children: [
          {
            title: <span style={{ fontSize: "12px" }}>{t("roadmap::gameMapHeader")}</span>,
            align: 'center',
            key: `gameMap`,
            width: 10,
            sorter: {
              compare: (a, b) => additionalData?.gameMap?.latest?.statistics?.[mapping(b?.winPlaceOdds?.latest)] - additionalData?.gameMap?.latest?.statistics?.[mapping(a?.winPlaceOdds?.latest)],
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `gameMap`)?.['order'],
            render: (_) => {
              const gameMap = additionalData?.gameMap;
              const key = mapping(_?.winPlaceOdds?.latest);
              const statistics = isSimulating ? gameMap?.simulation?.[key] : gameMap?.latest?.statistics?.[key];
              return (
                <div>
                  <div>
                    {key || "No Data"}
                  </div>
                  <div>
                    {Math.ceil(statistics * 100) || 0} %
                  </div>
                </div>
              )
            }
          }
        ]
      }
    ]
  }
}

export default GameMapColumn;