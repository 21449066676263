import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useKeycloak } from '@react-keycloak/web';
import { logout } from '../../services/actions/authAction'
import { Button, Layout, Menu, Switch } from 'antd';
import { DeploymentUnitOutlined, FileTextOutlined, FundProjectionScreenOutlined, GlobalOutlined, GroupOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import companyLogo from '../../assets/media/img/companylogo.png';
import { setTheme } from '../../services/reducers/generalSlice';
import { setToggle } from '../../services/reducers/drawerSlice';

import './Navigation.css';
const { SubMenu } = Menu;

export const NavigationBar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { keycloak, } = useKeycloak();
  const language = { "English": "en", "繁體中文": "cn" };
  const darkMode = useSelector(state => state.general.theme === 'dark');

  const handleLanguageChange = ({ key }) => {
    i18next
      .changeLanguage(language[key])
      .then((tFunction) => {
        tFunction('key');
      });
  }

  const handleChangeTheme = () => {
    if (darkMode) {
      dispatch(setTheme('light'));
    } else {
      dispatch(setTheme('dark'));
    }
  }

  const handleLogout = () => {
    dispatch(logout(keycloak));
    navigate("/");
  }

  const drawerToggle = () => {
    dispatch(setToggle(true))
  }

  const styles = {
    // button style
    button: {
      background: 'transparent',
      border: 'none',
      height: 58,
      color: 'white',
      fontSize: 20,
    },
  };

  // layout of the top nav bar
  return (
    <Layout.Header style={{ zIndex: 100, width: '100%' }} className="Navigation-Bar">
      <div className="Logo">
        <img className='Company-Logo' src={companyLogo} alt="" />
      </div>
      <Menu theme='light' mode="horizontal" selectedKeys={['1']}>
        <SubMenu icon={<GroupOutlined />} onTitleClick={() => { navigate('/entries') }} key="entries" title={t('entries').toUpperCase()} >
          <Menu.Item key="pastPerformance" onClick={() => { navigate('/past-performance') }}>
            {t('pastPerformance')}
          </Menu.Item>
        </SubMenu>
        <SubMenu icon={<FundProjectionScreenOutlined />} onTitleClick={() => { navigate('/') }} key="raceCardSubMenu" title={t('raceCard').toUpperCase()} >
          <Menu.Item key="standard" onClick={() => { navigate('/') }}>
            {t('standard')}
          </Menu.Item>
          <Menu.Item key="win" onClick={() => { navigate('/win') }}>
            {t('win')}
          </Menu.Item>
          <Menu.Item key="double" onClick={() => { navigate('/double') }}>
            {t('double')}
          </Menu.Item>
          <Menu.Item key="forecast" onClick={() => { navigate('/forecast') }}>
            {t('forecast')}
          </Menu.Item>
        </SubMenu>
        <SubMenu icon={<FileTextOutlined />} key="j/t info" title={t('jTInfo').toUpperCase()} >
          <Menu.Item key="jockey info" onClick={() => { navigate('/jt-info?type=jockey') }}>
            {t('jockeyInfo')}
          </Menu.Item>
          <Menu.Item key="trainer info" onClick={() => { navigate('/jt-info?type=trainer') }}>
            {t('trainerInfo')}
          </Menu.Item>
        </SubMenu>
        <Menu.Item icon={<DeploymentUnitOutlined />} key="collaboration" onClick={() => { navigate('/') }}>
          {t('collaboration')}
        </Menu.Item>
        <SubMenu icon={<UserOutlined />} key="accountSubMenu" title={t("account").toUpperCase()}>
          <Menu.Item key="accManagement" onClick={() => { navigate('/accountInfo') }}>
            {t('accountInfo')}
          </Menu.Item>
          <Menu.Item key="logout" onClick={handleLogout}>
            {t('logout')}
          </Menu.Item>
        </SubMenu>
        <SubMenu icon={<GlobalOutlined />} key="languageSubMenu" title={t("language").toUpperCase()}>
          {
            Object.keys(language).map(o =>
              <Menu.Item key={o} onClick={handleLanguageChange}>{o}</Menu.Item>
            )
          }
        </SubMenu>
        <Menu.Item key="toggle" onClick={handleChangeTheme} >
          <Switch className='dark-mode-toggle' onClick={handleChangeTheme} checked={darkMode} /> {t("darkMode")}
        </Menu.Item>
        <Menu.Item 
          key="refresh" 
          onClick={() => {
            caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
            window.location.reload(true);
          }} 
        >REFRESH</Menu.Item>
      </Menu>

      <Menu mode='horizontal' style={{ position: 'absolute', top: 0, right: 0 }} selectable={false}>
        <Menu.Item key="button" >
          <Button style={styles.button} onClick={drawerToggle}>
            <MenuOutlined />
          </Button>
        </Menu.Item>
      </Menu>

    </Layout.Header>
  );
}
