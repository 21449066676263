import { Row, Col, Table } from "antd"
import { useSelector } from "react-redux"

const StatisticsList = ({ data, cellStyle }) => {
  const columns = [
    {
      key: 'statistic',
      align: "center",
      onCell: () => {
        return { style: cellStyle }
      },
      render: (_, _2, index) => {
        return (
          <div style={{ fontSize: "12px", backgroundColor: index === 5 ? "rgba(255, 223, 0, 0.75)" : "inherit" }}>{_.statistic || 0}</div>
        )
      }
    }
  ]
  const dataSource = [data?.w, data?.q, data?.p, data?.fourth, data?.total, Math.ceil(data?.gain || 0)];
  return (
    <Table
      showHeader={false}
      columns={columns}
      className="Statistics-Table"
      dataSource={dataSource.map((_, index) => ({ key: index, statistic: _ }))}
      pagination={false}
      rowKey={(record)=>record.key}
    />
  )
}

export const StatisticColumn = (showJockey, statisticsData) => {
  const races = useSelector(state => state.entries.detailData.race);
  const cache = {};

  const getLatestStatistics = (code) => {
    if (code in cache) {
      return cache[code];
    }
    const data = { w: 0, q: 0, p: 0, fourth: 0, total: 0, gain: 0 }
    for (const race of Object.values(races)) {
      const horses = race?.horses?.filter(data => data[showJockey ? "jockeyCode" : "trainerCode"] === code);
      for (const horse of horses) {
        if (horse.placing == null) {
          continue;
        }
        switch (horse.placing) {
          case 1: data.w += 1; break;
          case 2: data.q += 1; break;
          case 3: data.p += 1; break;
          case 4: data.fourth += 1; break;
          default: break;
        }
        data.total += 1;
        data.gain += (horse.gain || 0);
      }
    }
    cache[code] = data;
    return data;
  }

  return {
    title: "St",
    align: "center",
    key: 'wins',
    fixed: 'left',
    width: 30,
    render: (_, _2, index) => {
      return (
        <div style={{ padding: "2px" }}>
          <Row>
            <Col span={24}>
              <StatisticsList data={index === 0 ? getLatestStatistics(_.code) : statisticsData?.[_.code]?.[index] } />
            </Col>
          </Row>
        </div>
      )
    },
  }
}