import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Col, Popover, Row } from 'antd';
import { StatisticsHoverTable } from '../subTables/StatisticsHoverTable';
import GainTable from '../subTables/GainTable';
import RankHistoryTable from '../subTables/RankHistoryTable';

export const JockeyColumn = (sortedInfo) => {
  const { t } = useTranslation();
  const hideJockey = useSelector(state => state.drawer.standardCardDrawer.hideColumn.hideJockey) && 'Hidden-Column';
  const highlightJockey = useSelector(state => state.drawer.standardCardDrawer.highlightColumn.highlightJockey) && 'Highlighted-Column';

  // column of horses' jockeys and their gains
  return {
    title: t("race::jockeyHeader"),
    className: [hideJockey, highlightJockey],
    align: 'center',
    children: [
      {
        title: t("race::nameHeader"),
        className: [hideJockey, highlightJockey],
        align: 'center',
        children: [
          {
            title: t("race::winPlaceGainHeader"),
            className: [hideJockey, highlightJockey],
            align: 'center',
            key: 'jockey',
            width: 100,
            sorter: {
              compare: (a, b) => a.jockeyGain - b.jockeyGain,
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === 'jockey')?.['order'],
            render: (_) => {
              const win = _.winPlaceOdds?.latest;
              let group = "tLT";
              if (win <= 3.5) {
                group = "fav";
              } else if (win <= 8) {
                group = "sup";
              } else if (win <= 17) {
                group = "inf";
              } else if (win > 17) {
                group = "unw";
              }
              return (
                <Popover content={StatisticsHoverTable(t, _.jockeySingleOdds, t("race::jockeyHoverHeader"), group)} overlayClassName="Popover" destroyTooltipOnHide={true}>
                  <GainTable dataSource={_.jockeyGain} highlighted={highlightJockey} />
                  <Row>
                    <Col span={5}>
                      <span style={{ textAlign: 'center', fontSize: "14px", textDecorationLine: "underline" }}>{_.jockeyGameMapPercentage > 99 ? 99 : _.jockeyGameMapPercentage}</span>
                    </Col>
                    <Col span={14}>
                      <span style={{ textAlign: 'center', fontSize: "17px", fontWeight: "bold" }}>{_.jockeyName}</span>
                    </Col>
                  </Row>
                  <RankHistoryTable dataSource={_.jockeyRankHistory} highlighted={highlightJockey} />
                </Popover>
              )
            }
          }
        ]
      },
    ]
  };
}