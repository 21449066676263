import { setEntriesDataWS } from '../../reducers/entriesSlice'
import store from "../../reducers"

export const handleEntriesEvent = (payload, raceDate) => async (dispatch) => {
  try {
    if (!raceDate) {
      return
    }
    await dispatch(setEntriesDataWS(payload));
  } catch (error) {
    console.error(error.message);
  }
}

export const handleShowJTEntries = (sendMessage, code, isJockey) => {
  const entries = store.getState().entries;
  const dateList = entries.additionalData.pastRaceDate.filter(d => !Object.keys(entries.pastData).includes(d));
  dateList.length > 0 && sendMessage({ "race_date_list": dateList });
}