import { Table } from "antd"
import { useSelector } from "react-redux";

import "./MorningExceriseMatrix.css";

const MorningExceriseMatrix = ({ horseCode, startWeek }) => {
  const weekData = useSelector(state => state.pastPerformance.morningExercise?.[horseCode]?.twelveWeeks);

  const columns = [
    {
      align: 'center',
      key: 'type',
      width: '10%',
      render: (_, _2, index) => {
        switch (index) {
          case 0: return <div>游</div>
          case 1: return <div>踱</div>
          case 2: return <div>閘</div>
          default: return <></>
        }
      }
    }
  ]

  for (let i = 0; i < 6; i++) {
    const key = String(startWeek + i);
    const scores = weekData?.[key]?.scores
    const workouts = weekData?.[key]?.workouts;
    columns.push({
      align: 'center',
      key: key,
      width: '10%',
      render: (_, _2, index) => {
        switch (index) {
          case 0: return <div>{scores?.swimming || 0}</div>;
          case 1: return <div>{scores?.trotting || 0}</div>;
          case 2: 
            const highlight = workouts?.filter(data => data?.type === "試閘" && data?.workout?.section_durations[data?.workout?.section_durations?.length - 1] <= 23.0)?.length;
            return <div className={ highlight ? "Morning-Exercise-Matrix-Highlight" : "" }>{(scores?.["barrier_trial"] + scores?.gallop + scores?.race) || 0}</div>;
          default: return <></>;
        };
      }
    });
  }

  return (
    <Table
      pagination={false}
      loading={false}
      dataSource={[{key: 0}, {key: 1}, {key: 2}]}
      columns={columns}
      showHeader={false}
      className="Morning-Excerise-Table"
      rowKey={(record)=>record.key}
    />
  )
}

export default MorningExceriseMatrix;
