import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const LandscapeDistribution = ({ store }) => {
  const { t } = useTranslation();
  // RaceDetails contains information needed for RaceInfowin
  // e.g. Win pool amount, Venue, Track, Class, Distance, ...
  const LandscapeDistribution = useSelector(
    (state) => state?.standard?.landscapeDistribution
  );

  const get_insight_symbol = (insight) => {
    switch (insight) {
      case "less":
        return "<";
      case "more":
        return ">";
      case "similar":
        return "~";
      default:
        return "";
    }
  };

  return (
    <>
      <div
        style={{
          textAlign: "right",
          fontSize: "16px",
          maxWidth: "260px",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid #b9b9b9",
                  fontSize: "10px",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                TT
              </th>
              <th
                style={{
                  border: "1px solid #b9b9b9",
                  fontSize: "10px",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                TN_NT
              </th>
              <th
                style={{
                  border: "1px solid #b9b9b9",
                  fontSize: "10px",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                NN
              </th>
              <th
                style={{
                  border: "1px solid #b9b9b9",
                  fontSize: "10px",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                O
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {get_insight_symbol(LandscapeDistribution.TT.insight)}{" "}
                {LandscapeDistribution.TT.expected}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {get_insight_symbol(LandscapeDistribution.TN_NT.insight)}{" "}
                {LandscapeDistribution.TN_NT.expected}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {get_insight_symbol(LandscapeDistribution.NN.insight)}{" "}
                {LandscapeDistribution.NN.expected}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {get_insight_symbol(LandscapeDistribution.O.insight)}{" "}
                {LandscapeDistribution.O.expected}
              </td>
            </tr>
            <tr>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {LandscapeDistribution.TT.actual}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {LandscapeDistribution.TN_NT.actual}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {LandscapeDistribution.NN.actual}
              </td>
              <td
                style={{
                  border: "1px solid #b9b9b9",
                  textAlign: "center",
                  padding: "0 10px",
                }}
              >
                {LandscapeDistribution.O.actual}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
