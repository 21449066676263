import { Table, Popover } from "antd";
import { useSelector } from 'react-redux';

const MatrixHoverTable = ({ jockey, trainer, title }) => {
  const hoverColumn = [
    {
      title: "I",
      align: 'center',
      key: 'index',
      width: '15.2%',
      onCell: (_, index) => {
        return index === 0 ? { rowSpan: 2 } : { rowSpan: 0 }
      },
      render: (_) => (
        <div>
          <div> {_.index?.[0] != null ? _.index[0] : '-'} </div>
          <div> {_.index?.[1] != null ? _.index[1] : '-'} </div>
          <div> {_.index?.[2] != null ? _.index[2] : '-'} </div>
        </div>
      )
    },
    {
      title: "W",
      align: 'center',
      key: '1',
      width: '15.2%',
      render: (_) => (
        <span>
          {_.first != null ? _.first : -1}
        </span>
      )
    },
    {
      title: "Q",
      align: 'center',
      key: '2',
      width: '15.2%',
      render: (_) => (
        <span>
          {_.second != null ? _.second : -1}
        </span>
      )
    },
    {
      title: "P",
      align: 'center',
      key: '3',
      width: '15.2%',
      render: (_) => (
        <span>
          {_.third != null ? _.third : -1}
        </span>
      )
    },
    {
      title: "4",
      align: 'center',
      key: '4',
      width: '15.2%',
      render: (_) => (
        <span>
          {_.fourth != null ? _.fourth : -1}
        </span>
      )
    },
    {
      title: "T",
      align: 'center',
      key: '5',
      width: '24.0%',
      render: (_) => (
        <span>
          {_.total != null ? _.total : -1}
        </span>
      )
    }
  ];

  return (
    <div style={{ padding: "2px" }}>
      <span>{title}</span>
      <Table
        pagination={false}
        dataSource={[jockey, trainer]}
        columns={hoverColumn}
        style={{ width: "100%", height: "100px" }}
        className="Matrix-Table"
        rowKey={(record, index) => index}
      />
    </div>
  );
}

const TableColumn = (dataSource) => {
  const highlightWin = useSelector(state => state.drawer.standardCardDrawer.highlightColumn.highlightWin) && 'Highlighted-Column';
  const hideWin = useSelector(state => state.drawer.standardCardDrawer.hideColumn.hideWin) && 'Hidden-Column';

  return [
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: 'index',
      width: '8.5%',
      onCell: (_, index) => {
        return index === 0 ? { rowSpan: 2 } : { rowSpan: 0 }
      },
      render: (_) => (
        <div>
          <Popover content={() => <MatrixHoverTable jockey={dataSource?.matrixDataJockey?.ten} trainer={dataSource?.matrixDataTrainer?.ten} title="Matrix at 10 minute" />} overlayClassName="Popover" destroyTooltipOnHide={true} style={{ width: "100%", height: "100%" }}>
            <div> {_?.ten?.index?.[0] != null ? _.ten.index[0] : '-'} </div>
            <div> {_?.ten?.index?.[1] != null ? _.ten.index[1] : '-'} </div>
            <div> {_?.ten?.index?.[2] != null ? _.ten.index[2] : '-'} </div>
          </Popover>
        </div>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: 'index',
      width: '8.5%',
      onCell: (_, index) => {
        return index === 0 ? { rowSpan: 2 } : { rowSpan: 0 }
      },
      render: (_) => (
        <div>
          <Popover content={() => <MatrixHoverTable jockey={dataSource?.matrixDataJockey?.five} trainer={dataSource?.matrixDataTrainer?.five} title="Matrix at 5 minute" />} overlayClassName="Popover" destroyTooltipOnHide={true} style={{ width: "100%", height: "100%" }}>
            <div> {_?.five?.index?.[0] != null ? _.five.index[0] : '-'} </div>
            <div> {_?.five?.index?.[1] != null ? _.five.index[1] : '-'} </div>
            <div> {_?.five?.index?.[2] != null ? _.five.index[2] : '-'} </div>
          </Popover>
        </div>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: 'index',
      width: '8.5%',
      onCell: (_, index) => {
        return index === 0 ? { rowSpan: 2 } : { rowSpan: 0 }
      },
      render: (_) => (
        <div>
          <div> {_?.latest?.index?.[0] != null ? _.latest.index[0] : '-'} </div>
          <div> {_?.latest?.index?.[1] != null ? _.latest.index[1] : '-'} </div>
          <div> {_?.latest?.index?.[2] != null ? _.latest.index[2] : '-'} </div>
        </div>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: '1',
      width: '14.0%',
      render: (_) => (
        <span>
          {_?.latest?.first != null ? _.latest.first : -1}
        </span>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: '2',
      width: '14.0%',
      render: (_) => (
        <span>
          {_?.latest?.second != null ? _.latest.second : -1}
        </span>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: '3',
      width: '14.0%',
      render: (_) => (
        <span>
          {_?.latest?.third != null ? _.latest.third : -1}
        </span>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: '4',
      width: '14.0%',
      render: (_) => (
        <span>
          {_?.latest?.fourth != null ? _.latest.fourth : -1}
        </span>
      )
    },
    {
      align: 'center',
      className: [highlightWin, hideWin],

      key: '5',
      width: '18.5%',
      render: (_) => (
        <span>
          {_?.latest?.total != null ? _.latest.total : -1}
        </span>
      )
    }
  ];
}

export const MatrixTable = ({ dataSource }) => {
  return (
    <div style={{ padding: "2px" }}>
      <Table
        pagination={false}
        dataSource={[dataSource?.matrixDataJockey, dataSource.matrixDataTrainer]}
        columns={TableColumn(dataSource)}
        style={{ width: "100%", height: "80px" }}
        className="Matrix-Table"
        showHeader={false}
        rowKey={(record, index) => index}
      />
    </div>
  );
}