import React from 'react';
import { useSelector } from 'react-redux';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, ReferenceLine } from 'recharts';
import { getAxisStroke, getGridStroke } from '../../../../services/utils/chartHelper';

// color mapping of each number digit
const colorMapping = {
  1: "rgb(255, 0, 255)",
  2: "rgb(0, 0, 255)",
  3: "rgb(0, 255, 0)",
  4: "rgb(255,165,0)",
}

const getIndexFromCI = (ci) => {
  if (ci >= 1.6) {
    return 1;
  } else if (ci >= 1.2) {
    return 2;
  } else if (ci >= 0.8) {
    return 3;
  } else if (ci >= 0) {
    return 4;
  }
}

const CustomDot = (cx, cy, value) => {
  // form a donut circle component with outer color as ten digit, centre color as unit digit
  return (
    <circle cx={cx} cy={cy} r={6} fill={colorMapping[getIndexFromCI(value)]} />
  )
}

const CustomLegend = () => {
  // mapping on each color with its represented number digit value
  return (
    <div>
      {
        Object.entries(colorMapping).map(([key, colorCode]) => {
          return <span style={{ color: colorCode, fontSize: "12px", padding: "2px" }} key={key}>&#9679;{key}</span>
        })
      }
    </div>
  )
}

const ticksFormatter = (value, hottestList) => {
  return hottestList?.[value - 1] || value;
}

const DoubleSignalGraph = () => {
  // the forecast/quinella scatter chart component
  const theme = useSelector(state => state.general.theme);
  const additionalData = useSelector(state => state.standard.additionalData);
  const { doubleSignal, weightedDoubleCI } = useSelector(state => state.standard.signalData);

  const { numOfHorseCurrent, hottestList, secondHottestList, gameMap } = additionalData;

  const grouping = gameMap?.latest?.grouping;
  let supIndex = grouping?.fav?.length + 0.5 || 0;
  let infIndex = grouping?.sup?.length + supIndex || 0;
  let unwIndex = grouping?.inf?.length + infIndex || 0;

  if (supIndex <= 0.5) {
    supIndex = -100;
  }

  const countFormater = (value) => {
    return weightedDoubleCI?.[ticksFormatter(value, hottestList)]?.averageDoubleCi?.toFixed(1) || "";
  }

  return numOfHorseCurrent ? (
    <ResponsiveContainer width={"99%"} minHeight={300} maxHeight={400} aspect={1} >
      <ScatterChart
        margin={{ top: -5, right: 8, left: 2, bottom: -15 }}
        style={{ pointerEvents: "visible" }}
      >
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          type="number"
          dataKey="firstHorseNumIndex"
          name="first horse num"
          domain={[1, numOfHorseCurrent]}
          interval={0}
          tickCount={numOfHorseCurrent}
          orientation="top"
          style={{ fontSize: "12px" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => ticksFormatter(value, hottestList)}
        />
        <YAxis
          type="number"
          dataKey="sceondHorseNumIndex"
          name="second horse num"
          width={22}
          domain={[1, secondHottestList?.length || 1]}
          interval={0}
          tickCount={secondHottestList?.length || 0}
          reversed={true}
          style={{ fontSize: "12px" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => ticksFormatter(value, secondHottestList)}
        />

        <Legend wrapperStyle={{ fontSize: "10px", bottom: -5, left: 8 }} content={CustomLegend} />

        <ReferenceLine stroke="green" isFront={true} segment={[{ x: supIndex, y: -1 }, { x: supIndex, y: secondHottestList?.length || 0 }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: infIndex, y: -1 }, { x: infIndex, y: secondHottestList?.length || 0 }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: unwIndex, y: -1 }, { x: unwIndex, y: secondHottestList?.length || 0 }]} alwaysShow={true} strokeWidth={2} />

        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: supIndex }, { x: numOfHorseCurrent, y: supIndex }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: infIndex }, { x: numOfHorseCurrent, y: infIndex }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: unwIndex }, { x: numOfHorseCurrent, y: unwIndex }]} alwaysShow={true} strokeWidth={2} />

        <XAxis
          xAxisId={1}
          type="number"
          dataKey="firstHorseNumIndex"
          name="first horse num"
          domain={[1, numOfHorseCurrent]}
          interval={0}
          tickCount={numOfHorseCurrent}
          orientation="bottom"
          style={{ fontSize: "10px", textDecoration: "underline" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => countFormater(value)}
        />
        <Scatter name="index" data={doubleSignal} isAnimationActive={false} shape={({ cx, cy, index }) => CustomDot(cx, cy, index)} />
      </ScatterChart>
    </ResponsiveContainer>
  ) : null;
};

export default DoubleSignalGraph;