import { useDispatch, useSelector } from "react-redux";
import { setHighlightedCode, resetHighlightedCode } from "../../services/reducers/pastPerformanceSlice";

const NameHighlight = ({ value, code, horseCode, needHorseCodeMatch=true }) => {
  const dispatch = useDispatch();
  const { highlightedCode } = useSelector(state => state.pastPerformance.additionalData);

  const onMouseEnter = () => {
    if (code === null) {
      return;
    }
    if (highlightedCode !== code) {
      dispatch(setHighlightedCode({ code: code, horseCode: horseCode, onClick: false }));
    }
  }

  const onMouseClick = () => {
    if (code === null) {
      return;
    }
    if (!highlightedCode.onClick) {
      dispatch(setHighlightedCode({ code: code, horseCode: horseCode, onClick: true }));
    } else {
      if (highlightedCode.value !== code) {
        dispatch(setHighlightedCode({ code: code, horseCode: horseCode, onClick: true }));
      } else {
        dispatch(resetHighlightedCode(true));
      }
    }
  }

  const onMouseLeave = () => {
    if (highlightedCode.value === code) {
      dispatch(resetHighlightedCode(false));
    }
  }

  const getClassName = () => {
    if (needHorseCodeMatch) {
      return code && highlightedCode.value === code && highlightedCode.horseCode === horseCode ? "Highlighted-Name" : "";
    }
    return code && highlightedCode.value === code ? "Highlighted-Name" : "";
  }

  return (
    <span
      className={getClassName()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onMouseClick}
    >
      {value}
    </span>
  )
}

export default NameHighlight;
