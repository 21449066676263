import { Table } from "antd";
import { useSelector } from "react-redux"

const columns = () => {
  return [
    {
      title: "Level",
      align: 'center',
      key: 'level',
      width: "30%",
      render: (_) => (
        <div>
          {_.level}
        </div>
      )
    },
    {
      title: "No.",
      align: 'center',
      key: 'level',
      width: "70%",
      render: (_) => (
        <div>
          {_.data?.join(",")}
        </div>
      )
    },
  ];
}

const RecommandationTable = ({dataKey, levelList}) => {
  const selectedTimeline = useSelector(state => state.standard.decisionTree.timelineSelected) || null;
  const dataSource = useSelector(state => state.standard.decisionTree.data?.[selectedTimeline]?.recommendations?.[dataKey]) || {};

  return (
    <div>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={[
          {level: levelList[0], data: dataSource?.level_1},
          {level: levelList[1], data: dataSource?.level_2},
          {level: levelList[2], data: dataSource?.level_3},
          {level: levelList[3], data: dataSource?.level_4},
        ]}
        columns={columns()}
        className="DecisionTree-Recommendation-Table"
        rowKey={(record, index) => index}
      />
    </div>
  )
}

export default RecommandationTable