import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { pastPerformanceDataProcessing } from "./handling";

const pastPerformanceSlice = createSlice({
  name: "pastPerformance",
  initialState: initialData(),
  reducers: {
    setPerformanceData: (state, data) => {
      // pass api to data handler
      pastPerformanceDataProcessing(state, data);
    },
    removePerformanceData: (state) => {
      // manually remove the all data and reset them if necessary
      state.horseData = {};
      state.pastPerformance = {};
      state.morningExercise = {};
      state.additionalData = {
        highlightedCode: {
          value: null,
          horseCode: null,
          onClick: false
        },
        highlightedRaceId: {
          value: null,
          onClick: false
        },
      };
      state.info = {
        winPoolAmount: -1, updatets: '-1', raceTime: null, raceStartTime: '', venue: 'N/A', track: 'N/A', class: 'N/A', course: 'N/A', distance: 'N/A'
      }
      state.winOdds = {};
    },
    setHighlightedCode: (state, data) => {
      const highlightedCode = state.additionalData.highlightedCode
      if (highlightedCode.onClick && !data.payload.onClick) {
        return;
      }
      highlightedCode.value = data.payload.code;
      highlightedCode.horseCode = data.payload.horseCode;
      highlightedCode.onClick = data.payload.onClick;
    },
    resetHighlightedCode: (state, data) => {
      if (data.payload || !state.additionalData.highlightedCode.onClick) {
        state.additionalData.highlightedCode = {
          value: null,
          horseCode: null,
          onClick: false
        };
      }
    },
    setHighlightedRaceId: (state, data) => {
      const highlightedRaceId = state.additionalData.highlightedRaceId;
      if (highlightedRaceId.onClick && !data.payload.onClick) {
        return;
      }
      highlightedRaceId.value = data.payload.id;
      highlightedRaceId.onClick = data.payload.onClick;
    },
    resetHighlightedRaceId: (state, data) => {
      if (data.payload || !state.additionalData.highlightedRaceId.onClick) {
        state.additionalData.highlightedRaceId = {
          value: null,
          horseCode: null,
          onClick: false
        };
      }
    }
  }
});

export const {
  setPerformanceData,
  removePerformanceData,
  setHighlightedCode,
  resetHighlightedCode,
  setHighlightedRaceId,
  resetHighlightedRaceId
} = pastPerformanceSlice.actions;

export default pastPerformanceSlice.reducer;