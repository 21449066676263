import React from 'react';
import { Table } from 'antd';

const DataEntry = ({ data, dataKey }) => {
  let ratio = null;
  if (data.expectedWinOdds?.[dataKey] && data.winPlaceOdds?.[dataKey]) {
    ratio = (data.expectedWinOdds?.[dataKey] / data.winPlaceOdds?.[dataKey]).toFixed(2);
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px' }}>
        <div style={{ textAlign: 'left', fontWeight: "bold" }} >{data.winPlaceOdds?.[dataKey] || "-"}</div>
        <div style={{ textAlign: 'right', fontWeight: "bold" }}>{data.expectedWinOdds?.[dataKey]?.toFixed(1) || "-"}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '4px', fontSize: "13px" }}>
        <div style={{ textAlign: 'left' }}>{data.confidentIndex?.[dataKey] || "-"}</div>
        <div style={{ textAlign: 'right' }}>{ratio || "-"}</div>
      </div>
    </>
  )
}

const TableColumn = (t, header) => {
  return {
    title: t("race::winHeader"),
    align: 'center',
    children: [
      {
        title: header.wPMinInterval[30],
        align: 'center',
        children: [
          {
            title: header.wPRatio.thirty || "-",
            align: 'center',
            key: 'winOddsThirty',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="thirty" />;
            }
          },
        ],
      },
      {
        title: header.wPMinInterval[10],
        align: 'center',
        children: [
          {
            title: header.wPRatio.ten || "-",
            align: 'center',
            key: 'winOddsTen',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="ten" />;
            }
          },
        ],
      },
      {
        title: header.wPMinInterval[6],
        align: 'center',
        children: [
          {
            title: header.wPRatio.six || "-",
            align: 'center',
            key: 'winOddsSix',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="six" />;
            }
          },
        ],
      },
      {
        title: header.wPMinInterval[3],
        align: 'center',
        children: [
          {
            title: header.wPRatio.three || "-",
            align: 'center',
            key: 'winOddsThree',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="three" />;
            }
          },
        ],
      },
      {
        title: header.wPMinInterval[0],
        align: 'center',
        children: [
          {
            title: header.wPRatio.zero || "-",
            align: 'center',
            key: 'winOddsThree',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="zero" />;
            }
          },
        ],
      },
      {
        title: 'latest',
        align: 'center',
        children: [
          {
            title: header.wPRatio.latest || "-",
            align: 'center',
            key: 'winOddsLast',
            width: '16.6%',
            render: (_) => {
              return <DataEntry data={_} dataKey="latest" />;
            }
          }
        ],
      },
    ]
  }
}

export const WinHoverTable = (t, data, headerData) => {
  // original win odds, ci and pool ratio table in hover mode
  const columns = [
    TableColumn(t, headerData)
  ];

  return (
    <div style={{ width: "600px", height: "150px" }}>
      <Table
        pagination={false}
        dataSource={[data]}
        columns={columns}
        rowKey={(record, index) => index}
      />
    </div>
  );
}
