import React from "react";
import { useTranslation } from "react-i18next"
import { Input, Button } from "antd";
import { LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import BG from "../GeneralUI/BG";

import "./AccountManagement.css";

export const ChangePassword = () => {
  const { t } = useTranslation();
  const iconRender = (visible) => visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

  // layout of the change password page
  return (
    <div className="Management-Container" >
      <BG />
      <h1 className="Change-Password-Title">{t("changeToNewPassword")}</h1>
      <h2 className="Change-Password-Input-Hint">{t("oldPassword")}</h2>
      <Input.Password
        className="Input"
        placeholder={t("oldPassword")}
        prefix={<LockOutlined />}
        iconRender={iconRender}
      />
      <h2 className="Change-Password-Input-Hint">{t("newPassword")}</h2>
      <Input.Password
        className="Input"
        placeholder={t("newPassword")}
        prefix={<LockOutlined />}
        iconRender={iconRender}
      />
      <h2 className="Change-Password-Input-Hint">{t("confirmNewPassword")}</h2>
      <Input.Password
        className="Input"
        placeholder={t("confirmNewPassword")}
        prefix={<LockOutlined />}
        iconRender={iconRender}
      />
      <Button className="Login-Input Login-Button" type="primary">
        {t("save")}
      </Button>
    </div>
  );
};
