import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import raceReducer from "./raceSlice";
import standardSlice from "./standardSlice"
import generalSlice from "./generalSlice";
import doubleSlice from "./doubleSlice";
import authSlice from "./authSlice";
import forecastSlice from "./forecastSlice";
import winSlice from "./winSlice";
import drawerSlice from "./drawerSlice";
import entriesSlice from "./entriesSlice";
import pastPerformanceSlice from "./pastPerformanceSlice";
import jtInfoSlice from "./jtInfoSlice";
import chatSlice from "./chatSlice";

const reducer = combineReducers({
  general: generalSlice,
  race: raceReducer,
  standard: standardSlice,
  double: doubleSlice,
  forecast: forecastSlice,
  auth: authSlice,
  win: winSlice,
  drawer: drawerSlice,
  entries: entriesSlice,
  pastPerformance: pastPerformanceSlice,
  jtInfo: jtInfoSlice,
  chat: chatSlice,
});

export default configureStore({
  reducer: reducer,
});