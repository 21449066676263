import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setSortedInfo } from "../../../services/reducers/standardSlice";
import AIPredictionColumn from "./columns/AIPredictionColumn";
import DoubleStatisticsColumn from "./columns/DoubleStatisticsColumn";
import FlushColumn from "./columns/FlushColumn";
import ForecastQuinellaColumn from "./columns/ForecastQuinellaColumn";
import GameMapColumn from "./columns/GameMapColumn";
import HorseNameColumnOverride from "./columns/HorseNameColumnOverride";
import HorseNumColumnOverride from "./columns/HorseNumColumnOverride";
import JockeyColumnOverride from "./columns/JockeyColumnOverride";
import JockeyTrainerColumnOverride from "./columns/JockeyTrainerColumnOverride";
import NoteColumn from "./columns/NoteColumn";
import OverBoughtColumn from "./columns/OverBoughtColumn";
import QWStatisticsColumn from "./columns/QWStatisticsColumn";
import ScoreColumn from "./columns/ScoreColumn";
import TotalScoreColumn from "./columns/TotalScoreColumn";
import TrainerColumnOverride from "./columns/TrainerColumnOverride";
import WinInvestmentStatisticsColumn from "./columns/WinInvestmentStatisticsColumn";
import WinMatrixColumnOverride from "./columns/WinMatrixColumnOverride";
import WinOddsColumnOverride from "./columns/WinOddsColumnOverride";
import SubTotalScoreColumn from "./columns/SubTotalScoreColumn";
import WinMatrixJTColumn from "./columns/WinMatrixJTColumn";
import OverBoughtTop4Column from "./columns/OverBoughtTop4Column";
import SpeedProColumn from "./columns/SpeedProColumn";
import ColdDoorStatisticsColumn from "./columns/ColdDoorStatisticsColumn";
import WinInvestmentSnapshotStatisticsColumn from "./columns/WinInvestmentSnapshotStatisticsColumn";
import NewColdDoorColumn from "./columns/NewColdDoorColumn";

export const SCORE_ORDER_MAPPING = [
  "commentaryScore",
  "speedProScore",
  "fitnessRatingScore",
  "qwScore",
  "jockeyMatrixScore",
  "trainerMatrixScore",
  "jtMatrixScore",
  "jTScore",
  "doubleCIScore",
  "gameMapScore",
  "fqScore",
  "overBoughtScore",
  "winInvestmentScore",
];

const RoadMapTable = ({ sendMessage }) => {
  const dispatch = useDispatch();

  const selectedRaceDate = useSelector((state) => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector((state) => state.race.selectedRaceNum);

  const userName = useSelector((state) => state.auth.userData?.given_name);

  // dataSource: basically all info like jockey/trainer name, all odds/CIs, velocity ...
  const dataSource = useSelector((state) =>
    Object.values(state.standard.detailData)
  );

  // headerData: ratios and intervals
  const {
    headerData,
    additionalData,
    sortedInfo,
    recommendation,
    userData,
    signalData,
  } = useSelector((state) => state.standard);

  const scores = useSelector((state) => state.standard.userData.scores);

  const sendMessageWhenChange = (command, payload) => {
    if (!sendMessage) {
      return;
    }
    sendMessage({
      command: command,
      payload: {
        race_date: selectedRaceDate,
        race_num: selectedRaceNum,
        user_name: userName,
        ...payload,
      },
    });
  };

  const columns = [
    FlushColumn(sendMessageWhenChange),

    HorseNumColumnOverride(sortedInfo),
    NewColdDoorColumn(sortedInfo),
    ColdDoorStatisticsColumn(),
    ScoreColumn(
      <span style={{ fontSize: "16px" }}>C</span>,
      sortedInfo,
      SCORE_ORDER_MAPPING[0],
      SCORE_ORDER_MAPPING.slice(0, 1),
      scores,
      sendMessageWhenChange
    ),
    SpeedProColumn(sortedInfo),
    ScoreColumn(
      <span style={{ fontSize: "16px" }}>S</span>,
      sortedInfo,
      SCORE_ORDER_MAPPING[1],
      SCORE_ORDER_MAPPING.slice(0, 2),
      scores,
      sendMessageWhenChange
    ),

    HorseNameColumnOverride(sortedInfo, additionalData),
    ScoreColumn(
      <span style={{ fontSize: "12px" }}>Fitness</span>,
      sortedInfo,
      SCORE_ORDER_MAPPING[2],
      SCORE_ORDER_MAPPING.slice(0, 3),
      scores,
      sendMessageWhenChange
    ),

    SubTotalScoreColumn(
      "C + S + T",
      sortedInfo,
      SCORE_ORDER_MAPPING.slice(0, 3)
    ),

    WinOddsColumnOverride(sortedInfo, headerData),
    QWStatisticsColumn(sortedInfo),
    ScoreColumn(
      "QW",
      sortedInfo,
      SCORE_ORDER_MAPPING[3],
      SCORE_ORDER_MAPPING.slice(0, 4),
      scores,
      sendMessageWhenChange
    ),

    WinMatrixColumnOverride(),
    ScoreColumn(
      "Jockey",
      sortedInfo,
      SCORE_ORDER_MAPPING[4],
      SCORE_ORDER_MAPPING.slice(0, 5),
      scores,
      sendMessageWhenChange
    ),
    ScoreColumn(
      "Trainer",
      sortedInfo,
      SCORE_ORDER_MAPPING[5],
      SCORE_ORDER_MAPPING.slice(0, 6),
      scores,
      sendMessageWhenChange
    ),

    WinMatrixJTColumn(sortedInfo),
    ScoreColumn(
      <span style={{ fontSize: "12px" }}>MatrixJT</span>,
      sortedInfo,
      SCORE_ORDER_MAPPING[6],
      SCORE_ORDER_MAPPING.slice(0, 7),
      scores,
      sendMessageWhenChange
    ),

    JockeyColumnOverride(),
    TrainerColumnOverride(),
    JockeyTrainerColumnOverride(),
    ScoreColumn(
      "JT",
      sortedInfo,
      SCORE_ORDER_MAPPING[7],
      SCORE_ORDER_MAPPING.slice(0, 8),
      scores,
      sendMessageWhenChange
    ),

    DoubleStatisticsColumn(sortedInfo),
    ScoreColumn(
      "Double",
      sortedInfo,
      SCORE_ORDER_MAPPING[8],
      SCORE_ORDER_MAPPING.slice(0, 9),
      scores,
      sendMessageWhenChange
    ),

    GameMapColumn(sortedInfo, additionalData, headerData),
    ScoreColumn(
      "GM",
      sortedInfo,
      SCORE_ORDER_MAPPING[9],
      SCORE_ORDER_MAPPING.slice(0, 10),
      scores,
      sendMessageWhenChange
    ),

    SubTotalScoreColumn(
      "Jockey + Trainer + MatrixJT + JT + Double + GM",
      sortedInfo,
      SCORE_ORDER_MAPPING.slice(4, 10)
    ),

    ForecastQuinellaColumn(sortedInfo, signalData),
    ScoreColumn(
      "FQ",
      sortedInfo,
      SCORE_ORDER_MAPPING[10],
      SCORE_ORDER_MAPPING.slice(0, 11),
      scores,
      sendMessageWhenChange
    ),

    OverBoughtColumn(),
    OverBoughtTop4Column(),
    ScoreColumn(
      "Over.B",
      sortedInfo,
      SCORE_ORDER_MAPPING[11],
      SCORE_ORDER_MAPPING.slice(0, 12),
      scores,
      sendMessageWhenChange
    ),

    SubTotalScoreColumn(
      "FQ + OverBought",
      sortedInfo,
      SCORE_ORDER_MAPPING.slice(10, 12)
    ),

    WinInvestmentStatisticsColumn(),
    WinInvestmentSnapshotStatisticsColumn(),
    ScoreColumn(
      "W.I",
      sortedInfo,
      SCORE_ORDER_MAPPING[12],
      SCORE_ORDER_MAPPING.slice(0, 13),
      scores,
      sendMessageWhenChange
    ),

    TotalScoreColumn(sortedInfo, userData, true),
    TotalScoreColumn(sortedInfo, userData, false, 0),
    TotalScoreColumn(sortedInfo, userData, false, 1),
    AIPredictionColumn(sortedInfo, recommendation),
  ];

  const handleChange = (_1, _2, sorter) => {
    // if the sort info is not an array, turn it into array
    if (!Array.isArray(sorter)) {
      sorter = [sorter];
    }
    // only keep the first and last sorting info
    if (sorter.length > 2) {
      sorter.splice(1, 1);
    }
    // map into redux acceptable format
    const info = sorter.map((data) => {
      return { columnKey: data.columnKey, order: data.order };
    });
    dispatch(setSortedInfo(info));
  };

  return (
    <div style={{ padding: 10, width: "97vw" }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        onChange={handleChange}
        scroll={{ x: "max-content" }}
        rowKey={(record, index) => index}
      />
    </div>
  );
};

export default RoadMapTable;
