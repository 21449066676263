import HorseNameColumn from "../../common/HorseNameColumn";

const HorseNameColumnOverride = (sortedInfo, additionalData) => {
  const winPoolDistributionGameMap = additionalData.winPoolDistributionGameMap;

  const column = HorseNameColumn(sortedInfo, winPoolDistributionGameMap);
  column.title = "horse";
  column.width = 90;
  return column;
}

export default HorseNameColumnOverride;
