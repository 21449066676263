export const SectionColumn = () => {
  return {
    title: "Section",
    align: 'center',
    key: 'section',
    render: (_) => {
      return (
        <div>{_?.sectionPositions?.join(" ")}</div>
      )
    }
  }
}