import { useTranslation } from "react-i18next"

export const InvestmentColumn = () => {
  const { t } = useTranslation();

  return {
    title: t("entries::investmentHeader"),
    align: "center",
    key: 'invest',
    fixed: 'left',
    width: 10,
    render: (_) => {
      if (_.totalWinInvestment){
        const value = Math.ceil(_.totalWinInvestment * 100);
        return (
          <div>
            <div>{value}%</div>
          </div>
        )
      }
      return (
        <div>-</div>
      )
    },
  }
}