import { useDispatch, useSelector } from "react-redux";
import { Button, Col, List, Popover, Row, Slider } from "antd";
import { appendSelectedHorse } from "../../../../services/reducers/standardSlice";

import "./RaceGameMap.css";
import { useState } from "react";

const RaceGameMapEntry = ({ data, statistics }) => {
  const dispatch = useDispatch();
  const horses = useSelector(state => state.standard.detailData);

  // toggle horse buttons action
  const setSelectedHorse = (data) => {
    dispatch(appendSelectedHorse(data))
  };
  return (
    <div className="Race-Game-Map-Entries">
      <Row type="flex" style={{ alignItems: "center" }} justify="center">
        <Col span={4} style={{ textAlign: "center" }} className="Race-Game-Map-Col-Right" >
          <span>{statistics ? Math.ceil(statistics * 100) : 0}</span>
        </Col>
        <Col span={17} style={{ textAlign: "left" }}>
          {data.slice().sort((a, b) => a.winOdds - b.winOdds).map(({ horseNum }) => {
            if (horses[horseNum]?.selected) {
              return (
                <Button className="Square-Number" onClick={() => setSelectedHorse({ horseNum: horseNum, selected: false })} key={horseNum}>{horseNum}</Button>
              )
            }
            return (
              <Button className="Circle-Number" onClick={() => setSelectedHorse({ horseNum: horseNum, selected: true })} key={horseNum}>{horseNum}</Button>
            )
          })}
        </Col>
        <Col span={3} style={{ textAlign: "center" }} className="Race-Game-Map-Col-Left">
          {data.length}
        </Col>
      </Row>
    </div>
  )
}

const RaceGameMapHeader = ({ time, wpRatio }) => {
  return (
    <div>
      <Row>
        <Col span={12} className="Race-Game-Map-Col-Right" style={{ fontWeight: "bold", textAlign: "center" }}>
          {new Date(time).toLocaleTimeString()}
        </Col>
        <Col span={12} style={{ fontWeight: "bold", textAlign: "center" }}>
          {wpRatio?.toFixed(2)}
        </Col>
      </Row>
    </div>
  )
}

const RaceGameMapList = ({ grouping, statistics, time, wpRatio }) => {
  if (!grouping?.fav) {
    return <div></div>;
  }
  return (
    <div style={{ width: "inherit", height: "100%", marginTop: "-16px" }}>
      <List
        size="small"
        bordered
        dataSource={Object.values(grouping)}
        header={<RaceGameMapHeader time={time} wpRatio={wpRatio} />}
        renderItem={(item, index) =>
          <List.Item>
            <RaceGameMapEntry data={item} statistics={statistics ? Object.values(statistics)[index] : null} />
          </List.Item>
        }
        className="Race-Game-Map"
        style={{ margin: "4px" }}
      />
    </div>
  )
}

const ConfigurableRaceMap = ({ prediction, latest }) => {
  const [selectedPoolRatio, setPoolRatio] = useState(latest?.poolRatio);
  const marks = {};
  for (const predict of prediction) {
    marks[predict.poolRatio] = String(predict.poolRatio);
  }
  marks[Number(latest?.poolRatio?.toFixed(1))] = String(latest?.poolRatio?.toFixed(1));
  const max = Math.max(...prediction.map(o => o.poolRatio));
  const min = Math.min(...prediction.map(o => o.poolRatio));

  return (
    <div>
      <Row>
        <Col span={4}>
          <Slider
            vertical
            marks={marks}
            step={null}
            defaultValue={prediction[0].poolRatio}
            max={max}
            min={min}
            style={{ height: "100px", marginLeft: "-12px" }}
            className="Race-Game-Map-Slider"
            onChange={(value) => setPoolRatio(value)}
          />
        </Col>
        <Col span={20}>
          <RaceGameMapList
            grouping={latest?.grouping}
            statistics={prediction.find(predict => predict.poolRatio === selectedPoolRatio)?.statistics || latest?.statistics}
            time={latest?.updateTs}
            wpRatio={selectedPoolRatio}
          />
        </Col>
      </Row>
    </div>
  )
}


const RaceGameMap = ({ vertical = false, width = "95%" }) => {
  const { latest, last, secondLast, prediction, zero } = useSelector(state => state.standard.additionalData.gameMap);

  const showPreviousGameMap = () => {
    if (prediction) {
      return <ConfigurableRaceMap prediction={prediction} latest={zero} />;
    }
    if (secondLast?.grouping) {
      return (
        <Popover
          content={<RaceGameMapList grouping={secondLast.grouping} statistics={secondLast.statistics} time={secondLast?.updateTs} wpRatio={secondLast?.poolRatio} />}
          overlayClassName="Popover"
          destroyTooltipOnHide={true}
          overlayStyle={{ width: "270px", height: "100%" }}
          placement="leftTop"
        >
          <div style={{ width: "inherit", height: "100%" }}>
            <RaceGameMapList grouping={last?.grouping} statistics={last?.statistics} time={last?.updateTs} wpRatio={last?.poolRatio} />
          </div>
        </Popover>
      );
    }
    return <RaceGameMapList grouping={last?.grouping} statistics={last?.statistics} time={last?.updateTs} wpRatio={last?.poolRatio} />;
  }

  if (vertical) {
    return (
      <div style={{ width: width }}>
        <div>
          <RaceGameMapList grouping={latest?.grouping} statistics={latest?.statistics} time={latest?.updateTs} wpRatio={latest?.poolRatio} />
        </div>
      </div>
    )
  }

  return (
    <div style={{ width: width, margin: "4px 2px 0px 6px" }}>
      <Row>
        <Col span={24}>
          <RaceGameMapList grouping={latest?.grouping} statistics={latest?.statistics} time={latest?.updateTs} wpRatio={latest?.poolRatio} />
        </Col>
      </Row>
    </div>
  );
};

export default RaceGameMap;
