import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Popover } from "antd";
import WinChart from "../graphs/WinChart";
import { WinHoverTable } from "../subTables/WinHoverTable";
import WinMatrixColumn from "../../common/WinMatrixColumn";

export const WinColumn = (header, sortedInfo) => {
  const { t } = useTranslation();
  const highlightWin =
    useSelector(
      (state) => state.drawer.standardCardDrawer.highlightColumn.highlightWin
    ) && "Highlighted-Column";
  const hideWin =
    useSelector(
      (state) => state.drawer.standardCardDrawer.hideColumn.hideWin
    ) && "Hidden-Column";

  // column that shows the win odds
  return {
    title: t("race::winHeader"),
    className: [highlightWin, hideWin],
    align: "center",
    children: [
      WinMatrixColumn(100),
      {
        // first part of win line graph column
        title: <label style={{ color: "rgb(0, 0, 255)" }}>&#9679;Win</label>,
        className: [highlightWin, hideWin],
        align: "center",
        children: [
          {
            title: <label style={{ color: "rgb(255,120,0)" }}>&#9679;QW</label>,
            className: [highlightWin, hideWin],
            align: "center",
            key: "winOddsSix",
            width: 65,
            onCell: (_) => {
              return { colSpan: 2 };
            },
            render: (_) => (
              <Popover
                content={WinHoverTable(t, _, header)}
                overlayClassName="Popover"
                destroyTooltipOnHide={true}
                style={{ width: "100%", height: "100%" }}
              >
                <div style={{ width: "99%", height: "90px" }}>
                  <WinChart
                    data={_.winGraphData}
                    range={[-30, 0]}
                    showRange={[-20, 0]}
                  />
                </div>
              </Popover>
            ),
          },
        ],
      },
      {
        // second part of win line graph column
        title: <label style={{ color: "rgb(66, 255, 56)" }}>&#9679;CI</label>,
        className: [highlightWin, hideWin],
        align: "center",
        children: [
          {
            title: (
              <label style={{ color: "rgb(255, 0, 255)" }}>&#9679;eWin</label>
            ),
            className: [highlightWin, hideWin],
            align: "center",
            key: "winOddsThree",
            width: 65,
            onCell: (_) => {
              return { colSpan: 0 };
            },
          },
        ],
      },
      {
        // wPOdds and CI at interval latest
        title: header.wPRatio.latest,
        align: "center",
        children: [
          {
            title: "latest",
            align: "center",
            key: "winOddsLast",
            width: 70,
            sorter: {
              compare: (a, b) => {
                if (a.winPlaceOdds.latest < 0) {
                  return 999;
                } else if (b.winPlaceOdds.latest < 0) {
                  return -1;
                } else {
                  return a.winPlaceOdds.latest - b.winPlaceOdds.latest;
                }
              },
              multiple: 1,
            },
            sortOrder: sortedInfo?.find(
              ({ columnKey }) => columnKey === "winOddsLast"
            )?.["order"],
            onCell: () => {
              return {
                className: "Highlighted-Win-Column Disable-Row-Hover",
              };
            },
            render: (_) => {
              return (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    {_.winPlaceOdds.latest}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "4px",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      {_.winQWinRatio ? _.winQWinRatio.toFixed(2) : "-"}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {_.confidentIndex.latest}
                    </div>
                  </div>
                </div>
              );
            },
          },
        ],
      },
    ],
  };
};
