import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import * as auth from '../services/actions/authAction';
import BG from '../components/GeneralUI/BG';
import { Input, Button } from 'antd';
import { UserOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie'

import "./Login.css";

export const Login = () => {
  const navigate = useNavigate();
  const { keycloak, } = useKeycloak();
  const dispatch = useDispatch();
  const [username, setUsername] = useState(Cookies.get('id') || "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // users can reach the homepage if the entered username and password is correct.
  const login = () => {
    dispatch(auth.login(username, password, keycloak)).then(
      (succeed) => {
        if (succeed) {
          navigate("/");
        } else {
          setErrorMessage("Invalid username or password. Please try again.");
        }
      }
    );
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      setErrorMessage("");
      login();
    }
  }

  useEffect(() => {
    dispatch(auth.autoLogin(keycloak));
  });

  // layout of the login page
  return (
    <div>
      <BG />
      <div style={{ verticalAlign: "middle", lineHeight: "100vh" }}>
        <div className="Login-Container" style={{ width: "55%", userSelect: "none" }}>
          <h1 className='Login-Logo' >
            REAL-TIME <br />DA PLATFORM
          </h1>
          <h2 className='Login-Logo-Sub'>AGILE EXTREME LAB PRESENTED</h2>
        </div>
        <div className="Login-Container" style={{ width: "45%" }}>
          <div className='Login-Container-Input'>
            <Input style={{ marginTop: "20%" }} onChange={(value) => setUsername(value.target.value)} onKeyDown={onKeyDown} className='Login-Input' placeholder="username" prefix={<UserOutlined />} value={username} />
            <Input.Password className='Login-Input' onChange={(value) => setPassword(value.target.value)} onKeyDown={onKeyDown} placeholder="password" prefix={<LockOutlined />} iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            <Button className='Login-Input Login-Button' type="primary" onClick={() => { login() }}>Login</Button>
            <Button style={{ color: "white" }} type="link" block >Forget Password?</Button>
            {(errorMessage !== "") && <span style={{ color: "red", fontSize: "16px", backgroundColor: "rgba(72, 88, 52, 1)", padding: "4px" }}>{errorMessage}</span>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login;