import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, ReferenceLine } from 'recharts';
import { useSelector } from 'react-redux';
import { getAxisStroke, getGridStroke } from '../../../services/utils/chartHelper';
import { getZeroMinute } from '../standard/graphs/WinChart';

const ForecastQuinellaChart = ({ data, range, showRange, reverseOdds, ratio, height }) => {
  // line chart display at forecast card for forecast, forecast reverse and quinella odds
  const theme = useSelector(state => state.general.theme);
  const showReal = useSelector(state => state.forecast.additionalData.showRealCurve);
  const showNorm = useSelector(state => state.forecast.additionalData.showNormCurve);

  const {raceTime, updateTsRaw} = useSelector(state => state.forecast);
  const zeroMinute = getZeroMinute(raceTime, updateTsRaw);

  return (
    <ResponsiveContainer width='100%' height={height}>
      <LineChart data={data} margin={{ top: 2, right: 4, left: 5, bottom: 6 }}>
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          height={8}
          dataKey="minute"
          domain={range}
          ticks={showRange}
          tick={{ fontSize: "8px" }}
          stroke={getAxisStroke(theme)}
        >
          <Label value={ratio} position={"insideTopRight"} style={{fontSize: "12px"}}  />
        </XAxis>
        <YAxis
          width={16}
          domain={[dataMin => dataMin < 999 ? Math.floor(dataMin * 0.9) : dataMin, dataMax => dataMax * 1.1 >= 999 ? 999 : Math.ceil(dataMax * 1.1)]}
          interval="preserveStartEnd"
          tick={{ fontSize: "8px", dx: 5, dy: -3.5 }}
          allowDecimals={false}
          stroke={getAxisStroke(theme)}
        />
        <Line type="monotone" hide={!showReal} dataKey="forecastNormal" dot={false} stroke={"rgb(12, 50, 220)"} strokeWidth={2} isAnimationActive={false} visibility />
        <Line type="monotone" hide={!showReal} dataKey="forecastReverse" dot={false} stroke={"rgb(94, 235, 75)"} strokeWidth={2} isAnimationActive={false} />
        <Line type="monotone" hide={!showReal} dataKey="quinella" dot={false} stroke="rgb(240, 51, 41)" strokeWidth={2} isAnimationActive={false} />

        <Line type="monotone" hide={!showNorm} dataKey="forecastNormalNorm" dot={false} stroke={"rgb(12, 50, 220)"} strokeWidth={2} isAnimationActive={false} strokeDasharray="5 5" />
        <Line type="monotone" hide={!showNorm} dataKey="forecastReverseNorm" dot={false} stroke={"rgb(94, 235, 75)"} strokeWidth={2} isAnimationActive={false} strokeDasharray="5 5" />
        <Line type="monotone" hide={!showNorm} dataKey="quinellaNorm" dot={false} stroke="rgb(240, 51, 41)" strokeWidth={2} isAnimationActive={false} strokeDasharray="5 5" />
        {(zeroMinute && zeroMinute > 0) && 
          <ReferenceLine x={zeroMinute * -1} stroke="rgba(0, 0, 0, 0.7)" strokeWidth={1} />
        }
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ForecastQuinellaChart;