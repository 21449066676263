import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import { NavigationBar } from '../components/GeneralUI/NavigationBar';

import RaceCard from '../pages/RaceCard';
import WinCard from '../pages/WinCard';
import AccManagement from '../pages/AccManagement';
import DoubleCard from '../pages/DoubleCard';
import ForecastCard from '../pages/ForecastCard';
import Entries from '../pages/Entries';
import PastPerformance from '../pages/PastPerformance';
import useQuery from '../services/utils/useQuery';
import JTInfo from '../pages/JTInfo';

const { Content } = Layout;

const AuthenticatedRoute = () => {
  const query = useQuery();

  return (
    <Layout>
      <NavigationBar />
      <Content>
        <Routes> {/* The Switch decides which component to show based on the current URL.*/}
          <Route path='/' element={<RaceCard />} />
          <Route path='/win' element={<WinCard />} />
          <Route path='/double' element={<DoubleCard />} />
          <Route path='/forecast' element={<ForecastCard />} />
          <Route path='/accountInfo' element={<AccManagement pageIndex={'1'} />} />
          <Route path='/changePassword' element={<AccManagement pageIndex={'2'} />} />
          <Route path='/entries' element={<Entries />} />
          <Route path='/past-performance' element={<PastPerformance />} />
          <Route path='/jt-info' element={<JTInfo type={query.get("type")} code={query.get("code")} />} />
        </Routes>
      </Content>
    </Layout>
  )
}

export default AuthenticatedRoute;