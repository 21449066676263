import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux";

const getBackgroundColor = (index) => {
  switch(index) {
    case 0: return "rgb(255,105,180)"; 
    case 1: return "rgb(0, 255, 0)";
    case 2: return "rgb(145, 145, 255)";
    default: return "";
  }
}

const HorseNameColumn = (sortedInfo, winPoolDistributionGameMap, width = "7.0%") => {
  const { t } = useTranslation();
  const hottestList = useSelector(state => state.standard.additionalData?.hottestList) || [null, null, null];

  return {
    // column of horses' handicap weights
    title: `${t("race::weightHeader")}`,
    align: 'center',
    key: 'weight',
    width: width,
    sorter: {
      compare: (a, b) => a.wgt - b.wgt,
      multiple: 1,
    },
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === 'weight')?.['order'],
    render: (_) => (
      <div>
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          {(_?.winPlaceOdds?.latest && _.winPlaceOdds.latest > 0) ? (0.825 * 100 / _.winPlaceOdds.latest).toFixed(1) : ""}
        </div>
        <div style={{ position: "absolute", top: 0, right: 0 }}>
          {winPoolDistributionGameMap?.find(e => Number(e.horseNum || 999) === Number(_.horseNum || -1))?.value}
        </div>
        <div 
          style={{ 
            textAlign: 'center', 
            fontSize: 20, 
            fontWeight: "bold",
            backgroundColor: getBackgroundColor(hottestList?.indexOf(_.horseNum)) 
          }}
        >
          {_.horseName}
        </div>
        <div style={{ textAlign: 'center', fontSize: 15 }} >{_.wgt} : {_.horseHandicapWeight}</div>
      </div>
    )
  }
}

export default HorseNameColumn;
