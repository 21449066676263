export const CIColumn = () => {
  return {
    title: "CI",
    align: 'center',
    key: 'ci',
    width: 50,
    render: (_) => {
      return (
        <div>{_.ci?.toFixed(2)}</div>
      )
    }
  }
}