import { CloseOutlined } from "@ant-design/icons";
import { getWeightedCIBackgroundColor } from "../double/CrossColumn";
import ForecastQuinellaChart from "./ForecastQuinellaChart"

const cellRendering = (horseNum, forecastHorseNum, data, ratio, fQSignal) => {
  // the same horse cannot have combination with itself
  if (String(horseNum) === forecastHorseNum) {
    return (
      <CloseOutlined />
    );
  }

  const colorMapping = {
    1: "rgba(255, 0, 255, 0.5)",
    2: "rgba(0, 0, 255, 0.5)",
    3: "rgba(0, 255, 0, 0.5)",
    4: "rgba(255,165,0, 0.9)",
  }
  if (Number(horseNum) === Number(forecastHorseNum)) {
    return <></>;
  }
  return (
    <div style={{ backgroundColor: colorMapping[fQSignal], width: "inherit", height: "inherit" }}>
      <ForecastQuinellaChart data={data} range={[-30, 0]} showRange={[-20, 0]} reverseOdds={false} ratio={ratio} height="100%"/>
    </div>
  );
}

export const CrossColumn = (forecastData, additioanlData, sortedHorseNumList) => {
  const columns = [];

  const fQStatistics = additioanlData?.fQStatistics;
  const fQSignal = additioanlData?.fQSignal;

  for (const key of sortedHorseNumList) {
    const column = {
      title: () => {
        return (
          <div>
            <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)", backgroundColor: getWeightedCIBackgroundColor(fQStatistics?.[key]?.weighted_quinella_forecast_ci) }}>
              {fQStatistics?.[key]?.weighted_quinella_forecast_ci?.toFixed(2) || "-"}
            </div>
            <div style={{ textAlign: "center", borderBottom: "1px solid rgba(120, 120, 120, 0.5)", backgroundColor: getWeightedCIBackgroundColor(fQStatistics?.[key]?.win_place_ci) }}>
              {fQStatistics?.[key]?.win_place_ci?.toFixed(2) || "-"}
            </div>
            <div style={{ textAlign: "center", backgroundColor: getWeightedCIBackgroundColor(fQStatistics?.[key]?.quinella_forecast_ci) }}>
              {fQStatistics?.[key]?.quinella_forecast_ci?.toFixed(2) || "-"}
            </div>
          </div>
        );
      },
      align: 'center',
      children: [
        {
          title: key,
          align: 'center',
          key: `cross_${key}`,
          width: '3.5%',
          render: (_) => {
            if (_?.fQStatisticsRow != null) {
              const statistics = fQStatistics?.[key];
              return (
                <div>
                <div
                  style={
                    {
                      color: statistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                      fontWeight: statistics?.jockey?.within_range ? "bold" : null,
                      borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
                    }
                  }
                >
                  {statistics?.jockey?.win_percentage != null ? (statistics.jockey.win_percentage * 100).toFixed(1) : "-"}%
                </div>
                <div style={{ color: statistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)", fontWeight: statistics?.trainer?.within_range ? "bold" : null }}>
                  {statistics?.trainer?.win_percentage != null ? (statistics.trainer.win_percentage * 100).toFixed(1) : "-"}%
                </div>
              </div>
              )
            }
            return cellRendering(_.horseNum, key, _.forecastGraphData[key], _.winRatio[key], fQSignal?.[_.horseNum]?.[key]);
          }
        }
      ],
    };
    columns.push(column);
  }

  return columns;
}