import * as API from "../../../middleware/API";
import { setJockeyData, setTrainerData } from "../../reducers/jtInfoSlice";
import store from "../../reducers";

let controller = null;

export const loadAllJockeyData = () => async (dispatch) => {
  // check if the previous api call is finished already, if not finished, abort the previous fetch
  if (controller) {
    controller.abort('Operation canceled by the user.');
  }
  // generate new controller signal to allow abortion of current fetch if necessary
  controller = new AbortController();
  const signal = controller.signal;

  try {
    if (Object.keys(store.getState().jtInfo.jockey.profile).length > 1 && Object.keys(store.getState().jtInfo.jockey.statistics).length > 1) {
      return;
    }
    // race call list for current race and if current race is not the first race, also add the previous race into call list
    let callList = [
      API.loadJockeyProfile(null, signal).catch(e => null),
    ];

    const [profiles] = await Promise.all(callList.map(p => p?.catch(e => null)));

    callList = [
      API.loadJockeyStatistics(Object.keys(profiles?.data?.payload?.data || {}).join(","), signal).catch(e => null),
    ]

    // call api with the race call list
    const [statistics] = await Promise.all(callList.map(p => p?.catch(e => null)));

    // reset controller for next api fetch
    controller = null;

    // map a large object to pass to reducer
    const detailData = {
      profile: profiles?.data?.payload?.data,
      statistics: statistics?.data?.payload?.data,
    };

    // pass the data object into reducer
    dispatch(setJockeyData(detailData));
  } catch (error) {
    console.error(error.message);
  }
}

export const loadAllTrainerData = () => async (dispatch) => {
  // check if the previous api call is finished already, if not finished, abort the previous fetch
  if (controller) {
    controller.abort('Operation canceled by the user.');
  }
  // generate new controller signal to allow abortion of current fetch if necessary
  controller = new AbortController();
  const signal = controller.signal;

  try {
    if (Object.keys(store.getState().jtInfo.trainer.profile).length > 1 && Object.keys(store.getState().jtInfo.trainer.statistics).length > 1) {
      return;
    }
    // race call list for current race and if current race is not the first race, also add the previous race into call list
    let callList = [
      API.loadTrainerProfile(null, signal).catch(e => null),
    ];

    const [profiles] = await Promise.all(callList.map(p => p?.catch(e => null)));

    callList = [
      API.loadTrainerStatistics(Object.keys(profiles?.data?.payload?.data || {}).join(","), signal).catch(e => null),
    ]

    // call api with the race call list
    const [statistics] = await Promise.all(callList.map(p => p?.catch(e => null)));

    // reset controller for next api fetch
    controller = null;

    // map a large object to pass to reducer
    const detailData = {
      profile: profiles?.data?.payload?.data,
      statistics: statistics?.data?.payload?.data,
    };

    // pass the data object into reducer
    dispatch(setTrainerData(detailData));
  } catch (error) {
    console.error(error.message);
  }
}

export const loadJTPastPerformance = (code, type) => async(dispatch) => {
  // check if the previous api call is finished already, if not finished, abort the previous fetch
  if (controller) {
    controller.abort('Operation canceled by the user.');
  }
  // generate new controller signal to allow abortion of current fetch if necessary
  controller = new AbortController();
  const signal = controller.signal;

  try {
    // race call list for current race and if current race is not the first race, also add the previous race into call list
    let callList = [
      API.loadPastPerformanceJT(code, type, signal).catch(e => null),
    ];

    const [pastPerformance] = await Promise.all(callList.map(p => p?.catch(e => null)));

    // reset controller for next api fetch
    controller = null;

    // map a large object to pass to reducer
    const detailData = {
      pastPerformance: pastPerformance?.data?.payload,
    };

    // pass the data object into reducer
    if (type === "trainer") {
      dispatch(setTrainerData(detailData));
    } else {
      dispatch(setJockeyData(detailData));
    }
  } catch (error) {
    console.error(error.message);
  }
}