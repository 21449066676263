import * as Helper from '../../utils/helper';

export const initialData = () => (
  {
    updateTS: Helper.currentTimeIn24HoursFormat(),
    updateTsRaw: null,
    raceTime: null,
    winOdds: {
      1: -1
    },
    detailData: {
      1: {
        horseNum: 1,
        winOdds: -1,
        forecastGraphData: { 1: [] },
        winRatio: { 1: null }
      }
    },
    additionalData: {
      fetchWin: true,
      fetchForecast: true,
      fetchQuinella: true,
      fetchForecastNorm: true,
      fetchQuinellaNorm: true,
      showRealCurve: true,
      showNormCurve: true,
      horseLowestWinOdds: null,
      horseSecondLowestWinOdds: null,
      fQStatistics: {},
      fQSignal: {}
    }
  }
)

export const generateEmptyValueGraphDataWin = (minStart, minEnd) => {
  const result = [];
  for (let i = minStart; i <= minEnd; i++) {
    result.push({ minute: i, pv: null, qw: null })
  }
  return result
}

export const generateEmptyValueGraphDataForecast = (minStart, minEnd) => {
  const result = [];
  for (let i = minStart; i <= minEnd; i++) {
    result.push({ 
      minute: i, 
      forecastNormal: null, 
      forecastReverse: null, 
      quinellaReverse: null, 
      forecastNormalNorm: null,  
      forecastReverseNorm: null,  
      quinellaNorm: null,  
    })
  }
  return result
}