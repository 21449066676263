import React from 'react';
import { Table } from 'antd';
import '../RaceTable.css';

const TableColumn = (data) => {
  const columns = [];
  for (let i = 0; i < data?.length; i++) {
    const column = {
      title: () => {
        return (
          <div style={{ width: "40px", margin: "10px" }}>{data[i].minute}</div>
        )
      },
      align: 'center',
      key: i,
      fixed: i === 0 ? 'left' : (i === data.length - 1 ? 'right' : ''),
      render: (_) => {
        return (
          <div style={{ width: "40px", margin: "10px" }}>{data[i].pv}</div>
        )
      }
    };
    columns.push(column);
  }
  return columns;
}
export const HoverTable = (data) => {
  const columns = TableColumn(data);

  return (
    <div style={{ width: "400px", height: "94px" }}>
      <Table
        pagination={false}
        dataSource={[{key: 0}]}
        columns={columns}
        className="double-hover-table"
        scroll={{
          x: true,
        }}
        rowKey={(record)=>record.key}
      />
    </div>
  );
}
