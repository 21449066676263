const PlacingColumn = (dataSource = null) => {
  const filterData = [];
  dataSource?.forEach(data => {
    if (!filterData.find(e => e.value === data.placeNum)){
      filterData.push({
        text: data.placeNum,
        value: data.placeNum,
      })
    }
  });
  filterData.sort((a, b) => a.value - b.value);
  return {
    title: "P.Num",
    align: 'center',
    key: 'placing',
    width: 60,
    filters: filterData,
    fixed: 'left',
    onFilter: (value, record) => record?.placeNum === value,
    render: (_) => {
      return (
        <div>{_?.placeNum}</div>
      )
    }
  }
}

export default PlacingColumn;
