import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setHideInfo, setHideCurrentHorse, setHideNextHorse, setHideColorBorder, setDoubleHighlightLowerLimit, setDoubleHighlightUpperLimit } from '../../services/reducers/drawerSlice';
import { Checkbox, InputNumber, Divider } from 'antd';
import '../../App.css'

export const DoubleCardDrawerItems = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const doubleCardDrawer = useSelector(state => state.drawer.doubleCardDrawer)

  const { hideRaceTitle, hideRacePicker, hideResetButton } = doubleCardDrawer.hideInfo;
  const { high, low } = doubleCardDrawer.highlightGraphsByOddsLimit;
  const hideColorBorder = doubleCardDrawer.hideColorBorder;
  const currentRace = doubleCardDrawer.currentRace;
  const nextRace = doubleCardDrawer.nextRace;

  const doubleCurrentData = useSelector(state => state.double.currentOdds);
  const doubleCurrentKeys = Object.keys(doubleCurrentData);
  const doubleNextData = useSelector(state => state.double.detailData);
  const doubleNextKeys = Object.keys(doubleNextData);

  const hideInfo = (e) => {
    dispatch(setHideInfo([e.target.id, e.target.checked]));
  }
  const onInputUpper = (e) => {
    dispatch(setDoubleHighlightLowerLimit(e));
  };
  const onInputLower = (e) => {
    dispatch(setDoubleHighlightUpperLimit(e));
  };
  const hideCol = (e) => {
    dispatch(setHideCurrentHorse([e.target.id, e.target.checked]));
  }
  const hideRow = (e) => {
    dispatch(setHideNextHorse([e.target.id, e.target.checked]));
  }
  const hideBorder = (e) => {
    dispatch(setHideColorBorder(e));
  }

  return (
    <>
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideHeadersTitle")}</p>
        <Checkbox id='hideRaceTitle' onChange={hideInfo} checked={hideRaceTitle} className="Drawer-Items-Checkbox">{t("setting::hideRaceTitle")}</Checkbox>
        <Checkbox id='hideRacePicker' onChange={hideInfo} checked={hideRacePicker} className="Drawer-Items-Checkbox">{t("setting::hideRacePicker")}</Checkbox>
        <Checkbox id='hideResetButton' onChange={hideInfo} checked={hideResetButton} className="Drawer-Items-Checkbox">{t("setting::hideResetButton")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideCurrentHorse")}</p>
        {doubleCurrentKeys && doubleCurrentKeys.map((horseNum) =>
          <Checkbox id={`hideCurrentHorse${horseNum}`} onChange={hideCol} checked={currentRace["hideCurrentHorse" + horseNum]} className="Drawer-Items-Checkbox">{horseNum}</Checkbox>
        )}
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideNextHorse")}</p>
        {doubleNextKeys && doubleNextKeys.map((horseNum) =>
          <Checkbox id={`hideNextHorse${horseNum}`} onChange={hideRow} checked={nextRace["hideNextHorse" + horseNum]} className="Drawer-Items-Checkbox">{horseNum}</Checkbox>
        )}
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::hideColorBorder")}</p>
        <Checkbox id='hideColorBorder' onChange={hideBorder} checked={hideColorBorder} className="Drawer-Items-Checkbox">{t("setting::hideColorBorderCheckbox")}</Checkbox>
      </div>
      <Divider className='Drawer-Divider' />
      <div>
        <p style={{ fontSize: "18px" }}>{t("setting::setHighlightingLimit")}</p>
        <span>{t("setting::highlightLow")}</span>
        <InputNumber onChange={onInputUpper} controls={false} value={low} /><br />
        <span>{t("setting::highlightHigh")}</span>
        <InputNumber onChange={onInputLower} controls={false} value={high} />
      </div>
    </>
  )
}