const dataMapping = (
  recommendLLM,
  recommendLLMGPT4,
  recommendLLMTop3,
  recommendLLMNext5,
  recommendLLMOthers,
  recommendLLMLiveRanking,
  command_0min
) => {
  const result = {};

  const appendNewEntries = (data, dataKey) => {
    for (const [key, entry] of Object.entries(data || [])) {
      !(key in result) && (result[key] = { minute: entry?.minute });
      result[key][dataKey] = entry;
      if (entry?.minute === 0) {
        result[key]["command_0min"] = command_0min;
      }
    }
  };
  appendNewEntries(recommendLLM, "llm");
  appendNewEntries(recommendLLMGPT4, "gpt4");
  appendNewEntries(recommendLLMTop3, "top3");
  appendNewEntries(recommendLLMNext5, "next5");
  appendNewEntries(recommendLLMOthers, "others");
  appendNewEntries(recommendLLMLiveRanking, "liveRanking");

  return result;
};

export default dataMapping;
