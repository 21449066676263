import { Button } from "antd"

export const WeekColumn = () => {
  const openNewTab = (raceDate, raceNum) => {
    const url = `https://racing.hkjc.com/racing/information/Chinese/Racing/LocalResults.aspx?RaceDate=${raceDate.replaceAll("-", "/")}&RaceNo=${raceNum}`;
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return {
    title: "Wk",
    align: 'center',
    fixed: 'left',
    children: [
      {
        title: "PoolR",
        align: 'center',
        key: 'poolRatio',
        width: 50,
        fixed: 'left',
        render: (_) => {
          return (
            <div>
              <div style={{ borderBottom: "1px solid rgb(210, 210, 210)" }}>
                <Button onClick={() => openNewTab(_.raceDate, _.raceNum)} className="Week-Button Button" size="small" style={{ marginBottom: "1px" }}>{_.week}</Button>
              </div>
              <div>{_.poolRatio?.toFixed(2)}</div>
            </div>
          )
        }
      }
    ]
  }
}