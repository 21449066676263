import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router';

import Login from '../pages/Login'

const UnauthenticatedRoute = () => {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route path='/' element={<Login />}></Route>
      <Route path='*' element={<Navigate to="/" />}></Route>
    </Routes>
  )
}

export default UnauthenticatedRoute;