import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { CurrentColumn } from './CurrentColumn';
import { CrossColumn } from './CrossColumn';

import '../RaceTable.css';
import { DoubleWpCIColumn } from './DoubleWpCIColumn';
import { WpCIColumn } from './WpCIColumn';
import { AvgDoubleCIColumn } from './AvgDoubleCIColumn';
import { DoubleStatisticsColumn } from './DoubleStatisticsColumn';

const DoubleTable = () => {
  // dataSource: basically all info like jockey/trainer name, nextOdds, CI, ...
  const dataSource = useSelector(state => Object.values(state.double.detailData));
  const doubleStatistics = useSelector(state => state.double.currentDoubleStatistics);
  const nextWinOddsList = useSelector(state => state.double.additionalData.nextWinOddsList) || [];
  // dataSource.sort((a, b) => a.nextOddsLatest > 0 ? (b.nextOddsLatest > 0 ? a.nextOddsLatest - b.nextOddsLatest : -1) : 999);
  dataSource.sort((a, b) => nextWinOddsList.indexOf(a.horseNum) - nextWinOddsList.indexOf(b.horseNum));
  if (dataSource.length > 0) {
    dataSource.push({ doubleStatisticsRow: doubleStatistics } || {});
  }
  // aliasData: alias of jockey/trainer surnames
  const aliasData = useSelector(state => state.double.aliasData);

  // doubleCurrentData: odds for current race
  const doubleCurrentData = useSelector(state => state.double.currentOdds);

  const currentOddsLatest = useSelector(state => state.double.currentOddsLatest);

  // doubleCurrentCode: jockey/trainer name code
  const doubleCurrentCode = useSelector(state => state.double.currentCode);

  const doubleCardDrawer = useSelector(state => state.drawer.doubleCardDrawer)
  const nextRace = doubleCardDrawer.nextRace;

  const columns = [
    // NextColumn(),
    DoubleStatisticsColumn(),
    DoubleWpCIColumn(),
    WpCIColumn(),
    AvgDoubleCIColumn(),
    CurrentColumn(aliasData),
    ...CrossColumn(doubleCurrentData, doubleCurrentCode, aliasData, currentOddsLatest)
  ]

  return (
    <div style={{ padding: 20 }}>
      <Table
        pagination={false}
        loading={dataSource.length === 0}
        dataSource={dataSource}
        columns={columns}
        rowKey={(record, index) => index}
        rowClassName={
          (record, index) => (
            nextRace[`hideNextHorse${index + 1}`] && "Hidden-Row"
          )
        }
      />
    </div>
  );
}

export default DoubleTable;
