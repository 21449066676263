import { createSlice } from "@reduxjs/toolkit";
import { initialData } from "./common";
import { jockeyDataProcessing, trainerDataProcessing } from "./handling";

const jtInfoSlice = createSlice({
  name: "jtInfo",
  initialState: initialData(),
  reducers: {
    setJockeyData: (state, data) => {
      // pass api to data handler
      jockeyDataProcessing(state, data);
    },
    setTrainerData: (state, data) => {
      // pass api to data handler
      trainerDataProcessing(state, data);
    },
  }
});

export const {
  setJockeyData,
  setTrainerData
} = jtInfoSlice.actions;

export default jtInfoSlice.reducer;