import { Table } from "antd";
import { HorseInfoColumn } from "./columns/HorseInfoColumn";
import { MiscellaneousColumn } from "./columns/MiscellaneousColumn";
import { WeekColumn } from "./columns/WeekColumn";
import TwoLayerHorseColumns from "./columns/TwoLayerHorseColumns";

import "./index.css"
import { SectionColumn } from "./columns/SectionColumn";
import { TimeColumn } from "./columns/TimeColumn";
import { LBWColumn } from "./columns/LBWColumn";
import { WinOddsColumn } from "./columns/WinOddsColumn";
import { CIColumn } from "./columns/CIColumn";
import { RaceIdColumn } from "./columns/RaceIdColumn";
import PlacingColumn from "./columns/PlacingColumn";

const PastPerformanceRaceTable = ({ dataSource, horseCode, pagination = false }) => {
  dataSource?.sort((a, b) => a.week - b.week);

  const columns = [
    RaceIdColumn(),
    WeekColumn(),
    MiscellaneousColumn(),
    PlacingColumn(dataSource),
    HorseInfoColumn(),
    ...TwoLayerHorseColumns("1st", "2nd", "first", "second", horseCode),
    ...TwoLayerHorseColumns("favoured", "3rd", "favored", "third", horseCode),
    WinOddsColumn(),
    CIColumn(),
    SectionColumn(),
    TimeColumn(),
    LBWColumn(),
  ];

  return (
    <Table
      pagination={pagination}
      loading={false}
      dataSource={dataSource}
      columns={columns}
      className="Past-Performance-Table"
      scroll={{ x: 'max-content' }}
      rowClassName={(_, index) => {
        if (index % 2 === 1) {
          return "Odd-Row";
        } else {
          return "Even-Row";
        }
      }}
      rowKey={(record) => record.raceId}
    />
  )
}

export default PastPerformanceRaceTable;
