import { Transfer } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';


const HorseSelector = ({setTargetHorse}) => {
  const mockData = useSelector((state) => state.standard.detailData)
  const initialTargetKeys = Object.values(mockData).map(
    (horse) => ({
      key: horse.horseNum,
      title: `${horse.horseNum}. ${horse.horseName}`,
    })
  );

  const [targetKeys, setTargetKeys] = useState([...(initialTargetKeys.map((item) => item.key))]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetKeys(nextTargetKeys.sort((a, b) => Number(a) - Number(b)));
    setTargetHorse(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    setTargetKeys([...(initialTargetKeys.map((item) => item.key))])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockData]);
  
  return (
    <div style={{ padding: "4px" }}>
      <h2>Horse Selector</h2>
      <Transfer
        dataSource={initialTargetKeys}
        titles={['Reserved', 'Selected']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default HorseSelector;