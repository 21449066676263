import { setForecastDataWS } from '../../reducers/forecastSlice';

export const handleForecastCardEvent = (payload, raceDate, raceNum) => async (dispatch) => {
  try {
    if (!raceDate || !raceNum) {
      return
    }
    await dispatch(setForecastDataWS(payload));
  } catch (error) {
    console.error(error.message);
  }
}
