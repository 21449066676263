import { useTranslation } from "react-i18next"
import { getBackgroundColor } from "./columns/RaceColumn"

export const Indicator = () => {
  const {t} = useTranslation();

  return (
    <div style={{ fontSize: "16px", textAlign: "left", paddingLeft: "20px" }}>
      <span>{t("entries::rankIndicator")}: </span>
      <span style={{ color: getBackgroundColor(1), padding: "4px" }}>&#9679;Win</span>
      <span style={{ color: getBackgroundColor(2), padding: "4px"  }}>&#9679;Q</span>
      <span style={{ color: getBackgroundColor(3), padding: "4px"  }}>&#9679;P</span>
      <span style={{ color: getBackgroundColor(4), padding: "4px"  }}>&#9679;4</span>
    </div>
  )
}
