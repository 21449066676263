import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadRaceDateAndRaceNum } from '../../services/actions/raceAction';
import { setRaceDate, setRaceNum } from '../../services/reducers/raceSlice'
import { useTranslation } from 'react-i18next';
import CalendarPicker from './CalendarPicker';
import moment from 'moment';
import { Select } from "antd";
import { RaceNumButtons } from './RaceNumButtons';

export const RaceSelector = ({ showRaceNumSelector = true, useDouble = false }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // this component handles race date, race num, interval (double table only) selection and changes
  const raceDates = useSelector(state => {
    const data = Object.keys(state.race.raceDateWithRaceNum);
    if (state.auth.userData?.resource_access?.["race-ui"]?.roles?.includes("admin")) {
      data.push("2023-08-14");
    }
    return data.reverse();
  });
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);

  useEffect(() => {
    dispatch(loadRaceDateAndRaceNum());
  }, [dispatch]);

  const handleRaceDateChange = (value) => {
    dispatch(setRaceDate((value instanceof moment) ? value.format("YYYY-MM-DD") : value));
    dispatch(setRaceNum(1));
  }

  const disabledDate = (date) => {
    return !raceDates.includes(date.format("YYYY-MM-DD"));
  };

  return (
    <>
      <div style={{ textAlign: 'left', paddingLeft: 20, paddingRight: 20, fontSize: "16px" }}>
        {t("race::raceDate")}:
        <Select
          size="default"
          value={selectedRaceDate}
          onChange={handleRaceDateChange}
          style={{ width: 160, paddingLeft: 5 }}
          dropdownClassName="Select-DropDown"
          className="Select">
          {
            raceDates.map(o =>
              <Select.Option key={o} value={o}>{o}</Select.Option>
            )
          }
        </Select>
        <CalendarPicker targetDate={selectedRaceDate} onSelect={handleRaceDateChange} disabledDate={disabledDate} allowHeaderDate={raceDates.map((value) => moment(value))} />

        {showRaceNumSelector && (<span style={{ paddingLeft: "16px" }}>
          {useDouble ? t("race::doubleNum") : t("race::raceNum")}:
          <RaceNumButtons useDouble={useDouble} />
        </span>)}

      </div>
    </>
  )
}