import { CloseOutlined, RobotOutlined } from "@ant-design/icons";
import { Button } from "antd";

const Header = ({setOpen}) => {
  return (
    <div
      style={{
        borderBottom: "2px solid rgb(220, 220, 220)",
        backgroundColor: "rgb(200, 200, 255)",
        height: "5%",
        borderRadius: "inherit"
      }}
    >
      <Button
        size="small"
        style={{
          position: "fixed",
          top: "2px",
          right: "2px",
          backgroundColor: "rgba(240, 240, 240, 0.5)"
        }}
        shape="circle"
        onClick={() => setOpen(false)}
      >
        <CloseOutlined />
      </Button>
      <h3 style={{ textAlign: "center" }}>
        <RobotOutlined />
        Chatbot
      </h3>

    </div>
  )
}

export default Header;