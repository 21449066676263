import * as Helper from "../../utils/helper";
import { generateEmptyValueGraphData } from "./logic/miscellaneous";

// dummy data structure of a race for developer, most of the data will be refreshed after api fetch
export const initialData = () => ({
  updateTS: Helper.currentTimeIn24HoursFormat(),
  info: {
    winPoolAmount: null,
    updatets: "",
    raceTime: null,
    updateTsRaw: null,
    venue: "",
    track: "",
    class: "",
    course: "",
    distance: "",
    name: "",
  },
  headerData: {
    forecastPoolPercentage: {
      lowest: null,
      secondLowest: null,
      thirdLowest: null,
    },
    forecastHorseNum: { lowest: null, secondLowest: null, thirdLowest: null },
    wPRatio: {
      thirty: null,
      ten: null,
      six: null,
      three: null,
      zero: null,
      latest: null,
    },
    wPMinInterval: { 30: null, 10: null, 6: null, 3: null, 0: null },
  },
  command: { command: [], minuteSnapshot: null, betDescision: "" },
  newColdDoor: { text: "", score: null },

  landscapeDistribution: {
    TT: { expected: null, insight: null, difference: null },
    TN_NT: { expected: null, insight: null, difference: null },
    NN: { expected: null, insight: null, difference: null },
    O: { expected: null, insight: null, difference: null },
  },
  additionalData: {
    numOfHorseCurrent: null,
    numOfHorseNext: null,
    simulationHorseNum: null,
    resultUrl: null,
    winInvestmentSum: null,
    winInvestmentSumTop6: null,
    winPoolDistributionGameMap: null,
    gameMap: {
      secondLast: {},
      last: {},
      latest: {
        grouping: { fav: null, sup: null, inf: null, unw: null },
        statistics: { fav: null, sup: null, inf: null, unw: null },
        poolRatio: null,
        updateTs: null,
      },
      zero: null,
    },
    horseTrackworkGrade: {},
    jtNode: { edges: [] },
    jockeyAlias: {},
    trainerAlias: {},
    top3WinPercentStatistics: {},
  },
  resultData: {
    poolResult: [
      {
        pool: "win",
        combination: "1",
        odds: 10.0,
      },
      {
        pool: "place",
        combination: "1",
        odds: 3.0,
      },
      {
        pool: "place",
        combination: "2",
        odds: 10.0,
      },
      {
        pool: "quinella",
        combination: "1, 2",
        odds: 12.0,
      },
    ],
    lossDetail: [],
  },
  detailData: {
    1: {
      key: 1,

      forecastOdds: { lowest: null, secondLowest: null, thirdLowest: null },
      forecastCi: { lowest: null, secondLowest: null, thirdLowest: null },
      forecastAbs: {},
      forecastNormAbs: {},
      quinellaOdds: { lowest: null, secondLowest: null, thirdLowest: null },
      quinellaCi: { lowest: null, secondLowest: null, thirdLowest: null },
      quinellaAbs: {},
      quinellaNormAbs: {},

      winPlaceOdds: {
        thirty: null,
        ten: null,
        six: null,
        three: null,
        zero: null,
        latest: null,
      },
      confidentIndex: {
        thirty: null,
        ten: null,
        six: null,
        three: null,
        zero: null,
        latest: null,
      },
      winInvestmentDiff: { two: null, one: null, zero: null, latest: null },
      expectedWinOdds: {
        thirty: null,
        ten: null,
        six: null,
        three: null,
        zero: null,
        latest: null,
      },

      winGraphData: [],
      winQWinRatio: null,
      derivativeOdds: { winToWin: null, quinellaToWin: null },

      horseName: "",
      horseNum: 1,
      wgt: null,
      horseHandicapWeight: null,
      tips: null,
      drawNum: null,
      rank: null,

      jockeyName: "",
      jockeyCode: "",
      jockeyGain: { addition: null, multiply: null },
      jockeySingleOdds: initStatistics(),
      jockeyRankHistory: null,
      jockeyFutureHorses: null,
      jockeyGameMapPercentage: null,

      trainerName: "",
      trainerCode: "",
      trainerGain: { addition: null, multiply: null },
      trainerSingleOdds: initStatistics(),
      trainerRankHistory: null,
      trainerFutureHorses: null,
      trainerGameMapPercentage: null,

      jtCollab: initStatistics(),

      selected: false,

      matrixDataJockey: initWinMatrix(),
      matrixDataTrainer: initWinMatrix(),

      newColdDoor: { text: "", score: null },
    },
  },
  sortedInfo: [],
  recommendation: {
    recommendLLM: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        update_ts: "...",
      },
    },
    recommendLLMGPT4: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        ndcgLlm: -1,
        ndcgRoadmap: -1,
        update_ts: "...",
        hotRoadmapMovement: [],
        hotGpt4Movement: [],
        top2Prediction: "...",
        doubleSignal: [],
      },
    },
    recommendLLMTop3: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        update_ts: "...",
      },
    },
    recommendLLMNext5: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        update_ts: "...",
      },
    },
    recommendLLMOthers: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        update_ts: "...",
      },
    },
    recommendLLMLiveRanking: {
      20: {
        ranking: [1, 2, 3, 4, 5, 6, 7],
        reborn: [1, 2, 3],
        minute: 20,
        update_ts: "...",
      },
    },
  },
  signalData: {
    doubleSignal: { first: null, second: null, cross: null },
    fQSignal: [],
    weightedDoubleCI: {},
    fqScoreSum: { 1: 0 },
  },
  decisionTree: {
    data: {
      "16:00:00": {},
    },
    horseSelected: [],
    timelineSelected: "16:00:00",
  },
  userData: {
    targetUser: [
      "94efb71c-ff6b-4aaf-8306-c686c58a0d4b",
      "8b690fc2-2923-47a9-ba54-89c8b2821b3f",
      "7f79b524-f7e7-4954-8afe-b4772684b48d",
    ],
    snapshotTriggered: false,
    selectiveRanking: {
      horses: {
        1: 1,
      },
      prompt: "",
    },
    userData: {
      score: {
        1: {
          trackworkScore: 1,
        },
      },
      note: {
        1: "",
      },
    },
    otherUserData: {},
    scoreData: {},
  },
});

export const horseInit = (horseNum, horse) => {
  return {
    horseNum: horseNum,
    forecastOdds: { lowest: -1, secondLowest: -1, thirdLowest: -1 },
    forecastCi: { lowest: -1, secondLowest: -1, thirdLowest: -1 },
    forecastAbs: {},
    forecastNormAbs: {},
    quinellaOdds: { lowest: -1, secondLowest: -1, thirdLowest: -1 },
    quinellaCi: { lowest: -1, secondLowest: -1, thirdLowest: -1 },
    quinellaAbs: {},
    quinellaNormAbs: {},

    winPlaceOdds: {
      thirty: -1,
      ten: -1,
      six: -1,
      three: -1,
      zero: -1,
      latest: -1,
    },
    confidentIndex: {
      thirty: -1,
      ten: -1,
      six: -1,
      three: -1,
      zero: -1,
      latest: -1,
    },
    winInvestmentDiff: { two: null, one: null, zero: null, latest: null },

    winGraphData: generateEmptyValueGraphData(-20, 0),
    winQWinRatio: null,
    derivativeOdds: { winToWin: -1, quinellaToWin: -1 },

    horseName: horse.horseCode,
    jockeyName: horse.jockeyCode,
    jockeyCode: horse.jockeyCode,
    wgt: "-",
    trainerName: horse.trainerCode,
    trainerCode: horse.trainerCode,
    rank: null,

    jockeyGain: { addition: -1, multiply: -1 },
    jockeySingleOdds: initStatistics(),
    jockeyRankHistory: null,
    jockeyFutureHorses: null,
    jockeyGameMapPercentage: null,

    trainerGain: { addition: -1, multiply: -1 },
    trainerSingleOdds: initStatistics(),
    trainerRankHistory: null,
    trainerFutureHorses: null,
    trainerGameMapPercentage: null,

    jtCollab: initStatistics(),

    selected: false,

    matrixDataJockey: initWinMatrix(),
    matrixDataTrainer: initWinMatrix(),

    totalScore: 0,
    userRank: 1,

    winInvestmentDifferenceStatistics: {},
    overboughtStatistics: {},
    qwStatistics: {},
    fQStatistics: {},
    speedProStatistics: {},
    coldDoorStatistics: {},
    newColdDoor: { text: "", score: null },
  };
};

export const reset = (state) => {
  state.headerData = {
    forecastPoolPercentage: {
      lowest: null,
      secondLowest: null,
      thirdLowest: null,
    },
    forecastHorseNum: { lowest: null, secondLowest: null, thirdLowest: null },
    wPRatio: { thirty: null, ten: null, six: null, three: null, latest: null },
    wPMinInterval: { 30: null, 10: null, 6: null, 3: null, 0: null },
  };
  state.info = {
    winPoolAmount: -1,
    updatets: "-1",
    raceTime: null,
    raceStartTime: "",
    venue: "N/A",
    track: "N/A",
    class: "N/A",
    course: "N/A",
    distance: "N/A",
    name: "N/A",
  };
  state.command = { command: [], minuteSnapshot: null, betDescision: "" };
  state.landscapeDistribution = {
    TT: { expected: null, insight: null, difference: null, actual: null },
    TN_NT: { expected: null, insight: null, difference: null, actual: null },
    NN: { expected: null, insight: null, difference: null, actual: null },
    O: { expected: null, insight: null, difference: null, actual: null },
  };
  state.additionalData = {
    numOfHorseCurrent: null,
    numOfHorseNext: null,
    resultUrl: null,
    winInvestmentSum: null,
    winInvestmentSumTop6: null,
    winPoolDistributionGameMap: null,
    gameMap: {
      secondLast: null,
      last: null,
      latest: null,
      zero: null,
    },
    horseTrackworkGrade: {},
    jtNode: { edges: [], statistics: {}, idHorseNumMapping: {} },
    sortingHorseNumList: [],
    top3WinPercentStatistics: {},
  };
  state.detailData = {};
  state.resultData = {
    poolResult: [],
    lossDetail: [],
  };
  state.signalData = {
    doubleSignal: { first: null, second: null, cross: null },
    fQSignal: [],
    weightedDoubleCI: {},
    fqScoreSum: {},
  };
  state.recommendation = {
    recommendLLM: null,
    recommendLLMGPT4: null,
    recommendLLMTop3: null,
    recommendLLMNext5: null,
    recommendLLMOthers: null,
    recommendLLMLiveRanking: null,
  };
  state.decisionTree = {
    data: {},
    horseSelected: [],
    timelineSelected: null,
  };
  state.userData = {
    userData: {
      score: {},
      note: {},
    },
    otherUserData: {},
    scoreData: {},
    targetUser: [
      "94efb71c-ff6b-4aaf-8306-c686c58a0d4b",
      "8b690fc2-2923-47a9-ba54-89c8b2821b3f",
      "7f79b524-f7e7-4954-8afe-b4772684b48d",
    ],
    snapshotTriggered: false,
    selectiveRanking: { horses: {}, prompt: "" },
  };
};

const initStatistics = () => {
  return {
    tTL: { wins: -1, total: -1, active: true },
    fav: { wins: -1, total: -1, active: false },
    sup: { wins: -1, total: -1, active: false },
    inf: { wins: -1, total: -1, active: false },
    unw: { wins: -1, total: -1, active: false },
    current: { wins: -1, total: -1, active: true },
  };
};

const initWinMatrix = () => {
  return {
    latest: {
      first: -1,
      second: -1,
      third: -1,
      fourth: -1,
      total: -1,
      index: -1,
    },
    five: {
      first: -1,
      second: -1,
      third: -1,
      fourth: -1,
      total: -1,
      index: -1,
    },
    ten: { first: -1, second: -1, third: -1, fourth: -1, total: -1, index: -1 },
  };
};
