import { generateEmptyValueGraphData, mapOddsByMinute } from "./common";
import * as Helper from '../../utils/helper';

export const doubleProcessingWS = (state, data) => {
  // prepare and extract all required data
  const doubleOdds = data.payload.doubleOdds;
  const doubleOddsLatest = data.payload.doubleOddsLatest;

  const firstHorses = data.payload.firstRace?.horses;
  const secondHorses = data.payload.secondRace?.horses;
  const firstWeightedDoubleCI = data.payload.weightedDoubleCI?.currentRace;

  const secondWeightedDoubleCI = data.payload.weightedDoubleCI?.nextRace;

  // const firstOddsLatest = data.payload.firstOddsLatest;
  const secondOddsLatest = data.payload.secondOddsLatest;

  const doubleStatistics = data.payload.doubleStatistics;
  const secondDoubleStatistics = data.payload.secondDoubleStatistics;
  
  const doubleCI = data.payload.doubleCI?.horses;


  (firstHorses) && (state.additionalData.totalNumOfHorsesFirst = Math.max(...firstHorses.map(horse => horse.horseNum)));
  (secondHorses) && (state.additionalData.totalNumOfHorsesSecond = Math.max(...secondHorses.map(horse => horse.horseNum)));

  state.updateTsRaw = data.payload.pool?.updateTs;
  state.raceTime = data.payload.firstRace?.raceTime;

  // store alias if no alias stored
  if (data.payload.jockeyAlias) {
    state.aliasData.jockey = data.payload.jockeyAlias;
  }
  if (data.payload.trainerAlias) {
    state.aliasData.trainer = data.payload.trainerAlias;
  }

  if (doubleStatistics) {
    state.currentDoubleStatistics = doubleStatistics;
  }

  // mapping win odds, and jt code of first race
  for (let horseNum = 1; horseNum <= state.additionalData.totalNumOfHorsesFirst; horseNum++) {
    if (!state.currentOdds[horseNum]) {
      state.currentOdds[horseNum] = generateEmptyValueGraphData(-30, 0);
    }
    const horse = firstHorses?.find(horse => horse.horseNum === horseNum);
    // state.currentOdds[horseNum] = firstOdds?.[horseNum] ? firstOdds[horseNum].slice(30).map((data, index) => { return { minute: index - 30, pv: data } }) : state.currentOdds[horseNum];
    if (horse) {
      state.currentCode[horseNum] = { jockey: horse.jockeyCode, trainer: horse.trainerCode };
    }

    if (firstWeightedDoubleCI?.[horseNum]?.winOdds != null || state?.currentOddsLatest?.[horseNum] == null) {
      state.currentOddsLatest[horseNum] = firstWeightedDoubleCI?.[horseNum]?.winOdds;
    }

    if (firstWeightedDoubleCI?.[horseNum] != null) {
      state.currentWeightedDoubleCI[horseNum] = firstWeightedDoubleCI[horseNum];
    }
  }
  // mapping each row data by each horse combinations
  for (let secondHorseNum = 1; secondHorseNum <= state.additionalData.totalNumOfHorsesSecond; secondHorseNum++) {
    const secondHorse = secondHorses?.find(horse => horse.horseNum === secondHorseNum);
    doubleHandling(state, secondHorseNum, doubleOdds, state.additionalData.totalNumOfHorsesFirst, secondHorse, doubleOddsLatest, secondWeightedDoubleCI, doubleCI, secondDoubleStatistics);
  }
  if (secondOddsLatest) {
    state.additionalData.nextWinOddsList = Object.entries(secondOddsLatest)
    .filter(e => e[1].latest.win > 0)
    .sort((a, b) => a[1].latest.win > 0 ? (b[1].latest.win > 0 ? a[1].latest.win - b[1].latest.win : -1) : 999)
    .map(e => Number(e[0]));
  }
  state.updateTS = Helper.currentTimeIn24HoursFormat();
}

const doubleHandling = (state, secondHorseNum, doubleOdds, totalNumOfHorsesFirst, secondHorse, doubleOddsLatest, secondWeightedDoubleCI, doubleCI, doubleStatistics) => {
  // if no each horse in data, initialize
  if (state.detailData[secondHorseNum] == null) {
    state.detailData[secondHorseNum] = {
      horseNum: secondHorseNum,
      nextOdds: generateEmptyValueGraphData(-60, -30),
      nextOddsLatest: null,
      odds: {},
      oddsLatest: {},
      code: {},
      weightedDoubleCI: {},
      doubleCI: {},
      doubleStatistics: {},
    };
  }
  const data = state.detailData[secondHorseNum];

  // store the next race horse win odds data
  if (secondWeightedDoubleCI?.[secondHorseNum]?.winOdds != null) {
    data.nextOddsLatest = secondWeightedDoubleCI[secondHorseNum].winOdds;
  }

  if (doubleStatistics?.[secondHorseNum]) {
    data.doubleStatistics = doubleStatistics[secondHorseNum];
  }

  // store double odds, and jt code of second race 
  for (let firstHorseNum = 1; firstHorseNum <= totalNumOfHorsesFirst; firstHorseNum++) {
    if (doubleOdds?.[firstHorseNum]?.[secondHorseNum]) {
      data.odds[firstHorseNum] = mapOddsByMinute(doubleOdds?.[firstHorseNum]?.[secondHorseNum], -30, 0);
    }
    if (doubleOddsLatest?.horses?.[firstHorseNum]?.[secondHorseNum]?.doubleOdds != null) {
      data.oddsLatest[firstHorseNum] = doubleOddsLatest?.horses?.[firstHorseNum]?.[secondHorseNum]?.doubleOdds;
    }

    if (doubleCI?.[firstHorseNum]?.[secondHorseNum] != null) {
      data.doubleCI[firstHorseNum] = doubleCI[firstHorseNum][secondHorseNum];
    }

    if (secondHorse) {
      data.code = { jockey: secondHorse.jockeyCode, trainer: secondHorse.trainerCode };
    }

    if (secondWeightedDoubleCI?.[secondHorseNum]) {
      data.weightedDoubleCI = secondWeightedDoubleCI[secondHorseNum]
    }
    
  }
}
