import { Col, Row } from "antd";

const OverBoughtColumn = () => {
  return {
    title: "Over\nBought",
    align: 'center',
    key: `overBought`,
    width: 60,
    onCell: (_) => {
      if (_.overboughtStatistics?.overbought){
        return { style: { backgroundColor: "rgba(255, 0, 0, 0.3)" } }
      }
    },
    render: (_) => {
      const winPercentageJockey = _.overboughtStatistics?.jockey?.win_percentage != null ? _.overboughtStatistics.jockey.win_percentage : _.overboughtStatistics?.winPercentageJockey;
      const winPercentageTrainer = _.overboughtStatistics?.trainer?.win_percentage != null ? _.overboughtStatistics.trainer.win_percentage : _.overboughtStatistics?.winPercentageTrainer;
      return (
        <div>
          <Row>
            <Col span={24}>
              {_.overboughtStatistics?.winOddsGroup || "-"}
            </Col>
          </Row>
          <Row>
            <Col 
              span={24} 
              style={{ 
                borderTop: "1px solid rgb(100,100,100)",
                color: _.overboughtStatistics?.jockey?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: _.overboughtStatistics?.jockey?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {winPercentageJockey != null ? (winPercentageJockey * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
          <Row>
            <Col 
              span={24} 
              style={{ 
                borderTop: "1px solid rgb(100,100,100)",
                color: _.overboughtStatistics?.trainer?.within_range ? null : "rgba(0, 0, 0, 0.3)",
                fontWeight: _.overboughtStatistics?.trainer?.within_range ? "bold" : null,
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }}
            >
              {winPercentageTrainer != null ? (winPercentageTrainer * 100).toFixed(1) : "-"}%
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default OverBoughtColumn;