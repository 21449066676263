import { Col, Row } from "antd";
import DecisionTree from "./DecisionTree";
import Selector from "./Selector";
import { useState } from "react";
import RecommandationTable from "./RecommendationTable";

const DecisionTreeSection = () => {
  const [targetHorse, setTargetHorse] = useState(null);

  return (
    <div style={{ padding: "16px", height: "90vh" }}>
      <Row style={{ height: "100%" }}>
        <Col span={6}>
          <Selector setTargetHorse={setTargetHorse} />
        </Col>
        <Col span={14}>
          <DecisionTree targetHorse={targetHorse} />
        </Col>
        <Col span={4}>
          <h2>All Races</h2>
          <RecommandationTable dataKey="all_races" levelList={["1 (25%)", "2 (30%)", "3 (45%)", "4 (52%)"]} />
          <br />
          <h2>Cold Races</h2>
          <RecommandationTable dataKey="cold_races" levelList={["1 (33%)", "2 (40%)", "3 (44%)", "4 (57%)"]} />
        </Col>
      </Row>
    </div>
  );
}

export default DecisionTreeSection;
