import React from 'react';
import { useSelector } from 'react-redux';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, Tooltip, ReferenceLine } from 'recharts';
import { getAxisStroke, getGridStroke } from '../../../../services/utils/chartHelper';
import ForecastQuinellaChart from '../../forecast/ForecastQuinellaChart';

// color mapping of each number digit
const colorMapping = {
  1: "rgb(255, 0, 255)",
  2: "rgb(0, 0, 255)",
  3: "rgb(0, 255, 0)",
  4: "rgb(255,165,0)",
}

const CustomTooltip = ({ active, payload }) => {
  // line chart to display forecast, forecast reverse and quinella odds
  const detailData = useSelector(state => state.standard.detailData)

  if (active) {
    const {firstHorseNum, sceondHorseNum} = payload[0].payload;
    // template array and map data into it
    const reducedData = [];
    for (let i = 0; i <= 30; i++) {
      reducedData[i] = {
        minute: -30 + i,
        forecastNormal: detailData?.[firstHorseNum].forecastAbs?.[sceondHorseNum]?.[i],
        forecastReverse: detailData?.[sceondHorseNum].forecastAbs?.[firstHorseNum]?.[i],
        quinella: detailData?.[firstHorseNum].quinellaAbs?.[sceondHorseNum]?.[i] ? detailData[firstHorseNum].quinellaAbs[sceondHorseNum][i] * 2 : null,
        forecastNormalNorm: detailData?.[firstHorseNum].forecastNormAbs?.[sceondHorseNum]?.[i],
        forecastReverseNorm: detailData?.[sceondHorseNum].forecastNormAbs?.[firstHorseNum]?.[i],
        quinellaNorm: detailData?.[firstHorseNum].quinellaNormAbs?.[sceondHorseNum]?.[i] ? detailData[firstHorseNum].quinellaNormAbs[sceondHorseNum][i] * 2 : null,
      }
    }
    // display the percentage data from api, matrix index and curve graph
    return (
      <div className="Fq-Signal-Graph-Hover">
        <div>Forecast: {reducedData?.[30]?.forecastNormal?.toFixed(1)}</div>
        <div>Quinella: {reducedData?.[30]?.quinella?.toFixed(1) / 2}</div>
        <ForecastQuinellaChart data={reducedData} range={[-30, 0]} showRange={[-20, 0]} height="65%" />
      </div>
    );
  }

  return null;
};

const CustomDot = (cx, cy, value) => {
  // form a donut circle component with outer color as ten digit, centre color as unit digit
  return (
    <circle cx={cx} cy={cy} r={6} fill={colorMapping[value]} />
  )
}

const CustomLegend = () => {
  // mapping on each color with its represented number digit value
  return (
    <div>
      {
        Object.entries(colorMapping).map(([key, colorCode]) => {
          return <span style={{ color: colorCode, fontSize: "12px", padding: "2px" }} key={key}>&#9679;{key}</span>
        })
      }
    </div>
  )
}

const ticksFormatter = (value, hottestList) => {
  return hottestList?.[value - 1] || value;
}

const ForecastQuinellaSignalGraphSorted = () => {
  // the forecast/quinella scatter chart component
  const theme = useSelector(state => state.general.theme);
  const additionalData = useSelector(state => state.standard.additionalData);
  const { numOfHorseCurrent, hottestList, gameMap } = additionalData;
  const {fQSignal, fqScoreSum} = useSelector(state => state.standard.signalData);
 
  const grouping = gameMap?.latest?.grouping;
  let supIndex = grouping?.fav?.length + 0.5 || 0;
  let infIndex = grouping?.sup?.length + supIndex || 0;
  let unwIndex = grouping?.inf?.length + infIndex || 0;

  if (supIndex <= 0.5) {
    supIndex = -100;
  }

  const countFormater = (value) => {
    return fqScoreSum?.[ticksFormatter(value, hottestList)] || "";
  }

  return numOfHorseCurrent ? (
    <ResponsiveContainer width={"99%"} minHeight={300} maxHeight={400} aspect={1} >
      <ScatterChart
        margin={{ top: -5, right: 8, left: 2, bottom: -15 }}
        style={{ pointerEvents: "visible" }}
      >
        <CartesianGrid stroke={getGridStroke(theme)} />
        <XAxis
          type="number"
          dataKey="firstHorseNumIndex"
          name="first horse num"
          domain={[1, numOfHorseCurrent]}
          interval={0}
          tickCount={numOfHorseCurrent}
          orientation="top"
          style={{ fontSize: "12px" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => ticksFormatter(value, hottestList)}
        />
        <YAxis
          type="number"
          dataKey="sceondHorseNumIndex"
          name="second horse num"
          width={22}
          domain={[1, numOfHorseCurrent]}
          interval={0}
          tickCount={numOfHorseCurrent}
          reversed={true}
          style={{ fontSize: "12px" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => ticksFormatter(value, hottestList)}
        />

        <Tooltip content={<CustomTooltip />} cursor={false} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ top: '-170px' }} />
        <Legend wrapperStyle={{ fontSize: "10px", bottom: -5, left: 8 }} content={CustomLegend} />

        <ReferenceLine stroke="green" isFront={true} segment={[{ x: supIndex, y: -1 }, { x: supIndex, y: numOfHorseCurrent }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: infIndex, y: -1 }, { x: infIndex, y: numOfHorseCurrent }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: unwIndex, y: -1 }, { x: unwIndex, y: numOfHorseCurrent }]} alwaysShow={true} strokeWidth={2} />

        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: supIndex }, { x: numOfHorseCurrent, y: supIndex }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: infIndex }, { x: numOfHorseCurrent, y: infIndex }]} alwaysShow={true} strokeWidth={2} />
        <ReferenceLine stroke="green" isFront={true} segment={[{ x: -1, y: unwIndex }, { x: numOfHorseCurrent, y: unwIndex }]} alwaysShow={true} strokeWidth={2} />

        <XAxis
          xAxisId={1}
          type="number"
          dataKey="firstHorseNumIndex"
          name="first horse num"
          domain={[1, numOfHorseCurrent]}
          interval={0}
          tickCount={numOfHorseCurrent}
          orientation="bottom"
          style={{ fontSize: "12px" }}
          stroke={getAxisStroke(theme)}
          tickFormatter={(value) => countFormater(value)}
        />
        <Scatter name="percentage" data={fQSignal} isAnimationActive={false} shape={({ cx, cy, index }) => CustomDot(cx, cy, index)} />
      </ScatterChart>
    </ResponsiveContainer>
  ) : null;
};

export default ForecastQuinellaSignalGraphSorted;