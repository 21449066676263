import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next"
import { Layout, Menu } from "antd";
import sampleProfile from "../../assets/media/img/sample-profile.jpg"

const { Sider } = Layout;

export const AccSider = ({ selectedKey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);

  // layout of the antd left nav bar
  return (
    <div
      style={{
        zIndex: 2,
      }}>
      <Sider
        style={{
          width: "100%",
          position: "fixed",
          overflow: "auto",
          overflowX: "hidden",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedKey]}
          defaultOpenKeys={["1"]}
          style={{
            height: "100%",
            borderRight: 0,
            minHeight: "",
            paddingTop: "100px",
          }}
        >
          <p className="AccSider-Welcome-Message">
            {t("welcome")}! {userData.given_name}!
          </p>
          <img
            src={sampleProfile}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "50%",
              padding: "20px",
            }}
            alt=""
          />
          <Menu.Item key="1" onClick={() => navigate('/accountInfo')}>{t('accountInfo')}</Menu.Item>
          <Menu.Item key="2" onClick={() => navigate('/changePassword')}>{t('changePassword')}</Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};
export default AccSider;
