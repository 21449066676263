const JOCKEY_DICTIONARY = {
  "巫顯東": {
    "code": "MHT",
    "alias": "東"
  },
  "賀銘年": {
    "code": "HAA",
    "alias": "賀"
  },
  "莫雅": {
    "code": "MR",
    "alias": "莫"
  },
  "蘇銘倫": {
    "code": "SC",
    "alias": "蘇"
  },
  "薛恩": {
    "code": "SHB",
    "alias": "薛"
  },
  "史卓豐": {
    "code": "SCC",
    "alias": "史"
  },
  "川田將雅": {
    "code": "KAY",
    "alias": "川"
  },
  "莫雷拉": {
    "code": "MOJ",
    "alias": "雷"
  },
  "巴度": {
    "code": "BA",
    "alias": "度"
  },
  "莫丹尼": {
    "code": "MDA",
    "alias": "莫"
  },
  "馬雅": {
    "code": "MMR",
    "alias": "馬"
  },
  "楊明綸": {
    "code": "YML",
    "alias": "綸"
  },
  "波健士": {
    "code": "BV",
    "alias": "波"
  },
  "彭國年": {
    "code": "PT",
    "alias": "彭"
  },
  "麥道朗": {
    "code": "MCJ",
    "alias": "麥"
  },
  "黃俊": {
    "code": "WCV",
    "alias": "黃"
  },
  "潘明輝": {
    "code": "PMF",
    "alias": "明"
  },
  "杜苑欣": {
    "code": "DHA",
    "alias": "杜"
  },
  "巴米高": {
    "code": "BAM",
    "alias": "巴"
  },
  "福永祐一": {
    "code": "FY",
    "alias": "福"
  },
  "連達文": {
    "code": "LDM",
    "alias": "連"
  },
  "嘉里": {
    "code": "CLR",
    "alias": "嘉"
  },
  "戴馬田": {
    "code": "DWM",
    "alias": "戴"
  },
  "陳嘉熙": {
    "code": "CHA",
    "alias": "熙"
  },
  "霍宏聲": {
    "code": "FEL",
    "alias": "霍"
  },
  "萬寧": {
    "code": "MAK",
    "alias": "萬"
  },
  "蔡明紹": {
    "code": "CML",
    "alias": "紹"
  },
  "黃皓楠": {
    "code": "WJH",
    "alias": "楠"
  },
  "梁家俊": {
    "code": "LDE",
    "alias": "俊"
  },
  "希威森": {
    "code": "HEL",
    "alias": "希"
  },
  "黎海榮": {
    "code": "LHW",
    "alias": "海"
  },
  "馬昆": {
    "code": "MTA",
    "alias": "馬"
  },
  "田泰安": {
    "code": "TEK",
    "alias": "田"
  },
  "班德禮": {
    "code": "BHW",
    "alias": "班"
  },
  "周俊樂": {
    "code": "CJE",
    "alias": "周"
  },
  "潘頓": {
    "code": "PZ",
    "alias": "潘"
  },
  "何澤堯": {
    "code": "HCY",
    "alias": "堯"
  },
  "鍾易禮": {
    "code": "CCY",
    "alias": "鍾"
  },
  "蘇兆輝": {
    "code": "DSS",
    "alias": "兆"
  }
}

const TRAINER_DICTIONARY = {
  "蔡約翰": {
    "code": "SJJ",
    "alias": "蔡"
  },
  "蘇保羅": {
    "code": "OSP",
    "alias": "蘇"
  },
  "郗國思": {
    "code": "HW",
    "alias": "郗"
  },
  "霍利時": {
    "code": "FD",
    "alias": "霍"
  },
  "沈集成": {
    "code": "SCS",
    "alias": "沈"
  },
  "丁冠豪": {
    "code": "TKH",
    "alias": "丁"
  },
  "藤原英昭": {
    "code": "FH",
    "alias": "藤"
  },
  "羅迪普": {
    "code": "DRD",
    "alias": "羅"
  },
  "方嘉柏": {
    "code": "FC",
    "alias": "方"
  },
  "容天鵬": {
    "code": "YTP",
    "alias": "容"
  },
  "高野友和": {
    "code": "TTO",
    "alias": "高"
  },
  "松下武士": {
    "code": "MAT",
    "alias": "松"
  },
  "苗禮德": {
    "code": "MA",
    "alias": "苗"
  },
  "羅富全": {
    "code": "LFC",
    "alias": "羅"
  },
  "鮑爾傑": {
    "code": "BJS",
    "alias": "鮑"
  },
  "賀賢": {
    "code": "HAD",
    "alias": "賀"
  },
  "音無秀孝": {
    "code": "OTH",
    "alias": "音"
  },
  "安田隆行": {
    "code": "YT",
    "alias": "安"
  },
  "文家良": {
    "code": "MKL",
    "alias": "文"
  },
  "大衛希斯": {
    "code": "HDA",
    "alias": "斯"
  },
  "徐雨石": {
    "code": "TYS",
    "alias": "石"
  },
  "告東尼": {
    "code": "CAS",
    "alias": "東"
  },
  "呂健威": {
    "code": "LKW",
    "alias": "呂"
  },
  "萩原清": {
    "code": "HAK",
    "alias": "萩"
  },
  "莫爾": {
    "code": "MUW",
    "alias": "莫"
  },
  "鄭俊偉": {
    "code": "CCW",
    "alias": "鄭"
  },
  "堀宣行": {
    "code": "HN",
    "alias": "堀"
  },
  "尾關知人": {
    "code": "OZT",
    "alias": "尾"
  },
  "姚本輝": {
    "code": "YPF",
    "alias": "姚"
  },
  "高伯新": {
    "code": "GR",
    "alias": "高"
  },
  "何良": {
    "code": "HL",
    "alias": "何"
  },
  "矢作芳人": {
    "code": "YY",
    "alias": "矢"
  },
  "葉楚航": {
    "code": "YCH",
    "alias": "葉"
  },
  "韋達": {
    "code": "WDJ",
    "alias": "韋"
  },
  "蘇偉賢": {
    "code": "SWY",
    "alias": "偉"
  },
  "岳伯仁": {
    "code": "OBA",
    "alias": "岳"
  },
  "伍鵬志": {
    "code": "NPC",
    "alias": "伍"
  }
}

export const getJockeyDataByName = (name) => {
  return JOCKEY_DICTIONARY?.[name];
}

export const getTrainerDataByName = (name) => {
  return TRAINER_DICTIONARY?.[name];
}

export const getJockeyCodeByAlias = (alias) => {
  for (const value of Object.values(JOCKEY_DICTIONARY)) {
    if (value.alias === alias) {
      return value.code;
    }
  }
  return null;
}

export const getTrainerCodeByAlias = (alias) => {
  for (const value of Object.values(TRAINER_DICTIONARY)) {
    if (value.alias === alias) {
      return value.code;
    }
  }
  return null;
}

