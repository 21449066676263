import { useRef } from "react";
import { useSelector } from "react-redux";
import { Background, Controls, ReactFlow } from "reactflow";
import dagre from 'dagre';
import "./DecisionTree.css";

const nodeWidth = 250;
const nodeHeight = 120;

const getLayoutedElements = (nodes, edges, direction = 'TB', targetHorse = null) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: direction });

  const newNode = [];
  const newEdge = [];
  nodes.forEach((node) => {
    dagreGraph.setNode(String(node.id), { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(String(edge.source_node_id), String(edge.target_node_id));
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const backgroundColor = (node.label === "Recommended" || node.label === "Reborn") ? "rgb(0, 255, 0)" :
      node.label === "Eliminated" ? "rgb(180, 180, 180)" : "rgb(255, 255, 255)";
    newNode.push({
      id: String(node.id),
      targetPosition: 'top',
      sourcePosition: 'bottom',
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
      style: {
        backgroundColor: backgroundColor
      },
      data: { label: node.label }
    })
  });

  edges.forEach((edge) => {
    const horseNumList = targetHorse ? edge.label?.filter(horseNum => targetHorse?.includes(horseNum)) : edge.label;
    newEdge.push({
      id: String(edge.id),
      source: String(edge.source_node_id),
      target: String(edge.target_node_id),
      animated: horseNumList.length > 0,
      style: {
        stroke: horseNumList.length > 0 ? "rgb(0, 0, 0)" : "rgb(220, 220, 220)",
      },
      className: edge?.highlight && "Edge-Highlighted",
      label: horseNumList?.join(",")
    })
  });

  return { nodes: newNode, edges: newEdge };
};

const DecisionTree = ({ targetHorse }) => {
  const ref = useRef();

  const selectedTimeline = useSelector(state => state.standard.decisionTree.timelineSelected) || null;
  const decisionTree = useSelector(state => state.standard.decisionTree.data?.[selectedTimeline]) || {};

  const { nodes, edges } = getLayoutedElements(decisionTree.nodes || [], decisionTree.edges || [], "TB", targetHorse)

  const text = (
    <div>
      Recommended Horse: {decisionTree?.recommendations?.level_0?.map((horseNum, index) => {
        const isOne = decisionTree?.reborn?.recent_1_percent?.includes(horseNum);
        const isThree = decisionTree?.reborn?.recent_3_percent?.includes(horseNum);
        const style = {
          textDecoration: (isOne || isThree || decisionTree?.reborn?.level_0?.includes(horseNum)) && "underline",
          backgroundColor: isOne ? "rgba(255, 255, 0, 1)" :
            isThree ? "rgba(255, 165, 0, 1)" : "",
          color: isOne ? "rgba(255, 0, 0, 1)" :
            isThree ? "rgba(0, 0, 0, 0.7)" : "",
          margin: "0 4px"
        }
        return <>
          <span style={style}>{horseNum}</span>
        </>
      })}
    </div>
  )

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <div style={{ fontSize: "20px" }}>
        {
          Object.keys(decisionTree).length !== 0 ? text : (<div className="No-Data">No Data</div>)
        }
      </div>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        elementsSelectable={true}
        nodesConnectable={false}
        nodesDraggable={false}
        zoomActivationKeyCode={null}
        fitView
      >
        {/* <MiniMap style={{ height: 120 }} zoomable pannable /> */}
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  )
}

export default DecisionTree;