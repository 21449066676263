import { Row } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JTProfile from "../components/JTInfo/JTProfile";
import JTSelector from "../components/JTInfo/JTSelector";
import PastPerformanceRaceTable from "../components/PastPerformance/PastPerformanceRaceTable";
import { loadAllJockeyData, loadAllTrainerData, loadJTPastPerformance } from "../services/actions/raceAction/jtInfo";

const JTInfo = ({ type, code }) => {
  const dispatch = useDispatch();
  const dataSource = useSelector(state => state.jtInfo?.[type]?.pastPerformance?.[code]?.slice() || []);

  useEffect(() => {
    if (type === "trainer") {
      dispatch(loadAllTrainerData());
    } else {
      dispatch(loadAllJockeyData());
    }
  });

  useEffect(() => {
    if (code) {
      dispatch(loadJTPastPerformance(code, type));
    }
  }, [dispatch, code, type]);

  return (
    <div style={{ padding: "4px 16px" }}>
      <div style={{ textAlign: "left", marginBottom: "8px" }}>
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{type === "trainer" ? "Trainer" : "Jockey"} Information</div>
        <JTSelector type={type} code={code} />
      </div>
      <div>
        <JTProfile type={type} code={code} />
      </div>
      <Row style={{ width: "1460px", marginTop: "4px" }}>
        <PastPerformanceRaceTable dataSource={dataSource} pagination={true} />
      </Row>
    </div>
  )
}

export default JTInfo;
