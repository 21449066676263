import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import Icon from '@ant-design/icons';
import firstRankIcon from '../../../../assets/media/img/first_rank_icon_alt.png';
import secondRankIcon from '../../../../assets/media/img/second_rank_icon_alt.png';
import thirdRankIcon from '../../../../assets/media/img/third_rank_icon_alt.png';
import fourthRankIcon from '../../../../assets/media/img/fourth_rank_icon_alt.png';

const rankIconMapping = {
  1: firstRankIcon,
  2: secondRankIcon,
  3: thirdRankIcon,
  4: fourthRankIcon
}

// mapping between medal icons and horse race results
const getRankIcon = (rank) => {
  if (rankIconMapping?.[rank] == null) {
    return;
  }
  return (
    <Icon component={
      () => (
        <img
          src={rankIconMapping?.[rank]}
          alt={`Rank_[${rank}]`}
          style={{ height: "24px", width: "24px" }}
        />
      )}
    />
  );
};

const getJockeySuitByHorseCode = (horseCode) => {
  return <img src={`https://racing.hkjc.com/racing/content/Images/RaceColor/${horseCode}.gif`} alt="" ></img>
}

const HorseNumColumnOverride = (sortedInfo) => {
  const { t } = useTranslation();

  return {
    // column of medal icons (if any), and horse numbers
    title: t("race::raceNumHeader"),
    align: 'center',
    key: 'horseNum',
    width: 90,
    sorter: {
      compare: (a, b) => a.horseNum - b.horseNum,
      multiple: 1,
    },
    fixed: "left",
    sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === 'horseNum')?.['order'],
    render: (_) => {
      return (
        <div>
          <Row>
            <Col span={5}>
              {getRankIcon(_.rank)}
            </Col>
            <Col span={10}>
              <div style={{ textAlign: 'center', fontSize: 24, fontWeight: "bold" }}>{_.horseNum}</div>
            </Col>
            <Col span={9}>
              {getJockeySuitByHorseCode(_.horseCode)}
            </Col>
          </Row>
          <Row>
            <Col span={9} offset={15}>
              {_.drawNum}
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export default HorseNumColumnOverride;
