import HorseSelector from "./HorseSelector";
import MockInput from "./MockInput";
import TimeLineSelector from "./TimeLineSelector";

const Selector = ({setTargetHorse}) => {
  return (
    <div style={{ border: "1px solid black", height: "100%" }}>
      <TimeLineSelector />
      <hr className="solid" />
      <HorseSelector setTargetHorse={setTargetHorse} />
      <hr className="solid" />
      <MockInput />
    </div>
  )
}

export default Selector;