import { Button } from "antd";

export const TimeColumn = () => {
  const openNewTab = (raceDate, raceNum) => {
    const date = new Date(raceDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const dateText = `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${date.getFullYear()}`;
    const url = `https://racing.hkjc.com/racing/information/Chinese/Racing/DisplaySectionalTime.aspx?RaceDate=${dateText}&RaceNo=${raceNum}`;
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return {
    title: "Time",
    align: 'center',
    key: 'time',
    render: (_) => {
      return (
        <div style={{ margin: "4px" }}>
          <Button onClick={() => openNewTab(_.raceDate, _.raceNum)} className="Week-Button Button" size="small" style={{ marginBottom: "1px" }}>          
            {_.finishInterval || "--"}
          </Button>
        </div>
      )
    }
  }
}