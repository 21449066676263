import { useTranslation } from "react-i18next";
import WinChart from "../standard/graphs/WinChart";

const VerticalRecordColumn = (additionalData, aliasData, code) => {
  const { t } = useTranslation();

  const columns = {
    title: t('race::winHeader'),
    key: 'win',
    align: 'center',
    children: []
  };
  // for each horse, generate a column
  // first row is win curve graph
  for (let i = 1; i <= additionalData.totalNumOfHorses; i++) {
    columns.children.push({
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: "4px" }}>
            <div style={{ textAlign: 'center' }}>{aliasData?.jockey?.[code[i].jockey]}</div>
            <div style={{ textAlign: 'center' }}>{i}</div>
            <div style={{ textAlign: 'center' }}>{aliasData?.trainer?.[code[i].trainer]}</div>
          </div>
        )
      },
      key: i,
      align: 'center',
      width: '7.0%',
      onCell: () => {
        return { className: "Disable-Row-Hover" }
      },
      render: (_) => {
        return (
          <div style={{ height: "160px" }}>
            <WinChart data={_?.[i]?.graphData || []} range={[-30, 0]} showRange={[-20, 0]} />
          </div>
        )
      }
    })
  }
  return columns;
}

export default VerticalRecordColumn;