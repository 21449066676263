import { Table } from "antd";

const RankHistoryTable = ({ dataSource, highlighted }) => {
  const TableColumn = () => {
    return [
      {
        align: 'center',
        className: highlighted,
        key: '1',
        width: '25.0%',
        render: (_) => (
          <span>
            {_.data?.[0] != null ? _.data[0] : '-'}
          </span>
        )
      },
      {
        align: 'center',
        className: highlighted,
        key: '2',
        width: '25.0%',
        render: (_) => (
          <span>
            {_.data?.[1] != null ? _.data[1] : '-'}
          </span>
        )
      },
      {
        align: 'center',
        className: highlighted,
        key: '3',
        width: '25.0%',
        render: (_) => (
          <span>
            {_.data?.[2] != null ? _.data[2] : '-'}
          </span>
        )
      },
      {
        align: 'center',
        className: highlighted,
        key: '4',
        width: '25.0%',
        render: (_) => (
          <span>
            {_.data?.[3] != null ? _.data[3] : '-'}
          </span>
        )
      }
    ];
  }
  return (
    <div style={{ padding: "2px", paddingLeft: "4px", paddingRight: "4px" }}>
      <Table
        pagination={false}
        dataSource={[{ data: dataSource }]}
        columns={TableColumn()}
        style={{ width: "100%", height: "30px" }}
        className="Rank-History-Table"
        showHeader={false}
        rowKey={(record, index) => index}
      />
    </div>
  );
}

export default RankHistoryTable;