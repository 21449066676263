import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetSortedInfo } from '../../services/reducers/standardSlice'
import { Button } from 'antd';
import { RetweetOutlined } from '@ant-design/icons/lib/icons';

const HorseHideInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const resetRaceTable = () => {
    dispatch(resetSortedInfo());
  };

  // reset button on card
  return (
    <div style={{ margin: "2px", fontSize: "16px" }}>
      <Button shape="round" icon={<RetweetOutlined />} size='small' onClick={resetRaceTable} className="Button">{t("reset")}</Button>
    </div>
  )
}

export default HorseHideInfo;