import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ForecastTable } from "../subTables/ForecastTable";
import ForecastQuinellaSignalGraphSorted from "../graphs/ForecastQuinellaSignalGraphSorted";
import DoubleSignalGraph from "../graphs/DoubleSignalGraph";

export const ForecastColumn = () => {
  const { t } = useTranslation();
  const { hideForecastQuinellaSignalGraph, hideForecastTable } = useSelector(
    (state) => state.drawer.standardCardDrawer.hideChart
  );
  const highlightForecast =
    useSelector(
      (state) =>
        state.drawer.standardCardDrawer.highlightColumn.highlightForecast
    ) && "Highlighted-Column";
  const hideForecast =
    useSelector(
      (state) => state.drawer.standardCardDrawer.hideColumn.hideForecast
    ) && "Hidden-Column";

  // column that shows the FORECAST RADAR, FORECAST TABLE and the DOUBLE RADAR
  return {
    title: t("race::forecastQuinellaSignalHeader"),
    className: [highlightForecast, hideForecast],
    align: "center",
    children: [
      {
        title: t("race::forecastHeader"),
        className: [highlightForecast, hideForecast],
        align: "center",
        children: [
          {
            title: t("race::doubleSignalHeader"),
            className: [highlightForecast, hideForecast],
            align: "center",
            key: "forecast",
            width: 220,
            onCell: (_, index) => {
              return {
                rowSpan: index === 0 ? 20 : 0,
                className: "Disable-Row-Hover",
                style: { pointerEvents: "none", zIndex: 1001 },
              };
            },
            render: (_) => {
              return (
                <div>
                  {!hideForecastQuinellaSignalGraph && (
                    <ForecastQuinellaSignalGraphSorted />
                  )}
                  {/* the same table used in forecast card */}
                  {!hideForecastTable && <ForecastTable />}
                  <DoubleSignalGraph />
                </div>
              );
            },
          },
        ],
      },
    ],
  };
};
