import { createSlice } from "@reduxjs/toolkit";

// dont change these names cuz they are used to link to checkboxes actions
const initialData = {
  toggle: false,
  standardCardDrawer: {
    hideInfo: {
      hideRaceTitle: false,
      hideRacePicker: false,
      hideResetButton: false,
      hideRaceInfo: false,
    },

    hideChart: {
      hideForecastQuinellaSignalGraph: false,
      hideForecastTable: false,
      hideDoubleSignalGraph: false,
    },

    hideLine: {
      hideCILine: false,
      hidePoolRatioLine: false,
      hideWinLine: false,
      hideQWLine: false,
    },

    hideColumn: {
      hideForecast: false,
      hideWin: false,
      hideDerivative: false,
      hideHorse: false,
      hideJockey: false,
      hideTrainer: false,
      hideJTFuture: false,
      hideJT: false,
      hideVelocity: false,
    },

    highlightColumn: {
      highlightForecast: false,
      highlightWin: false,
      highlightDerivative: false,
      highlightHorse: false,
      highlightJockey: false,
      highlightTrainer: false,
      highlightJTFuture: false,
      highlightJT: false,
      highlightVelocity: false,
    },

    colors: {
      colorPickertoggle: false,
      // default color of the color picker
      color1: {
        hex: "#39FF14"
      }
    },
    winPlaceGainLimit: 100,
  },

  doubleCardDrawer: {
    hideInfo: {
      hideRaceTitle: false,
      hideRacePicker: false,
      hideResetButton: false,
    },
    currentRace: {
      hideCurrentHorse1: false,
      hideCurrentHorse2: false,
      hideCurrentHorse3: false,
      hideCurrentHorse4: false,
      hideCurrentHorse5: false,
      hideCurrentHorse6: false,
      hideCurrentHorse7: false,
      hideCurrentHorse8: false,
      hideCurrentHorse9: false,
      hideCurrentHorse10: false,
      hideCurrentHorse11: false,
      hideCurrentHorse12: false,
      hideCurrentHorse13: false,
      hideCurrentHorse14: false,
    },
    nextRace: {
      hideNextHorse1: false,
      hideNextHorse2: false,
      hideNextHorse3: false,
      hideNextHorse4: false,
      hideNextHorse5: false,
      hideNextHorse6: false,
      hideNextHorse7: false,
      hideNextHorse8: false,
      hideNextHorse9: false,
      hideNextHorse10: false,
      hideNextHorse11: false,
      hideNextHorse12: false,
      hideNextHorse13: false,
      hideNextHorse14: false,
    },
    hideColorBorder: false,
    highlightGraphsByOddsLimit: {
      low: 10,
      high: 500
    }
  }
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState: initialData,
  reducers: {
    // general for all cards
    setToggle: (state, data) => {
      state.toggle = data.payload;
    },
    resetSetting: (state, data) => {
      state.standardCardDrawer = initialData.standardCardDrawer;
      state.doubleCardDrawer = initialData.doubleCardDrawer;
      // state = initialData. ...;
    },
    setHideInfo: (state, data) => {
      state.standardCardDrawer.hideInfo[data.payload[0]] = data.payload[1];
      state.doubleCardDrawer.hideInfo[data.payload[0]] = data.payload[1];
    },

    // standard card
    setHideChart: (state, data) => {
      state.standardCardDrawer.hideChart[data.payload[0]] = data.payload[1];
    },
    setHideLine: (state, data) => {
      state.standardCardDrawer.hideLine[data.payload[0]] = data.payload[1];
    },
    setHideColumn: (state, data) => {
      state.standardCardDrawer.hideColumn[data.payload[0]] = data.payload[1];
    },
    setHighlightColumn: (state, data) => {
      state.standardCardDrawer.highlightColumn[data.payload[0]] = data.payload[1];
    },
    setWinPlaceGainLimit: (state, data) => {
      state.standardCardDrawer.winPlaceGainLimit = data.payload;
    },

    // double card
    setHideCurrentHorse: (state, data) => {
      state.doubleCardDrawer.currentRace[data.payload[0]] = data.payload[1];
    },
    setHideNextHorse: (state, data) => {
      state.doubleCardDrawer.nextRace[data.payload[0]] = data.payload[1];
    },
    setHideColorBorder: (state, data) => {
      state.doubleCardDrawer.hideColorBorder = !state.doubleCardDrawer.hideColorBorder;
    },
    setDoubleHighlightLowerLimit: (state, data) => {
      state.doubleCardDrawer.highlightGraphsByOddsLimit.low = data.payload;
    },
    setDoubleHighlightUpperLimit: (state, data) => {
      state.doubleCardDrawer.highlightGraphsByOddsLimit.high = data.payload;
    },

    // color picker
    setColorPickerToggle: (state, data) => {
      state.standardCardDrawer.colors.colorPickertoggle = !state.standardCardDrawer.colors.colorPickertoggle;
    },
    closeColorPicker: (state, data) => {
      state.standardCardDrawer.colors.colorPickertoggle = false;
    },
    setColor: (state, data) => {
      state.standardCardDrawer.colors.color1 = data.payload;
    }
  },
});

export const { setToggle, resetSetting, setHideInfo, setHideChart, setHideLine, setHideColumn, setHighlightColumn, setWinPlaceGainLimit, setColorPickerToggle, closeColorPicker, setColor, setHideCurrentHorse, setHideNextHorse, setHideColorBorder, setDoubleHighlightLowerLimit, setDoubleHighlightUpperLimit } = drawerSlice.actions;
export default drawerSlice.reducer;