import { getWeightedCIBackgroundColor } from './CrossColumn';

export const WpCIColumn = () => {
  // column that shows all the WINS ODDS of the NEXT RACE
  return {
    title: "",
    align: 'center',
    children: [
      {
        title: <div style={{ fontSize: "12px" }}>Wp.CI</div>,
        align: 'center',
        key: "wpCI",
        width: '2.5%',
        render: (_) => {
          if (_?.doubleStatisticsRow != null) {
            return <></>;
          }
          return (
            <div style={{ backgroundColor: getWeightedCIBackgroundColor(_.weightedDoubleCI?.winPlaceCi), height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
              {_.weightedDoubleCI?.winPlaceCi?.toFixed(2) || "-"}
            </div>
          )
        }
      }
    ],
  };
}