import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import keycloak from '../services/keycloak';
import LastUpdate from '../components/RaceCard/LastUpdate';
import { RaceSelector } from '../components/RaceCard/RaceSelector';
import WinTable from '../components/RaceCard/win';
import { removeWinData } from '../services/reducers/winSlice';
import useWebSocket from '../services/utils/useWebSocket';
import ControlPanel from '../components/drawers/ControlPanel';
import { handleWinCardEvent } from '../services/actions/raceAction/win';
import API_URL_DOMAIN from '../middleware/BaseURL';

export const WinCard = () => {
  const dispatch = useDispatch();
  const { t, } = useTranslation();
  const DrawerItems = () => {
    return (
      <div>
        a,b,c
      </div>
    )
  }

  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);
  const isReplaying = useSelector(state => state.race.isReplaying);
  const websocketURL = !isReplaying ? `wss://${API_URL_DOMAIN}/ui/v1/ws/win-race-card` : "";

  const { sendMessage } = useWebSocket({
    url: websocketURL,
    requestParam: {
      raceDate: selectedRaceDate,
      raceNum: selectedRaceNum,
      authorization: `${keycloak.token}`
    },
    allowInitialize: !isReplaying && selectedRaceDate != null,
    onMessage: payload => dispatch(handleWinCardEvent(payload, selectedRaceDate, selectedRaceNum)),
    websocketName: "win page",
  });

  // remove last race data when change the selected race date and race num
  useEffect(() => {
    dispatch(removeWinData())
  }, [dispatch, selectedRaceDate, selectedRaceNum]);

  useEffect(() => {
    sendMessage({ "race_date": selectedRaceDate, "race_num": selectedRaceNum });
  }, [sendMessage, selectedRaceDate, selectedRaceNum]);

  return (
    <div>
      <ControlPanel title="Win Card Settings" DrawerItems={DrawerItems} />
      <div style={{ paddingTop: '16px' }}>
        <div style={{ display: "flex" }}>
          <LastUpdate target='win' />
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{t("winCard")}</span>
        </div>
        <RaceSelector />
        <WinTable />
      </div>
    </div>
  )
}

export default WinCard;
