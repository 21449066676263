import { Button, Switch, Table } from "antd";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useReactScreenshot from "../../../services/utils/useReactScreenshot";
import { takeScreenshot } from "../../../services/utils/helper";

const placingMapping = {
  1: {
    color: "rgb(255, 255, 100)",
    text: "Win"
  },
  2: {
    color: "rgb(0, 230, 0)",
    text: "Q"
  },
  3: {
    color: "rgb(200, 200, 255)",
    text: "P"
  },
  4: {
    color: "rgb(190, 190, 190)",
    text: "4th"
  },
}

const winOddsGroupMapping = (value) => {
  if (value <= 3.5) {
    return 1;
  } else if (value <= 8) {
    return 2;
  } else if (value <= 17) {
    return 3;
  } else if (value > 17) {
    return 4;
  }
}

const getColumn = (data, jockeyAlias, trainerAlias, useJockey = true) => {
  const columns = {};
  for (const entry of data) {
    for (const horse of entry.data) {
      const code = useJockey ? horse?.jockey_code : horse?.trainer_code;
      if (columns[code]) {
        continue;
      }
      columns[code] = {
        title: useJockey ? jockeyAlias?.[code] : trainerAlias?.[code],
        align: 'center',
        key: code,
        width: 100,
        render: (_) => {
          const data = _.data?.filter((item) => useJockey ? item?.jockey_code === code : item?.trainer_code === code);
          if (data.length === 0) return (<></>);
          const entires = data.map((entry) => {
            const otherCode = useJockey ? entry?.trainer_code : entry?.jockey_code;
            if (entry?.placing <= 4 && entry?.loss == null) {
              const mappping = placingMapping[Number(entry.placing)];
              return (
                <div style={{ backgroundColor: mappping.color }}>
                  <span style={{ fontWeight: "bold" }}>
                    {mappping.text} T{winOddsGroupMapping(entry?.final_win_odds)}
                  </span>
                  ({useJockey ? trainerAlias?.[otherCode] : jockeyAlias?.[otherCode]})
                </div>
              )
            }
            const loss = (Math.floor(entry?.loss) / 1000).toFixed(0);
            if ((useJockey ? entry?.trainer_code : entry.jockey_code) == null) {
              return (
                <div>
                  {loss}
                </div>
              )
            }
            return (
              <div style={{ backgroundColor: placingMapping[entry?.placing]?.color }}>
                {loss} ({useJockey ? trainerAlias?.[otherCode] : jockeyAlias?.[otherCode]})
              </div>
            )
          });
          return (
            <>
              {entires}
            </>
          )
        }
      }
    }
  }
  return Object.values(columns);
}

const getTotal = (data, useJockey) => {
  const result = {};
  for (const entry of data) {
    for (const horse of entry.data) {
      const code = useJockey ? horse?.jockey_code : horse?.trainer_code;
      if (horse?.is_winner) continue;
      if (horse?.placing === 2 && horse?.loss == null) continue;
      if (result[code]) {
        result[code].loss += horse?.loss;
        continue;
      }
      result[code] = {
        loss: horse?.loss,
      }
      result[code][useJockey ? "jockey_code" : "trainer_code"] = code;
    }
  }

  return Object.values(result);
}

const LossDetailTable = () => {
  const selectedRaceDate = useSelector(state => state.race.selectedRaceDate);
  const selectedRaceNum = useSelector(state => state.race.selectedRaceNum);
  const dataSourceRaw = useSelector(state => state.standard.resultData.lossDetail);
  const jockeyAlias = useSelector(state => state.standard.additionalData.jockeyAlias);
  const trainerAlias = useSelector(state => state.standard.additionalData.trainerAlias);

  const [screenshotLock, setScreenshotLock] = useState(false);
  const [useJockey, setUseJockey] = useState(true);

  const ref = useRef(null)

  const { getScreenshot } = useReactScreenshot();

  if (dataSourceRaw.length === 0) return (<></>);

  const dataSource = Object.entries(dataSourceRaw).map(([key, value]) => { return { raceNum: key, data: value } });

  const mutliColumns = getColumn(dataSource, jockeyAlias, trainerAlias, useJockey);
  dataSource.push({
    raceNum: "Total Loss",
    data: getTotal(dataSource, useJockey),
  });

  const columns = [
    {
      title: 'Race',
      align: 'center',
      key: 'race',
      width: 50,
      fixed: "left",
      render: (_) => {
        return (
          <div>
            {_?.raceNum}
          </div>
        )
      }
    },
    ...mutliColumns,
  ];

  const width = (mutliColumns.length + 1) * 100;

  return (
    <div style={{ width: `${width}px`, maxWidth: "95vw", margin: "0px 24px", marginBottom: "12px" }} ref={ref}>
      <div style={{ textAlign: "left", fontSize: "24px", fontWeight: "bold" }}>
        Loss Detail Table
        <Switch
          checkedChildren="Jockey"
          unCheckedChildren="Trainer"
          defaultChecked
          onChange={() => setUseJockey(!useJockey)}
          checked={useJockey}
          style={{ marginLeft: "6px" }}
        />
        <span>
          {[1, 2, 3, 4].map(v => (
            <span style={{ fontSize: "16px", marginLeft: "4px", backgroundColor: placingMapping[v].color }}>{v}</span>
          ))}
        </span>
        <Button
          style={{ marginLeft: "8px" }}
          onClick={() => {
            if (screenshotLock) return;
            setScreenshotLock(true);
            setTimeout(() => {
              takeScreenshot(ref, getScreenshot, `${selectedRaceDate} #${selectedRaceNum}`);
              setScreenshotLock(false);
            }, 1000);
          }}
          className="Button"
          size="small"
        >
          Take screenshot
        </Button>
      </div>
      <div style={{ width: "inherit", maxWidth: "95vw" }}>
        <Table
          pagination={false}
          loading={dataSource.length === 0}
          dataSource={dataSource}
          columns={columns}
          scroll={{ x: "99%" }}
          className="Loss-Detail-Table"
          rowKey={(record, index) => index}
        />
      </div>
    </div>
  )
};

export default LossDetailTable;
