import { Col, Row, Table } from "antd";
import { useSelector } from "react-redux";

const getColumns = (keys) => {
  const getColumn = (key) => {
    return {
      title: <div style={{ fontSize: "10px" }}>{key}</div>,
      align: 'center',
      key: key,
      width: "20%",
      render: (_) => {
        return (
          <div>
            {_?.[key] ? (_?.[key] * 100)?.toFixed(1) : "--"}%
          </div>
        )
      },
    }
  }
  return keys.map(key => getColumn(key));
}

const QuartetTable = () => {
  const dataSourceQuartet = [useSelector(state => state.standard.additionalData.quartetStatistics)] || [];
  const dataSourceTierce = [useSelector(state => state.standard.additionalData.tierceStatistics)] || [];

  return (
    <div style={{ height: "20px", width: "360px", position: "absolute", top: "190px", left: "41vw" }}>
      <Row>
        <Col span={1}>
          <span style={{ writingMode: "vertical-rl", fontSize: "10px", textOrientation: "revert" }}>Quartet</span>
        </Col>
        <Col span={23}>
          <Table
            pagination={false}
            dataSource={dataSourceQuartet}
            columns={getColumns(["<1000", "1000-2000", "2000-5000", "5000-10000", ">10000"])}
            rowKey={(record) => 1}
            className="Quartet-Table"
          />
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <span style={{ writingMode: "vertical-lr", fontSize: "10px" }}>Tierce</span>
        </Col>
        <Col span={23}>
          <Table
            pagination={false}
            dataSource={dataSourceTierce}
            columns={getColumns(["<125", "125-250", "250-500", "500-1000", ">1000"])}
            rowKey={(record) => 1}
            className="Tierce-Table"
          />
        </Col>
      </Row>
    </div>
  )
}

export default QuartetTable; 
