import { AudioOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { appendUserMessage } from "../../services/reducers/chatSlice";

const Footer = ({ sendMessage, currentSection, connected }) => {
  const dispatch = useDispatch();

  const waitForNewMessage = useSelector(state => Object.values(state.chat).some(section => section.waitForNewMessage === true));
  const disabled = (connected === false);

  const {
    transcript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [input, setInput] = useState("");

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const onClickSendMessage = () => {
    if (input === "") {
      return;
    }
    setInput("");
    if (disabled) {
      return;
    }
    dispatch(appendUserMessage({ input, currentSection }));
      sendMessage({
        command: "send_new_user_message",
        payload: {
          message: input,
          type: currentSection
        }
      });
  }

  return (
    <div
      style={{
        height: "7%",
        padding: "4px 4px",
        display: "flex",
        justifyContent: "space-between",
        columnGap: "4px",
        alignItems: "center",
      }}
    >
      <Input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder="Type a prompt ..."
        disabled={waitForNewMessage || disabled}
        onKeyDown={(event) => event.key === "Enter" && onClickSendMessage()}
        size="large"
      />
      <Button
        onMouseDown={SpeechRecognition.startListening}
        onMouseUp={() => {
          SpeechRecognition.stopListening();
          setInput(transcript);
        }}
        size="large"
        icon={<AudioOutlined />}
        disabled={waitForNewMessage || disabled}
      />
      <Button
        size="large"
        icon={<SendOutlined />}
        onClick={onClickSendMessage}
        disabled={waitForNewMessage || disabled}
      />
    </div>
  )
}

export default Footer;