import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 

import { useKeycloak } from '@react-keycloak/web'; 
import useInterval from '../services/utils/useInterval';
import { setTheme } from '../services/reducers/generalSlice';
import { refreshTokenAPI } from '../services/actions/authAction';
import Cookies from 'js-cookie'

import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

const Rroutes = () => {
  const { keycloak, } = useKeycloak();
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.auth.authenticated)

  useEffect(() => {
    const theme = Cookies.get('theme');
    (theme) && (dispatch(setTheme(theme)));
  }, [dispatch])

  useInterval(() => {
    dispatch(refreshTokenAPI(keycloak));
  }, 5 * 1000 * 60);

  return authenticated ? <AuthenticatedRoute /> : <UnauthenticatedRoute />;
}

export default Rroutes;