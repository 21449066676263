import { useDispatch, useSelector } from "react-redux";
import { setHighlightedRaceId, resetHighlightedRaceId } from "../../services/reducers/pastPerformanceSlice";

const IdHighlight = ({ value }) => {
  const dispatch = useDispatch();
  const { highlightedRaceId } = useSelector(state => state.pastPerformance.additionalData);

  const onMouseEnter = () => {
    if (highlightedRaceId.value !== value) {
      dispatch(setHighlightedRaceId({ id: value, onClick: false }));
    }
  }

  const onMouseClick = () => {
    if (!highlightedRaceId.onClick) {
      dispatch(setHighlightedRaceId({ id: value, onClick: true }));
    } else {
      if (highlightedRaceId.value !== value) {
        dispatch(setHighlightedRaceId({ id: value, onClick: true }));
      } else {
        dispatch(resetHighlightedRaceId(true));
      }
    }
  }

  const onMouseLeave = () => {
    if (highlightedRaceId.value === value) {
      dispatch(resetHighlightedRaceId(false));
    }
  }

  const getClassName = () => {
    return highlightedRaceId.value === value ? "Highlighted-Name" : "";
  }

  return (
    <span
      className={getClassName()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onMouseClick}
    >
      {value}
    </span>
  )
}

export default IdHighlight;
