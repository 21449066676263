const ColdDoorStatisticsColumn = (sortedInfo) => {
  return {
    title: <div style={{ fontSize: "14px" }}>{"Cold Door\nStat"}</div>,
    align: 'center',
    key: `coldDoorStatistics`,
    width: 30,
    render: (_) => {
      const coldDoorStatistics = _?.coldDoorStatistics;
      return (
        <div>
          <div
            style={
              {
                borderBottom: "1px solid rgba(0, 0, 0, 0.5)"
              }
            }
          >
            {coldDoorStatistics?.jockey?.win_percentage != null ? (coldDoorStatistics.jockey.win_percentage * 100).toFixed(1) : "-"}%
          </div>
          <div>
            {coldDoorStatistics?.trainer?.win_percentage != null ? (coldDoorStatistics.trainer.win_percentage * 100).toFixed(1) : "-"}%
          </div>
        </div>
      )
    }
  }
}

export default ColdDoorStatisticsColumn;
