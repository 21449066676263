import { Button, Modal } from "antd";
import { useState } from "react";
import { handleShowJTEntries } from "../../services/actions/raceAction/entries";
import JTPastModalTable from "./jtPastmodalTable";

const JTPastModal = ({ value, code, showJockey, sendMessage}) => {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    handleShowJTEntries(sendMessage, code, showJockey);
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button
          onClick={showModal}
          style={{ padding: "5px" }}
          className="Button"
        >
          {value}
        </Button>
      <Modal
        title={
          <div>
            Past 6 race date for {value}
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"95%"}
      >
        <JTPastModalTable showJockey={showJockey} code={code} />
      </Modal>
    </div>
  )
}

export default JTPastModal;
