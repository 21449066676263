import { List, Row, Col, } from "antd";
import { useSelector } from "react-redux";
import { Fraction } from "fractional";
import Icon from '@ant-design/icons';

import firstRankIcon from '../../../assets/media/img/first_rank_icon_alt.png';
import secondRankIcon from '../../../assets/media/img/second_rank_icon_alt.png';
import thirdRankIcon from '../../../assets/media/img/third_rank_icon_alt.png';
import fourthRankIcon from '../../../assets/media/img/fourth_rank_icon_alt.png'
import { getFraction } from "../../../services/utils/fraction";

const rankIconMapping = {
  1: firstRankIcon,
  2: secondRankIcon,
  3: thirdRankIcon,
  4: fourthRankIcon
}

// mapping between medal icons and horse race results
const getRankIcon = (rank) => {
  if (!rankIconMapping?.[rank] || rankIconMapping[rank] > 4) {
    return (
      <div style={{ marginTop: "8px" }}>
        <span style={{ textDecoration: "underline", fontSize: "28px" }}>{rank}</span>
      </div>
    );
  }
  return (
    <Icon component={
      () => (
        <img
          src={rankIconMapping?.[rank]}
          alt={`Rank_[${rank}]`}
          style={{ height: "32px", width: "32px", marginTop: "8px" }}
        />
      )}
    />
  );
};

const getLengthBehindFraction = (lengthBehindWinner) => {
  if (lengthBehindWinner == null) {
    return [null, null, null];
  }
  const integar = Math.floor(lengthBehindWinner)
  const fraction = new Fraction(lengthBehindWinner - integar);
  return [integar, fraction.numerator, fraction.denominator];
}

const HorseResultEntry = ({ data, hottestList, winPool }) => {
  const lengthBehind = getLengthBehindFraction(data.lengthBehindWinner);
  const loss = (hottestList.includes(Number(data.horseNum)) && data.rank > 1) ? winPool * 0.825 / data.finalWinOdds : null;

  return (
    <div>
      <Row gutter={[0, -100]}>
        <Col span={1}>
          {getRankIcon(data.rank)}
        </Col>
        <Col span={2}>
          <div style={{ marginTop: "8px" }}>
            <span style={{ fontWeight: "bold", fontSize: "28px" }}>{data.horseNum}</span>
          </div>
        </Col>
        <Col span={3} style={{ paddingRight: "10px" }}>
          <span style={{ fontWeight: "bold" }}>{data.horseName}</span>
          <div style={{ margin: "-10px 0 -10px 0" }}>
            <span className="Minor-Line">{data.wgt}</span>
          </div>
        </Col>
        <Col span={3}>
          <span>{data.jockeyName}</span>
          <div style={{ margin: "-10px 0 -10px 0" }}>
            <span className="Minor-Line">{data.horseHandicapWeight}</span>
          </div>
        </Col>
        <Col span={3}>
          <span>{data.trainerName}</span>
        </Col>
        <Col span={1}>
          <div style={{ borderStyle: "ridge", marginTop: "8px" }}>
            <span>{data.drawNum}</span>
          </div>
        </Col>
        <Col span={5}>
          <div style={{ marginTop: "2px", fontSize: "16px" }}>
            <span>{data?.runningPositions?.join(" ")} (+{lengthBehind?.[0]}{getFraction(lengthBehind?.[1], lengthBehind?.[2])})</span>
          </div>
          <div style={{ marginTop: "2px", fontSize: "16px" }}>
            <span>{data.runningDuration}</span>
          </div>
        </Col>
        <Col span={2}>
          <div style={{ marginTop: "8px" }}>
            <sup style={{ padding: "2px" }}>win</sup>
            <span style={{ marginTop: "8px" }}>{data.finalWinOdds || -1}</span>
          </div>
        </Col>
        <Col span={2}>
          <div style={{ marginTop: "8px" }}>
            <sup style={{ padding: "2px" }}>ci</sup>
            <span style={{ marginTop: "8px" }}>{data.confidentIndex.latest || -1}</span>
          </div>
        </Col>
        <Col span={2}>
          {loss != null && (
            <div style={{ marginTop: "8px" }}>
              <sup style={{ padding: "2px" }}>L</sup>
              <span style={{ marginTop: "8px" }}>{(loss / 1000).toFixed(0)}K</span>
            </div>
          )}
        </Col>
      </Row>
    </div>
  )
}

export const HorseResultList = () => {
  const dataSource = useSelector(state => Object.values(state.standard.detailData));
  const hottestList = useSelector(state => (state.standard.additionalData.hottestList || []).slice(0, 3));
  const winPool = useSelector(state => state.standard.info.winPoolAmount);

  dataSource.sort((a, b) => {
    if (a?.scratched === true) return 1;
    if (b?.scratched === true) return -1;
    return a.rank - b.rank;
  });

  return (
    <List
      itemLayout="horizontal"
      loading={dataSource.length === 0}
      dataSource={dataSource}
      renderItem={(data) => (
        <List.Item className="Result-Horses-List-Item">
          <HorseResultEntry data={data} hottestList={hottestList} winPool={winPool} />
        </List.Item>
      )}
      className="Result-Horses-List"
    />
  );
}