import { Button, Col, Radio, Row, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { copyBetlineToClipboard } from "../../../services/actions/raceAction/roadmap";
import "./CopySection.css";

const CopySection = () => {
  const [bankerChoice, setBankerChoice] = useState("top");
  const [selectionValue, setSelectionValue] = useState(5);

  const totalNumOfHorse = useSelector(state => state.standard.additionalData.numOfHorseCurrent);

  const onBankerChoiceChange = (e) => {
    setBankerChoice(e.target.value);
  };

  const onSelectionValueChange = (value) => {
    setSelectionValue(value);
  };

  const onFinalClick = (pool) => {
    copyBetlineToClipboard(pool, bankerChoice, Number(selectionValue));
  }

  return (
    <div className="CopySection">
      <Row>
        <Col span={2}>
          <span style={{ fontWeight: "bold" }}>Banker:</span>
        </Col>
        <Col span={5} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.2)" }}>
          <Radio.Group onChange={onBankerChoiceChange} value={bankerChoice}>
            <Radio value={"top"} className="Radio-Button">Top</Radio>
            <Radio value={"secondTop"} className="Radio-Button">Second Top</Radio>
          </Radio.Group>
        </Col>
        <Col span={2}>
          <span style={{ fontWeight: "bold" }}>Selection:</span>
        </Col>
        <Col span={2} style={{ borderRight: "1px solid rgba(0, 0, 0, 0.2)" }}>
          <Select
            size={"small"}
            defaultValue={5}
            options={[...Array(totalNumOfHorse).keys()].map(i => ({ value: i + 1, label: i + 1 }))}
            style={{ padding: "0" }}
            onChange={onSelectionValueChange}
            value={selectionValue}
          />
        </Col>
        <Col span={2}>
          <Button className="Button" size="small" onClick={() => onFinalClick(null)}>No Pool</Button>
        </Col>
        <Col span={1}>
          <Button className="Button" size="small"  onClick={() => onFinalClick("tb")}>tb</Button>
        </Col>
        <Col span={1} style={{ marginRight: "4px" }}>
          <Button className="Button" size="small"  onClick={() => onFinalClick("tbm")}>tbm</Button>
        </Col>
        <Col span={1}>
          <Button className="Button" size="small"  onClick={() => onFinalClick("qb")}>qb</Button>
        </Col>
        <Col span={1} style={{ marginRight: "4px" }}>
          <Button className="Button" size="small"  onClick={() => onFinalClick("qbm")}>qbm</Button>
        </Col>
      </Row>
    </div>
  );
}

export default CopySection;
