import { Button, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMockDecisionTreeHandling } from "../../../services/reducers/standardSlice";

const MockInput = () => {
  const dispatch = useDispatch();

  const [text, setText] = useState();

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
    dispatch(setMockDecisionTreeHandling(text));
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={showModal}
        style={{ width: "auto", fontSize: "16px" }}
        className="Button"
        size="small"
      >
        Mock Test
      </Button>
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            Prompt
          </div>
        }
        visible={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea value={text} onChange={value => setText(value.target.value)} rows={6} placeholder="Drop your prompt here" />
      </Modal>
    </div>
  )
}

export default MockInput;