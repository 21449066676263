import { Popover } from "antd";
import { useSelector } from "react-redux";

const sumAllRequiredScore = (sumKey, scores, givenScores) => {
  return sumKey.reduce((prev, curr) => prev + (scores?.[curr] || 0) + (givenScores?.[curr] || 0), 0);
}

const HoverWindow = ({content}) => {
  return (
    <div>
      {content}
    </div>
  )
}

const SubTotalScoreColumn = (columnName, sortedInfo, sumKey) => {
  const scoreData = useSelector(state => state.standard.userData?.scoreData || {});
  const userScores = useSelector(state => state.standard.userData?.userData?.score || {});

  // const keyValueSum = sumAllRequiredScore(sumKey, scores, givenScores);
  return {
    title: <span style={{ fontSize: "10px" }}>SubTotal</span>,
    align: 'center',
    key: `subTotalScore_${columnName}`,
    children: [
      {
        title: <Popover content={<HoverWindow content={columnName} />} placement="bottom"><div style={{ fontSize: "10px" }}>Hover</div></Popover>,
        align: 'center',
        key: `subTotalScore_${columnName}`,
        width: 45,
        sorter: {
          compare: (a, b) => {
            if (a?.scratched === true) return 1;
            if (b?.scratched === true) return -1;
            const sumA = sumAllRequiredScore(sumKey, userScores?.[Number(a.horseNum)], scoreData?.[Number(a.horseNum)]);
            const sumB = sumAllRequiredScore(sumKey, userScores?.[Number(b.horseNum)], scoreData?.[Number(b.horseNum)]);
            return (sumB || 0) - (sumA || 0);
          },
          multiple: 1,
        },
        sortOrder: sortedInfo?.find(({ columnKey }) => columnKey === `subTotalScore_${columnName}`)?.['order'],
        onCell: () => {
          return { style: { backgroundColor: "rgba(255, 220, 220)" } };
        },
        render: (_) => {
          return (
            <div style={{ fontWeight: "bold" }}>
              {sumAllRequiredScore(sumKey, userScores?.[Number(_.horseNum)], scoreData?.[Number(_.horseNum)])}
            </div>
          )
        }
      }
    ]
  }
}

export default SubTotalScoreColumn;